import { Directive, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AssessmentSummary,
  AssessmentToBeAssignedCard,
  AssessmentWithContractorUpdatesCard,
  CompletedAssessmentsCard,
  InProgressAssessmentCards,
  NewAssessmentCard,
  ProgressCard,
  ProgressStats,
  ResubmitsCard,
  SearchArgs,
} from '../../index';
import { SearchFilterComponent } from '../../../features/assessor-dashboard/search-filter/search-filter.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { SearchResultTableComponent } from '../../../features/assessor-dashboard/search-result-table/search-result-table.component';
import { OpsReportingStatus } from '../../../core/services/assessment/assessment.service';
import { DataTableAbstract } from '../data-table-abstract/data-table-abstract';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { ProgressCardData } from 'src/app/shared/models/progressCards/progress-card-data';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUserAccountId } from '../../../core/ngrx/selectors/currentUser.selectors';
import { DataRequestTypeEnum } from '../../enums/data-request-type-enum';
import { ExpiryFilter } from '../../enums/expiry-filter';
import { SubmittedForQACard } from '../../models/progressCards/submitted-for-qa-card';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { PermissionsEnum } from '../../enums/permissions-enum';

@Injectable()
@Directive()
export abstract class AssessorDashboardAbstract extends DataTableAbstract implements OnInit, OnDestroy {
  @ViewChild(SearchFilterComponent) searchFilterComponent: SearchFilterComponent;
  @ViewChild(SearchResultTableComponent) searchResultTableComponent: SearchResultTableComponent;

  progressCards: ProgressCard[] = [];
  contractorName: string;
  searchArgs: SearchArgs;
  assessmentServiceSubscription: Subscription;
  // So any derived classes can have a different default grid
  public apiRequestType = ApiRequestTypes.NewAssessments;
  protected userId: string;
  private expiryfilter: ExpiryFilter;

  protected constructor(
    protected dialog,
    protected searchService,
    protected router,
    protected assessmentService,
    protected store,
    protected permissionsService: PermissionsService,
    protected actions,
    protected accountService,
    protected bundleService,
    protected alertsService,
    protected activatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );

    this.isAssessorAccount = true;
    this.showCheckbox = false;
  }

  ngOnDestroy(): void {}

  public ngOnInit(): void {
    this.dataRequestType = DataRequestTypeEnum.Assessment;
    void this.retrieveDataRequest(this.uiFilter, this.apiRequestType, this.assessmentListScope);
  }

  public retrieveAssessorLevel(assessorRole: string) {
    this.assessmentService.retrieveAssessorAccount(assessorRole);
    this.retrieveAssessments();
  }

  public async onProgressCardSelection(progressStats: ProgressStats): Promise<void> {
    this.apiRequestType = progressStats.apiRequestType;
    this.expiryfilter = progressStats.expiryFilter;
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType, this.assessmentListScope, this.expiryfilter);
    if (this.searchResultTableComponent.header) this.searchResultTableComponent.header = progressStats.label;
    this.searchFilterComponent.resetSearchArgs();
    this.searchFilterComponent.resetFilterSelection();
  }

  protected retrieveAssessments(): void {
    this.assessmentServiceSubscription = this.assessmentService
      .getAssessmentProgress()
      .subscribe((assessmentSummary: Map<string, AssessmentSummary[]>) => this.createProgressCards(assessmentSummary));
  }

  protected async createProgressCards(assessmentGroups: Map<string, AssessmentSummary[]>): Promise<void> {
    const progressCards: ProgressCard[] = [];

    if (assessmentGroups) {
      for (const entry of Object.entries(assessmentGroups)) {
        const reportingStatus = entry[0] as OpsReportingStatus;

        if (
          reportingStatus === OpsReportingStatus.SubmittedForQA &&
          !(await this.permissionsService.hasPermission(PermissionsEnum.YourQAAssessments))
        ) {
          continue;
        }

        const progressCard = this.createProgressCard(entry[0] as OpsReportingStatus, entry[1]);
        if (progressCard !== undefined) {
          progressCards.push(progressCard);
        }
      }
    }

    this.progressCards = progressCards;
  }

  protected async getUserId(): Promise<void> {
    this.userId = await firstValueFrom(this.store.select(selectCurrentUserAccountId));
  }

  private createProgressCard(opsReportingStatus: OpsReportingStatus, progressCardData: ProgressCardData): ProgressCard {
    switch (opsReportingStatus) {
      case OpsReportingStatus.New:
        return new NewAssessmentCard(progressCardData);
      case OpsReportingStatus.InProgress:
        return new InProgressAssessmentCards(progressCardData);
      case OpsReportingStatus.AdditionalInfoRequired:
        return new AssessmentWithContractorUpdatesCard(progressCardData);
      case OpsReportingStatus.ToBeAssigned:
        return new AssessmentToBeAssignedCard(progressCardData);
      case OpsReportingStatus.Resubmitted:
        return new ResubmitsCard(progressCardData);
      case OpsReportingStatus.SubmittedForQA:
        return new SubmittedForQACard(progressCardData);
      case OpsReportingStatus.Completed:
        return new CompletedAssessmentsCard(progressCardData);
      default:
        return undefined;
    }
  }

  async runSearch($event: any): Promise<void> {
    const search = Object.keys($event);

    if (this.uiFilter) this.uiFilter = this.uiFilter.filter((item) => !search.includes(item.property.toString()));
    else this.uiFilter = [];

    Object.keys($event).map((key) => this.uiFilter.push({ property: key, value: $event[key] }));
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType, this.assessmentListScope, this.expiryfilter);
    this.searchArgs = $event;
  }
}
