<div class="text-center" *ngIf="isLoading$ | async; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
  <div>
    <div class="search-div">
      <mat-form-field class="search-form-field" floatLabel="never">
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="Search"
          autocomplete="off"
          (keyup)="applyFilter()"
          trim
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onSearchClear()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="name"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>Number</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.productNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="defaultPrice">
          <th mat-header-cell *matHeaderCellDef>Default Price</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.default }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button mat-icon-button (click)="assessmentSelected(row.id)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="onDelete(row)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pazeSize]="12"
        [pageSizeOptions]="[12, 100]"
      ></mat-paginator>
    </div>
  </div>
</ng-template>
