<div mat-dialog-content>
  <p>Choose assessor</p>
  <mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select [(ngModel)]="selectedAssessor">
      <mat-option *ngFor="let assessor of assessors" [value]="assessor.id">
        {{ assessor.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [disabled]='showLoadingBar' (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    [disabled]='showLoadingBar'
    (click)="assignAssessments()">
    Assign
  </button>
  <mat-progress-bar *ngIf='showLoadingBar' mode="query"></mat-progress-bar>
</div>
