<ng-content></ng-content>
<form [formGroup]='parent' novalidate>
	<div class='form-group row'>
		<label class='col-md-5'>Address1</label>
		<div class='col-md-7'>
			<div>
				<input
					   [readonly]='readOnly$ | async'
					   class='form-control'
					   formControlName='street1'
					   id='Address1'
					   title='Address1'
					   type='text'
				/>
			</div>

		</div>
	</div>
	<div class='form-group row'>
		<label class='col-md-5'>Address2</label>
		<div class='col-md-7'>
			<div>
				<input
					[readonly]='readOnly$ | async'
					class='form-control'
					formControlName='street2'
					id='Address2'
					title='Address2'
					type='text'
				/>
			</div>
		</div>
	</div>
	<div class='form-group row'>
		<label class='col-md-5'>Address3</label>
		<div class='col-md-7'>
			<div>
				<input

					[readonly]='readOnly$ | async'
					class='form-control'
					formControlName='street3'
					id='Address3'
					title='Address3'
					type='text'
				/>
			</div>
		</div>
	</div>
	<div class='form-group row'>
		<label class='col-md-5'>City</label>
		<div class='col-md-7'>
			<div>
				<input
					[readonly]='readOnly$ | async'
					class='form-control'
					formControlName='city'
					id='city'
					title='city'
					type='text'
				/>
			</div>
		</div>
	</div>
	<div class='form-group row'>
		<label class='col-md-5'>State/Province</label>
		<div class='col-md-7'>
			<div>
				<input
					[readonly]='readOnly$ | async'
					class='form-control'
					formControlName='state'
					id='state'
					title='state'
					type='text'
				/>
			</div>
		</div>
	</div>
	<div class='form-group row'>
		<label class='col-md-5'>ZIP/Postal Code</label>
		<div class='col-md-7'>
			<div>
				<input
					[readonly]='readOnly$ | async'
					class='form-control'
					formControlName='postCode'
					id='PostCode'
					title='PostCode'
					type='postCode'
				/>
			</div>
		</div>
	</div>
	<div class='form-group row'>
		<label class='col-md-5'>Country/Region</label>
		<div class='col-md-7'>
			<div>
				<input
					[readonly]='readOnly$ | async'
					class='form-control'
					formControlName='county'
					id='county'
					title='county'
					type='text'
				/>
			</div>
		</div>
	</div>
</form>
