import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-helptip",
  templateUrl: "./helptip.component.html",
  styleUrls: ["./helptip.component.scss"],
})
export class HelptipComponent implements OnInit {
  @Input() displayText: string;
  @Input() popoverText: string;

  constructor() {}

  ngOnInit(): void {}
}
