export class FirAccreditationLevel {
  private static readonly GettingStarted = 1;
  private static readonly PocketsGoodPractice = 2;
  private static readonly Strategic = 3;
  private static readonly Embedded = 4;
  private static readonly ExternalInfluencer = 5;
  private static readonly None = 0;

  public static resolveLevel(level: string): number {
    switch (level) {
      case "getting started":
        return FirAccreditationLevel.GettingStarted;
      case "pockets of good practice":
        return FirAccreditationLevel.PocketsGoodPractice;
      case "strategic":
        return FirAccreditationLevel.Strategic;
      case "embedded":
        return FirAccreditationLevel.Embedded;
      case "external influencer":
        return FirAccreditationLevel.ExternalInfluencer;
      default:
        return FirAccreditationLevel.None;
    }
  }
}
