import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkCategory } from 'src/app/shared/models/Work-Category';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class WorkCategoryHttpService {
  constructor(private http: HttpClient, private readonly api: ApiRoutesClass) {}

  public getWorkCategoriesList(): Observable<WorkCategory[]> {
    return this.http.get<WorkCategory[]>(`${this.api.WorkCategories()}/GetWorkCategoriesList`);
  }

  public GetWorkCategoryById(id: String): Observable<WorkCategory> {
    return this.http.get<WorkCategory>(`${this.api.WorkCategories()}/GetWorkCategoryById/${id}`);
  }

  public CreateOrUpdateWorkCategory(module: WorkCategory): Observable<any> {
    return this.http.post<WorkCategory>(`${this.api.WorkCategories()}/CreateOrUpdateWorkCategory`, module);
  }
}
