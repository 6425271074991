import * as AppState from '../../../State';
import { createReducer, on } from '@ngrx/store';
import { CountdownTimerModel } from '../../../shared/models/assessment-countdown.model';
import * as countdownTimerActions from '../actions/assessmentCountdownTimer.actions';

export interface State extends AppState.State {
  countDown: CountdownState;
}
export interface CountdownState {
  assessment: CountdownTimerModel;
  verification: CountdownTimerModel;
}

export interface CountdownErrorState extends CountdownState {
  loading: boolean;
  error: object;
}

const initialState: CountdownState = {
  assessment: {
    bundleId: '',
    bundleName: '',
    respondByDateTime: null,
    assessmentIds: [],
  },
  verification: {
    bundleId: '',
    bundleName: '',
    respondByDateTime: null,
    assessmentIds: [],
  },
};

export const countdownTimerReducer = createReducer<CountdownState>(
  initialState,
  on(countdownTimerActions.countdownTimerLoad, (state, action): CountdownState => {
    return {
      ...state,
    };
  }),
  on(countdownTimerActions.countdownTimerLoadSuccess, (state, action): CountdownState => {
    return {
      ...state,
      assessment: { ...action.assessment },
      verification: { ...action.verification },
    };
  }),
  on(countdownTimerActions.countdownTimerLoadError, (state, action): CountdownErrorState => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
);
