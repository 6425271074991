import { createAction, props } from "@ngrx/store";
import { QuestionSetTable } from "src/app/shared/models/QuestionSetTable";

export enum QuestionSetTableActions {
  Load = "[QuestionSet Table] Load",
  LoadSuccess = "[QuestionSet Table] Load Successful",
  LoadError = "[QuestionSet Table] Load Error",
  Create = "[Module Create] Create",
}

export const QuestionSetTableLoad = createAction(
  QuestionSetTableActions.Load,
  props<{ accountId: string }>()
);
export const QuestionSetTableLoadSuccess = createAction(
  QuestionSetTableActions.LoadSuccess,
  props<{ modules: QuestionSetTable[] }>()
);
export const QuestionSetTableLoadError = createAction(
  QuestionSetTableActions.LoadError,
  props<{ error: string }>()
);

export const ModuleCreateSuccess = createAction(
  QuestionSetTableActions.Create,
  props<{ module: QuestionSetTable }>()
);
