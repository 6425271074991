import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Survey } from "survey-angular";

@Component({
  selector: "app-fail-modal",
  templateUrl: "./fail-modal.component.html",
  styleUrls: ["./fail-modal.component.scss"],
})
export class FailModalComponent implements OnInit {
  public newStatus: number;
  public survey: Survey;
  public modalComment: string = "";

  constructor(public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {}

  public modalConfirmFail(): void {
    this.bsModalRef.content = this.modalComment;
    this.bsModalRef.hide();
  }
}
