import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLink',
})
export class IsLinkPipe implements PipeTransform {
  transform(value: any): boolean {
    // Regular expression to match a URL
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Check if the value matches the URL regex
    return typeof value === 'string' && urlRegex.test(value);
  }
}
