import { createReducer, on } from "@ngrx/store";
import * as AppState from "../../../State";
import {
  GetAssessments,
  StoreAssessments,
} from "../actions/assessments.actions";
import { AssessmentSummary } from "../../../shared";

export interface State extends AppState.State {
  assessments: AssessmentState;
}

export interface AssessmentState {
  assessments: Array<AssessmentSummary>;
}

const initialState: AssessmentState = {
  assessments: [],
};

const _assessmentsReducer = createReducer(
  initialState,
  on(GetAssessments, (state, action) => ({
    ...state,
  })),

  on(StoreAssessments, (state, action) => ({
    ...state,
    assessments: action.assessments,
  }))
);

export function AssessmentsReducer(state, action) {
  return _assessmentsReducer(state, action);
}
