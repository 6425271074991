import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductCurrentMembershipState } from "../reducers/ProductCurrentMembershipTable.reducer";

export const selectProductCurrentMembershipFeatureState =
  createFeatureSelector<ProductCurrentMembershipState>(
    "ProductCurrentMembership"
  );

export const getProductCurrentMembershipSelector = createSelector(
  selectProductCurrentMembershipFeatureState,
  (state) => state.productCurrentMemberships
);

export const getProductCurrentMembershipLoadingSelector = createSelector(
  selectProductCurrentMembershipFeatureState,
  (state) => state.loading
);
