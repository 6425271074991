import { ExpiryDateChangeDialog } from '../../../features/assessor-dashboard/expiry-date-change-modal/expiry-date-change-modal.component';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { SelectionModel } from '@angular/cdk/collections';
import { TargetDateChangeDialog } from '../../../features/assessor-dashboard/target-date-change-modal/target-date-change-modal.component';
import { SearchResultTableComponent } from '../../../features/assessor-dashboard/search-result-table/search-result-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { Directive, ElementRef, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { AssessorAssignmentDialog } from '../../../features/assessor-dashboard/assessor-assignment-modal/assessor-assignment-modal.component';
import { AssessmentSummary } from '../../models/assessment-summary';
import { DecisionDateChangeDialog } from '../../../features/assessor-dashboard/decision-date-change-modal/decision-date-change-modal.component';
import { SearchFilterComponent } from '../../../features/assessor-dashboard/search-filter/search-filter.component';
import { GetAssessments } from '../../../core/ngrx/actions/assessments.actions';
import { firstValueFrom } from 'rxjs';
import { StatusChangeDialog } from '../../../features/assessor-dashboard/status-change-modal/status-change-modal.component';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { SearchArgs } from '../../models/search-args';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { UIFilter } from '../../models/ui-filter';
import { AssessorCompanyAssignmentDialog } from 'src/app/features/assessor-dashboard/assessor-company-assignment-modal/assessor-company-assignment-modal.component';
import { AssessmentListScope } from '../../enums/assessment-list-scope';
import { ExpiryFilter } from '../../enums/expiry-filter';
import { FurtherInfoModalComponent } from '../../../features/assessment/components/modals/further-info-modal/further-info-modal.component';
import { Redirector } from '../../utililties/redirector';
import { DataRequestTypeEnum } from '../../enums/data-request-type-enum';
import { PermissionsEnum } from '../../enums/permissions-enum';
import { ActionsSubject, Store } from '@ngrx/store';
import { storeDispatchAsync } from 'src/app/core/ngrx/store-dispatch-async';
import { AlertsApiRequestTypes, AlertsService } from '../../../core/services/alerts/alerts.service';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ContactsEmailsTableActions } from '../../../core/ngrx/actions/ContactsEmailsTable.actions';
import { ProductCurrentMembershipTableActions } from '../../../core/ngrx/actions/productCurrentMembershipTable.actions';
import { getProductCurrentMembershipSelector } from '../../../core/ngrx/selectors/ProductCurrentMembershipTable.selectors';
import {
  selectContractorAccountContact,
  selectContractorAccountId,
  selectContractorAccountInsurance,
  selectContractorAccountName,
  selectContractorAccountWorkCategories,
} from '../../../core/ngrx/selectors/contractor-account.selectors';
import { selectCurrentUser, selectCurrentUserAccountId } from '../../../core/ngrx/selectors/currentUser.selectors';
import {
  ManualAssessorFees,
  MarkAssessmentPaidService,
  SaveAssessorFees,
} from '../../../core/services/mark-assessment-paid/mark-assessment-paid.service';
import { CurrentUserState } from '../../../core/ngrx/reducers/CurrentUser.reducer';
import { AssessorActions } from '../../../core/ngrx/actions/assessor.actions';
import { getAssessorsSelector } from '../../../core/ngrx/selectors/assessor.selectors';
import { MatDialog } from '@angular/material/dialog';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { AccountService } from '../../../core/services/account/account.service';
import { TrackingEventService } from '../../../core/services/tracking-event-service/tracking-event.service';

export type CompanyDetailsRequestType = {
  accountId: string;
  companyName: string;
};

@Injectable()
@Directive()
export abstract class DataTableAbstract implements OnInit {
  @ViewChild('searchFilterComponent')
  protected searchFilterComponent: SearchFilterComponent;
  @ViewChild('searchResultTableComponent')
  protected searchResultTableComponent: SearchResultTableComponent;
  @ViewChild('teams') teams: ElementRef;

  protected contractorName: string;
  protected searchArgs: SearchArgs;
  protected MultigridAssessmentViewType: ApiRequestTypes;
  protected selection = new SelectionModel<AssessmentSummary>(true, []); // Multi select
  protected assessmentListScope = AssessmentListScope.None;
  protected companyAccountId: string;
  protected companyName: string;

  @Input() tableName = '';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() dataRequestType: DataRequestTypeEnum | AlertsApiRequestTypes;
  @Input() uiFilter: UIFilter[];
  @Input() displayedColumns: Array<string>;
  @Input() columnNames: Array<string>;

  public showAssignAssessorButton: boolean;
  public showChangeStatusButton: boolean;
  public showChangeTargetDateButton: boolean;
  public showChangeDecisionDateButton: boolean;
  public showChangeExpiryDateButton: boolean;
  public showCheckbox: boolean;
  public isLoading: boolean;
  public dataSource: MatTableDataSource<AssessmentSummary>;
  public assessorName: string;
  public status: string;
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum; // NOTE: Expose enum to view
  public isAssessorAccount = false;
  public viewType: ApiRequestTypes;

  protected constructor(
    protected dialog: MatDialog,
    protected searchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute,
    protected trackingService?: TrackingEventService,
    protected markAsPaidService?: MarkAssessmentPaidService
  ) {}

  ngOnInit(): void {
    this.assessmentService.removeSelectedAssessmentState();

    if (this.apiRequestType) {
      this.store.dispatch(GetAssessments({ view: this.apiRequestType, assessmentListScope: this.assessmentListScope }));
    }
  }

  public async assessmentSelected(event: any): Promise<void> {
    this.isLoading = true;
    const { type, id, key, dataTableId } = event;

    const selectAndNavigate = async (bundleId: string): Promise<void> => {
      const bundle = await firstValueFrom(this.bundleService.retrieveBundleAsAssessmentSummaries$(bundleId));
      await this.assessmentService.selectAssessment(bundle[0].Id);
      const queryParams = this.assessmentService.getRouterLinkQueryParams(id, bundle, bundle[0].Id, undefined);
      Redirector.navigateAndAppendReturnUrl(this.router, `/assessment/assess/${bundle[0].Id}`, dataTableId, {
        bundleId: event.BundleId || id,
        next: queryParams.next,
      });
    };

    if (type == 'alert') {
      this.selection = event;
      await this.openVerifierAlertModal();
    } else if (key == 'Bundle' || ['Verified Contractor', 'Elite Assessment', 'Elite Verification'].includes(event.Product)) {
      await selectAndNavigate(event.BundleId || id);
    } else {
      await this.assessmentService.selectAssessment(id);
      Redirector.navigateAndAppendReturnUrl(this.router, `/assessment/assess/${id}`, dataTableId);
    }

    this.isLoading = false;
  }

  public async contractorSelected(event: any): Promise<void> {
    await this.router.navigateByUrl(`/assessment/companydetails/${event.accountId.uid}`);
  }

  public quickSearch(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public openAssessorChangeDialog(): void {
    const dialogRef = this.dialog.open(AssessorAssignmentDialog, {
      width: '350px',
      data: {
        name: this.assessorName,
        selection: this.selection,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      for (const i in this.selection.selected[0]) {
        this.assessmentService.purgeAssessmentState(this.selection.selected[0][i].Id);
      }
      await this.refreshTable();
      this.assessorName = result;
    });
  }

  public async enterAssessorFees(assessorFeesArray: ManualAssessorFees[]): Promise<void> {
    this.isLoading = true;
    const currentUser: CurrentUserState = await firstValueFrom(this.store.select(selectCurrentUser));

    const payload: SaveAssessorFees[] = assessorFeesArray.map((el: ManualAssessorFees): SaveAssessorFees => {
      return {
        id: el.id || null,
        bundleId: el.bundleId,
        assessorId: el.assessorId,
        cost: parseInt(el.cost as unknown as string),
        actionedById: currentUser.currentAccount.currentContactId,
        actionedDate: el.actionedBy,
      };
    });

    try {
      await firstValueFrom(this.markAsPaidService.markAsPaid$(payload));
      await this.retrieveDataRequest(
        [{ property: 'assessorId', value: currentUser.currentAccount.currentContactId }],
        ApiRequestTypes.VerificationInvoices
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  }

  public openAssessorCompanyChangeDialog(): void {
    const dialogRef = this.dialog.open(AssessorCompanyAssignmentDialog, {
      width: '350px',
      data: {
        name: this.assessorName,
        selection: this.selection,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.refreshTable();
      this.assessorName = result;
    });
  }

  public setSelection(val): void {
    if (val.viewType) this.apiRequestType = val.viewType;
    this.selection.setSelection(val);
  }

  public openStatusChangeDialog(): void {
    const dialogRef = this.dialog.open(StatusChangeDialog, {
      width: '350px',
      data: {
        name: this.status,
        selection: this.selection,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.refreshTable();
      this.status = result;
    });
  }

  public openTargetDateChangeDialog(): void {
    const dialogRef = this.dialog.open(TargetDateChangeDialog, {
      width: '350px',
      data: {
        name: this.status,
        selection: this.selection,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.refreshTable();
      this.status = result;
    });
  }

  public openDecisionDateChangeDialog(): void {
    const dialogRef = this.dialog.open(DecisionDateChangeDialog, {
      width: '350px',
      data: {
        name: this.status,
        selection: this.selection,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.refreshTable();
      this.status = result;
    });
  }

  public openExpiryDateChangeDialog(): void {
    const dialogRef = this.dialog.open(ExpiryDateChangeDialog, {
      width: '350px',
      data: {
        name: this.status,
        selection: this.selection,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.refreshTable();
      this.status = result;
    });
  }

  public async retrieveDataRequest(
    filter?: UIFilter[],
    viewType?: ApiRequestTypes | AlertsApiRequestTypes,
    assessmentListScope: AssessmentListScope = AssessmentListScope.None,
    expiryFilter: ExpiryFilter = ExpiryFilter.none
  ): Promise<void> {
    this.isLoading = true;
    const accountId = await firstValueFrom(this.activatedRoute.url);
    filter = this.setCompanyAccountIdFilter(viewType, filter);

    if (filter == undefined || filter.length <= 0) filter = null;
    switch (this.dataRequestType) {
      case DataRequestTypeEnum.Assessment:
        const assessments = await this.assessmentService.getAssessments(
          viewType as ApiRequestTypes,
          filter,
          assessmentListScope,
          expiryFilter
        );
        this.populateTableDataSet(assessments, viewType);
        break;
      case DataRequestTypeEnum.Bundle:
        const bundles = await firstValueFrom(this.bundleService.retrieveBundles$(viewType, filter));
        this.populateTableDataSet(bundles, viewType);
        break;
      case DataRequestTypeEnum.Contractor:
        const contractors = await firstValueFrom(this.accountService.getContractors());
        this.populateTableDataSet(contractors);
        break;
      case DataRequestTypeEnum.Alert:
        const alerts = await firstValueFrom(this.alertsService.retrieveAlerts$(viewType, filter as unknown as UIFilter));
        this.populateTableDataSet(alerts);
        break;
      case DataRequestTypeEnum.OutstandingAlerts:
        const outstandingAlerts = await firstValueFrom(this.alertsService.retrieveOutstandingAlerts$(this.companyAccountId));
        this.populateTableDataSet(outstandingAlerts);
        break;
      case DataRequestTypeEnum.Products:
        await storeDispatchAsync(this.store, this.actions, ProductCurrentMembershipTableActions.LoadSuccess, {
          type: ProductCurrentMembershipTableActions.Load,
          accountId: this.companyAccountId,
        });
        const products = await firstValueFrom(this.store.select(getProductCurrentMembershipSelector));
        this.populateTableDataSet(products);
        break;
      case DataRequestTypeEnum.AssessmentHistory:
        const trackingEvents = await firstValueFrom(this.trackingService.retrieveTrackingEvents(accountId[1].path));
        this.populateTableDataSet(trackingEvents);
        break;
      case DataRequestTypeEnum.AccountHistory:
        const auditHistory = await firstValueFrom(this.trackingService.retrieveAuditHistory(accountId[1].path));
        this.populateTableDataSet(auditHistory);
        break;
      case DataRequestTypeEnum.Trades:
        const trades = await firstValueFrom(this.store.select(selectContractorAccountWorkCategories));
        this.populateTableDataSet(trades);
        break;
      case DataRequestTypeEnum.Contacts:
        await storeDispatchAsync(this.store, this.actions, ContactsEmailsTableActions.LoadSuccess, {
          type: ContactsEmailsTableActions.Load,
          accountId: this.activatedRoute.snapshot.params.id,
        });
        const contacts: Array<any> = await firstValueFrom(this.store.select(selectContractorAccountContact));
        this.populateTableDataSet(contacts);
        break;
      case DataRequestTypeEnum.PurchaseHistory:
        const purchaseHistory: Array<any> = await firstValueFrom(this.accountService.getPurchaseHistory(this.companyAccountId));
        this.populateTableDataSet(purchaseHistory);
        break;
      case DataRequestTypeEnum.Insurance:
        const insurance: Array<any> = await firstValueFrom(this.store.select(selectContractorAccountInsurance));
        this.populateTableDataSet(insurance);
        break;
      case DataRequestTypeEnum.AssessmentInvoices:
        const manualAssessorFees: ManualAssessorFees[] = await firstValueFrom(
          this.markAsPaidService.getInvoicesAssessments$(this.uiFilter)
        );
        this.populateTableDataSet(manualAssessorFees);
        break;
      case DataRequestTypeEnum.UserAccounts:
        await storeDispatchAsync(this.store, this.actions, AssessorActions.ListSuccess, {
          type: AssessorActions.List,
        });
        const assessors: Array<any> = await firstValueFrom(this.store.select(getAssessorsSelector));

        let enabledAssessors = [];
        let disabledAssessors = [];
        if (filter[0].value == 0) {
          enabledAssessors = assessors.filter((el) => el.statusCode == 0);
          this.populateTableDataSet(enabledAssessors);
        } else {
          disabledAssessors = assessors.filter((el) => el.statusCode == 1);
          this.populateTableDataSet(disabledAssessors);
        }
        break;
      default:
        return;
    }
  }

  /**
   * Determine if the viewType is of type Company Alerts. If so construct a filter containing the original filter and a new object
   * containing the company account id.
   * @param viewType
   * @param filter
   * @returns UIFilter[]
   */
  private setCompanyAccountIdFilter(viewType: AlertsApiRequestTypes | ApiRequestTypes, filter: UIFilter[]): UIFilter[] {
    if (viewType === AlertsApiRequestTypes.CompanyAlerts) {
      return [...filter, { property: 'accountId', value: this.companyAccountId }];
    }

    if (viewType === ApiRequestTypes.CompanyVerificationBundles || viewType === ApiRequestTypes.AccountVerifications) {
      return [{ property: 'accountId', value: this.companyAccountId }];
    }

    return filter;
  }

  protected async getCompanyId(): Promise<CompanyDetailsRequestType> {
    this.companyAccountId = await firstValueFrom(this.store.select(selectContractorAccountId));
    this.companyName = await firstValueFrom(this.store.select(selectContractorAccountName));

    return {
      accountId: this.companyAccountId,
      companyName: this.companyName,
    };
  }

  protected async getCurrentUserAccountId(): Promise<void> {
    this.companyAccountId = await firstValueFrom(this.store.select(selectCurrentUserAccountId));
  }

  protected async openVerifierAlertModal(): Promise<void> {
    const currentRoute: UrlSegment[] = await firstValueFrom(this.activatedRoute.url);
    let isActionable = true;

    if (currentRoute.find((i) => i.path === 'companydetails')) {
      isActionable = false;
    }

    const dialogRef = this.dialog.open(FurtherInfoModalComponent, {
      width: '40%',
      height: '50%',
      data: {
        name: this.assessorName,
        selection: { ...this.selection, actionableContent: isActionable },
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      await this.refreshTable();
      this.assessorName = result;
    });
  }

  protected async refreshTable(): Promise<void> {
    // eslint-disable-next-line no-console
    console.warn(
      'Refreshing table data with: ',
      `UIFilter: ${this.uiFilter}`,
      `API Request Type: ${this.apiRequestType}`,
      `Assessment List Scope: ${this.assessmentListScope}`
    );
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType, this.assessmentListScope);
  }

  private populateTableDataSet(data, viewType?): void {
    if (data.length > 0) this.dataSource = new MatTableDataSource<AssessmentSummary>(data);
    this.viewType = viewType;
    this.isLoading = false;
  }
}

export type CompanyDetails = {
  companyName: string;
  accountId: string;
  userAccountId: string;
  name: string;
};
