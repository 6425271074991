<h1>Opportunities</h1>
<div>
  <mat-form-field>
    <input (keyup)="quickSearch($event.target.value)" matInput placeholder="Quick Search" />
  </mat-form-field>
</div>
<div class="button-row">
  <button *ngIf="showHideWithdrawButton$() | async" class="btn btn-danger" [disabled]="this.isLoading || this.selection.isEmpty()"
    (click)="withdrawClick()" mat-raised-button>
    Withdraw
  </button>
</div>
<mat-divider></mat-divider>
<app-data-table [columnNames]="columnNames" [enableRowClick]="false" (selectionEmitter)="setSelection($event)"
  [showCheckbox]="true" (clickActionEmitter)="assessmentSelected($event)" [dataSource]="dataSource"
  [displayedColumns]="displayedColumns" [isLoading]="isLoading">
</app-data-table>