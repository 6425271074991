import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AssessmentLayoutComponent } from './assessment-layout/assessment-layout.component';
import { DocumentsComponent } from './documents/documents.component';
import { AssessmentBreadcrumbsModule } from './assessment-breadcrumbs/assessment-breadcrumbs.module';
import { AssessmentPageComponent } from './assessment-page/assessment-page.component';
import { LoadingSpinnerModule } from '../../shared/components/loading-spinner/loading-spinner.module';
import { BusinessSizePipe } from './components/pipes/business-size/business-size.pipe';
import { FormAddressDisplayComponent } from './components/form-address-display/form-address-display.component';
import { AssessmentRoutingModule } from './assessment-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactFieldDisplayComponent } from './components/contact-field-display/contact-field-display.component';
import { SurveyJsAssessmentComponent } from './components/survey-js-assessment/survey-js-assessment.component';
import { HelptipComponent } from './components/helptip/helptip.component';
import { AssessmentAlertsPanelComponent } from './components/assessment-alerts-panel/assessment-alerts-panel.component';
import { AddressComponent } from './address/address.component';
import { CompanyTimelineComponent } from './company-timeline/company-timeline.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';
import { AccreditationSummaryComponent } from './AccreditationSummary/accreditation-summary.component';
import { ImsAndinsuranceComponent } from './ims-and-insurance/ims-and-insurance.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { ProductAssessmentComponent } from './product-assessment/product-assessment.component';
import { ContactsAndEmployeesComponent } from './contacts-and-employees/contacts-and-employees.component';
import { FIRSummaryComponent } from './FIR-Summary/FIR-Summary.component';
import { TradesComponent } from './trades/trades.component';
import { ProductCurrentMembershipComponent } from './product-currentMembership/product-currentMembership.component';
import { ContactsEmailsComponent } from './contacts-emails/contacts-emails.component';
import { ModuleTableComponent } from './module-table/module-table.component';
import { QuestionSetTableComponent } from './question-set-table/question-set-table.component';
import { AngularMaterialModule } from 'src/app/core/angular-material.module';
import { productTableComponent } from './product-table/product-table.component';
import { WorkCategoriesTableComponent } from './work-categories-table/work-categories-table.component';
import { ModuleAddOrUpdateComponent } from './module-add-or-update/module-add-or-update.component';
import { WorkCategoryAddOrUpdateComponent } from './work-category-add-or-update/work-category-add-or-update.component';
import { QuestionSetDialogComponent } from './question-set-dialog/question-set-dialog.component';
import { FailModalComponent } from './components/modals/fail-modal/fail-modal.component';
import { FailToResubmitModalComponent } from './components/modals/fail-to-resubmit-modal/fail-to-resubmit-modal.component';
import { BusinessSummaryModalComponent } from './components/modals/business-summary-modal/business-summary-modal.component';
import { WorkCategoriesModalComponent } from './components/modals/work-categories-modal/work-categories-modal.component';
import { FurtherInfoModalComponent } from './components/modals/further-info-modal/further-info-modal.component';
import { FurtherInfoSurveyModalComponent } from './components/modals/further-info-survey-modal/further-info-survey-modal.component';
import { AssessorDashboardModule } from '../assessor-dashboard/assessor-dashboard.module';
import { AssessorsTableComponent } from './assessors-table/assessors-table.component';
import { AssessorAddOrUpdateComponent } from './assessor-add-or-update/assessor-add-or-update.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AssessorDashboardRoutingModule } from '../assessor-dashboard/assessor-dashboard-routing.module';
import { MaterialModule } from '../../../material/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { ChangeAssessmentStatusComponent } from './components/survey-js-assessment/change-assessment-status/change-assessment-status.component';
import { ProductTableControllerComponent } from './product-assessment/product-table-controller/product-table-controller.component';
import { WorkRegionSelecterComponent } from './work-areas/work-region-selecter/work-region-selecter.component';
import { LuxonModule } from 'luxon-angular';
import { CompanyTimelineModalComponent } from './company-timeline/company-timeline-modal/company-timeline-modal.component';
import { NgxEditorModule } from 'ngx-editor';
import { SnackBarClass } from '../../shared/components/snack-bar/snack-bar.class';
import { ClientContactsComponent } from './clients/client-contacts/client-contacts.component';
import { ClientContactAddUpdateComponent } from './clients/client-contact-add-update/client-contact-add-update.component';
import { ContractorAccountInsuranceGrid } from './ims-and-insurance/insurance-grid/contractor-account-insurance-grid.component';
import { InsuranceTableComponent } from './ims-and-insurance/insurance-table/insurance-table.component';
import { WorkCategoriesComponent } from './work-categories/work-categories.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IsDatePipe } from '../../shared/pipe/is-date.pipe';
import { BundleTypesTableComponent } from './bundle-types-table/bundle-types-table.component';
import { BundleTypeUpdateComponent } from './bundle-type-update/bundle-type-update.component';
import { AssessorsTableControllerComponent } from './assessors-table/assessors-table-controller/assessors-table-controller.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    AssessmentLayoutComponent,
    AddressComponent,
    DocumentsComponent,
    AssessmentPageComponent,
    BusinessSizePipe,
    FormAddressDisplayComponent,
    ContactFieldDisplayComponent,
    SurveyJsAssessmentComponent,
    HelptipComponent,
    AssessmentAlertsPanelComponent,
    CompanyTimelineComponent,
    AccreditationSummaryComponent,
    ImsAndinsuranceComponent,
    InsuranceComponent,
    ProductAssessmentComponent,
    ContactsAndEmployeesComponent,
    FIRSummaryComponent,
    TradesComponent,
    ProductCurrentMembershipComponent,
    ContactsEmailsComponent,
    ModuleTableComponent,
    ModuleAddOrUpdateComponent,
    QuestionSetTableComponent,
    productTableComponent,
    WorkCategoriesTableComponent,
    WorkCategoryAddOrUpdateComponent,
    QuestionSetDialogComponent,
    FailModalComponent,
    FailToResubmitModalComponent,
    BusinessSummaryModalComponent,
    WorkCategoriesModalComponent,
    FurtherInfoModalComponent,
    FurtherInfoSurveyModalComponent,
    AssessorsTableComponent,
    BundleTypesTableComponent,
    WorkRegionSelecterComponent,
    AssessorAddOrUpdateComponent,
    ChangeAssessmentStatusComponent,
    ProductTableControllerComponent,
    CompanyTimelineModalComponent,
    ClientContactsComponent,
    ClientContactAddUpdateComponent,
    ContractorAccountInsuranceGrid,
    InsuranceTableComponent,
    WorkCategoriesComponent,
    AssessorsTableControllerComponent,
    BundleTypeUpdateComponent,
  ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AssessmentBreadcrumbsModule,
        RouterModule,
        NgxDatatableModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
        LoadingSpinnerModule,
        SharedModule,
        AssessmentRoutingModule,
        FlexLayoutModule,
        FlexModule,
        GridModule,
        AssessorDashboardModule,
        BsDropdownModule.forRoot(),
        AssessorDashboardRoutingModule,
        MaterialModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatCardModule,
        LuxonModule,
        NgxEditorModule.forRoot(),
        AngularEditorModule,
        CollapseModule,
    ],
  providers: [
    DataTableComponent,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    BsModalRef,
    SnackBarClass,
    IsDatePipe,
  ],
  exports: [
    ProductCurrentMembershipComponent,
    MatFormFieldModule,
    MatDialogModule,
    ProductTableControllerComponent,
    CompanyTimelineComponent,
    WorkCategoriesTableComponent,
    ImsAndinsuranceComponent,
    ContractorAccountInsuranceGrid,
    WorkRegionSelecterComponent,
    WorkCategoriesComponent,
  ],
})
export class AssessmentModule {}
