export interface ProductivityInfo {
  userId: string;
  userName: string;
  assessmentId: string;
  assessmentName: string;
  status: number;
  contractorName: string;
  contractorId: string;
}

export const ProductivityAssessmentStatus = {
  Submitted: 1,
  SubmittedWithAdditionalInfo: 2,
  Assigned: 3,
  Accepted: 4,
  Rejected: 5,
  ReturnedToContractor: 6,
  ClosedFailed: 7,
  ClosedFailedToResubmit: 8,
  ClosedCompliant: 9,
};
