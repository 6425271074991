import { AccountInfo } from '@azure/msal-browser';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';

export abstract class UserAuthenticationAbstract {
    abstract logout(): void;

    abstract getLoggedInUserId(): string;

    abstract getLoggedInUserName(): string;

    abstract getLoggedInUser(): AccountInfo;

    abstract isLoggedIn(): boolean | Observable<boolean>;

    abstract acquireToken(): any | Observable<any>;

    abstract login(): void;

    abstract eventSubject(): Subject<string>;

    abstract shouldIncludeToken(url: string): boolean;
}
