<div *ngIf="parentViewEnabled">
  <div class="row mt-5">
    <div
      *ngIf="showFastTrackFilter"
      class="col-sm-12 col-md-3 d-flex align-items-center"
    >
      <div class="form-check">
        <input
          type="checkbox"
          [checked]="filterByFastTrack"
          (change)="fastTrackedChanged($event.target.checked)"
          id="filterByFastTrack"
          class="form-check-input"
        />
        <label class="form-check-label" for="filterByFastTrack">
          Fast Track
        </label>
      </div>
    </div>
    <div
      class="col-sm-12 col-md-{{
        parentViewColumnSize
      }} d-flex justify-content-start"
    >
      <select
        id="product-filter"
        class="custom-select"
        [(ngModel)]="selectedProductOption"
        (change)="productChanged($event.target.value)"
        #productfilterRef
      >
        <option value="Filter by product">Filter by product</option>
        <option *ngFor="let product of products" value="{{ product.id }}">
          {{ product.name }}
        </option>
      </select>
    </div>

    <div
      class="col-sm-12 col-md-{{
        parentViewColumnSize
      }} d-flex justify-content-center"
    >
      <select
        id="assessor-filter"
        class="custom-select"
        [(ngModel)]="selectedAssessorOption"
        (change)="assessorChanged($event.target.value)"
        #assessorfilterRef
      >
        <option value="Filter by assessor">Filter by assessor</option>
        <option *ngFor="let assessor of assessors" value="{{ assessor.id }}" >
          {{ assessor.fullName }}
        </option>
      </select>
    </div>

    <div
      class="col-sm-12 col-md-{{
        parentViewColumnSize
      }} d-flex justify-content-end"
    >
      <select
        id="date-filter"
        class="custom-select"
        [(ngModel)]="selectedDateOption"
        (change)="dateChanged($event.target.value)"
        #datefilterRef
      >
        <option value="Filter by date">Filter by date</option>
        <option *ngFor="let date of dates" value="{{ date.option }}">
          {{ date.option }}
        </option>
      </select>
    </div>
  </div>
</div>
<div *ngIf="!parentViewEnabled">
  <div class="row mt-5">
    <div
      *ngIf="showFastTrackFilter"
      class="col-sm-12 col-md-2 d-flex align-items-center"
    >
      <div class="form-check">
        <input
          type="checkbox"
          [checked]="filterByFastTrack"
          (change)="fastTrackedChanged($event.target.checked)"
          id="filterByFastTrack"
          class="form-check-input"
        />
        <label class="form-check-label" for="filterByFastTrack">
          Fast Track
        </label>
      </div>
    </div>
    <div
      class="col-sm-12 col-md-{{
        childViewColumnSize
      }} d-flex justify-content-start"
    >
      <select
        id="product-filter"
        class="custom-select"
        (change)="productChanged($event.target.value)"
        #productfilterRef
      >
        <option value="0">Filter by product</option>
        <!-- <option value="870520003">Filter by Supplier</option> -->
        <option value="0">All products</option>
        <option *ngFor="let product of products" value="{{ product.id }}">
          {{ product.name }}
        </option>
      </select>
    </div>

    <div
      class="col-sm-12 col-md-{{
        childViewColumnSize
      }} d-flex justify-content-end"
    >
      <select
        id="date-filter"
        class="custom-select"
        (change)="dateChanged($event.target.value)"
        #datefilterRef
      >
        <option value="0">Filter by date</option>
        <option *ngFor="let date of dates" value="{{ date.option }}">
          {{ date.option }}
        </option>
      </select>
    </div>
  </div>
</div>
