import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from 'src/app/core/open-api';
import { lastValueFrom } from 'rxjs';
import { ApiRoutesClass } from 'src/app/shared';
import { AzureUserAuthenticationService } from '../../../core/services/azure-authentication/user-authentication/azure-user-authentication.service';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-mychas-login',
  templateUrl: './mychas-login.component.html',
  styleUrls: ['./mychas-login.component.scss'],
})
export class MychasLoginComponent implements OnInit {
  public loginToMyChasLoading = false;
  public loginToMyChasError = false;
  public loginToMyChasSuccess = false;
  private companyId: string | undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly contactService: ContactService,
    private readonly userAuthentication: AzureUserAuthenticationService,
    private readonly api: ApiRoutesClass
  ) {}

  public ngOnInit(): void {
    this.companyId = this.route.snapshot.params.id;
  }

  public async loginToMyChas(): Promise<void> {
    if (!this.companyId) this.loginToMyChasError = true;

    const account: AccountInfo = this.userAuthentication.getLoggedInUser();
    const accountName = account.name.split(' ');

    this.loginToMyChasLoading = true;

    const contactId = await lastValueFrom(
      this.contactService.createLogMeInContact({
        accountId: this.companyId,
        firstName: accountName[0],
        lastName: accountName[1],
        email: account.username,
      })
    );

    if (!contactId) {
      this.loginToMyChasLoading = false;
      this.loginToMyChasError = true;
    }

    this.loginToMyChasLoading = false;
    alert('You have been added as a contact to this account for 2 hours.');
    this.loginToMyChasSuccess = true;
    window.open(`${this.api.MyChasEndpoint()}/?contactId=${contactId}`, '_blank');
  }
}
