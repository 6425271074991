import { Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';
import { AccountInfo, EventType, IPublicClientApplication } from '@azure/msal-browser';
import { from, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { ApiRoutesClass } from '../../../../shared';
import { MonitorService } from '../../monitorService/monitor.service';
import { azureB2c } from '../../../../shared/constants/azure-b2c';
import { MonitorSeverity } from '../../../../shared/constants/monitor-severity';
import { eSeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root',
})
export class AzureUserAuthenticationService {
  public account: any = undefined;
  public loginSuccessSubject: Subject<EventType> = new Subject();
  public setFlowTask: Promise<void>;
  private clientApplication: IPublicClientApplication;
  private config: IEnvironment;

  constructor(private store: Store, private readonly api: ApiRoutesClass, private readonly monitorService: MonitorService) {
    const env = new Environment();
    this.config = env.getConfigSync();
    this.setFlowTask = this.setFlow(this.config.authentication.auth.authority);
  }

  private async setFlow(flowUrl: any): Promise<void> {
    const auth = {
      clientId: this.config.authentication.auth.clientId,
      authority: flowUrl,
      redirectUri: this.config.authentication.auth.redirectUri,
      navigateToLoginRequestUrl: this.config.authentication.auth.navigateToLoginRequestUrl,
      knownAuthorities: this.config.authentication.auth.knownAuthorities,
      postLogoutRedirectUri: this.config.authentication.auth.postLogoutRedirectUri,
    };
    this.clientApplication = await msal.PublicClientApplication.createPublicClientApplication({ auth });

    this.clientApplication.addEventCallback((eventMessage: any): void => {
      if (eventMessage.eventType === msal.EventType.LOGIN_SUCCESS || eventMessage.eventType === msal.EventType.ACQUIRE_TOKEN_SUCCESS) {
        this.clientApplication.setActiveAccount(eventMessage.payload.account);
        this.loginSuccessSubject.next(eventMessage);
      }
    });
    this.clientApplication
      .handleRedirectPromise()
      .then(async (): Promise<void> => {
        this.account = this.clientApplication.getActiveAccount();
        if (!this.account) {
          await this.clientApplication.loginRedirect();
        }
      })
      .catch(async (error: any): Promise<void> => {
        await this.serverFailResponse(error);
      });
  }

  public async azureLogout(): Promise<void> {
    localStorage.removeItem(azureB2c.ACCESS_TOKEN_KEY);
    localStorage.removeItem('expiresOn');
    sessionStorage.setItem('currentuserstate', '');
    sessionStorage.setItem('currentaccount', '');
    if (this.clientApplication) await this.clientApplication.logoutRedirect(this.config.authentication.auth);
  }

  public getLoggedInUser(): AccountInfo {
    return this.clientApplication.getAllAccounts()[0];
  }

  public getLoggedInUserId(): string {
    if (this.clientApplication) return this.clientApplication.getAllAccounts()[0]?.username;
  }

  isLoggedIn(): boolean {
    return this.account !== undefined;
  }

  acquireToken(): any | Observable<any> {
    const account: AccountInfo = this.clientApplication.getAllAccounts()[0];
    return from(
      this.clientApplication
        .acquireTokenSilent({
          scopes: this.config.authentication.scopes,
          account: account,
        })
        .catch(async (error: any) => {
          this.monitorService.logException(error, eSeverityLevel.Critical);
          await this.clientApplication.acquireTokenRedirect({ scopes: this.config.authentication.scopes });
          throw new Error(error);
        })
    );
  }

  public async loginSignupRedirect(): Promise<void> {
    try {
      await this.setFlowTask;
      await this.clientApplication.loginRedirect({ scopes: this.config.authentication.scopes });
    } catch (e) {
      console.warn(e);
    }
  }

  public async resetPassword(): Promise<void> {
    try {
      await this.setFlow(this.config.authentication.auth.resetAuthority);
      await this.loginSignupRedirect();
    } catch (authErr) {
      this.monitorService.logException(authErr, MonitorSeverity.ERROR);
    }
  }

  private async serverFailResponse(error: any): Promise<void> {
    if (this.checkIfForgotPassword(error)) {
      await this.resetPassword();
      await this.loginSignupRedirect();
    } else {
      await this.loginSignupRedirect();
    }
  }

  private checkIfForgotPassword(authErr: any): boolean {
    return authErr.errorMessage?.includes('AADB2C90118') || authErr.errorMessage.indexOf(azureB2c.ERROR_CODE_FORGOTTON_PASSWORD) > -1;
  }
}
