import { Component } from '@angular/core';

@Component({
  selector: 'app-audit-history-tab',
  templateUrl: './audit-history-tab.component.html',
  styleUrls: ['./audit-history-tab.component.scss']
})
export class AuditHistoryTabComponent {

}
