import { Pipe, PipeTransform } from "@angular/core";
import { Region } from "../../models/regions";

@Pipe({
  name: "workRegionDisplay",
})
export class WorkRegionDisplayPipe implements PipeTransform {
  transform(value: Region[], ...args: unknown[]): string {
    let regions = "";
    value.forEach((region) => (regions = regions.concat(`${region.name}, `)));
    return regions;
  }
}
