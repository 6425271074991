import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectContractorAccountAddress, selectContractorAccountContact } from 'src/app/core/ngrx/selectors/contractor-account.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Contact } from 'src/app/core/open-api';
import { ContractorAccountService } from 'src/app/core/services/company-account/contractor-account.service';

@Component({
  selector: 'app-summary-tab',
  templateUrl: './summary-tab.component.html',
  styleUrls: ['./summary-tab.component.scss'],
})
export class SummaryTabComponent implements OnInit, OnDestroy {
  public address$: Observable<any> = this.store.select(selectContractorAccountAddress);
  public primaryContactSubject: BehaviorSubject<Contact> = new BehaviorSubject(null);
  private contactsSubscription: Subscription = new Subscription();

  constructor(private readonly store: Store, private readonly contractorAccountService: ContractorAccountService) {}

  public ngOnInit(): void {
    this.contactsSubscription = this.store.select(selectContractorAccountContact).subscribe((contacts: Contact[]) => {
      if (contacts && contacts.length > 0) {
        this.primaryContactSubject.next(contacts.find((y) => y.isPrimaryContact === true));
      }
    });
  }

  public ngOnDestroy(): void {
    this.contactsSubscription.unsubscribe();
  }

  protected getPrimaryContact(): Observable<Contact> {
    return this.primaryContactSubject.asObservable();
  }

  async refreshContacts(): Promise<void> {
    await this.contractorAccountService.refreshStoreContacts$();
  }
}
