import { Component, Inject, Input, OnInit } from '@angular/core';
import { Survey } from 'survey-angular';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../../assessor-dashboard/assessor-assignment-modal/assessor-assignment-modal.component';
import { Alert, AlertAction, AlertsService } from '../../../../../core/services/alerts/alerts.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-further-info-modal',
  templateUrl: './further-info-modal.component.html',
  styleUrls: ['./further-info-modal.component.scss'],
})
export class FurtherInfoModalComponent implements OnInit {
  @Input() actionableDialog: boolean;
  public survey: Survey;
  public alert: Partial<Alert>;
  public comments: string;
  public isDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly alertsService: AlertsService
  ) {}

  public ngOnInit(): void {
    this.alert = this.data.selection as Partial<Alert>;
  }

  /**
   * Approve or Reject a verification
   * If verification rejected, comments must be supplied to the service call.
   * @param status - True or False depending on Accepted or Rejected
   * @param docVerificationId - ID of the alert
   * @return Promise<void>
   */
  public async actionVerificationAlert(status: boolean, docVerificationId: string): Promise<void> {
    this.isDisabled = true;
    const payload: AlertAction = {
      approve: status,
      comments: this.comments,
      docVerificationId: docVerificationId,
    };

    await firstValueFrom(this.alertsService.actionAlert(payload));
    this.isDisabled = false;
    this.dialogRef.close();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
