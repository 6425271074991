import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, interval, Subject, take } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { CompanyTimelineModalComponent } from './company-timeline-modal/company-timeline-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { selectContractorAccountId, selectContractorAccountTimeline } from '../../../core/ngrx/selectors/contractor-account.selectors';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { angularEditorConfig } from '../../../shared/components/phone-call-accordian/phone-call-accordion.component';
import { DateTime } from 'luxon';
import { storeDispatchAsync } from '../../../core/ngrx/store-dispatch-async';
import { ContractorAccountActions } from '../../../core/ngrx/actions/contractor-account.actions';

@Component({
  selector: 'app-company-timeline',
  templateUrl: './company-timeline.component.html',
  styleUrls: ['./company-timeline.component.scss'],
})
export class CompanyTimelineComponent implements OnInit, OnDestroy {
  public dateType: typeof DateTime = DateTime;
  public timelineData = [];
  public dialogRef: MatDialogRef<any>;
  public loading: boolean;
  public editorConfig: AngularEditorConfig = {
    ...angularEditorConfig,
    editable: false,
    enableToolbar: false,
    placeholder: '',
    showToolbar: false,
    minHeight: '80px',
    maxHeight: 'auto',
  };
  private destroyed$: Subject<boolean> = new Subject();
  public pauseTimelineRefresh = false;
  public searchArgs = '';

  constructor(private readonly store: Store, private readonly actions: ActionsSubject, private readonly dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.retrieveTimelineData();
    this.timelinePolling();
  }

  /**
   * Update the timeline every 10 minutes.
   * Timeline polling is destroyed when ngOnDestroy is invoked.
   * @private
   */
  private timelinePolling(): void {
    interval(600000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (): Promise<void> => {
        await this.refreshTimelineData();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  public displayModal(data: any): void {
    const newData = {
      ...data,
    };
    this.dialogRef = this.dialog.open(CompanyTimelineModalComponent, {
      width: '1300px',
      data: {
        name: '',
        selection: newData,
      },
    });
    this.refreshDataState().subscribe();
  }

  public async retrieveTimelineData(): Promise<void> {
    this.loading = true;
    this.timelineData = await firstValueFrom(this.store.select(selectContractorAccountTimeline));
    this.loading = false;
  }

  /**
   * Refresh timeline data if polling has not been paused by an open accordion state
   */
  public async refreshTimelineData(): Promise<void> {
    if (this.pauseTimelineRefresh) return;

    this.loading = true;
    const accountId: string = await firstValueFrom(this.store.select(selectContractorAccountId));
    await storeDispatchAsync(this.store, this.actions, ContractorAccountActions.RefreshContractorAccountTimelineSuccess, {
      type: ContractorAccountActions.RefreshTimeline,
      accountId: accountId,
    });
    this.timelineData = await firstValueFrom(this.store.select(selectContractorAccountTimeline));
    this.loading = false;
  }

  public resetState(form: FormGroup, accordian: MatAccordion): void {
    this.pauseTimelineRefresh = false;
    form.reset();
    setTimeout(() => {
      accordian.closeAll();
    }, 100);
  }

  private refreshDataState(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      map(() => this.retrieveTimelineData()),
      take(1)
    );
  }
}
