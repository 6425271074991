import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { getQuestionSetTableLoadingSelector, getQuestionSetTableSelector } from 'src/app/core/ngrx/selectors/QuestionSetTable.selectors';
import { QuestionSetTableActions } from 'src/app/core/ngrx/actions/QuestionSetTable.actions';
import { QuestionSetTable } from 'src/app/shared/models/QuestionSetTable';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QuestionSetHttpService } from 'src/app/core/services/account/QuestionSetHttpService';

@Component({
  selector: 'app-question-set-table',
  templateUrl: './question-set-table.component.html',
  styleUrls: ['./question-set-table.component.scss'],
})
export class QuestionSetTableComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<QuestionSetTable>;
  displayedColumns: string[] = ['name', 'version', 'questionSetNumber', 'validFrom', 'expires', 'created', 'actions'];
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild('csvReader')
  csvReader: any;
  public records: any[] = [];
  selectedFile: any = null;
  searchKey: string;
  QuestionSetTableSub: Subscription;
  isLoading$ = this.store.select(getQuestionSetTableLoadingSelector);
  selectedRows = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store,
    private questionSetService: QuestionSetHttpService
  ) {}

  ngOnInit(): void {
    this.QuestionSetTableSub = this.store.select(getQuestionSetTableSelector).subscribe((t) => {
      this.dataSource = new MatTableDataSource(t);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
    this.store.dispatch({
      type: QuestionSetTableActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.QuestionSetTableSub) {
      this.QuestionSetTableSub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  onSelect({ selected }): void {
    this.selectedRows = selected;
  }

  assessmentSelected(assessmentId: string): void {
    this.router.navigate([`/assessment/editModule/${assessmentId}`]);
  }

  uploadListener($event: any): void {
    this.selectedFile = $event.target.files[0] ?? null;
    const files = $event.srcElement.files;
    const formData = new FormData();

    if (files && files.length > 0) {
      for (let file of files) {
        formData.append('files', file);
      }

      this.questionSetService.uploadCSV(formData).subscribe(() => {
        this.csvReader.nativeElement.value = '';
        window.alert('Upload Completed');
        this.store.dispatch({
          type: QuestionSetTableActions.Load,
          accountId: this.route.snapshot.params.id,
        });
        this.selectedFile = '';
      });
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr: QuestionSetTable[] = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: QuestionSetTable = {
          id: curruntRecord[0].trim(),
          questionSetNumber: curruntRecord[1].trim(),
          version: parseFloat(curruntRecord[2].trim()),
          name: curruntRecord[3].trim(),
          validFrom: curruntRecord[4] ? new Date(curruntRecord[4].trim()) : null,
          expires: curruntRecord[5] ? new Date(curruntRecord[5].trim()) : null,
          displayOrder: parseInt(curruntRecord[6].trim()),
          created: new Date(),
          uploadFileName: 'test',
        };

        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
  }

  onSearchClear(): void {
    this.searchKey = '';
  }

  applyFilter(): void {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
