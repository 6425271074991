<div class="container">
    <h2>Assessor Workload</h2>
    <div class="container">
        <div class="table-container mat-elevation-z8">
            <table [dataSource]="dataSource" mat-table multiTemplateDataRows matSort>
                <ng-container matColumnDef="assessor">
                    <th id="assessor" *matHeaderCellDef mat-header-cell mat-sort-header
                        sortActionDescription="Sort by Assessor">
                        Assessor
                    </th>
                    <td *matCellDef="let element" mat-cell>
                        {{element.assessor}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="assessments">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by Assessments">
                        Bundles
                    </th>
                    <td *matCellDef="let element" mat-cell>
                        {{element.bundles}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="percent" sortActionDescription="Sort by Percentage">
                    <th *matHeaderCellDef mat-header-cell>
                        Percentage
                    </th>
                    <td *matCellDef="let element" mat-cell>
                        <mat-progress-bar mode="determinate" [value]="element.percent"></mat-progress-bar>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-detail-row" [@expanded]="row == expandedRow ? 'expanded' : 'collapsed'">
                            <app-assessor-workload-details [dataRequestType]='filterName'  (click)='logger()' *ngIf="expandedRow === row" enabled [apiRequestType]="apiRequestTypes.YourActiveAssessments"
                                enableRowClick="false" [assessorId]="row.assessorId">
                            </app-assessor-workload-details>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="expandedRow = expandedRow === row ? null : row">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expanded-detail-row"
                    [class.expanded]="row == expandedRow"></tr>
            </table>

            <div *ngIf="isLoading" class="loading-spinner_container">
                <app-loading-spinner></app-loading-spinner>
            </div>

            <div *ngIf="dataSource.data.length == 0" ngClass="d-flex justify-content-center pt-3">
                <p>No data to display</p>
            </div>

            <mat-paginator [pageSizeOptions]="[10, 20, 50]" aria-label="Select page of assessors" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>