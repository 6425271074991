<div *ngIf="isLoading" class="loading-spinner_container">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="assessment-container">
  <div class="alert alert-danger mt-3" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>

  <div *ngIf="!isLoading">
    <h4 *ngIf="verificationCount.timeRemaining">Contractor response required by: {{verificationCount.timeRemaining | isDate}}</h4>
    <h4 *ngIf="assessmentCount.timeRemaining">Contractor response required by: {{assessmentCount.timeRemaining | isDate}}</h4>
    <app-survey-js-assessment [assessmentCountdown]="assessmentCount" [verificationCountdown]="verificationCount"
      (updateSuccess)="onSuccessfulUpdate()"
    ></app-survey-js-assessment>
  </div>
</div>
