import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";

import { Subscription } from "rxjs";
import {
  getProductTableLoadingSelector,
  getProductTableSelector,
} from "src/app/core/ngrx/selectors/productTable.selectors";
import { ProductTableActions } from "src/app/core/ngrx/actions/ProductTable.actions";
import { ProductTable } from "src/app/shared/models/ProductTable";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-product-table",
  templateUrl: "./product-table.component.html",
  styleUrls: ["./product-table.component.scss"],
})
export class productTableComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<ProductTable>;
  displayedColumns: string[] = ["name", "number", "defaultPrice", "actions"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ProductCurrentMembershipTableSub: Subscription;
  isLoading$ = this.store.select(getProductTableLoadingSelector);
  selectedRows = [];
  searchKey: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.ProductCurrentMembershipTableSub = this.store
      .select(getProductTableSelector)
      .subscribe((t) => {
        this.dataSource = new MatTableDataSource(t);
        setTimeout((t) => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    this.store.dispatch({
      type: ProductTableActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.ProductCurrentMembershipTableSub) {
      this.ProductCurrentMembershipTableSub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  onSelect({ selected }): void {
    this.selectedRows = selected;
  }

  assessmentSelected(assessmentId: string): void {
    this.router.navigate([`/assessment/editproduct/${assessmentId}`]);
  }

  onSearchClear(): void {
    this.searchKey = "";
  }

  applyFilter(): void {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
