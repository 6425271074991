import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit = 500, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ');
    }

    let result = value.substring(0, limit);

    if(result.trim().length < value.trim().length)
      result += ellipsis;

    return result === ellipsis ? "" : result;
  }
}
