import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { AngularMaterialModule } from '../../../core/angular-material.module';
import { AssessmentModule } from '../../assessment/assessment.module';
import { ContractDashboardModule } from '../contractor-dashboard.module';
import { WorkAreasTabComponent } from './work-areas-tab/work-areas-tab.component';
import { SummaryTabComponent } from './summary-tab/summary-tab.component';
import { CompanyDetailsTabComponent } from './company-details-tab/company-details-tab.component';
import { ContactsTabComponent } from './contacts-tab/contacts-tab.component';
import { ProductsTabComponent } from './products-tab/products-tab.component';
import { InsuranceTabComponent } from './insurance-tab/insurance-tab.component';
import { TrackingHistoryTabComponent } from './tracking-history-tab/tracking-history-tab.component';
import { PanelsModule } from '../panels/panels.module';
import { GetTabComponent } from './get-tab/get-tab.component';
import { PanelComponent } from './panel/panel.component';
import { AuditHistoryTabComponent } from './audit-history-tab/audit-history-tab.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    PanelComponent,
    SummaryTabComponent,
    CompanyDetailsTabComponent,
    ContactsTabComponent,
    ProductsTabComponent,
    InsuranceTabComponent,
    WorkAreasTabComponent,
    TrackingHistoryTabComponent,
    GetTabComponent,
    AuditHistoryTabComponent,
  ],
  exports: [MatFormFieldModule, MatDialogModule],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    LoadingSpinnerModule,
    AngularMaterialModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCardModule,
    AssessmentModule,
    ContractDashboardModule,
    PanelsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ModalModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class TabsModule {}
