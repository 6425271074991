/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum WellKnownDocumentType {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6,
  NUMBER_7 = 7,
  NUMBER_8 = 8,
  NUMBER_9 = 9,
  NUMBER_10 = 10,
  NUMBER_11 = 11,
  NUMBER_12 = 12,
  NUMBER_13 = 13,
  NUMBER_14 = 14,
  NUMBER_15 = 15,
  NUMBER_16 = 16,
  NUMBER_17 = 17,
  NUMBER_18 = 18,
  NUMBER_19 = 19,
  NUMBER_20 = 20,
  NUMBER_21 = 21,
  NUMBER_22 = 22,
  NUMBER_23 = 23,
  NUMBER_24 = 24,
  NUMBER_25 = 25,
  NUMBER_26 = 26,
  NUMBER_27 = 27,
  NUMBER_28 = 28,
  NUMBER_29 = 29,
  NUMBER_30 = 30,
  NUMBER_31 = 31,
  NUMBER_32 = 32,
  NUMBER_33 = 33,
  NUMBER_34 = 34,
  NUMBER_35 = 35,
  NUMBER_36 = 36,
  NUMBER_37 = 37,
  NUMBER_38 = 38,
  NUMBER_39 = 39,
  NUMBER_40 = 40,
  NUMBER_41 = 41,
  NUMBER_42 = 42,
  NUMBER_43 = 43,
  NUMBER_44 = 44,
  NUMBER_45 = 45,
  NUMBER_46 = 46,
  NUMBER_47 = 47,
  NUMBER_48 = 48,
  NUMBER_49 = 49,
  NUMBER_50 = 50,
  NUMBER_51 = 51,
  NUMBER_52 = 52,
  NUMBER_53 = 53,
  NUMBER_54 = 54,
  NUMBER_55 = 55,
  NUMBER_56 = 56,
  NUMBER_57 = 57,
  NUMBER_58 = 58,
  NUMBER_59 = 59,
  NUMBER_60 = 60,
  NUMBER_61 = 61,
  NUMBER_62 = 62,
  NUMBER_63 = 63,
  NUMBER_64 = 64,
  NUMBER_65 = 65,
  NUMBER_66 = 66,
  NUMBER_67 = 67,
  NUMBER_68 = 68,
  NUMBER_69 = 69,
  NUMBER_70 = 70,
  NUMBER_71 = 71,
  NUMBER_72 = 72,
  NUMBER_73 = 73,
  NUMBER_74 = 74,
  NUMBER_75 = 75,
  NUMBER_76 = 76,
  NUMBER_77 = 77,
  NUMBER_78 = 78,
  NUMBER_79 = 79,
  NUMBER_80 = 80,
  NUMBER_81 = 81,
  NUMBER_82 = 82,
  NUMBER_83 = 83,
  NUMBER_84 = 84,
  NUMBER_85 = 85,
  NUMBER_86 = 86,
  NUMBER_87 = 87,
  NUMBER_88 = 88,
  NUMBER_89 = 89,
  NUMBER_90 = 90,
  NUMBER_91 = 91,
  NUMBER_92 = 92,
  NUMBER_93 = 93,
  NUMBER_94 = 94,
  NUMBER_95 = 95,
  NUMBER_96 = 96,
  NUMBER_97 = 97,
  NUMBER_98 = 98,
  NUMBER_99 = 99,
  NUMBER_100 = 100,
  NUMBER_101 = 101,
  NUMBER_102 = 102,
  NUMBER_103 = 103,
  NUMBER_104 = 104,
  NUMBER_105 = 105,
  NUMBER_106 = 106,
  NUMBER_107 = 107,
  NUMBER_108 = 108,
  NUMBER_109 = 109,
  NUMBER_110 = 110,
  NUMBER_111 = 111,
  NUMBER_112 = 112,
  NUMBER_113 = 113,
  NUMBER_114 = 114,
  NUMBER_115 = 115,
  NUMBER_116 = 116,
  NUMBER_117 = 117,
  NUMBER_118 = 118,
  NUMBER_119 = 119,
  NUMBER_120 = 120,
}
