import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  EmployeeInformationLoad,
  EmployeeInformationLoadError,
  EmployeeInformationLoadSuccess,
  EmployeeInformationSave,
  EmployeeInformationSaveError,
  EmployeeInformationSaveSuccess,
} from "../actions/employeeInformation.actions";
import { AccountHttpService } from "../../services/account/account-http.service";

@Injectable()
export class EmployeeInformationEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeInformationLoad),
      mergeMap((action) =>
        this.accountService.GetEmployeeInfo(action.accountId).pipe(
          map((b) =>
            EmployeeInformationLoadSuccess({ EmployeeInformation: b })
          ),
          catchError((error) => of(EmployeeInformationLoadError({ error })))
        )
      )
    )
  );
  Save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeInformationSave),
      mergeMap((action) =>
        this.accountService
          .SaveEmployeeInformation(action.accountId, action.employeeInformation)
          .pipe(
            map((b) => EmployeeInformationSaveSuccess()),
            catchError((error) => of(EmployeeInformationSaveError({ error })))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountHttpService
  ) {}
}
