import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { PhoneCallDirectionTypes } from '../../open-api';
import { CompanyTimeline } from '../../../shared/models/CompanyTimeline';
import { map } from 'rxjs/operators';
import { take } from 'rxjs';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class CompanyTimelineService {
  constructor(private readonly _http: HttpClient, private readonly api: ApiRoutesClass) {}

  public createTimelineNote$(note: Note): Observable<TimeLineResponse> {
    return this._http.post<TimeLineResponse>(`${this.api.Crm()}/timeline/note`, note);
  }

  public createTimelinePhoneCall$(phoneCall: PhoneCall): Observable<TimeLineResponse> {
    return this._http.post<TimeLineResponse>(`${this.api.Crm()}/timeline/phonecall`, phoneCall);
  }

  public getTimeline$(accountId: string): Observable<CompanyTimeline[]> {
    return this._http.get<CompanyTimeline[]>(`${this.api.Crm()}/timeline/${accountId}`).pipe(
      map((timeline) => {
        return timeline.map((el) => {
          return {
            ...el,
          };
        });
      }),
      take(1)
    );
  }
}

export type PhoneCall = {
  phoneCallDirection: PhoneCallDirectionTypes;
  subject: string;
  description: string;
  regardingAccountId: string;
  createdById: string;
  createdBy: string;
  fromContactId : string;
  toContactId: string;
};

export type Note = {
  subject: string;
  description: string;
  regardingAccountId: string;
  createdById: string;
  createdBy: string;
};

type TimeLineResponse = {
  entryType: number;
  subject: string;
  description: string;
  createdBy: string;
  createdOn: Date;
};
