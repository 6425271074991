<form [formGroup]="detailsForm" novalidate>
  <section fxLayout="row wrap">
    <mat-card fxFlex="650px" fxFlex.xs="100%">
      <mat-card-title>Module</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div fxLayout="row" fxLayoutGap="20px" class="form-container">
          <div>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" />
              <mat-error *ngIf="hasError('name', 'required')"
                >Name is required</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Module Type</mat-label>
              <mat-select formControlName="moduleType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let p of moduleTypes" [value]="p.id">
                  {{ p.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Assessment Type</mat-label>
              <mat-select formControlName="assessmentType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let p of assessmentTypes" [value]="p.code">
                  {{ p.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Module Number</mat-label>
              <input matInput type="text" formControlName="moduleNumber" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Variant</mat-label>
              <mat-select formControlName="variant">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let p of variants" [value]="p.id">
                  {{ p.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-card-actions align="left">
          <button
            color="primary"
            mat-raised-button
            type="button"
            [disabled]="!detailsForm.valid"
            (click)="create()"
          >
            <mat-icon>play_arrow</mat-icon>Save
          </button>
          <button
            color="primary"
            mat-raised-button
            type="button"
            (click)="onAdd()"
          >
            <mat-icon>add</mat-icon>Add
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </section>
</form>
<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="created"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ row.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>Version</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ row.version }}
      </td>
    </ng-container>
    <ng-container matColumnDef="questionSetNumber">
      <th mat-header-cell *matHeaderCellDef>question SetNumber</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ row.questionSetNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="validFrom">
      <th mat-header-cell *matHeaderCellDef>Valid From</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ row.validFrom | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="expires">
      <th mat-header-cell *matHeaderCellDef>Expires</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ row.expires | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ row.created | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <button mat-icon-button color="warn" (click)="onDelete(row)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[12, 100]"></mat-paginator>
</div>
