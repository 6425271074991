import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorFactory {
  public formatErrorToResponse(error: HttpErrorResponse): ErrorObject {
    const httpError: ErrorObject = {
      status: error.status,
      message: error.message,
      path: error.url,
    };
    console.error(httpError);
    return httpError;
  }
}

export type ErrorObject = {
  status: number;
  message: string;
  path: string;
};
