/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum VariantType {
  NUMBER_885570000 = 885570000,
  NUMBER_885570001 = 885570001,
  NUMBER_885570002 = 885570002,
  NUMBER_885570003 = 885570003,
  NUMBER_885570004 = 885570004,
  NUMBER_885570005 = 885570005,
  NUMBER_885570006 = 885570006,
}
