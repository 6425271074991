<div class='table-header'>

	<div class='container'>
		<mat-form-field>
			<input (keyup)='quickSearch($event.target.value)' matInput placeholder='Quick Search' />
		</mat-form-field>
		<mat-divider></mat-divider>
		<app-data-table #dataTable (clickActionEmitter)='assessmentSelected($event)'
						(selectionEmitter)='setSelection($event)'
						[columnNames]='columnNames'
						[dataSource]='dataSource' [displayedColumns]='displayedColumns'
						[isLoading]='isLoading' [showCheckbox]=false [enableRowClick]="false" [allowRowSelect]="false">
		</app-data-table>
	</div>
</div>
