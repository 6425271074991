/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Notification {
  id?: string;
  notificationType?: number;
  title?: string | null;
  description?: string | null;
  notificationEndDateTime?: string;
  hasBeenViewed?: boolean;
  accountId?: string;
  notificationCategory?: number;
  regardingId?: string | null;
  regardingEntityType?: number;
  regardinEntityAssociatedDate?: string | null;
  notes?: string | null;
  isActive?: boolean;
}
