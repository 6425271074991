<mat-accordion class='example-headers-align' multi>
	<mat-expansion-panel (afterExpand)="accordionState(true)" (afterCollapse)="accordionState(false)">
		<mat-expansion-panel-header>
			<mat-panel-title>
				Create new phone call
			</mat-panel-title>
		</mat-expansion-panel-header>
		<form [formGroup]='phoneCallForm' class='example-form mb-2'>
			<mat-form-field class='w-100'>
				<mat-label>Subject</mat-label>
				<input formControlName='subject' matInput min='1' type='text'>
			</mat-form-field>
			<br />
			<mat-form-field class='w-100'>
				<mat-label>Direction</mat-label>
				<mat-select formControlName='direction' required>
					<mat-option [value]='0'>Incoming</mat-option>
					<mat-option [value]='1'>Outgoing</mat-option>
				</mat-select>
			</mat-form-field>
			<br />
			<mat-form-field class='w-100'>
				<mat-label>Call From</mat-label>
				<mat-select formControlName='from' required>
					<div *ngIf="this.phoneCallForm.get('direction').value == 0; else isOutgoing">
						<mat-option
								*ngFor='let contact of contactIds | async'
								[value]='contact.id'>{{contact.fullName}}
						</mat-option>
					</div>
					<ng-template #isOutgoing>
						<mat-option *ngIf=" (currentUser | async) as name"
									[value]="name.contactId">{{name.contactname}}
						</mat-option>
					</ng-template>
				</mat-select>
			</mat-form-field>
			<br />
			<mat-form-field class='w-100'>
				<mat-label>Call To</mat-label>
				<mat-select formControlName='to'  required>
					<div *ngIf="this.phoneCallForm.get('direction').value == 1; else isInComing">
						<mat-option
								*ngFor='let contact of contactIds | async'
								[value]='contact.id'>{{contact.fullName}}
						</mat-option>
					</div>
					<ng-template #isInComing>
						<mat-option *ngIf=" (currentUser | async) as name"
									[value]="name.contactId">{{name.contactname}}
						</mat-option>
					</ng-template>
				</mat-select>
			</mat-form-field>
			<br />


			<div class='NgxEditor__Wrapper' >
				<angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
			</div>

		</form>
		<button
				(click)='resetState()'
				color='warn'
				mat-raised-button
				type='submit'
		>
			<mat-icon>cancel</mat-icon>
			Cancel
		</button>
		<button style="float: right"
			(click)='createNewPhoneCall()'
			[disabled]='phoneCallForm.invalid'
			color='primary'
			mat-raised-button
			type='submit'
		>
			<mat-icon>add</mat-icon>
			Add
		</button>

		<div *ngIf='requestPending'>
			<mat-progress-bar mode='query'></mat-progress-bar>
		</div>
	</mat-expansion-panel>
</mat-accordion>