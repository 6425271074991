import { Component, OnInit } from '@angular/core';
import { ClientModuleHttpService } from '../../core/services/Client-Module/client-module-http.service';
import { ClientModuleItem } from '../../shared/models/client-module-item';
import {firstValueFrom, Observable} from 'rxjs';
import { AccountHttpService } from '../../core/services/account/account-http.service';
import { AccountViewModel } from '../../core/open-api';

@Component({
  selector: 'app-client-modules',
  templateUrl: './client-modules.component.html',
  styleUrls: ['./client-modules.component.scss'],
})
export class ClientModulesComponent implements OnInit {
  public isLoading = true;
  public dataSource: ClientModuleItem[];
  public displayedColumns: string[];
  public accounts: AccountViewModel[];

  public assessmentTypes = [
    { id: 885570000, name: 'Assessment' },
    { id: 885570001, name: 'Self-Certify' },
    { id: 885570002, name: 'Deemed to Satisfy' },
    { id: 885570003, name: 'Verification' },
    { id: 885570004, name: 'Onsite' },
    { id: 885570005, name: 'Assured' },
  ];

  constructor(private readonly clientModuleService: ClientModuleHttpService, private readonly accountHttpService: AccountHttpService) {}

  async ngOnInit(): Promise<void> {
    this.accounts = await firstValueFrom(this.accountHttpService.getAllClients$());
    this.dataSource = await firstValueFrom(this.clientModuleService.getModules$());
    this.isLoading = false;
    this.displayedColumns = ['name', 'client', 'assessmentType', 'actions'];
  }

  public getClient(accountId: string): string {
    let acc = this.accounts.filter((x) => x.accountId == accountId)[0];
    return acc.accountName;
  }

  public getAssessmentType(assessmentTypeId: number): string {
    return this.assessmentTypes.filter((x) => x.id == assessmentTypeId)[0].name;
  }

  public async deleteClientModule(id: string) : Promise<void>{
    this.isLoading = true;
    await firstValueFrom(this.clientModuleService.deleteClientModule$(id));
    this.dataSource = await firstValueFrom(this.clientModuleService.getModules$());
    this.isLoading = false;
  }
}
