import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-business-summary-modal",
  templateUrl: "./business-summary-modal.component.html",
  styleUrls: ["./business-summary-modal.component.scss"],
})
export class BusinessSummaryModalComponent implements OnInit {
  public contractorDetails: any;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}
}
