import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { DataTableAbstract } from '../../../shared/abstract-classes/data-table-abstract/data-table-abstract';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { UIFilter } from '../../../shared/models/ui-filter';
import { DataRequestTypeEnum } from '../../../shared/enums/data-request-type-enum';
import { AlertsService } from '../../../core/services/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../../core/services/search/search.service';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { AccountService } from '../../../core/services/account/account.service';
import { BundlesService } from '../../../core/services/bundles/bundles.service';

@Component({
  selector: 'app-work-categories',
  templateUrl: './work-categories.component.html',
  styleUrls: ['./work-categories.component.scss'],
})
export class WorkCategoriesComponent extends DataTableAbstract implements OnInit {
  @Input() tableName = 'Trades';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() enableRowClick = false;
  @Input() uiFilter: UIFilter[];
  @Input() dataRequestType: DataRequestTypeEnum = DataRequestTypeEnum.Trades;
  @Input() displayedColumns = ['name', 'actions'];
  @Input() displayAssignButton: boolean;
  @Input() columnNames: Array<any> = ['Name', 'Actions'];

  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );
  }

  async ngOnInit(): Promise<void> {
    await this.retrieveDataRequest();
    super.ngOnInit();
  }
}
