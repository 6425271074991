import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../State';
import { ImsAndinsurance } from '../../../shared/models/ImsAndinsurance';
import { ImsAndinsuranceLoad, ImsAndinsuranceLoadError, ImsAndinsuranceLoadSuccess } from '../actions/imsAndinsurance.actions';

export interface State extends AppState.State {
  ImsAndinsurance: ImsAndinsuranceState;
}

export interface ImsAndinsuranceState {
  ImsAndinsurance: ImsAndinsurance;
  loading: boolean;
  error: string;
}

const initialState: ImsAndinsuranceState = {
  ImsAndinsurance: undefined,
  loading: true,
  error: '',
};

const _ImsAndinsuranceReducer = createReducer(
  initialState,
  on(ImsAndinsuranceLoad, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(
    ImsAndinsuranceLoadSuccess,
    (state, action): ImsAndinsuranceState => ({
      ...state,
      loading: false,
      ImsAndinsurance: action.ImsAndinsurance,
    })
  ),
  on(
    ImsAndinsuranceLoadError,
    (state, action): ImsAndinsuranceState => ({
      ...state,
      error: action.error,
    })
  )
);

export function ImsAndinsuranceReducer(state, action) {
  return _ImsAndinsuranceReducer(state, action);
}
