import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AssessmentLayoutComponent } from './assessment-layout/assessment-layout.component';
import { DocumentsComponent } from './documents/documents.component';
import { AssessmentPageComponent } from './assessment-page/assessment-page.component';
import { ModuleTableComponent } from './module-table/module-table.component';
import { ModuleAddOrUpdateComponent } from './module-add-or-update/module-add-or-update.component';
import { QuestionSetTableComponent } from './question-set-table/question-set-table.component';
import { productTableComponent } from './product-table/product-table.component';
import { WorkCategoriesTableComponent } from './work-categories-table/work-categories-table.component';
import { WorkCategoryAddOrUpdateComponent } from './work-category-add-or-update/work-category-add-or-update.component';
import { AssessorAddOrUpdateComponent } from './assessor-add-or-update/assessor-add-or-update.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientContactsComponent } from './clients/client-contacts/client-contacts.component';
import { ClientContactAddUpdateComponent } from './clients/client-contact-add-update/client-contact-add-update.component';
import { PanelComponent } from '../contractor-dashboard/tabs/panel/panel.component';
import { BundleTypesTableComponent } from './bundle-types-table/bundle-types-table.component';
import { BundleTypeUpdateComponent } from './bundle-type-update/bundle-type-update.component';
import { AssessorsTableControllerComponent } from './assessors-table/assessors-table-controller/assessors-table-controller.component';

const routes: Routes = [
  {
    path: '',
    component: AssessmentLayoutComponent,
    children: [
      { path: 'assess/:id', component: AssessmentPageComponent },
      { path: 'companydetails/:id', component: PanelComponent },
      { path: 'documents/:id', component: DocumentsComponent },

      { path: 'module', component: ModuleTableComponent },
      { path: 'createModule', component: ModuleAddOrUpdateComponent },
      { path: 'editModule/:id', component: ModuleAddOrUpdateComponent },

      { path: 'questionSet', component: QuestionSetTableComponent },
      { path: 'products', component: productTableComponent },

      { path: 'test', component: QuestionSetTableComponent },
      { path: 'test2', component: productTableComponent },

      { path: 'workCategories', component: WorkCategoriesTableComponent },
      {
        path: 'createWorkCategory',
        component: WorkCategoryAddOrUpdateComponent,
      },
      {
        path: 'editWorkCategory/:id',
        component: WorkCategoryAddOrUpdateComponent,
      },

      { path: 'user-management', component: AssessorsTableControllerComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'clients/:clientId/contacts', component: ClientContactsComponent },
      { path: 'clients/:clientId/contacts/add', component: ClientContactAddUpdateComponent },
      { path: 'clients/:clientId/contacts/edit/:contactId', component: ClientContactAddUpdateComponent },
      { path: 'createAssessor', component: AssessorAddOrUpdateComponent },
      { path: 'editAssessor/:id', component: AssessorAddOrUpdateComponent },
      { path: 'routing', component: BundleTypesTableComponent },
      { path: 'editRouting/:id', component: BundleTypeUpdateComponent },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssessmentRoutingModule {}
