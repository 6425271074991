export class AssessmentProgressStatus {
  public static readonly Rejected = 'Assessment Rejected';
  public static readonly Submitted = 'Submitted';
  public static readonly SubmittedAwaitingOthers = 'Submitted - Awaiting Others';
  public static readonly AwaitingAcceptance = 'Awaiting Acceptance';
  public static readonly InProgress = 'In Progress';
  public static readonly UnderAssessment = 'Under Assessment';
  public static readonly Accepted = 'Assessment Accepted';
  public static readonly Resubmitted = 'Resubmitted';
  public static readonly OnHold = 'On Hold';
  public static readonly AdditionalInfoRequired = 'Additional Information Required';
  public static readonly SubmittedForQA = 'Submitted For QA';
  public static readonly PassedManualQA = 'Passed Manual QA';
  public static readonly FailedManualQA = 'Failed Manual QA';
  public static readonly Complete = 'Complete';
  public static readonly CompleteAwaitingOthers = 'Complete - Awaiting Others';
  public static readonly Verified = 'Verified';
  public static readonly SelfCertified = 'Self Certified';
  public static readonly Accredited = 'Accredited';
  public static readonly Failed = 'Failed';
  public static readonly FailedResubmit = 'Fail Resubmit';
  public static readonly Withdrawn = 'Withdrawn';
}
