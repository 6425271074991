export enum ApiRequestTypes {
  YourActiveAssessments = 1,
  RecentAssessments = 2,
  UnverifiedDocuments = 3,
  CompanyDetails = 6,
  YourActiveBundles = 8,
  UnassignedBundles = 9,
  NewAssessments = 11,
  InProgressAssessments = 13,
  AdditionalInformationRequired = 14,
  Resubmits = 15,
  Completed = 16,
  AssessorDashboardNew = 17,
  AssessorDashboardInProgress = 18,
  UnassignedAssessments = 19,
  AssessorDashboardAdditionalInformationRequired = 20,
  AssessorDashboardResubmitted = 21,
  AssessorDashboardCompleted = 22,
  AssessorDashboardToBeAssigned = 23,
  AssessorWorkload = 24,
  RecentVerificationBundles = 25,
  CompanyVerificationBundles = 26,
  AccountVerifications = 28,
  AssessmentInvoices = 29,
  VerificationInvoices = 30,
  AssessorDashboardSubmittedForQA = 31,
  YourQAVerificationBundles = 32,
  UnassignedQAVerificationBundles = 34,
}
