import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AccountHttpService} from "../../../../core/services/account/account-http.service";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {ContactService, CreateContactCommand} from "../../../../core/open-api";

@Component({
  selector: 'app-client-contact-add-update',
  templateUrl: './client-contact-add-update.component.html',
  styleUrls: ['./client-contact-add-update.component.scss']
})
export class ClientContactAddUpdateComponent implements OnInit {

  public isLoading = true;
  public detailsForm: UntypedFormGroup;
  private CreateContactCommand: CreateContactCommand;
  public role: string;
  public routeParams = {
    clientId: undefined,
    contactId: undefined
  }

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly accountService: AccountHttpService,
              private readonly contactService: ContactService,
              private readonly formBuilder: FormBuilder) { }

  async ngOnInit(): Promise<void> {
    await this.initializeForm();
    this.routeParams = {
      clientId: this.route.snapshot.paramMap.get("clientId"),
      contactId: this.route.snapshot.paramMap.get("contactId")
    }

    this.isLoading = false;
  }

  private async initializeForm() : Promise<void> {
    this.detailsForm = this.formBuilder.group({
      accountId: [ this.routeParams.clientId, Validators.required ],
      firstName: [ null, Validators.required ],
      lastName : [null, Validators.required ],
      email: [null, [Validators.required, Validators.email] ],
      mobilePhone: [ null ],
      position: [ null ]
    });

    if (this.routeParams.contactId) {
      let contact = await firstValueFrom(this.accountService.getContact$(this.routeParams.contactId));
      this.detailsForm.setValue({
        accountId: contact.accountId,
        firstName: contact.firstName,
        lastName : contact.lastName,
        email: contact.email,
        mobilePhone: contact.mobilePhone,
        position: contact.position
      })
    }
  }

  public async onSave() : Promise<void> {
    const formValues = this.detailsForm.value;

    this.CreateContactCommand = {
      accountId: this.routeParams.clientId,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      position: formValues.position,
      mobilephone: formValues.mobilePhone
    }
    await firstValueFrom(this.contactService.saveContact(this.CreateContactCommand));
    await this.router.navigate(['/assessment/clients', this.routeParams.clientId, 'contacts' ]);
  }
}
