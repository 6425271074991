import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountSelection } from './view-models/account-selection';
import { AccountSelector } from 'src/app/core/services/account-selection/account-selector';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-select-account-menu',
  templateUrl: './select-account-menu.component.html',
  styleUrls: ['./select-account-menu.component.scss'],
})
export class SelectAccountMenuComponent implements OnInit, OnDestroy {
  @Input() userId: string;
  private selectedAccount: AccountSelection;
  private selectedAccountSubscription: Subscription;

  constructor(private accountSelector: AccountSelector) {}

  ngOnDestroy(): void {
    this.selectedAccountSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedAccountSubscription = this.accountSelector.getSubject().subscribe((x) => (this.selectedAccount = x));
    // this.accountSelector.cacheUserAccounts();
  }

  public getAccounts(): AccountSelection[] {
    return this.accountSelector.getUserAccounts();
  }

  public selectAccount(selectedAccount: AccountSelection): void {
    this.accountSelector.selectAccount(selectedAccount.id);
  }

  public getSelectedAccount(): AccountSelection {
    return this.selectedAccount;
  }
}
