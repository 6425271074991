<div class='container' *ngIf='!isLoading; else showSpinner'>
  <div class="row mb-4 mt-4">
    <ng-container *ngFor="let card of progressCards">
      <div class="col20pc">
        <app-progress-card (progressCardSelected)="onProgressCardSelection($event)" [progressCard]="card">
        </app-progress-card>
      </div>
    </ng-container>
  </div>
  <div class="row mb-4">
    <app-search-filter (filterChanged)="runSearch($event)" [parentViewEnabled]="true" [searchArgs]="searchArgs" #searchFilterComponent>
    </app-search-filter>
  </div>
  <div class="table-header">
    <mat-form-field>
      <input (keyup)="quickSearch($event.target.value)" matInput placeholder="Quick Search" />
    </mat-form-field>
    <mat-divider></mat-divider>
    <app-data-table [columnNames]='columnNames' (selectionEmitter)='setSelection($event)' [showCheckbox]=true
      (clickActionEmitter)='assessmentSelected($event)' [dataSource]='dataSource' [displayedColumns]='displayedColumns'
      [isLoading]='isLoading'></app-data-table> <!-- NEW TABLE COMPONENT -->
  </div>
</div>

<ng-template #showSpinner class="loading-spinner_container">
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>