import { Injectable } from '@angular/core';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { CompanyTimelineService } from '../company-timeline/company-timeline.service';
import { HttpClient } from '@angular/common/http';
import { Store, ActionsSubject } from '@ngrx/store';
import { selectContractorAccountId } from 'src/app/core/ngrx/selectors/contractor-account.selectors';
import { storeDispatchAsync } from 'src/app/core/ngrx/store-dispatch-async';
import { ContractorAccountActions } from 'src/app/core/ngrx/actions/contractor-account.actions';
import { ApiRoutesClass } from '../../../shared';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractorAccountService {
  constructor(
    private readonly timelineService: CompanyTimelineService,
    private readonly http: HttpClient,
    private readonly apiRoutes: ApiRoutesClass,
    private readonly store: Store,
    private readonly actions: ActionsSubject
  ) {}

  /**
   * DO NOT change the order of these calls. The order is specific and tied into the contractor-account NGRX effect mapping.
   * Changing the order will break the contractor dashboard.
   * @param accountId for the contractor company.
   */
  public retrieveData$(accountId: string): Observable<any> {
    return forkJoin([
      this.http.get(`${this.apiRoutes.Profile()}/summary/${accountId}`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Contacts()}/account/${accountId}/contacts`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Profile()}/address/${accountId}?addressType=1`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Profile()}/membership/${accountId}`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Profile()}/companyDetails/${accountId}`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.WorkArea()}/${accountId}/getaccountcombinedworkregions`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Profile()}/employee/${accountId}`).pipe(catchError(() => of({}))),
      this.timelineService.getTimeline$(accountId).pipe(catchError(() => of([]))),
      this.http
        .get(`${this.apiRoutes.WorkCategories()}/account/${accountId}/getaccountworkcategorieshierarchyall`)
        .pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Profile()}/company/${accountId}`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.Profile()}/insurance/${accountId}`).pipe(catchError(() => of({}))),
      this.http.get(`${this.apiRoutes.WorkArea()}/${accountId}/distance`).pipe(catchError(() => of({}))),
    ]);
  }

  public updateDetails$(accountId: string, payload: any, type: ContractorAccountServiceEnum): Observable<any> {
    const url: string = this.apiRouteBuilder(accountId, type);
    if (url.includes('workRegions') || url.includes('contact')) payload = [payload];
    return this.http.post(url, payload);
  }

  public apiRouteBuilder(accountId: string, type: string): string {
    switch (type) {
      case ContractorAccountServiceEnum.PrimaryContact:
        return `${this.apiRoutes.Profile()}/contact/${accountId}`;
      case ContractorAccountServiceEnum.RegisteredAddress:
        return `${this.apiRoutes.Profile()}/address/${accountId}?addressType=registeredaddress`; // resolve to either 1 or 2, hard coded for now.
      case ContractorAccountServiceEnum.MembershipSummary:
        return `${this.apiRoutes.Profile()}/membership/${accountId}`;
      case ContractorAccountServiceEnum.BusinessDetails:
        return `${this.apiRoutes.Profile()}/companyDetails/${accountId}`;
      case ContractorAccountServiceEnum.WorkAreas:
        return `${this.apiRoutes.Profile()}/workRegions/${accountId}`;
      case ContractorAccountServiceEnum.CompanySummary2:
        return `${this.apiRoutes.Profile()}/employee/${accountId}`;
      case ContractorAccountServiceEnum.Summary:
        return `${this.apiRoutes.Profile()}/summary/${accountId}`;
      case ContractorAccountServiceEnum.Company:
        return `${this.apiRoutes.Profile()}/company/${accountId}`;
      default:
        return;
    }
  }

  public refreshTimeline$(accountId: string): Observable<any> {
    return this.timelineService.getTimeline$(accountId).pipe(catchError(() => of([])));
  }

  public retrieveContacts$(accountId: string): Observable<any> {
    return this.http.get(`${this.apiRoutes.Contacts()}/account/${accountId}/contacts`).pipe(catchError(() => of({})));
  }

  public async refreshStoreContacts$(): Promise<void> {
    const accountId: string = await firstValueFrom(this.store.select(selectContractorAccountId));
    await storeDispatchAsync(this.store, this.actions, ContractorAccountActions.RefreshContactsSuccess, {
      type: ContractorAccountActions.RefreshContacts,
      accountId: accountId,
    });
  }
}

export enum ContractorAccountServiceEnum {
  PrimaryContact = 'PrimaryContact',
  RegisteredAddress = 'RegisteredAddress',
  MembershipSummary = 'MembershipSummary',
  CompanySummary2 = 'CompanySummary2',
  WorkAreas = 'WorkAreas',
  BusinessDetails = 'BusinessDetails',
  Summary = 'Summary',
  Company = 'Company',
}
