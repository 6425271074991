<div class="mt-3">
  <mat-form-field>
    <input (keyup)="quickSearch($event.target.value)" matInput placeholder="Quick Search" />
  </mat-form-field>
</div>
<app-data-table-button-bar [assignAssessor]="true" [enabledButtons]='enabledButtons' (assignAssessorClick)="openAssessorChangeDialog()">
</app-data-table-button-bar>
<mat-divider></mat-divider>
<app-data-table [columnNames]='columnNames' (selectionEmitter)='setSelection($event)'
                [showCheckbox]=true (clickActionEmitter)='assessmentSelected($event)' [dataSource]='dataSource'
                [displayedColumns]='displayedColumns' [isLoading]='isLoading' [enableRowClick]="enableRowClick">
</app-data-table>
