<div *ngIf="isLoading; else content" class="loading-spinner_container">
  <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #content>
  <a [fragment]="dataTableId" [id]="dataTableId"></a>
  <mat-table class="table" [dataSource]="dataSource" mat-table matSort>
    <ng-container *ngFor="let data of displayedColumns; index as i">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            *ngIf="showCheckbox"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (change)="selection.toggle(row); selectActionRequest()"
            (click)="$event.stopPropagation()"
            *ngIf="showCheckbox || !row.paid"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <!-- Grid Views -->
      <ng-container [matColumnDef]="data">
        <mat-header-cell *matHeaderCellDef [sortActionDescription]="data" mat-sort-header>
          {{ columnNames[i] }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let y = index">
          <span *ngIf="element[data] | isLink; else notLink">
            <a target="_blank" [href]="element[data]"><mat-icon>cloud_download</mat-icon></a>
          </span>
          <span [hidden]="true">{{ element[data] }}</span>
          <ng-template #notLink>
            <div *ngIf="columnNames[i] == 'Assessor Fee'; else notAssessorFee">
              <input
                matInput
                type="number"
                #assessorFee
                [value]="element[data]"
                (change)="updateAssessorFees(parseInt(assessorFee.value), y)"
              />
            </div>
            <ng-template #notAssessorFee>
              <div [innerHTML]="element[data] | dataFormatter : columnNames[i]"></div>
            </ng-template>
          </ng-template>
        </mat-cell>
      </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row (click)="clickActionRequest(row)" *matRowDef="let row; columns: displayedColumns; let y = index"></mat-row>
  </mat-table>
  <div *ngIf="dataSource?.data?.length <= 0" ngClass="d-flex justify-content-center pt-3">
    <p>No data to display</p>
  </div>
  <button *ngIf="assessorFeesSaveButton" (click)="saveAssessorFeesEmitter.emit(updatedAssessorFees)" color="primary" mat-raised-button>
    Save
  </button>
  <button
    mat-button
    *ngIf="viewType == apiRequestTypes.AssessorDashboardCompleted || assessorFeesSaveButton"
    [matMenuTriggerFor]="menu"
    color="primary"
    mat-raised-button
    style="float: right"
  >
    Export Data
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="exportTable(dataExportFileTypes.JSON)">JSON</button>
    <button mat-menu-item (click)="exportTable(dataExportFileTypes.CSV)">CSV</button>
    <button mat-menu-item (click)="exportTable(dataExportFileTypes.EXCEL)">EXCEL</button>
  </mat-menu>
</ng-template>
<mat-paginator [pageSizeOptions]="[10, 20, 50]" aria-label="Select page of assessors" showFirstLastButtons> </mat-paginator>
