import { Component, Input, OnInit } from '@angular/core';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { ActionsSubject, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { AssessmentListScope } from 'src/app/shared/enums/assessment-list-scope';
import { AssessorDashboardAbstract } from '../../../shared/abstract-classes/assessor-dashboard-abstract/assessor-dashboard-abstract';
import { AccountSelectorService } from 'src/app/core/services/account-selection/account-selector.service';
import { DataRequestTypeEnum } from '../../../shared/enums/data-request-type-enum';
import { SearchService } from '../../../core/services/search/search.service';
import { AccountService } from '../../../core/services/account/account.service';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { AlertsService } from '../../../core/services/alerts/alerts.service';

@Component({
  selector: 'app-assessor-workload-details',
  templateUrl: '../../membership-dashboard/membership-dashboard.component.html',
  styleUrls: ['../../membership-dashboard/membership-dashboard.component.scss'],
})
export class AssessorWorkloadDetails extends AssessorDashboardAbstract implements OnInit {
  @Input() assessorId: string = '';
  @Input() tableName: string = '';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() displayAssignButton: boolean;
  @Input() enableRowClick: boolean;
  public displayedColumns = [];
  public multigridDashboardButton: typeof MultigridDashboardButton = MultigridDashboardButton; // NOTE: Expose enum to view
  public filterName = null;

  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute,
    protected accountSelectorService: AccountSelectorService
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );

    this.dataRequestType = DataRequestTypeEnum.Assessment;
  }

  async ngOnInit() {
    this.enabledButtons = [MultigridDashboardButton.AssignAssessor];
    this.enableRowClick = true;
    this.displayAssignButton = true;
    this.apiRequestType = ApiRequestTypes.AssessorWorkload;
    this.assessmentListScope = AssessmentListScope.Assessor;
    this.uiFilter = [
      { property: 'accountId', value: this.accountSelectorService.getUserAccounts()[0].id },
      { property: 'assessorId', value: this.assessorId },
    ];
    this.displayedColumns = ['select', 'ContractorName', 'AssessmentName', 'CreatedDate', 'Status', 'IncompleteSections'];
    this.columnNames = ['select', 'Contractor Name', 'Product', 'Submission Date', 'Status', 'Incomplete Sections'];
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType);
    super.ngOnInit();
  }
}
