<div class="text-center" *ngIf="isLoading$ | async; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
<div *ngIf='showHideTableView(permissionsEnum.AccreditationSummary) | async'>
  <form [formGroup]="detailsForm" novalidate>
    <div class="form-group row">
      <label class="col-md-5">Accreditation Level</label>
      <div class="col-md-7">
        <div *ngIf="!readOnly; else accreditationLevel">
          <select
              id="accreditationLevel"
              class="custom-select"
              formControlName="accreditationLevel"
          >
            <option *ngFor="let option of options" [ngValue]="option.value">
              {{ option.name }}
            </option>
          </select>

        </div>
        <ng-template #accreditationLevel>
          <input [readOnly]='true'
                 [placeholder]='accreditationLevelName '
                 class="form-control"
                 type="text"

          />
        </ng-template>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-5">Accreditation Expiry</label>
      <div class="col-md-7">
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input [readonly]='readOnly'
                   [value]='detailsForm.value.accreditationExpiry | date: "dd/MM/yyyy" '
                matInput
                [matDatepicker]="picker"
                id="accreditationExpiry"
                formControlName="accreditationExpiry"
                title="accreditationExpiry"
            />
            <mat-datepicker-toggle [hidden]='readOnly'
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
    </div>
    <app-action-buttons [readOnly]='readOnly' [authentication]='permissionsEnum.AccreditationSummaryEditButton' (saveDataEmitter)='onSave()' (isReadOnly)='readOnly=$event'></app-action-buttons>
  </form>
</div>
</ng-template>
