import {Component, OnInit} from '@angular/core';
import {AccountHttpService} from "../../../core/services/account/account-http.service";
import {AccountViewModel} from "../../../core/open-api";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  public isLoading = true;
  public dataSource: AccountViewModel[];
  public displayedColumns: string[];

  constructor(private readonly accountService: AccountHttpService) { }

  async ngOnInit(): Promise<void> {
    this.displayedColumns = [ 'accountName' ];
    this.dataSource = await firstValueFrom(this.accountService.getAllClients$());
    this.isLoading = false;
  }
}

