<div class="container">
  <h2>Contractor Contacts</h2>
  <div class="container">
          <div class="table-header">
            <mat-form-field>
              <input
                matInput
                (keyup)="quickSearch($event.target.value)"
                placeholder="Quick Search"
              />
            </mat-form-field>
          </div>
    <app-data-table [columnNames]='columnNames' (selectionEmitter)='setSelection($event)' [showCheckbox]=true  (clickActionEmitter)='contractorSelected($event)' [dataSource]='dataSource' [isLoading]='isLoading' [displayedColumns]='displayedColumns'></app-data-table>
  </div>
</div>
