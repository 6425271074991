export class AssessmentStatus {
  public static readonly AssessmentComplete: number = 885570008;
  public static readonly FailedToResubmit: number = 885570001;
  public static readonly Failed: number = 885570000;
  public static readonly RequestFurtherInformation: number = 870520003;
  public static readonly AssessmentAccepted: number = 885570005;
  public static readonly AssessmentRejected: number = 885570006;
  public static readonly PassedManualQA: number = 885570020;
  public static readonly FailedManualQA: number = 885570021;
}
