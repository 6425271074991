import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RouteDetectionService {
  constructor(private readonly router: Router) {}

  /**
   * Refactor if needed to support determining multiple routes. Currently not required.
   */
  public isAssessmentRoute$(): Observable<boolean> {
    return this.router.events
    .pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      map((route) => {
        return !!route.url.includes("assessment/assess");
      })
    );
  }
}
