import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { PermissionsEnum } from '../../enums/permissions-enum';
import { PermissionsMap } from '../../maps/permissions-map';

@Component({
  selector: 'app-data-table-button-bar',
  templateUrl: './data-table-button-bar.component.html',
  styleUrls: ['./data-table-button-bar.component.scss'],
})
export class DataTableButtonBarComponent {
  @Input()
  disabled = false;

  @Input()
  enabledButtons;

  @Input()
  assignAssessor = false;

  @Output()
  assignAssessorClick: EventEmitter<void> = new EventEmitter();

  @Input()
  assignAssessorCompany = false;

  @Output()
  assignAssessorCompanyClick: EventEmitter<void> = new EventEmitter();

  @Input()
  changeStatus = false;

  @Output()
  changeStatusClick: EventEmitter<void> = new EventEmitter();

  @Input()
  changeTargetDate = false;

  @Output()
  changeTargetDateClick: EventEmitter<void> = new EventEmitter();

  @Input()
  changeDecisionDate = false;

  @Output()
  changeDecisionDateClick: EventEmitter<void> = new EventEmitter();

  @Input()
  changeExpiryDate = false;

  @Output()
  changeExpiryDateClick: EventEmitter<void> = new EventEmitter();

  public permissionEnum: typeof PermissionsEnum = PermissionsEnum; // NOTE: Expose enum to view
  public multigridDashboardButton = MultigridDashboardButton; // NOTE: Expose enum to view

  constructor(protected permissionsService: PermissionsService) {}

  public isButtonEnabled(button: MultigridDashboardButton): boolean {
    return this.enabledButtons.includes(button);
  }

  public hasPermission(permissionRequestId: string): Observable<boolean> {
    const permissionId = PermissionsMap.get(permissionRequestId as PermissionsEnum);
    return this.permissionsService.authenticateUser$(permissionId);
  }
}
