/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CompanySearchResult {
  id?: string | null;
  readonly displayName?: string | null;
  companyName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  town?: string | null;
  area?: string | null;
  postcode?: string | null;
  countryCode?: string | null;
  readonly addressSummary?: string | null;
  systemName?: string | null;
  systemId?: string | null;
  abNumber?: string | null;
  acNumber?: string | null;
  isAusGstRegistered?: boolean;
  companyType?: string | null;
  ausState?: string | null;
  companiesHouseNumber?: string | null;
  isExactMatchOnSystemId?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
}
