import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductTableState } from "../reducers/ProductTable.reducer";

export const selectProductTableFeatureState =
  createFeatureSelector<ProductTableState>("ProductTable");

export const getProductTableSelector = createSelector(
  selectProductTableFeatureState,
  (state) => state.Products
);

export const getProductTableLoadingSelector = createSelector(
  selectProductTableFeatureState,
  (state) => state.loading
);
