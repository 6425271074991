<div class="mychas-login">
    <div class="mychas-login__container">
        <button mat-raised-button
            color="primary"
            (click)="loginToMyChas()"
            [disabled]="loginToMyChasLoading"
            [hidden]="loginToMyChasLoading"
        tooltip="Allow pop ups before using this button">
            <i class="fas fa-arrow-right"></i>
            Login to MYCHAS
        </button>	
        <div *ngIf="loginToMyChasLoading" class="mychas-login__container-spinner">
            <app-loading-spinner></app-loading-spinner>
            <small>Logging in...</small>
        </div>
        <p *ngIf="loginToMyChasError" class="u-text-error">There has been an error logging you in, please try again.</p>
    </div>
</div>