import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../../../core/services/search/search.service';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { AssessorDashboardAbstract } from '../../../shared/abstract-classes/assessor-dashboard-abstract/assessor-dashboard-abstract';
import { AssessmentListScope } from 'src/app/shared/enums/assessment-list-scope';
import { DataRequestTypeEnum } from '../../../shared/enums/data-request-type-enum';
import { AccountService } from '../../../core/services/account/account.service';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { AlertsService } from '../../../core/services/alerts/alerts.service';
import { selectCurrentUser } from '../../../core/ngrx/selectors/currentUser.selectors';
import { Subscription } from 'rxjs/internal/Subscription';
import { CurrentUserState } from '../../../core/ngrx/reducers/CurrentUser.reducer';

@Component({
  selector: 'app-assessor-company-dashboard',
  templateUrl: './assessor-company-dashboard.component.html',
  styleUrls: ['./assessor-company-dashboard.component.scss'],
})
export class AssessorCompanyDashboardComponent extends AssessorDashboardAbstract implements OnInit, OnDestroy {
  @Input() displayedColumns = [
    'select',
    'ContractorName',
    'AssessorCompanyName',
    'AssignedAssessor',
    'Product',
    'Submissiondate',
    'Status',
    'IncompleteSections',
  ];
  @Input() columnNames = [
    'select',
    'Contractor Name',
    'Assessor Company Name',
    'Assigned Assessor',
    'Product',
    'Date Submitted',
    'Status',
    'Incomplete Sections',
  ];
  @Input() showAssignAssessorButton = true;
  @Input() apiRequestType = ApiRequestTypes.AssessorDashboardNew;
  @Input() assessmentListScope = AssessmentListScope.AssessorCompany;
  @Input() enabledButtons = [MultigridDashboardButton.AssignAssessor];
  @Input() uiFilter = [];
  private subscriptions$: Subscription;

  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );

    this.dataRequestType = DataRequestTypeEnum.Assessment;
  }

  ngOnInit(): void {
    this.subscriptions$ = this.store.select(selectCurrentUser).subscribe((user: CurrentUserState) => {
      this.retrieveAssessorLevel('parent');
      this.uiFilter = [{ property: 'accountId', value: user.currentAccount.accountId }];
      super.ngOnInit();
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    super.ngOnDestroy();
  }
}
