<div *ngFor="let gridView of gridViews">
    <div *ngIf="showHideTableView(gridView.permissionRequestName) | async">
        <app-your-dashboard-component
                                            [enabledButtons]="gridView.enabledButtons"
                                            [apiRequestType]="gridView.apiRequestType"
                                            [enableRowClick]="gridView.enableRowClick"
                                            [tableName]="gridView.tableName"
                                            [displayAssignButton]="gridView.displayAssignButton"
                                            [dataRequestType]='gridView.dataRequestType'
                                            [uiFilter]='gridView.uiFilter'
                                            [displayedColumns]='gridView.displayedColumns'
                                            [columnNames]='gridView.columnNames'>
        </app-your-dashboard-component>
    </div>
</div>
