import { AssessmentStatus } from 'src/app/shared/classes/assessment-status';
import { AssessmentSummary } from '../../../../shared';
import { AssessmentProgressStatus } from '../../../../shared/classes/assessment-progress-status';
import { AssessmentService } from '../assessment.service';
import { AssessmentAction } from './assessment-action';

export class FailedManualQAAction extends AssessmentAction {
  constructor(
    assessmentService: AssessmentService,
    private readonly assessmentId: string,
    private readonly answers: any,
    private readonly comment: string,
    private readonly assessmentSummary: AssessmentSummary
  ) {
    super(assessmentService);
  }

  async run(): Promise<any> {
    await this.assessmentService.updateAssessment(this.assessmentId, {
      answers: this.answers,
      status: AssessmentStatus.FailedManualQA,
      comment: this.comment,
    });

    const oldStatus = this.assessmentSummary.Status;
    this.assessmentSummary.Status = AssessmentProgressStatus.FailedManualQA;
    this.assessmentService.update(this.assessmentSummary, oldStatus);
  }
}
