import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiRoutesClass } from '../../../shared';
import { UIFilter } from '../../../shared/models/ui-filter';

@Injectable({
  providedIn: 'root',
})
export class MarkAssessmentPaidService {
  constructor(private readonly http: HttpClient, private readonly apiRoutesClass: ApiRoutesClass) {}

  public getInvoicesAssessments$(uiFilter: UIFilter[]): Observable<ManualAssessorFees[]> {
    let params = null;
    if (uiFilter) {
      let body = JSON.stringify(uiFilter);
      body = body.replace('&', '%26');
      params = new HttpParams({
        fromString: `filter=${body}`,
      });
    }

    return this.http.get<ManualAssessorFees[]>(`${this.apiRoutesClass.assessorCosts()}`, {
      responseType: 'json',
      params: params ? params : null,
    });
  }

  public markAsPaid$(payload: SaveAssessorFees[]): Observable<object> {
    return this.http.post(`${this.apiRoutesClass.assessorCosts()}`, payload);
  }
}

export type ManualAssessorFees = {
  assessorName: string;
  assessorOrganisationName: string;
  accountName: string;
  bundleType: string;
  cost: number;
  actionedBy: Date;
  actionedById: string;
  actionedDate: Date;
  assessorId: string;
  bundleId: string;
  id: string;
};

export type SaveAssessorFees = {
  id: string;
  bundleId: string;
  assessorId: string;
  cost: number;
  actionedById: string;
  actionedDate: Date | null;
};
