import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { ContactsEmail } from '../../../shared/models/ContactsEmail';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class TrackingEventService {
  constructor(private readonly _http: HttpClient, private readonly api: ApiRoutesClass) {}

  public retrieveTrackingEvents(accountId: string): Observable<TrackingEvent[]> {
    return this._http.get<TrackingEvent[]>(`${this.api.Audit()}/${accountId}/tracking`);
  }

  public retrieveAuditHistory(accountId: string): Observable<AuditEvent[]> {
    return this._http.get<AuditEvent[]>(`${this.api.Audit()}/${accountId}/audit`);
  }
}

type AuditEvent = {
  entityName: string;
  user: string;
  oldValue: string[],
  newValue: string[],
  date: Date
}

type TrackingEvent = {
  eventType: string;
  entityType: string;
  name: string;
  actionBy: ContactsEmail;
  data: object;
  timeStamp: Date;
};
