import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Contact } from "src/app/shared";

@Component({
  selector: "app-contact-field-display",
  templateUrl: "./contact-field-display.component.html",
  styleUrls: ["./contact-field-display.component.scss"],
})
export class ContactFieldDisplayComponent implements OnInit {
  @Input() contacts: Contact[];
  modalRef: BsModalRef;

  columns = [
    { prop: "name", name: "Name" },
    { prop: "position", name: "Position" },
    { prop: "email", name: "Email" },
    { prop: "phone", name: "Phone" },
  ];

  constructor(private modalService: BsModalService) {
    this.contacts = [];
  }

  ngOnInit(): void {}

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass("modal-lg");
  }

  get primaryContactName(): string {
    if (this.contacts.length <= 0) {
      return "";
    }
    return this.contacts[0].name;
  }
}
