import { AssessmentService } from '../assessment.service';
import { AssessmentAction } from './assessment-action';

export class ProgressiveSaveAction extends AssessmentAction {
  constructor(
    assessmentService: AssessmentService,
    private readonly assessmentId: string,
    private readonly answers: any,
    private readonly incompleteSections: number
  ) {
    super(assessmentService);
  }

  async run(): Promise<any> {
    await this.assessmentService.updateAssessment(this.assessmentId, {
      answers: this.answers,
      incompleteSections: this.incompleteSections,
    });
  }
}
