/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Certificate {
  id?: string;
  name?: string | null;
  description?: string | null;
  templateName?: string | null;
  templateFileType?: string | null;
  bodyText?: string | null;
  isActive?: boolean;
  createdBy?: string;
  createdUtc?: string;
  updatedBy?: string | null;
  updatedUtc?: string | null;
  readonly fileName?: string | null;
}
