import { Injectable } from '@angular/core';
import { AccountSelector } from 'src/app/core/services/account-selection/account-selector';
import { AccountSelection } from 'src/app/shared/components/select-account-menu/view-models/account-selection';
import { AvailableAccountSelection } from './viewModel/available-account-selection';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { UserAuthenticationAbstract } from '../authentication/user-authentication-abstract';
import { CheckNewAuthenticationResponse, CompanyDetails, LatestBundleResponse } from 'src/app/shared/models/CheckNewAuthenticationResponse';
import { CurrentUserState } from '../../ngrx/reducers/CurrentUser.reducer';
import { firstValueFrom } from 'rxjs';
import { ApiRoutesClass } from '../../../shared';
import { ActionsSubject, Store } from '@ngrx/store';
import {
  selectCurrentUser,
  selectCurrentUserBundles,
  selectCurrentUserCompanyDetails,
  selectCurrentUserCurrentAccount,
} from '../../ngrx/selectors/currentUser.selectors';
import { CurrentUserActionsEnum } from '../../ngrx/actions/currentUser.Actions';
import { storeDispatchAsync } from '../../ngrx/store-dispatch-async';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectorService implements AccountSelector {
  private accountSelection: Map<string, AccountSelection>;
  private readonly selectedAccountSubject: ReplaySubject<AccountSelection>;
  private companyDetails: CompanyDetails;
  private data: any;
  private bundles: LatestBundleResponse[];
  private authAccount: CheckNewAuthenticationResponse;

  constructor(
    private http: HttpClient,
    private userAuthentication: UserAuthenticationAbstract,
    private readonly api: ApiRoutesClass,
    private readonly store: Store,
    private readonly actions: ActionsSubject
  ) {
    this.accountSelection = new Map<string, AccountSelection>();
    this.selectedAccountSubject = new ReplaySubject(1);
    this.selectedAccountSubject.next(new AvailableAccountSelection('', '', 'Loading Accounts...', 'Child'));
  }

  getAccount(id: string): AccountSelection {
    return this.accountSelection.get(id);
  }

  selectAccount(id: string): void {
    this.selectedAccountSubject.next(this.getAccount(id));
  }

  getSubject(): Subject<AccountSelection> {
    this.cacheUserAccounts();
    return this.selectedAccountSubject;
  }

  /**
   * On page refresh retrieve the currentUser from the session and hydrate the store.
   * @returns parsed currentUser object from session storage
   * @type Promise
   */
  public async getCurrentActiveUser(): Promise<CurrentUserState> {
    const currentUser: string = sessionStorage.getItem('currentuserstate');

    if (currentUser) {
      await storeDispatchAsync(this.store, this.actions, '', {
        type: CurrentUserActionsEnum.StoreCurrentUser,
        currentUser: JSON.parse(currentUser),
      });
      return JSON.parse(currentUser);
    }

    return null;
  }

  private buildAccountSummary(data: any): CurrentUserState {
    const summary: CurrentUserState = {
      loaded: data.loaded,
      allAccounts: data.allAccounts,
      currentAccount: data.currentAccount,
      contacts: data.contacts,
      roles: data.roles,
      companyDetails: data.companyDetails,
      bundles: data.bundles,
      priceLists: null,
      isFreeMembership: data.isFreeMembership,
      featureFlags: null,
      permissions: data.permissions,
      opsPortalDefaultRoute: data.opsPortalDefaultRoute,
    };
    return summary;
  }

  public async cacheUserAccounts(): Promise<void> {
    this.data = await firstValueFrom(this.store.select(selectCurrentUser));
    this.authAccount = await firstValueFrom(this.store.select(selectCurrentUserCurrentAccount));
    this.bundles = await firstValueFrom(this.store.select(selectCurrentUserBundles));
    this.companyDetails = await firstValueFrom(this.store.select(selectCurrentUserCompanyDetails));
    this.buildAccountSummary(this.data);
    this.selectedAccountSubject.next(
      new AvailableAccountSelection(this.authAccount?.accountId, this.authAccount?.currentContactId, this.authAccount?.accountName, 'Child')
    );
    this.data.allAccounts.forEach((x: any) => {
      this.accountSelection.set(x.accountId, new AvailableAccountSelection(x.accountId, x.contactId, x.accountName, 'Child'));
    });
  }

  getUserAccounts(): AccountSelection[] {
    return Array.from(this.accountSelection.values());
  }
}
