<div class="d-flex">
    <h2 class="mr-auto">{{ module?.name }} - Question Sets</h2>
    <button class="mr-3" color="secondary" mat-raised-button [routerLink]="['/', 'client-modules']" >Back</button>
    <button color="primary" mat-raised-button (click)="openUploadDialog()">Upload</button>
</div>
<div *ngIf="isLoading; else displayTable" class="mt-3">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>

<ng-template #displayTable>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>Version</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.version }}
                </td>
            </ng-container>
            <ng-container matColumnDef="questionSetNumber">
                <th mat-header-cell *matHeaderCellDef>Number</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.questionSetNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="validFrom">
                <th mat-header-cell *matHeaderCellDef>Valid From</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.validFrom | date: "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="createdUtc">
                <th mat-header-cell *matHeaderCellDef>Created</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.created | date: "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="expires">
                <th mat-header-cell *matHeaderCellDef>Expires</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.expires | date: "dd/MM/yyyy" }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-template>