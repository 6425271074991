<h2 mat-dialog-title>Upload File</h2>

<div mat-dialog-content>
    <button mat-raised-button color="primary" (click)="fileInput.click()" [disabled]="showLoadingBar">Choose File</button>
    <input matInput type="file" hidden #fileInput (change)="handleChange($event.target.files)" />

    <div *ngIf="showLoadingBar">
        <mat-progress-bar mode="query"></mat-progress-bar>
    </div>

    <div class="p-3 bg-danger text-white"  *ngIf="this.error"> {{ this.error }}</div>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>