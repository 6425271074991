<div class="card__body text-center py-2">
    <button *ngIf='retrieveOldStatus.DisplayStatus == AssessmentStatus.Submitted
        || retrieveOldStatus.DisplayStatus == AssessmentStatus.SubmittedAwaitingOthers'
        (click)="requestStatusChange(AssessmentStatus.InProgress)" [disabled]='isLoading'
        class="btn btn-lg mr-2 btn-primary">
        Change to In Progress
    </button>
    <button *ngIf='retrieveOldStatus.DisplayStatus == AssessmentStatus.AdditionalInfoRequired'
        (click)="requestStatusChange(AssessmentStatus.Submitted)" [disabled]='isLoading'
        class="btn btn-lg mr-2 btn-primary">
        Change to Submitted
    </button>
    <button *ngIf='retrieveOldStatus.DisplayStatus == AssessmentStatus.AdditionalInfoRequired'
        (click)="requestStatusChange(AssessmentStatus.Complete)" [disabled]='isLoading'
        class="btn btn-lg mr-2 btn-success">
        Change to Completed
    </button>
    <button *ngIf='retrieveOldStatus.DisplayStatus ==  AssessmentStatus.Resubmitted || AssessmentStatus.Submitted || AssessmentStatus.Failed && retrieveOldStatus.DisplayStatus !== AssessmentStatus.AdditionalInfoRequired'
        (click)="requestStatusChange(AssessmentStatus.AdditionalInfoRequired)" [disabled]='isLoading'
        class="btn btn-lg mr-2 btn-primary">
        Change to Additional Information Required
    </button>
</div>
<div *ngIf='isLoading'>
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>
