import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectContractorAccountContact } from 'src/app/core/ngrx/selectors/contractor-account.selectors';
import { Store } from '@ngrx/store';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Contact } from 'src/app/core/open-api';
import { ContractorAccountService } from 'src/app/core/services/company-account/contractor-account.service';

@Component({
  selector: 'app-contacts-tab',
  templateUrl: './contacts-tab.component.html',
  styleUrls: ['./contacts-tab.component.scss'],
})
export class ContactsTabComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public contacts$: Observable<Contact[]>;
  public contacts: Contact[];
  private contactsSubscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly contractorAccountService: ContractorAccountService,
    protected readonly fb: FormBuilder
  ) {
    this.contacts$ = this.store.select(selectContractorAccountContact);
  }

  async ngOnInit(): Promise<void> {
    this.contactsSubscription = this.store.select(selectContractorAccountContact).subscribe((contacts: Contact[]) => {
      if (contacts && contacts.length > 0) {
        this.contacts = this.sortContacts(contacts);
      }
    });
    await this.refreshContacts();
  }

  public ngOnDestroy(): void {
    this.contactsSubscription.unsubscribe();
  }

  async refreshContacts(): Promise<void> {
    await this.contractorAccountService.refreshStoreContacts$();
    this.contacts = this.sortContacts(await firstValueFrom(this.store.select(selectContractorAccountContact)));
  }

  private sortContacts(contacts: Contact[]): Contact[] {
    return contacts.sort((a, b) => {
      if (a.isPrimaryContact === b.isPrimaryContact) {
        return (a.firstName || '').localeCompare(b.firstName || '');
      }
      return a.isPrimaryContact ? -1 : 1;
    });
  }
}
