import { createReducer, on } from "@ngrx/store";
import { ModuleTable } from "src/app/shared/models/ModuleTable";
import * as AppState from "../../../State";
import {
  ModuleTableLoad,
  ModuleTableLoadError,
  ModuleTableLoadSuccess,
} from "../actions/ModuleTable.actions";

export interface State extends AppState.State {
  modules: ModuleTable[];
}

export interface ModuleTableState {
  modules: ModuleTable[];
  loading: boolean;
  error: string;
}

const initialState: ModuleTableState = {
  modules: undefined,
  loading: true,
  error: "",
};

const _ModuleTableReducer = createReducer(
  initialState,
  on(ModuleTableLoad, (state, action) => ({
    ...state,
    loading: true,
    error: "",
  })),
  on(
    ModuleTableLoadSuccess,
    (state, action): ModuleTableState => ({
      ...state,
      loading: false,
      error: "",
      modules: action.modules,
    })
  ),
  on(
    ModuleTableLoadError,
    (state, action): ModuleTableState => ({
      ...state,
      error: action.error,
    })
  )
);

export function ModuleTableReducer(state, action) {
  return _ModuleTableReducer(state, action);
}
