import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-panel-action-buttons',
  templateUrl: './panel-action-buttons.component.html',
  styleUrls: ['./panel-action-buttons.component.scss'],
})
export class PanelActionButtonsComponent {
  @Input() public loading = false;

  @Output() submitEmitter = new EventEmitter();
  @Output() buttonStateEmitter = new EventEmitter();

  @Input() editState = false;

  public emitButtonState(state: boolean): void {
    this.buttonStateEmitter.emit(state);
  }
}
