import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";

import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";

import { FIRSummaryActions } from "src/app/core/ngrx/actions/FIRSummary.actions";
import { FIRSummary } from "src/app/shared/models/FIRSummary";
import {
  getFIRSummaryLoadingSelector,
  getFIRSummarySelector,
} from "src/app/core/ngrx/selectors/FIRSummary.selectors";

@Component({
  selector: "app-FIR-Summary",
  templateUrl: "./FIR-Summary.component.html",
  styleUrls: ["./FIR-Summary.component.scss"],
})
export class FIRSummaryComponent implements OnInit, OnDestroy {
  companyDetailsSub: Subscription;
  isLoading$ = this.store.select(getFIRSummaryLoadingSelector);
  detailsForm = this.initializeForm();

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.companyDetailsSub = this.store
      .select(getFIRSummarySelector)
      .subscribe((t) => {
        this.detailsForm = this.initializeForm(t);
      });
    this.store.dispatch({
      type: FIRSummaryActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.companyDetailsSub) {
      this.companyDetailsSub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  initializeForm(account: FIRSummary = {} as FIRSummary): UntypedFormGroup {
    return this.fb.group({
      firAccreditationExpiryDate: [account.firAccreditationExpiryDate || ""],
      firMembershipExpiryDate: [account.firMembershipExpiryDate || ""],
      firAccreditationLevel: [account.firAccreditationLevel || ""],
    });
  }
}
