import { Component, Input, OnInit } from "@angular/core";

import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-contacts-and-employees",
  templateUrl: "./contacts-and-employees.component.html",
  styleUrls: ["./contacts-and-employees.component.scss"],
})
export class ContactsAndEmployeesComponent implements OnInit {
  _parent: UntypedFormGroup;
  get parent(): UntypedFormGroup {
    return this._parent;
  }

  @Input()
  set parent(value: UntypedFormGroup) {
    this._parent = value;
  }

  constructor() {}

  ngOnInit(): void {}

  get readOnly() {
    return true;
  }
}
