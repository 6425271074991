import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "assessmentDisplay",
})
export class AssessmentDisplayPipe implements PipeTransform {
  assessmentDisplayMap: Map<string, string>;

  constructor() {
    this.assessmentDisplayMap = new Map([["Submitted", "Awaiting Acceptance"]]);
  }

  transform(value: string, ...args: unknown[]): string {
    const displayStatus: string = this.assessmentDisplayMap.get(value);
    return displayStatus !== undefined ? displayStatus : value;
  }
}
