import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  QuestionSetTableLoad,
  QuestionSetTableLoadError,
  QuestionSetTableLoadSuccess,
} from "../actions/QuestionSetTable.actions";
import { QuestionSetHttpService } from "../../services/account/QuestionSetHttpService";

@Injectable()
export class QuestionSetTableEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetTableLoad),
      mergeMap((action) =>
        this.accountService.getQuestionSetTable().pipe(
          map((b) =>
            QuestionSetTableLoadSuccess({
              modules: b,
            })
          ),
          catchError((error) => of(QuestionSetTableLoadError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: QuestionSetHttpService
  ) {}
}
