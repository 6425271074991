<div class="form-group row">
  <div class="col-md-4">
    <strong
      ><label class="col-form-label">
        {{ name }}
      </label></strong
    >
  </div>
  <div class="col-sm-8 form-control-static">
    <select
      id="assessor-filter"
      class="custom-select"
      [(ngModel)]="selectedOption"
      (ngModelChange)="onChange($event)"
    >
      <option [value]="default">Select {{ name }}</option>
      <option *ngFor="let option of options" [ngValue]="option">
        {{ option.name }}
      </option>
    </select>
  </div>
</div>
