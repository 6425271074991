import { Component } from '@angular/core';
import { UIFilter } from '../../../../shared/models/ui-filter';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { GridView } from '../../../assessor-dashboard/your-dashboard/your-dashboard-table-controller/your-dashboard-table-controller.component';
import { ApiRequestTypes } from '../../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { DataRequestTypeEnum } from '../../../../shared/enums/data-request-type-enum';
import { AlertsApiRequestTypes, AlertsFilterTypes } from '../../../../core/services/alerts/alerts.service';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { firstValueFrom, Observable } from 'rxjs';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';
import { ApiRoutesClass, featureFlagNames } from '../../../../shared';
import { SnackBarClass } from '../../../../shared/components/snack-bar/snack-bar.class';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagService } from '../../../../core/services/feature-flags/feature-flag.service';

@Component({
  selector: 'app-product-table-controller',
  templateUrl: './product-table-controller.component.html',
  styleUrls: ['./product-table-controller.component.scss'],
})
export class ProductTableControllerComponent {
  public filter: UIFilter[];
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum;
  public loading = false;
  public bundleTimesExtended: any;
  private readonly accountId: string = this.route.snapshot.paramMap.get('id');
  public selectedDate: Date;

  public gridViews: GridView[] = [
    new GridView(
      'Purchase History', // also named Opportunities.
      PermissionsEnum.ActiveVerificationBundles,
      null,
      [],
      false,
      ['type', 'membershipExpiryDate', 'estimatedRevenue', 'paymentAmount', 'paymentReceiptNumber', 'paymentDate', 'creationDate'],
      [],
      DataRequestTypeEnum.PurchaseHistory,
      false,
      ['Bundle', 'Membership Expiry', 'Estimated Revenue', 'Payment Amount', 'Payment Receipt No.', 'Payment Date', 'Created']
    ),
    /**
     * THESE ARE ASSESSMENT BUNDLES. THE TABLE NAME IS DEMONSTRATION PURPOSE ONLY.
     */
    new GridView(
      'Assessments',
      PermissionsEnum.ActiveVerificationBundles,
      ApiRequestTypes.AccountVerifications,
      [],
      true,
      [
        'AssessmentName',
        'Status',
        'DecisionDate',
        'Validity',
        'ExpiryDate',
        'CreatedDate',
        'Variant',
        'AssignedAssessor',
        'assessorCompanyName',
      ],
      [{ property: 'accountId', value: '' }],
      DataRequestTypeEnum.Assessment,
      false,
      ['Product', 'Status', 'Decision Date', 'Validity', 'Expiry Date', 'Created Date', 'Variant', 'Assigned Assessor', 'Assessor Company']
    ),

    new GridView(
      'Outstanding Alerts',
      PermissionsEnum.CompanyOutstandingAlerts,
      AlertsApiRequestTypes.CompanyAlerts,
      [],
      false,
      ['assessmentName', 'questionDesription', 'expiryDate', 'content'],
      [],
      DataRequestTypeEnum.OutstandingAlerts,
      false,
      ['Assessment', 'Document', 'Expiry Date', 'Content']
    ),

    new GridView(
      'Active Alerts',
      PermissionsEnum.CompanyActiveAlerts,
      AlertsApiRequestTypes.CompanyAlerts,
      [],
      false,
      ['documentTitle', 'assessmentName', 'documentExpiryDate', 'createdDateTime'],
      [{ property: 'statusId', value: AlertsFilterTypes.Submitted }],
      DataRequestTypeEnum.Alert,
      false,
      ['Document', 'Assessment Name', 'New Document Expiry Date', 'Creation Date']
    ),
  ];

  constructor(
    private readonly permissionsService: PermissionsService,
    private readonly snackbar: SnackBarClass,
    private readonly apiRoutes: ApiRoutesClass,
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    public readonly matDialog: MatDialog,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  public showHideTableView$(gridview): Observable<boolean> {
    const permissionId = PermissionsMap.get(gridview);
    return this.permissionsService.authenticateUser$(permissionId);
  }

  public featureFlagEnabled$(feature: string): Observable<boolean> {
    return this.featureFlagService.isFeatureEnabled$(feature);
  }

  public async getReopenCount(): Promise<void> {
    this.bundleTimesExtended = await firstValueFrom(this.http.get(`${this.apiRoutes.Accounts()}/${this.accountId}/times-reopened`));
  }

  public async updateBundleDeadline(): Promise<void> {
    this.loading = true;

    try {
      await firstValueFrom(this.http.post(`${this.apiRoutes.Accounts()}/${this.accountId}/re-open`, this.selectedDate));
      await this.getReopenCount();
    } catch (e) {
      this.snackbar.displaySnackbar(e.message, 'x', 'center', 'top');
    } finally {
      this.loading = false;
      setTimeout(() => {
        this.matDialog.closeAll();
      }, 1500);
    }
  }

  protected readonly featureFlagNames = featureFlagNames;
}
