<div *ngIf="isLoading" class="loading-spinner_container">
  <app-loading-spinner></app-loading-spinner>
</div>
<div *ngIf="!isLoading" class="page">
  <div class="page__header">
    <span class="page__header-content">
    </span>
    <div class="page__header-extra">
      <div class="item--checked">
        <i *ngIf="authenticatePermissions$(permissionsEnum.FastTrackEnabled) | async" [ngClass]="{
            'fa-check-circle': assessmentSummary.FastTrack === true,
            'fa-times-circle': assessmentSummary.FastTrack === false
          }" class="item__icon fas"></i>
        <span *ngIf="authenticatePermissions$(permissionsEnum.FastTrackEnabled) | async" [ngClass]="{
            'is-fast-track': assessmentSummary.FastTrack === true
          }" class="item__text">Fast Track</span>
      </div>
      <div>
        Times Returned: {{ assessmentSummary.TimesReturned }}
      </div>
      <div>
        <div>Status: {{ assessmentSummary.DisplayStatus }}</div>
        <div>Assessor: {{assessmentSummary.AssignedAssessor}}</div>
      </div>
    </div>
  </div>
  <div class="page__section">
    <div class="card--small page__section-item">
      <div class="card__body">
        <h6 class="card__subtitle">
          Company Name
          <span *ngIf="contractorDetails.tradingName">/ TA</span>
        </h6>
        <h5 class="card__title">
          <span class="company-name">{{
            assessmentSummary.ContractorName
            }}</span>
          <div *ngIf="contractorDetails.tradingName">
            {{ contractorDetails.tradingName }}
          </div>
        </h5>
      </div>
    </div>
    <div class="card--small page__section-item">
      <div class="card__body">
        <h6 class="card__subtitle">Application Type</h6>
        <h5 class="card__title">{{ assessmentSummary.Variant }}</h5>
      </div>
    </div>
    <div class="card--small card--bolder page__section-item--smaller">
      <div class="card__body">
        <h6 class="card__subtitle">Direct</h6>
        <h5 class="card__title">
          {{ contractorDetails.numberOfDirectEmployees }}
        </h5>
      </div>
    </div>
    <div class="card--small page__section-item--smaller">
      <div class="card__body">
        <h6 class="card__subtitle">Business Size</h6>
        <h5 class="card__title">{{ contractorDetails.businessSize }}</h5>
      </div>
    </div>
  </div>
  <div class="page__section">
    <div class="card--small page__section-item">
      <div class="card__body">
        <h6 class="card__subtitle">Registered Address</h6>
        <h5 class="card__title">
          <app-form-address-display [address]="contractorDetails.registerAddress"></app-form-address-display>
        </h5>
      </div>
    </div>
    <div *ngIf="contractorDetails.id.companyHouseNo" class="card--small page__section-item">
      <div class="card__body">
        <h6 class="card__subtitle">Companies House Number</h6>
        <h5 class="card__title">
          {{ contractorDetails.id.companyHouseNo }}
          <a href="https://find-and-update.company-information.service.gov.uk/company/{{
              contractorDetails.id.companyHouseNo
            }}" target="_blank">
            <i class="fas fa-external-link-alt"></i>
          </a>
        </h5>
      </div>
    </div>
    <div class="card--small page__section-item">
      <div class="card__body">
        <h6 class="card__subtitle">Work Categories</h6>
        <h5 class="card__title">
          {{ workCategoriesText }}
          <button (click)="showWorkCategories()" *ngIf="workCategoriesText.length > maxCharacters" class="btn btn-link">
            See more
          </button>
        </h5>
      </div>
    </div>
    <div class="card--small page__section-item">
      <div class="card__body">
        <h6 class="card__subtitle">Business Summary</h6>
        <h5 *ngIf="businessSummaryText.length !== 0" class="card__title">
          {{ businessSummaryText }}
          <button (click)="showBusinessSummary()" *ngIf="businessSummaryText.length > maxCharacters"
            class="btn btn-link">
            See more
          </button>
        </h5>
        <h5 *ngIf="businessSummaryText.length === 0" class="card__title">
          <i>No Business Summary specified</i>
        </h5>

        <h5 *ngIf="averageAccreditedLevel" class="card__title">
          Average accredited level: {{ averageAccreditedLevel }}
        </h5>
      </div>
    </div>
  </div>
  <div *ngIf="showAcceptRejectButtons" class="actions text-left">
    <button (click)="acceptAssessment()" class="btn btn-lg mr-2 btn-success">
      Accept
    </button>
    <button (click)="rejectAssessment()" class="btn btn-lg mr-2 btn-danger">
      Reject
    </button>
  </div>
  <div *ngIf="showFailedQAReasons" class="card">
    <div class="card-header bg-danger text-light" (click)="collapse.toggle()" [attr.aria-expanded]="true" aria-controls="failed-qa-reasons">
        Failed QA<span class="fa float-right" [class]="collapse.isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'"></span>
    </div>
    <div class="card-body overflow-auto" id="failed-qa-reasons" [collapse]="false" #collapse="bs-collapse" style="max-height: 200px;">
        {{assessmentSummary.FailureReason}}
    </div>
  </div>
</div>

<div class="survey-wrapper">
  <div [class.d-none]="isOnActionPage && !hasBeenSubmitted" id="surveyElement"></div>
  <div *ngIf="questionSetData && !hasBeenSubmitted && isOnActionPage && !isSubmittedForQA" class="panel card">
    <div class="card-header">
      <h4>{{ assessmentOutcomeTitle }}</h4>
    </div>
    <div class="card__body text-center py-2">
      <button (click)="requestFurtherInfo()" [disabled]="
          isAssessmentDisabled || isAssessmentAwaitingContractorResponse
        " class="btn btn-lg mr-2 btn-primary">
        Request Further Information
      </button>
      <button (click)="approve()" [disabled]="
          isAssessmentDisabled ||
          isAssessmentAwaitingContractorResponse ||
          isAnyAssessmentNonCompliant
        " class="btn btn-lg mr-2 btn-success">
        Approve
      </button>
      <button (click)="failedToResubmit()" [hidden]="!isAssessment" [disabled]="
          isAssessmentDisabled && !isAssessmentAwaitingContractorResponse
        " class="btn btn-lg mr-2 btn-danger">
        Failed to Resubmit
      </button>
      <button (click)="fail()" [hidden]="!isAssessment" [disabled]="
          (isAssessmentDisabled && !isAssessmentAwaitingContractorResponse) ||
          isAssessmentAwaitingContractorResponse
        " class="btn btn-lg btn-danger">
        Fail
      </button>
    </div>
    <app-change-assessment-status *ngIf="authenticatePermissions$(permissionsEnum.EditAssessmentState)" (statusEmitter)='updateStatus($event)'
      [assessmentId]='assessmentId'></app-change-assessment-status>
  </div>
  <div *ngIf="questionSetData && isOnActionPage && isSubmittedForQA" class="panel card">
    <div class="card-header">
      <h4>{{ assessmentOutcomeTitle }}</h4>
    </div>
    <div class="card__body text-center py-2">
      <button (click)="passedQA()" class="btn btn-lg mr-2 btn-success">
        Passed QA
      </button>
      <button (click)="failedQA()" class="btn btn-lg mr-2 btn-danger">
        Failed QA
      </button>
    </div>
    <app-change-assessment-status *ngIf="authenticatePermissions$(permissionsEnum.EditAssessmentState)" (statusEmitter)='updateStatus($event)'
      [assessmentId]='assessmentId'></app-change-assessment-status>
  </div>
  <app-assessment-alerts-panel *ngIf="isOnActionPage" [alerts]="alerts"></app-assessment-alerts-panel>
  <button *ngIf="!isOnFirstPage" class="btn btn-lg sd-btn btn-secondary sv_prev_btn" (click)="survey.prevPage()">Previous</button>
  <button *ngIf="!isOnActionPage" class="btn btn-lg sd-btn btn-primary float-right sv_next_btn" (click)="survey.nextPage()"> NEXT</button>
</div>
