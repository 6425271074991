import { Component, OnInit } from '@angular/core';
import { SearchArgs } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../core/services/assessment/assessment.service';
import { ActionsSubject, Store } from '@ngrx/store';
import { PermissionsService } from '../../core/services/permissions/permissions.service';
import { DataTableAbstract } from '../../shared/abstract-classes/data-table-abstract/data-table-abstract';
import { DataRequestTypeEnum } from '../../shared/enums/data-request-type-enum';
import { SearchService } from '../../core/services/search/search.service';
import { BundlesService } from '../../core/services/bundles/bundles.service';
import { AlertsService } from '../../core/services/alerts/alerts.service';

@Component({
  selector: 'app-contractor-dashboard',
  templateUrl: './contractor-dashboard.component.html',
  styleUrls: ['./contractor-dashboard.component.scss'],
})
export class ContractDashboardComponent extends DataTableAbstract implements OnInit {
  public searchArgs: SearchArgs;

  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );
  }

  async ngOnInit(): Promise<void> {
    this.displayedColumns = ['accountName', 'email', 'membershipId', 'telephone', 'correspondenceAddressPostcode'];
    this.columnNames = ['Account', 'Email', 'Membership Id', 'Telephone', 'Post Code'];
    this.dataRequestType = DataRequestTypeEnum.Contractor;
    await this.retrieveDataRequest();
    super.ngOnInit();
  }
}
