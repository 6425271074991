import { Injectable } from '@angular/core';
import {ClientModuleItem} from "../../../shared/models/client-module-item";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ApiRoutesClass} from "../../../shared";
import {Observable} from "rxjs/internal/Observable";
import {AddClientSpecificModuleCommand} from "../../../shared/models/add-client-specific-module-command";
import {AssessmentModuleCreate, QuestionSet} from "../../open-api";

@Injectable({
  providedIn: 'root'
})
export class ClientModuleHttpService {

  constructor(private readonly http: HttpClient,
              private readonly api: ApiRoutesClass) { }

  public getModules$(accountId: string = null) : Observable<ClientModuleItem[]> {
    if(accountId)
      return this.http.get<ClientModuleItem[]>(`${this.api.ClientModule()}/${accountId}/modules`);

    return this.http.get<ClientModuleItem[]>(`${this.api.ClientModule()}`);
  };

  public getModule$(moduleId: string = null) : Observable<ClientModuleItem> {
    return this.http.get<ClientModuleItem>(`${this.api.ClientModule()}/${moduleId}`);
  };

  public addOrUpdateClientModule$(req: AddClientSpecificModuleCommand, moduleId: string): Observable<AssessmentModuleCreate> {
    if(moduleId)
      return this.http.put(`${this.api.ClientModule()}/${moduleId}`, req);

    return this.http.post(`${this.api.ClientModule()}`, req);
  }

  public deleteClientModule$(moduleId: string): Observable<any> {
      return this.http.delete<void>(`${this.api.ClientModule()}/${moduleId}/delete`);
  }

  public getQuestionSets$(moduleId: string) : Observable<QuestionSet[]> {
    return this.http.get<QuestionSet[]>(`${this.api.ClientModule()}/${moduleId}/questionSets`);
  }

    public uploadQuestionSet$(moduleId: string, file: File) : Observable<HttpResponse<number>> {
        const formData: FormData = new FormData();
        formData.append('files', file);
        return this.http.patch<HttpResponse<number>>(`${this.api.ClientModule()}/${moduleId}/questionSet`, formData);
    }
}
