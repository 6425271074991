<div class="form-group row">
  <div class="col-md-4">
    <strong
      ><label class="col-form-label"> Primary Contact Name </label></strong
    >
  </div>
  <div class="col-md-4 form-control-static">
    <p class="form-control-plaintext">{{ primaryContactName }}</p>
  </div>
  <div class="col-md-4 form-control-static">
    <button
      type="button"
      id="viewContactsbtn"
      class="btn btn-link mr-2"
      (click)="openModal(template)"
    >
      View Contacts <i class="fa fa-address-card-o" aria-hidden="true"></i>
    </button>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Contacts</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="contacts-tbl">
      <thead>
        <tr>
          <th>Name</th>
          <th>Position</th>
          <th>Email</th>
          <th>Phone</th>
        </tr>
      </thead>

      <tr *ngFor="let row of contacts">
        <td class="narrow">{{ row.name }}</td>
        <td class="narrow">{{ row.position }}</td>
        <td class="wide">{{ row.email }}</td>
        <td class="narrow">{{ row.phone }}</td>
      </tr>
    </table>
  </div>
</ng-template>
