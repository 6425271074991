<div class="d-flex">
    <h2 class="mr-auto">Clients</h2>
</div>
<div *ngIf="isLoading; else displayTable" class="mt-3">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #displayTable>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="w-100" >
            <ng-container matColumnDef="accountName">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.accountName }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/assessment/clients', row.accountId, 'contacts']"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</ng-template>