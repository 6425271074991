<div class="text-center" *ngIf="isLoading$ | async; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
  <form [formGroup]="detailsForm" novalidate>
    <div class="form-group row">
      <label class="col-md-6">FIR Membership Expiry Date</label>
      <div class="col-md-6">
        <div *ngIf="!readOnly; else firMembershipExpiryDate">
          <input
            id="firMembershipExpiryDate"
            class="form-control"
            type="text"
            formControlName="firMembershipExpiryDate"
            title="firMembershipExpiryDate"
          />
        </div>
        <ng-template #firMembershipExpiryDate>
          {{ detailsForm.value.firMembershipExpiryDate | date: "dd/MM/YYYY" }}
        </ng-template>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-6">FIR Accreditation Expiry Date</label>
      <div class="col-md-6">
        <div *ngIf="!readOnly; else firMembershipExpiryDate">
          <input
            id="firMembershipExpiryDate"
            class="form-control"
            type="text"
            formControlName="firMembershipExpiryDate"
            title="firMembershipExpiryDate"
          />
        </div>
        <ng-template #firMembershipExpiryDate>
          {{ detailsForm.value.firMembershipExpiryDate | date: "dd/MM/YYYY" }}
        </ng-template>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-6">FIR Accreditation Level</label>
      <div class="col-md-6">
        <div *ngIf="!readOnly; else firAccreditationLevel">
          <input
            id="firAccreditationLevel"
            class="form-control"
            type="text"
            formControlName="firAccreditationLevel"
            title="firAccreditationLevel"
          />
        </div>
        <ng-template #firAccreditationLevel>
          {{ detailsForm.value.firAccreditationLevel }}
        </ng-template>
      </div>
    </div>
  </form>
</ng-template>
