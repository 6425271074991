import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { PanelAbstract } from '../panel-abstract/panel-abstract.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import {
  ContractorAccountService,
  ContractorAccountServiceEnum,
} from '../../../../core/services/company-account/contractor-account.service';

@Component({
  selector: 'app-work-areas-panel',
  templateUrl: './work-areas-panel.component.html',
  styleUrls: ['../panel-abstract/panel-abstract.component.scss', './work-areas-panel.component.scss'],
})
export class WorkAreasPanelComponent extends PanelAbstract implements OnInit {
  @Input() data;
  @Input() multiple = false;
  public submitRequest = false;
  public formGroup: UntypedFormGroup;
  protected permissionsRequest: PermissionsEnum = PermissionsEnum.WorkRegionsSummaryEditButton;
  protected displayActionButtons = true;
  protected panelType = ContractorAccountServiceEnum.WorkAreas;

  constructor(
    protected fb: FormBuilder,
    protected readonly snackBar: MatSnackBar,
    protected readonly store: Store,
    protected readonly route: ActivatedRoute,
    protected actions: ActionsSubject,
    protected readonly permissionsService: PermissionsService,
    protected readonly contractorAccountService: ContractorAccountService
  ) {
    super(fb, snackBar, store, route, actions, permissionsService, contractorAccountService);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }
}
