import { createAction, props } from "@ngrx/store";
import { CompanyInformation } from "src/app/shared/models/CompanyInformation";

export enum CompanyInformationActions {
  Load = "[Company Information] Load",
  LoadSuccess = "[Company Information] Load Successful",
  LoadError = "[Company Information] Load Error",

  save = "[Company Information] save",
  saveSuccess = "[Company Information] save Success",
  saveError = "[Company Information] save Error",
}

export const CompanyInformationLoad = createAction(
  CompanyInformationActions.Load,
  props<{ accountId: string }>()
);
export const CompanyInformationLoadSuccess = createAction(
  CompanyInformationActions.LoadSuccess,
  props<{ companyInformation: CompanyInformation }>()
);
export const CompanyInformationLoadError = createAction(
  CompanyInformationActions.LoadError,
  props<{ error: string }>()
);

//save
export const CompanyInformationSave = createAction(
  CompanyInformationActions.save,
  props<{ accountId: string; companyInformation: CompanyInformation }>()
);
export const CompanyInformationSaveSuccess = createAction(
  CompanyInformationActions.saveSuccess
);
export const CompanyInformationSaveError = createAction(
  CompanyInformationActions.saveError,
  props<{ error: string }>()
);
