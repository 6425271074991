import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountService } from './api/account.service';
import { AddressSearchService } from './api/address-search.service';
import { AnswersService } from './api/answers.service';
import { AquipaService } from './api/aquipa.service';
import { AssessmentService } from './api/assessment.service';
import { AssessorService } from './api/assessor.service';
import { AuditEventsService } from './api/audit-events.service';
import { AzureUtilsService } from './api/azure-utils.service';
import { BusinessShieldService } from './api/business-shield.service';
import { CertificatesService } from './api/certificates.service';
import { ClientModuleService } from './api/client-module.service';
import { CompanySearchService } from './api/company-search.service';
import { ContactService } from './api/contact.service';
import { ContractorProfileService } from './api/contractor-profile.service';
import { CredenxiaService } from './api/credenxia.service';
import { CrmService } from './api/crm.service';
import { CurrentUserService } from './api/current-user.service';
import { DiagnosticService } from './api/diagnostic.service';
import { DocumentsService } from './api/documents.service';
import { FeatureFlagService } from './api/feature-flag.service';
import { FinancialService } from './api/financial.service';
import { HumanFocusService } from './api/human-focus.service';
import { LeadService } from './api/lead.service';
import { LegacyEventTestingService } from './api/legacy-event-testing.service';
import { ModuleService } from './api/module.service';
import { NotificationService } from './api/notification.service';
import { PaymentService } from './api/payment.service';
import { PricingService } from './api/pricing.service';
import { ProductService } from './api/product.service';
import { ProductivityService } from './api/productivity.service';
import { QuestionSetService } from './api/question-set.service';
import { QuestionSetChoicesService } from './api/question-set-choices.service';
import { SupplyChainService } from './api/supply-chain.service';
import { ThreadPoolConfigurationService } from './api/thread-pool-configuration.service';
import { UserGroupsService } from './api/user-groups.service';
import { VerifierService } from './api/verifier.service';
import { WorkCategoriesService } from './api/work-categories.service';
import { WorkLocationService } from './api/work-location.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
