import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { WorkCategoryHttpService } from 'src/app/core/services/account/work-category-http.service';
import { WorkCategory } from 'src/app/shared/models/Work-Category';
import { getLoaded } from '../../../core/ngrx/selectors/contractor-account.selectors';

@Component({
  selector: 'app-work-category-add-or-update',
  templateUrl: './work-category-add-or-update.component.html',
  styleUrls: ['./work-category-add-or-update.component.scss'],
})
export class WorkCategoryAddOrUpdateComponent implements OnInit, OnDestroy {
  CompanySummary3Sub: Subscription;
  isLoading$ = this.store.select(getLoaded);
  detailsForm = this.initializeForm();
  moduleTypes: any[];
  assessmentTypes: any[];
  variants: any[];
  id: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store,
    private accountService: WorkCategoryHttpService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.accountService.GetWorkCategoryById(this.id).subscribe((t) => {
        this.detailsForm = this.initializeForm(t);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.CompanySummary3Sub) {
      this.CompanySummary3Sub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  initializeForm(account: WorkCategory = {} as WorkCategory): UntypedFormGroup {
    return this.fb.group({
      id: [account.id || null],
      name: [account.name || '', Validators.required],
      workCode: [account.workCode || 0],
      chasSectors: [account.chasSectors?.join(', ') || ''],
    });
  }

  onSave(): void {
    if (this.id != null) {
      this.detailsForm.patchValue({ id: this.id });
    }
    this.accountService.CreateOrUpdateWorkCategory(this.detailsForm.value).subscribe((t) => {
      this.router.navigate(['/assessment/workCategories']);
    });
  }

  public hasError = (controlName: string, errorName: string) => this.detailsForm.controls[controlName].hasError(errorName);
}
