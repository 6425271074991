import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  transform(dataArray: any[], searchArg: string): any[] {
    if (!dataArray) {
      return [];
    }
    return dataArray.filter((item) => {
      return Object.values(item).some((value): boolean => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchArg.toLowerCase());
        }
        return false;
      });
    });
  }
}
