import { createAction, props } from "@ngrx/store";

export enum WorkCategoriesActions {
  Load = "[WorkCategories ] Load",
  LoadSuccess = "[WorkCategories ] Load Successful",
  LoadError = "[WorkCategories ] Load Error",
}

export const WorkCategoriesLoad = createAction(
  WorkCategoriesActions.Load,
  props<{ accountId: string }>()
);
export const WorkCategoriesLoadSuccess = createAction(
  WorkCategoriesActions.LoadSuccess,
  props<{ WorkCategories: any[] }>()
);
export const WorkCategoriesLoadError = createAction(
  WorkCategoriesActions.LoadError,
  props<{ error: any }>()
);
