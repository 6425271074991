import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AzureUserAuthenticationService } from 'src/app/core/services/azure-authentication/user-authentication/azure-user-authentication.service';
import { firstValueFrom, Subject } from 'rxjs';
import { Redirector } from '../../../shared/utililties/redirector';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { MonitorSeverity } from '../../../shared/constants/monitor-severity';
import { MonitorService } from '../../../core/services/monitorService/monitor.service';
import { EventType } from '@azure/msal-browser';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.scss'],
})
export class OauthCallbackComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private readonly authService: AzureUserAuthenticationService,
    private readonly router: Router,
    private readonly monitorService: MonitorService
  ) {}

  ngOnInit(): void {
    this.authService.loginSuccessSubject
      .pipe(
        tap(() => console.warn('Login successful')),
        catchError((error) => {
          this.monitorService.logException(error.message, MonitorSeverity.ERROR);
          throw new Error(error);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((event: any): void => {
        if (event.eventType === EventType.LOGIN_SUCCESS || EventType.ACQUIRE_TOKEN_SUCCESS) {
          Redirector.navigateAndAppendReturnUrl(this.router, '/request-account');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
