import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
})
export class InsuranceComponent implements OnInit, OnDestroy {
  _parent: UntypedFormGroup;
  get parent(): UntypedFormGroup {
    return this._parent;
  }

  @Input()
  set parent(value: UntypedFormGroup) {
    this._parent = value;
  }

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  get readOnly() {
    return true;
  }
}
