import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  ContactsEmailsTableLoad,
  ContactsEmailsTableLoadError,
  ContactsEmailsTableLoadSuccess,
  ContactsEmailsTableSave,
  ContactsEmailsTableSaveError,
  ContactsEmailsTableSaveSuccess,
} from "../actions/ContactsEmailsTable.actions";

import { AccountHttpService } from "../../services/account/account-http.service";

@Injectable()
export class ContactsEmailsTableEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsEmailsTableLoad),
      mergeMap((action) =>
        this.accountService.GetContacts(action.accountId).pipe(
          map((b) =>
            ContactsEmailsTableLoadSuccess({
              ContactsEmails: b,
            })
          ),
          catchError((error) => of(ContactsEmailsTableLoadError({ error })))
        )
      )
    )
  );
  Save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsEmailsTableSave),
      mergeMap((action) =>
        this.accountService.SaveContact(action.contactsEmail).pipe(
          map((b) =>
            ContactsEmailsTableSaveSuccess({
              contactsEmail: action.contactsEmail,
            })
          ),
          catchError((error) => of(ContactsEmailsTableSaveError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountHttpService
  ) {}
}
