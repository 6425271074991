import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContractDashboardComponent } from "./contractor-dashboard.component";
import { SharedModule } from "../../shared/shared.module";
import { MaterialModule } from "src/material/material.module";
import { CommonModule } from "@angular/common";
import { TrackingEventsComponent } from './tracking-events/tracking-events.component';
import { TrackingEventsControllerComponent } from './tracking-events/tracking-events-controller/tracking-events-controller.component';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { AuditHistoryControllerComponent } from "./audit-history/audit-history-controller/audit-history-controller.component";

const routes: Routes = [{ path: "", component: ContractDashboardComponent }];

@NgModule({
  declarations: [ContractDashboardComponent, TrackingEventsComponent, TrackingEventsControllerComponent, AuditHistoryControllerComponent, AuditHistoryComponent],
  imports: [CommonModule, RouterModule.forChild(routes), SharedModule, MaterialModule],
  providers: [],
  exports: [TrackingEventsControllerComponent, AuditHistoryComponent, AuditHistoryControllerComponent],
})
export class ContractDashboardModule {}
