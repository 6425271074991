import { AccountSelection } from "src/app/shared/components/select-account-menu/view-models/account-selection";

export class AvailableAccountSelection implements AccountSelection {
  constructor(id: string, contactid: string, name: string, role: string) {
    this.id = id;
    this.contactid = contactid;
    this.name = name;
    this.role = role;
  }

  id: string;
  contactid: string;
  name: string;
  role: string;
}
