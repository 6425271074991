<div class='container'>
	<div class='table-header'>
		<mat-form-field>
			<input
				(keyup)='quickSearch($event.target.value)'
				matInput
				placeholder='Quick Search'
			/>
		</mat-form-field>
	</div>
	<app-data-table (clickActionEmitter)='contractorSelected($event)' (selectionEmitter)='setSelection($event)'
					[columnNames]='columnNames'
					[dataSource]='dataSource' [displayedColumns]='displayedColumns'
					[isLoading]='isLoading' [showCheckbox]=true></app-data-table>
</div>

<!--<div class="text-center" *ngIf="isLoading$ | async; else displayForm">-->
<!--  <app-loading-spinner></app-loading-spinner>-->
<!--</div>-->
<!--<ng-template #displayForm>-->
<!--  <table-->
<!--    mat-table-->
<!--    [dataSource]="dataSource"-->
<!--    matSort-->
<!--    matSortActive="firstName"-->
<!--    matSortDirection="desc"-->
<!--  >-->
<!--    <ng-container matColumnDef="firstName">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>-->
<!--      <td mat-cell *matCellDef="let row; let i = index">-->
<!--        <ng-container *ngIf="readOnly || selectedRow !== row">-->
<!--          {{ row.firstName }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!readOnly && selectedRow === row">-->
<!--          <input-->
<!--            class="form-control"-->
<!--            type="text"-->
<!--            title="firstName"-->
<!--            [formControl]="firstName"-->
<!--          />-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--    <ng-container matColumnDef="lastName">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>-->
<!--      <td mat-cell *matCellDef="let row; let i = index">-->
<!--        <ng-container *ngIf="readOnly || selectedRow !== row">-->
<!--          {{ row.lastName }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!readOnly && selectedRow === row">-->
<!--          <input-->
<!--            class="form-control"-->
<!--            type="text"-->
<!--            title="lastName"-->
<!--            [formControl]="lastName"-->
<!--          />-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--    <ng-container matColumnDef="email">-->
<!--      <th mat-header-cell *matHeaderCellDef>email</th>-->
<!--      <td mat-cell *matCellDef="let row; let i = index">-->
<!--        <ng-container *ngIf="readOnly || selectedRow !== row">-->
<!--          {{ row.email }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!readOnly && selectedRow === row">-->
<!--          <input-->
<!--            id="email"-->
<!--            class="form-control"-->
<!--            type="text"-->
<!--            title="email"-->
<!--            [formControl]="email"-->
<!--          />-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--    <ng-container matColumnDef="createdBy">-->
<!--      <th mat-header-cell *matHeaderCellDef>createdBy</th>-->
<!--      <td mat-cell *matCellDef="let row; let i = index">-->
<!--        <ng-container *ngIf="readOnly">-->
<!--          {{ row.createdUtc | date: "dd/MM/yyyy HH:mm" }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--<div *ngIf='showHideTableView(permissionsEnum.ContactEmails) | async'>-->
<!--  <ng-container matColumnDef="actions">-->
<!--    <th mat-header-cell *matHeaderCellDef>Actions</th>-->
<!--    <td mat-cell *matCellDef="let row; let i = index">-->
<!--      <button-->
<!--          color="primary"-->
<!--          mat-raised-button-->
<!--          type="button"-->
<!--          (click)="onEditMode(row)"-->
<!--          *ngIf='showHideTableView(permissionsEnum.ContactEmailsEditButton) | async'-->
<!--      >-->
<!--        <mat-icon>edit</mat-icon>-->
<!--      </button>-->
<!--      <button-->
<!--          color="primary"-->
<!--          mat-raised-button-->
<!--          type="button"-->
<!--          (click)="onCancel()"-->
<!--          *ngIf=' selectedRow === row'-->
<!--      >-->
<!--        <mat-icon>arrow_back</mat-icon>-->
<!--      </button>-->
<!--      <button-->
<!--          color="primary"-->
<!--          mat-raised-button-->
<!--          type="button"-->
<!--          (click)="onSave(row)"-->
<!--          *ngIf='(showHideTableView(permissionsEnum.ContactEmailsCheckButton) | async) && selectedRow === row'-->
<!--      >-->
<!--        <mat-icon>check</mat-icon>-->
<!--      </button>-->
<!--    </td>-->
<!--  </ng-container>-->
<!--</div>-->
<!--    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>-->
<!--  </table>-->
<!--</ng-template>-->
