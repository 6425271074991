import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { ContractorAccountService, ContractorAccountServiceEnum } from 'src/app/core/services/company-account/contractor-account.service';
import { PanelAbstract } from '../panel-abstract/panel-abstract.component';

@Component({
  selector: 'app-contact-panel',
  templateUrl: '/contact-panel.component.html',
  styleUrls: ['../panel-abstract/panel-abstract.component.scss', './contact-panel.component.scss'],
})
export class ContactPanelComponent extends PanelAbstract implements OnInit {
  @Input() data;
  @Input() multiple = false;
  @Output() contactSaved = new EventEmitter();
  public formGroup: UntypedFormGroup;
  protected permissionsRequest: PermissionsEnum = PermissionsEnum.ContactSummaryEditButton;
  protected displayActionButtons = true;
  protected panelType = ContractorAccountServiceEnum.PrimaryContact;
  protected contactIsPrimaryContact = false;

  constructor(
    protected fb: FormBuilder,
    protected readonly snackBar: MatSnackBar,
    protected readonly store: Store,
    protected readonly route: ActivatedRoute,
    protected actions: ActionsSubject,
    protected readonly permissionsService: PermissionsService,
    protected readonly contractorAccountService: ContractorAccountService
  ) {
    super(fb, snackBar, store, route, actions, permissionsService, contractorAccountService);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.contactIsPrimaryContact = this.data.isPrimaryContact;
  }

  async saveContact(): Promise<void> {
    await super.submit();
    this.contactSaved.emit();
  }
}
