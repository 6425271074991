import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './core/services/azure-authentication/login-guard/login-guard';
import { OauthCallbackComponent } from './features/OAuth/oauth-callback/oauth-callback.component';
import { LoadingPageComponent } from './shared/components/loading-page/loading-page.component';

const routes: Routes = [
  {
    path: 'oauthcallback',
    component: OauthCallbackComponent,
  },
  {
    path: 'request-account',
    component: LoadingPageComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/features/assessor-dashboard/assessor-dashboard.module').then((m) => m.AssessorDashboardModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'membership',
    loadChildren: () => import('src/app/features/membership-dashboard/membership-dashboard.module').then((m) => m.MembershipDasboardModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'finance',
    loadChildren: () => import('src/app/features/finance-dashboard/finance-dashboard.module').then((m) => m.FinanceDashboardModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'assessment',
    loadChildren: () => import('src/app/features/assessment/assessment.module').then((m) => m.AssessmentModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'productivity',
    loadChildren: () => import('src/app/features/productivity/productivity.module').then((m) => m.ProductivityModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'assessor-productivity',
    loadChildren: () =>
      import('src/app/features/assessor-productivity/assessor-productivity.module').then((m) => m.AssessorProductivityModule),
  },
  {
    path: 'matching-tool',
    loadChildren: () => import('src/app/features/matching-tool/matching-tool.module').then((m) => m.MatchingToolModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'client-files',
    loadChildren: () => import('src/app/features/client-files/client-files.module').then((m) => m.ClientFilesModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'opportunties',
    loadChildren: () => import('src/app/features/opportunties/opportunity.module').then((m) => m.OpportunityModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'contractors',
    loadChildren: () => import('src/app/features/contractor-dashboard/contractor-dashboard.module').then((m) => m.ContractDashboardModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'client-modules',
    loadChildren: () => import('src/app/features/client-modules/client-modules.module').then((m) => m.ClientModulesModule),
    canActivate: [LoginGuard],
  },
  { path: '', redirectTo: '/oauthcallback', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
