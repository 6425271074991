<div class="panel card" *ngIf="alerts && alerts.length > 0">
  <div class="card-header">
    <h4>Alerts</h4>
  </div>
  <div class="card-body">
    <div *ngFor="let alert of alerts; let alertIndex = index">
      <h5>{{ alert.title }}</h5>
      <p *ngIf="alert.dateTime">{{ alert.dateTime | date: "dd/MM/yyyy" }}</p>
      <p *ngIf="alert.description">{{ alert.description }}</p>

      <div *ngFor="let answer of alert.answers; let answerIndex = index">
        <div *ngIf="showSectionName(alertIndex, answerIndex)" class="mt-4">
          <strong>{{ answer.section }}</strong>
        </div>
        <div>
          {{ answer.question }} -
          {{ answer.dateTime | date: "dd/MM/yyyy HH:mm" }}
        </div>
        <p class="alert-response-contents">{{ answer.answer }}</p>
      </div>
    </div>
  </div>
</div>
