import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { getLoaded } from '../../../core/ngrx/selectors/contractor-account.selectors';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  readOnly$ = this.store.select(getLoaded);

  constructor(private store: Store) {}

  _parent: UntypedFormGroup;

  get parent(): UntypedFormGroup {
    return this._parent;
  }

  @Input()
  set parent(value: UntypedFormGroup) {
    this._parent = value;
  }

  ngOnInit(): void {}
}
