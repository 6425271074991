<button mat-raised-button color="primary"
        (click)="editState = true; emitButtonState(editState)"
        [hidden]="editState">
    <i class="fas fa-edit"></i>
    Edit
</button>
<div class="panel__footer-editing" *ngIf="editState">
    <button mat-raised-button color="primary" [disabled]="loading" (click)="editState = false; emitButtonState(editState)">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <button
            [disabled]="loading"
            mat-raised-button color="primary"
            (click)="submitEmitter.emit(true)">
        <mat-icon>check</mat-icon>
    </button>
</div>