/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OpportunityLine {
  id?: string;
  productId?: string | null;
  quantity?: number | null;
  description?: string | null;
  name?: string | null;
  unitAmount?: number;
  subTotalAmount?: number;
  discountAmount?: number;
  netAmount?: number;
  vatPercent?: number;
  readonly vatAmount?: number;
  readonly grossAmount?: number;
}
