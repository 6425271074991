import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AzureUserAuthenticationService } from '../user-authentication/azure-user-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authenticationService: AzureUserAuthenticationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isLoggedIn: boolean = this.authenticationService.isLoggedIn();

    if (!isLoggedIn) {
      await this.authenticationService.loginSignupRedirect();
    }

    return isLoggedIn;
  }
}
