import { createAction, props } from '@ngrx/store';
import { ApiRequestTypes } from './MultigridAssessmentViewType.actions';
import { UIFilter } from 'src/app/shared/models/ui-filter';
import { AssessmentListScope } from 'src/app/shared/enums/assessment-list-scope';

export enum AssessmentsActions {
  GetAssessments = '[Assessments] Get Assessments',
  StoreAssessments = '[Assessments] Store Assessments'
}

export const GetAssessments = createAction(
  AssessmentsActions.GetAssessments,
  props<{
    view: ApiRequestTypes;
    filter?: UIFilter[];
    assessmentListScope: AssessmentListScope
  }>()
);

export const StoreAssessments = createAction(AssessmentsActions.StoreAssessments, props<{ assessments: Array<any> }>());
