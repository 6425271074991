<div class="modal-header">
  <h4 class="modal-title pull-left">Work Categories</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Level 1</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="segment">
      <th mat-header-cell *matHeaderCellDef>Level 2</th>
      <td mat-cell *matCellDef="let row">{{ row.segmentTitle }}</td>
    </ng-container>
    <ng-container matColumnDef="family">
      <th mat-header-cell *matHeaderCellDef>Level 3</th>
      <td mat-cell *matCellDef="let row">{{ row.familyTitle }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>