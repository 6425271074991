export enum PermissionsEnum {
  VerificationDashboard = 'Verification Dashboard',
  AssessmentAdminNav = 'Assessment Admin Nav',
  AssessorDashboard = 'Assessor Dashboard',
  AssessorCompanyDashboard = 'Assessor Company Dashboard',
  AssignAssessor = 'Assign Assessor',
  AssignAssessorCompany = 'Assign Assessor Company',
  OpsPortalAccess = 'Ops Portal Access',
  AdminNav = 'Admin Nav',
  ProductivityNav = 'Your Productivity Nav',
  ProductivityStatsNav = 'Productivity Stats Nav',
  ReportsNav = 'Reports Nav',
  ContractorsNav = 'Contractors Nav',
  OpportunitiesNav = 'Opportunities Nav',
  ClientFilesNav = 'Client Files Nav',
  CreditSafeToolNav = 'Credit Safe Tool Nav',
  DocumentsNav = 'Documents Nav',
  MembershipNav = 'Membership Nav',
  YourAssessments = 'Your Assessments',
  UnassignedAssessments = 'Unassigned Assessments',
  RecentAssessments = 'Recent Assessments',
  YourActiveAssessments = 'Your Active Assessments',
  Products = 'Products',
  UnassignedAssessmentBundles = 'Unassigned Assessment Bundles',
  ActiveAssessmentBundles = 'Active Assessment Bundles',
  UnassignedVerificationBundles = 'Unassigned Verification Bundles',
  ActiveVerificationBundles = 'Active Verification Bundles',
  DocumentVerifications = 'Document Verifications',
  AssessorWorkload = 'Assessor Workload',
  WithdrawAssessment = 'Withdraw Assessment',
  AdminModulesNav = 'Admin Modules Nav',
  AdminQuestionSets = 'Admin QuestionSets',
  AdminProductsNav = 'Admin Products Nav',
  AdminSupplyChainNav = 'Admin Supply Chain Nav',
  AdminRegionsNav = 'Admin Regions Nav',
  AdminWorkCategoriesNav = 'Admin Work Categories Nav',
  AdminAssessorNav = 'Admin Assessors Nav',
  CompanyInformationEditButton = 'Company Information Edit Button',
  CompanyInformation = 'Company Information',
  MembershipSummaryEditButton = 'Membership Summary Edit Button',
  MembershipSummary = 'Membership Summary',
  AccreditationSummaryEditButton = 'Accreditation Summary Edit Button',
  AccreditationSummary = 'Accreditation Summary',
  ContactSummaryEditButton = 'Contact Summary Edit Button',
  ContactSummary = 'Contact Summary',
  SummaryEditButton = 'Summary Edit Button',
  Summary = 'Summary',
  ContactEmails = 'Contact',
  ContactEmailsEditButton = 'Contact Emails Edit Button',
  WorkRegionsSummary = 'Work Regions Summary',
  WorkRegionsSummaryEditButton = 'Work Regions Summary Edit Button',
  AddressSummary = 'Address Summary',
  AddressSummaryEditButton = 'Address Summary Edit Button',
  BusinessSummary = 'Business Summary',
  BusinessSummaryEditButton = 'Business Summary Edit Button',
  ThirdPartySummary = 'Third Party Summary',
  ThirdPartySummaryEditButton = 'Third Party Summary Edit Button',
  ThirdPartyKeysSummary = 'Third Party Keys Summary',
  ThirdPartyKeysEditButton = 'Third Party Keys Edit Button',
  EditAnyAssessment = 'Edit Any Assessment',
  FastTrackEnabled = 'Fast Track Enabled',
  CompanyActiveAlerts = 'Company Active Alerts',
  CompanyOutstandingAlerts = 'Company Outstanding Alerts',
  ResponseRequiredBy = 'Response Required By',
  ClientModules = 'Client Modules',
  EditAssessmentState = 'Edit Assessment State',
  EnableDisableAccount = 'Enable / Disable Account',
  ManualAssessorFees = 'Manual Assessor Fees',
  FinanceDashboard = 'Finance Dashboard',
  CompanyVerificationBundles = 'Company Verification Bundles',
  YourQAVerificationBundles = 'Your QA Verification Bundles',
  UnassignedQAVerificationBundles = 'Unassigned QA Verification Bundles',
  YourQAAssessments = 'Your QA Assessments',
  AdminRoutingNav = 'Admin Routing Nav',
  ViewDisabledAccountsGrid = 'View Disabled Accounts Grid',
  ViewEnabledAccountsGrid = 'View Enabled Accounts Grid',
  Admin_HangfireDashboardNav = 'Admin Hangfire Dashboard Nav',
}
