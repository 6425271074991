import { createAction, props } from "@ngrx/store";
import { ProductCurrentMembership } from "src/app/shared/models/ProductCurrentMembership";

export enum ProductCurrentMembershipTableActions {
  Load = "[Product Current Membership Table] Load",
  LoadSuccess = "[Product Current Membership Table] Load Successful",
  LoadError = "[Product Current Membership Table] Load Error",
}

export const ProductCurrentMembershipTableLoad = createAction(
  ProductCurrentMembershipTableActions.Load,
  props<{ accountId: string }>()
);
export const ProductCurrentMembershipTableLoadSuccess = createAction(
  ProductCurrentMembershipTableActions.LoadSuccess,
  props<{ productCurrentMembership: ProductCurrentMembership[] }>()
);
export const ProductCurrentMembershipTableLoadError = createAction(
  ProductCurrentMembershipTableActions.LoadError,
  props<{ error: string }>()
);
