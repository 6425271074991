import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ImsAndinsuranceState } from "../reducers/imsAndinsurance.reducer";

export const selectImsAndinsuranceFeatureState =
  createFeatureSelector<ImsAndinsuranceState>("imsAndinsurance");

export const getImsAndinsuranceSelector = createSelector(
  selectImsAndinsuranceFeatureState,
  (state) => state.ImsAndinsurance
);

export const getImsAndinsuranceLoadingSelector = createSelector(
  selectImsAndinsuranceFeatureState,
  (state) => state.loading
);
