import { createReducer, on } from "@ngrx/store";
import { ProductTable } from "src/app/shared/models/ProductTable";
import * as AppState from "../../../State";
import {
  ProductTableLoad,
  ProductTableLoadError,
  ProductTableLoadSuccess,
} from "../actions/ProductTable.actions";

export interface State extends AppState.State {
  Products: ProductTable[];
}

export interface ProductTableState {
  Products: ProductTable[];
  loading: boolean;
  error: string;
}

const initialState: ProductTableState = {
  Products: undefined,
  loading: true,
  error: "",
};

const _ProductTableReducer = createReducer(
  initialState,
  on(ProductTableLoad, (state, action) => ({
    ...state,
    loading: true,
    error: "",
  })),
  on(
    ProductTableLoadSuccess,
    (state, action): ProductTableState => ({
      ...state,
      loading: false,
      error: "",
      Products: action.Products,
    })
  ),
  on(
    ProductTableLoadError,
    (state, action): ProductTableState => ({
      ...state,
      error: action.error,
    })
  )
);

export function ProductTableReducer(state, action) {
  return _ProductTableReducer(state, action);
}
