<div class="modal-header">
  <h4 class="modal-title pull-left">Failure Reasons</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <textarea class="form-control" [(ngModel)]="modalComment"></textarea>
  <div class="mt-3">
    <button
      type="button"
      class="btn btn-primary mr-2"
      [disabled]='!modalComment'
      (click)="modalConfirmFail()"
    >
      Continue
    </button>
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">
      Cancel
    </button>
  </div>
</div>
