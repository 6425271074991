import { NestedTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { ActivatedRoute } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { storeDispatchAsync } from '../../../core/ngrx/store-dispatch-async';
import { TradesActions } from '../../../core/ngrx/actions/trades.actions';
import { getTradesSelector } from '../../../core/ngrx/selectors/trade.selectors';

export interface Categories {
  name: string;
  subCategories?: Categories[];
}

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss'],
})
export class TradesComponent {
  public treeControl = new NestedTreeControl<Categories>((node) => node.subCategories);
  public dataSource = new MatTreeNestedDataSource<Categories>();
  public config: IEnvironment;
  public hasChild = (_: number, node: Categories) => !!node.subCategories && node.subCategories.length > 0;

  constructor(
    private readonly http: HttpClient,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly actions: ActionsSubject
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize(): Promise<void> {
    const accountId = await firstValueFrom(this.activatedRoute.url);
    await storeDispatchAsync(this.store, this.actions, TradesActions.LoadSuccess, {
      type: TradesActions.Load,
      accountId: accountId[1].path,
    });
    this.dataSource.data = await firstValueFrom(this.store.select(getTradesSelector));
  }
}
