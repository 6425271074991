import { Component } from '@angular/core';
import { UIFilter } from '../../../../shared/models/ui-filter';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { GridView } from '../../../assessor-dashboard/your-dashboard/your-dashboard-table-controller/your-dashboard-table-controller.component';
import { DataRequestTypeEnum } from '../../../../shared/enums/data-request-type-enum';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { Observable } from 'rxjs';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';
import { ContactsEmail } from '../../../../shared/models/ContactsEmail';

@Component({
  selector: 'app-tracking-events-controller',
  templateUrl: './tracking-events-controller.component.html',
  styleUrls: ['./tracking-events-controller.component.scss'],
})
export class TrackingEventsControllerComponent {
  public filter: UIFilter[];
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum;

  public gridViews: GridView[] = [
    new GridView(
      'Events',
      PermissionsEnum.UnassignedVerificationBundles, // permissions need to change once created.
      null,
      [],
      false,
      ['eventType', 'entityType', 'name', 'actionBy', 'data', 'timestamp'],
      [],
      DataRequestTypeEnum.AssessmentHistory,
      false,
      ['Event', 'Type', 'Name', 'Actioned By', 'Data', 'Date']
    ),
  ];

  constructor(private readonly permissionsService: PermissionsService) {}

  public showHideTableView(gridview): Observable<boolean> {
    const permissionId = PermissionsMap.get(gridview);
    return this.permissionsService.authenticateUser$(permissionId);
  }
}
