<div class="form-group row">
  <div class="col-md-4">
    <strong
      ><label class="col-form-label">
        {{ name }}
      </label></strong
    >
  </div>
  <div class="col-sm-8 form-control-static">
    <p *ngIf="!type" class="form-control-plaintext">{{ value }}</p>
    <textarea
      *ngIf="type === 'large'"
      rows="5"
      cols="30"
      readonly
      class="form-control-plaintext"
      value="{{ value }}"
    ></textarea>

    <p
      *ngIf="type === 'website' && !validateWebsite"
      class="form-control-plaintext"
    >
      {{ value }}
    </p>
    <a
      *ngIf="type === 'website' && validateWebsite"
      [href]="value"
      target="_new"
      >{{ value }}</a
    >

    <p
      *ngIf="type === 'email' && !validateEmail"
      class="form-control-plaintext"
    >
      {{ value }}
    </p>
    <a *ngIf="type === 'email' && validateEmail" [href]="'mailto:' + value">{{
      value
    }}</a>
  </div>
</div>
