import { Component, Input, OnInit } from "@angular/core";
import { AssessorAlert } from "src/app/shared/models/AssessorAssessmentAlert";

@Component({
  selector: "app-assessment-alerts-panel",
  templateUrl: "./assessment-alerts-panel.component.html",
  styleUrls: ["./assessment-alerts-panel.component.scss"],
})
export class AssessmentAlertsPanelComponent implements OnInit {
  @Input() alerts: Array<AssessorAlert>;

  constructor() {}

  ngOnInit(): void {}

  showSectionName(alertIndex: number, answerIndex: number): boolean {
    if (answerIndex === 0) {
      return true;
    }
    const alert = this.alerts[alertIndex];
    const answer = alert.answers[answerIndex];
    const previousAlert = alert.answers[answerIndex - 1];
    return answer.section !== previousAlert.section;
  }
}
