<div class="text-center" *ngIf="isLoading$ | async; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
  <mat-dialog-content>
    <div class="search-div">
      <mat-form-field class="search-form-field" floatLabel="never">
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="Search"
          autocomplete="off"
          (keyup)="applyFilter()"
          trim
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onSearchClear()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <h2>Select & Save QuestionSet</h2>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="created"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef>Version</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.version }}
          </td>
        </ng-container>
        <ng-container matColumnDef="questionSetNumber">
          <th mat-header-cell *matHeaderCellDef>question SetNumber</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.questionSetNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="validFrom">
          <th mat-header-cell *matHeaderCellDef>Valid From</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.validFrom | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="expires">
          <th mat-header-cell *matHeaderCellDef>Expires</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.expires | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.created | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button mat-icon-button (click)="onSelect(row)">Select</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ highlight: selectedRow == row }"
        ></tr>
      </table>
      <mat-paginator
        [pazeSize]="12"
        [pageSizeOptions]="[8, 100]"
      ></mat-paginator>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="mat-raised-button"
      color="primary"
      type="button"
      mat-raised-button
      (click)="onCancel()"
    >
      <mat-icon>arrow_back</mat-icon>Cancel
    </button>
    <button color="primary" type="button" mat-raised-button (click)="onSave()">
      <mat-icon>check</mat-icon> Save & Close
    </button>
  </mat-dialog-actions>
</ng-template>
