import { Component } from '@angular/core';
import { SiteMenuService } from 'src/app/core/services/site-menu/site-menu.service';
import { AzureUserAuthenticationService } from '../../../core/services/azure-authentication/user-authentication/azure-user-authentication.service';
import { PermissionsEnum } from '../../enums/permissions-enum';
import { Observable } from 'rxjs';
import { PermissionsMap } from '../../maps/permissions-map';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserName } from '../../../core/ngrx/selectors/currentUser.selectors';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum;
  public userName$: Observable<string> = this.store.select(selectCurrentUserName);

  constructor(
    private readonly azureUserAuthenticationService: AzureUserAuthenticationService,
    private readonly siteMenuService: SiteMenuService,
    private readonly permissionsService: PermissionsService,
    protected readonly store: Store
  ) {}

  public async logout(): Promise<void> {
    await this.azureUserAuthenticationService.azureLogout();
  }

  public authenticateUserPermissions(permissionRequestId: PermissionsEnum): Observable<any> {
    const permissionId: string = PermissionsMap.get(permissionRequestId);
    return this.permissionsService.authenticateUser$(permissionId);
  }

  public async resetPassword(): Promise<void> {
    sessionStorage.setItem('redirectUrl', window.location.href);
    await this.azureUserAuthenticationService.resetPassword();
    await this.azureUserAuthenticationService.loginSignupRedirect();
  }

  public getUserId(): string {
    return this.azureUserAuthenticationService.getLoggedInUserId();
  }

  public toggleMenu(): void {
    this.siteMenuService.toggle();
  }
}
