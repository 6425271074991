let config: IEnvironment;

export class Environment {
  constructor() {}

  public async getConfig(): Promise<IEnvironment> {
    if (config) return config;

    const response = await fetch('/assets/settings/environment.json');
    const environment: IEnvironment = await response.json();
    config = environment;
    window['AppConfig'] = environment;

    return environment;
  }

  public getConfigSync(): IEnvironment {
    return config;
  }
}

export interface IEnvironment {
  production: boolean;
  baseApiUrl: string;
  crmBaseUrl: string;
  myChasUrl: string;
  monitorConfig: {
    connectionString: string;
    enableAutoRouteTracking: boolean;
  };
  authentication: {
    auth: {
      clientId: string;
      authority: string;
      chasauthority: string;
      resetAuthority: string;
      redirectUri: string;
      navigateToLoginRequestUrl: boolean;
      validateAuthority: boolean;
      knownAuthorities: Array<string>;
      postLogoutRedirectUri: string;
    };
    scopes: Array<string>;
    endpoint: string;
  };
  assessorApi: {
    baseApiUrl: string;
    catalystApiUserImpersonation: string;
    assessmentReadScope: string;
  };
  assessmentCache: {
    timeout: number;
  };
  alertsApi: {
    alerts: string;
    assignAlert: string;
  };
  featureFlags: {
    enableProductivity: boolean;
    enableAssessorProductivity: boolean;
  };
  productivityLoadInterval: number;
  productivityAccess: string[];
  productivityCRMUrl: string;
  session: {
    idleTimeout: number;
    timeoutNotification: number;
  };
  siteFooter: {
    telephone: string;
    email: string;
  };
  buildNumber: string;
}
