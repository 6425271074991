import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { AssessmentSummary, SelectOptionModel } from 'src/app/shared';
import { SingleOptionSelectFieldComponent } from 'src/app/shared/components/single-option-select-field/single-option-select-field.component';
import { AssessmentProgressStatus } from '../../../shared/classes/assessment-progress-status';

@Component({
  selector: 'app-assignment-modal',
  templateUrl: './assignment-modal.component.html',
  styleUrls: ['./assignment-modal.component.scss'],
})
export class AssignmentModalComponent implements OnInit, OnDestroy {
  @ViewChild('assignAssessmentModal', { static: false })
  modal: ModalDirective;
  @ViewChild('assessorSelections', { static: false })
  assessorSelection: SingleOptionSelectFieldComponent;
  assessments: AssessmentSummary[];
  successMessage: string;
  errorMessage: string;
  selectableOptions: SelectOptionModel[] = [];
  disableAssign: boolean;
  selected: SelectOptionModel;
  isUpdating = false;
  @Output()
  modalClose: EventEmitter<void> = new EventEmitter<void>();
  private assessorSubscription: Subscription;

  constructor(private assessmentService: AssessmentService) {}

  get isBulkAssignFeatureEnabled(): boolean {
    return null;
  }

  get assignLabel(): string {
    return this.assessments && this.assessments.length > 1 ? 'Assign assessments' : 'Assign assessment';
  }

  ngOnInit(): void {
    this.assessorSubscription = this.assessmentService.getAssessorsSubject().subscribe((x) => {
      this.selectableOptions = [];
      x.forEach((assessor) =>
        this.selectableOptions.push({
          name: assessor.name,
          value: assessor.id,
        })
      );
    });
  }

  show(assessments: AssessmentSummary[]): void {
    this.assessments = assessments;
    this.assessorSelection.options = this.selectableOptions;

    this.selected = null;
    this.assessorSelection.setSelection('');

    this.disableAssign = true;
    this.modal.show();
  }

  assign(): void {
    this.successMessage = null;
    this.errorMessage = null;

    if (!this.assessments || this.assessments.length === 0) {
      return;
    }

    this.isUpdating = true;
    this.disableAssign = true;
    this.assessmentService
      .assignAssessments$(
        this.assessments.map((a) => a.InternalId),
        this.selected.value,
        true
      )
      .subscribe(
        (success) => {
          if (this.assessments.length > 1) {
            this.successMessage = `${this.assessments.length} assessments are successfully assigned`;
          } else {
            this.successMessage = `${this.assessments[0].Id} successfully assigned`;
          }
          this.assessments.forEach((assessment) => {
            assessment.AssignedAssessor = this.selected.name;
            assessment.AssignedAssessorId = this.selected.value;

            if (assessment.Status !== AssessmentProgressStatus.SubmittedForQA) {
              const oldStatus = assessment.Status;
              assessment.Status = AssessmentProgressStatus.Submitted;
              this.assessmentService.update(assessment, oldStatus);
            }
          });
          this.isUpdating = false;
          this.disableAssign = false;
        },
        (errorResponse) => {
          this.errorMessage = `Error assigning assessment: ${errorResponse.error.Message}`;
          this.isUpdating = false;
          this.disableAssign = false;
        }
      );
  }

  getAssessors(): SelectOptionModel[] {
    return this.selectableOptions;
  }

  selectChange(selection: SelectOptionModel): void {
    if (selection.value === undefined) {
      this.disableAssign = true;
    } else {
      this.disableAssign = false;
    }

    this.selected = selection;
  }

  hide(): void {
    this.successMessage = undefined;
    this.errorMessage = undefined;
    this.assessorSelection.setSelection('');
    this.selected = undefined;
    this.assessments = [];
    this.modalClose.emit();
    this.modal.hide();
  }

  ngOnDestroy(): void {
    //this.assessorSubscription.unsubscribe();
  }
}
