import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyTimelineService, Note } from '../../../core/services/company-timeline/company-timeline.service';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUserContactData } from '../../../core/ngrx/selectors/currentUser.selectors';
import { CompanyTimelineComponent } from '../../../features/assessment/company-timeline/company-timeline.component';
import { SnackBarClass } from '../snack-bar/snack-bar.class';
import { selectContractorAccountId } from '../../../core/ngrx/selectors/contractor-account.selectors';
import { AccountContact } from '../../models/CheckNewAuthenticationResponse';
import { angularEditorConfig } from '../phone-call-accordian/phone-call-accordion.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-notes-accordion',
  templateUrl: './notes-accordion.component.html',
  styleUrls: ['./notes-accordion.component.scss'],
})
export class NotesAccordionComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public notesForm: FormGroup;
  public requestPending = false;
  public editorConfig: AngularEditorConfig = angularEditorConfig;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly timelineService: CompanyTimelineService,
    private readonly store: Store,
    private readonly snackbarClass: SnackBarClass,
    private readonly companyTimeline: CompanyTimelineComponent
  ) {}

  ngOnInit(): void {
    this.hydrateForm();
  }

  public async addNewNote(): Promise<void> {
    const accountId: string = await firstValueFrom(this.store.select(selectContractorAccountId));
    const currentUser: AccountContact = await firstValueFrom(this.store.select(selectCurrentUserContactData));
    const note: Note = {
      subject: this.notesForm.get('title').value,
      description: this.notesForm.get('note').value,
      regardingAccountId: accountId,
      createdById: currentUser.contactId,
      createdBy: currentUser.contactname,
    };
    this.requestPending = true;
    try {
      await firstValueFrom(this.timelineService.createTimelineNote$(note));
      this.hydrateForm();
    } catch (e) {
      this.snackbarClass.displaySnackbar('Error: Please contact your system administrator', 'Close');
    } finally {
      this.requestPending = false;
      this.resetState();
      await this.refreshTimeLine();
    }
  }

  public resetState = () => this.companyTimeline.resetState(this.notesForm, this.accordion);
  public refreshTimeLine = () => this.companyTimeline.refreshTimelineData();

  public accordionState(value: boolean): void {
    this.companyTimeline.pauseTimelineRefresh = value;
  }
  private hydrateForm(): void {
    this.notesForm = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      note: new FormControl('', Validators.required),
    });
  }
}
