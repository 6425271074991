import { createAction, props } from "@ngrx/store";
import { ContactsEmail } from "src/app/shared/models/ContactsEmail";

export enum ContactsEmailsTableActions {
  Load = "[Contacts Emails Table] Load",
  LoadSuccess = "[Contacts Emails Table] Load Successful",
  LoadError = "[Contacts Emails Table] Load Error",

  save = "[Contacts Emails Table] save",
  saveSuccess = "[Contacts Emails Table] save Success",
  saveError = "[Contacts Emails Table] save Error",
}

export const ContactsEmailsTableLoad = createAction(
  ContactsEmailsTableActions.Load,
  props<{ accountId: string }>()
);
export const ContactsEmailsTableLoadSuccess = createAction(
  ContactsEmailsTableActions.LoadSuccess,
  props<{ ContactsEmails: ContactsEmail[] }>()
);
export const ContactsEmailsTableLoadError = createAction(
  ContactsEmailsTableActions.LoadError,
  props<{ error: string }>()
);

//save
export const ContactsEmailsTableSave = createAction(
  ContactsEmailsTableActions.save,
  props<{ accountId: string; contactsEmail: ContactsEmail }>()
);
export const ContactsEmailsTableSaveSuccess = createAction(
  ContactsEmailsTableActions.saveSuccess,
  props<{ contactsEmail: ContactsEmail }>()
);
export const ContactsEmailsTableSaveError = createAction(
  ContactsEmailsTableActions.saveError,
  props<{ error: string }>()
);
