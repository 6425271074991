import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { YourDashboardTableControllerComponent } from './your-dashboard/your-dashboard-table-controller/your-dashboard-table-controller.component';
import { AssessorDashboardComponent } from './assessor-dashboard/assessor-dashboard.component';
import { AssessorCompanyDashboardComponent } from './assessor-company-dashboard/assessor-company-dashboard.component';
import { AssessmentAdminDashboardComponent } from './assessment-admin-dashboard/assessment-admin-dashboard.component';

const routes: Routes = [
  { path: 'assessmentadmin', component: AssessmentAdminDashboardComponent },
  { path: 'assessor', component: AssessorDashboardComponent },
  { path: 'customer-service', component: YourDashboardTableControllerComponent },
  { path: 'parentAssessor', component: AssessorCompanyDashboardComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssessorDashboardRoutingModule {}
