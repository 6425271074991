import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";

import { Subscription } from "rxjs";
import {
  getModuleTableLoadingSelector,
  getModuleTableSelector,
} from "src/app/core/ngrx/selectors/ModuleTable.selectors";
import { ModuleTableActions } from "src/app/core/ngrx/actions/ModuleTable.actions";
import { MatTableDataSource } from "@angular/material/table";
import { ModuleTable } from "src/app/shared/models/ModuleTable";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-module-table",
  templateUrl: "./module-table.component.html",
  styleUrls: ["./module-table.component.scss"],
})
export class ModuleTableComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<ModuleTable>;
  displayedColumns: string[] = [
    "name",
    "variant",
    "moduleType",
    "assessmentType",
    "employeeLower",
    "employeeUpper",
    "actions",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ProductCurrentMembershipTableSub: Subscription;
  isLoading$ = this.store.select(getModuleTableLoadingSelector);
  searchKey: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.ProductCurrentMembershipTableSub = this.store
      .select(getModuleTableSelector)
      .subscribe((t) => {
        this.dataSource = new MatTableDataSource(t);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    this.store.dispatch({
      type: ModuleTableActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.ProductCurrentMembershipTableSub) {
      this.ProductCurrentMembershipTableSub.unsubscribe();
    }
  }

  assessmentSelected(assessmentId: string): void {
    console.log(assessmentId);
    // this.assessmentService.selectAssessment(assessmentId);
    this.router.navigate([`/assessment/editModule/${assessmentId}`]);
  }

  onSearchClear(): void {
    this.searchKey = "";
  }

  applyFilter(): void {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
