<nav class="navbar navbar-expand-sm navbar-dark chas-navbar">
  <div class="logo-container">
    <div>
      <span class="navbar-text" routerLink="/">
        <a class="navbar-brand"><img alt="" class="logo" src="../../../assets/img/chas-logo-white.png" /></a>
        Operations Portal
      </span>

      <!-- Menu toggle button (hidden at "desktop" screen widths.) -->
      <button (click)="toggleMenu()" class="menuButton" title="Show/hide menu" type="button">
        <i aria-hidden="true" class="fa fa-fw fa-bars"></i>
      </button>
    </div>
  </div>
  <div class="collapse navbar-collapse show" id="NavbarMenuItems">
    <ul class="navbar-nav">
      <li class="nav-item">
        <app-select-account-menu [userId]="getUserId()"></app-select-account-menu>
      </li>
    </ul>
    <div class="breadcrumb ml-auto">
      <a *ngIf="authenticateUserPermissions(permissionEnum.ContractorsNav) | async" routerLink="/contractors">
        <mat-icon><i class="fa fa-users"></i></mat-icon>
        <mat-list-text>Search Contractors</mat-list-text>
      </a>
    </div>
    <div class="user-details ml-auto">
      <div class="user-left">
        <div class="btn-group" dropdown>
          <div class="btn btn-primary dropdown-toggle" dropdownToggle type="button">
            <div class="user-name">
              {{ userName$ | async }}
            </div>
          </div>
          <div *dropdownMenu class="dropdown-menu" role="menu">
            <a (click)="resetPassword()" class="dropdown-item pointer">
              <i class="fa fa-lg fa-fw fa-lock"></i>
              Change my password
            </a>
          </div>
        </div>
      </div>
      <div class="user-right">
        <button (click)="logout()" class="btn sign-out" title="Log out" type="button">
          <i class="fa fa-lg fa-fw fa-sign-out"></i>
        </button>
      </div>
    </div>
  </div>
</nav>
