import { PermissionsEnum } from '../enums/permissions-enum';

export const PermissionsMap = new Map([
  [PermissionsEnum.VerificationDashboard, 'A2FBCCB2-2E03-4840-A918-EFF32266A939'],
  [PermissionsEnum.AssessorDashboard, '3420D7B2-370B-498B-B0B8-78CEE67E3529'],
  [PermissionsEnum.AssessorCompanyDashboard, 'E17F3D64-2C9B-4319-AF37-FF9B75626728'],
  [PermissionsEnum.AssignAssessor, 'DB68E6C9-5E27-4CDC-A2B4-1D017B35AB34'],
  [PermissionsEnum.AssignAssessorCompany, '93490A32-11D6-4A69-B58F-3001BD601023'],
  [PermissionsEnum.OpsPortalAccess, 'AE5AF2DE-900A-48F9-B002-E6B5BD1AD0B2'],
  [PermissionsEnum.AdminNav, '0CC84C17-A4C1-4546-A2B1-3B50AD4C8514'],
  [PermissionsEnum.ProductivityNav, '7EFA3E29-5CF9-4F35-B20E-EC928563A040'],
  [PermissionsEnum.ProductivityStatsNav, '52CC4448-67B7-4044-AEEB-870F7374CCA2'],
  [PermissionsEnum.ReportsNav, 'AC487A20-6D4A-4F17-ABF3-9244D24153CB'],
  [PermissionsEnum.ContractorsNav, 'DFDD3F4B-64DA-44A5-9A8D-8E205AAD766D'],
  [PermissionsEnum.OpportunitiesNav, 'E80A215E-4A09-4052-B43F-82ACDEC857E8'],
  [PermissionsEnum.ClientFilesNav, '89A1AE7E-AFBF-4BC2-8CB3-D1D916B6168B'],
  [PermissionsEnum.CreditSafeToolNav, 'F1DAA579-86BE-4806-9158-C5AD7624BB52'],
  [PermissionsEnum.DocumentsNav, '2BD60A25-C2D9-41F4-8B86-EE8B97BB3CC4'],
  [PermissionsEnum.YourAssessments, '5B119754-DE49-451C-8B04-ACFDC7248D36'],
  [PermissionsEnum.UnassignedAssessments, '94F3109A-4FBD-42F4-93DF-5C96168B78DF'],
  [PermissionsEnum.RecentAssessments, '8A0195C7-71D6-431E-B87B-937C4EA6D8C8'],
  [PermissionsEnum.YourActiveAssessments, 'A7329C6A-8642-4FCE-A4A1-EA257F7CB826'],
  [PermissionsEnum.Products, 'CF3418F8-9A8C-40F0-92C2-36C9A3800238'],
  [PermissionsEnum.MembershipNav, 'F245F396-06E2-4AF7-AB75-BDB57E54FE31'],
  [PermissionsEnum.AssessmentAdminNav, 'C9D0120E-59CB-4B06-B976-9EFA18B34A01'],
  [PermissionsEnum.UnassignedAssessmentBundles, 'A127A894-BF11-4970-8F0B-3947B68E29D5'],
  [PermissionsEnum.ActiveAssessmentBundles, 'F151001A-CB5F-47A8-ABAC-C6B63509085B'],
  [PermissionsEnum.UnassignedVerificationBundles, '020F1D72-3EB1-4AB1-8C14-0042AA1C487E'],
  [PermissionsEnum.ActiveVerificationBundles, '1B602E3C-3D69-4512-ABE4-7C8FA507A18A'],
  [PermissionsEnum.DocumentVerifications, '0E9D4F91-6B45-4691-B0F3-888E6A288860'],
  [PermissionsEnum.AssessorWorkload, '753FEB37-D27A-41C4-9C41-AC7CF76058D6'],
  [PermissionsEnum.WithdrawAssessment, 'D7D140BC-0E18-4246-9C28-9238522F0FCA'],
  [PermissionsEnum.AdminModulesNav, 'B1C90180-F26A-4E8F-8DD2-C8D43A44A77D'],
  [PermissionsEnum.AdminQuestionSets, 'A8D82434-24A0-4C95-8EEF-55EE517CB209'],
  [PermissionsEnum.AdminProductsNav, 'FF5CBD73-394D-4759-ACAE-E5E2046645D8'],
  [PermissionsEnum.AdminSupplyChainNav, '61D53BFE-FD5B-417A-89F4-7D8A109C08C5'],
  [PermissionsEnum.AdminRegionsNav, 'A00AF569-2D4B-463C-825D-721CF3C6D0D7'],
  [PermissionsEnum.AdminWorkCategoriesNav, 'EB6E266E-BAB4-46E4-AD5E-4E1D95FB374F'],
  [PermissionsEnum.AdminAssessorNav, '0D2BA27F-B635-4283-A925-A6552C6D9FB2'],
  [PermissionsEnum.CompanyInformationEditButton, '825D928C-B8F7-442E-AD34-8F3C1F5C8007'],
  [PermissionsEnum.CompanyInformation, 'C696EC2E-D600-4114-9B64-DE94F54EB164'],
  [PermissionsEnum.MembershipSummary, '5614CD33-55CF-4529-AF46-827A1B33EFBA'],
  [PermissionsEnum.MembershipSummaryEditButton, '67B1B75B-D215-493B-B2D8-6D356D8EE4AE'],
  [PermissionsEnum.AccreditationSummary, '54B3211D-D51E-47F1-860A-544564E7897F'],
  [PermissionsEnum.AccreditationSummaryEditButton, '14296D3C-9FAA-46FC-BF92-CE1D28A45419'],
  [PermissionsEnum.ContactSummary, '514262C0-6339-4508-B95D-6E3D0ABECEF1'],
  [PermissionsEnum.ContactSummaryEditButton, 'A96ED65E-14DF-46E1-94A9-DC54E29B8626'],
  [PermissionsEnum.Summary, 'A66DE837-6881-46D7-838C-D02D6F796643'],
  [PermissionsEnum.SummaryEditButton, '03F2692D-C9F2-4E24-8E2B-33D2274ABB70'],
  [PermissionsEnum.ContactEmailsEditButton, 'CDFA3DF5-035E-4FE0-B448-5C10C289746F'],
  [PermissionsEnum.ContactEmails, '83AD4D4D-FAAD-4378-B6B8-7B273A9130C6'],
  [PermissionsEnum.EditAnyAssessment, '76A4358C-62A8-45A7-946E-B02D8B322BC1'],
  [PermissionsEnum.FastTrackEnabled, 'BD90BC65-5925-45AF-A3E3-4B5C1D5FC24A'],
  [PermissionsEnum.CompanyActiveAlerts, '41E41C24-4953-4DC1-89B1-64B94B591E31'],
  [PermissionsEnum.CompanyOutstandingAlerts, 'C616A8CC-A293-4564-8D88-C45B0B20A81C'],
  [PermissionsEnum.ResponseRequiredBy, 'B9A6325A-93C7-47A1-8FDE-FFE1F6FB1F90'],
  [PermissionsEnum.WorkRegionsSummary, '98B26C29-B035-49C1-A7C8-ED8157BAF9DF'],
  [PermissionsEnum.WorkRegionsSummaryEditButton, 'C45CCD92-5613-45C4-9C5D-6356A2300674'],
  [PermissionsEnum.ClientModules, '55494483-FA2F-4AF4-AFB5-A1FA00757F5E'],
  [PermissionsEnum.AddressSummary, '2C3BF6A3-CBAA-4BC6-A24C-CC315955A56C'],
  [PermissionsEnum.AddressSummaryEditButton, '2F6891BD-D11A-41A0-B129-8E136F355F3C'],
  [PermissionsEnum.BusinessSummary, '3AE01D06-5C10-49A9-AB23-FDCDBB8C8F83'],
  [PermissionsEnum.BusinessSummaryEditButton, 'A98A8844-17D7-46F5-A2A6-FEA368689528'],
  [PermissionsEnum.ThirdPartySummary, '28BD0B59-932A-469F-BC5E-A626F27EB9F9'],
  [PermissionsEnum.ThirdPartySummaryEditButton, 'C764ABAD-0E4B-4EBC-938A-F7B94A4F5950'],
  [PermissionsEnum.ThirdPartyKeysSummary, 'D84F9755-6A71-4F59-A519-9B13A1C7F7B1'],
  [PermissionsEnum.ThirdPartyKeysEditButton, '5DDF66A2-FD83-4D90-A405-D9E63A11BEEA'],
  [PermissionsEnum.EditAssessmentState, '449D4548-7F22-41AD-8097-04DD4030F9A7'],
  [PermissionsEnum.EnableDisableAccount, 'AB57AE03-E8E5-476B-BA7B-AFA8018D7009'],
  [PermissionsEnum.ManualAssessorFees, '0ED79FC3-67AE-4455-A004-CA81F863AFE9'],
  [PermissionsEnum.FinanceDashboard, '660D0386-72B4-476B-BB32-328E7BDEC1DE'],
  [PermissionsEnum.CompanyVerificationBundles, 'C66F1871-3886-4958-A54B-72A7B282EA3A'],
  [PermissionsEnum.YourQAVerificationBundles, '4C232F4D-E503-4BD3-80F3-9946D7ED98CA'],
  [PermissionsEnum.UnassignedQAVerificationBundles, '3CF16947-4B93-436B-BBA2-2A1EAFA76A57'],
  [PermissionsEnum.YourQAAssessments, '4B485463-0322-440B-A297-EF0907C87643'],
  [PermissionsEnum.AdminRoutingNav, '88377EB7-D576-4E4B-BDAF-CCB27BEEDEF8'],
  [PermissionsEnum.ViewDisabledAccountsGrid, '8C06C4D0-68F8-4295-BF7B-9857B4E025D2'],
  [PermissionsEnum.ViewEnabledAccountsGrid, 'C4433997-9520-47EB-AFCA-F2F2069CBB3F'],
  [PermissionsEnum.Admin_HangfireDashboardNav, '88377EB7-D576-4E4B-BDAF-CCB27BEEDEF8'],
]);
