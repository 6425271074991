import { createAction, props } from "@ngrx/store";
import { FIRSummary } from "src/app/shared/models/FIRSummary";

export enum FIRSummaryActions {
  Load = "[FIR Summary] Load",
  LoadSuccess = "[FIR Summary] Load Successful",
  LoadError = "[FIR Summary] Load Error",
}

export const FIRSummaryLoad = createAction(
  FIRSummaryActions.Load,
  props<{ accountId: string }>()
);
export const FIRSummaryLoadSuccess = createAction(
  FIRSummaryActions.LoadSuccess,
  props<{ FIRSummary: FIRSummary }>()
);
export const FIRSummaryLoadError = createAction(
  FIRSummaryActions.LoadError,
  props<{ error: string }>()
);
