import { createReducer, on } from "@ngrx/store";
import * as AppState from "../../../State";
import { FIRSummary } from "../../../shared/models/FIRSummary";
import {
  FIRSummaryLoad,
  FIRSummaryLoadError,
  FIRSummaryLoadSuccess,
} from "../actions/FIRSummary.actions";

export interface State extends AppState.State {
  FIRSummary: FIRSummaryState;
}

export interface FIRSummaryState {
  FIRSummary: FIRSummary;
  loading: boolean;
  error: string;
}

const initialState: FIRSummaryState = {
  FIRSummary: undefined,
  loading: true,
  error: "",
};

const _FIRSummaryReducer = createReducer(
  initialState,
  on(FIRSummaryLoad, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(
    FIRSummaryLoadSuccess,
    (state, action): FIRSummaryState => ({
      ...state,
      loading: false,
      FIRSummary: action.FIRSummary,
    })
  ),
  on(
    FIRSummaryLoadError,
    (state, action): FIRSummaryState => ({
      ...state,
      error: action.error,
    })
  )
);

export function FIRSummaryReducer(state, action) {
  return _FIRSummaryReducer(state, action);
}
