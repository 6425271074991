/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddressView {
  id?: string | null;
  name?: string | null;
  latitude?: number;
  longitude?: number;
  street1?: string | null;
  street2?: string | null;
  street3?: string | null;
  postcode?: string | null;
  city?: string | null;
  town?: string | null;
  county?: string | null;
  country?: string | null;
  countryCode?: string | null;
  state?: string | null;
}
