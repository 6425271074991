<div class="d-flex">
    <h2 class="mr-auto">Client Modules</h2>
    <button color="primary" [routerLink]='["/", "client-modules", "add"]' mat-raised-button>
        Add
    </button>
</div>
<div *ngIf="isLoading; else displayTable" class="mt-3">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #displayTable>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="w-100" >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row" role="button" [routerLink]="['/', 'client-modules', 'question-sets', row.id]">
                    {{ row.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let row">
                    {{ getClient(row.clientAccountId) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="assessmentType">
                <th mat-header-cell *matHeaderCellDef>Assessment Type</th>
                <td mat-cell *matCellDef="let row">
                    {{ getAssessmentType(row.assessmentTypeId) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="deleteClientModule(row.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</ng-template>

