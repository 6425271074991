<span
  class="small helptip ml-2"
  [popover]="popoverTemplate"
  [outsideClick]="true"
  triggers="click"
  containerClass="qs-popover"
>
  <i class="fa fa-lg fa-info-circle"></i> {{ displayText }}
</span>
<ng-template #popoverTemplate>
  <div [innerHtml]="popoverText"></div>
</ng-template>
