<div class="button-row">
  <button
    [disabled]="disabled"
    (click)="assignAssessorClick.emit()"
    *ngIf="
      assignAssessor && (hasPermission(permissionEnum.AssignAssessor) | async) && isButtonEnabled(multigridDashboardButton.AssignAssessor)
    "
    color="primary"
    mat-raised-button
  >
    Assign
  </button>
  <button
    [disabled]="disabled"
    (click)="assignAssessorCompanyClick.emit()"
    *ngIf="assignAssessorCompany && (hasPermission(permissionEnum.AssignAssessorCompany) | async)"
    color="primary"
    mat-raised-button
  >
    Assign to Company
  </button>
  <button
    [disabled]="disabled"
    (click)="changeStatusClick.emit()"
    *ngIf="isButtonEnabled(multigridDashboardButton.ChangeStatus)"
    color="primary"
    mat-raised-button
  >
    Change Status
  </button>
  <button
    [disabled]="disabled"
    (click)="changeTargetDateClick.emit()"
    *ngIf="isButtonEnabled(multigridDashboardButton.ChangeTargetDate)"
    color="primary"
    mat-raised-button
  >
    Change Target Date
  </button>
  <button
    [disabled]="disabled"
    (click)="changeDecisionDateClick.emit()"
    *ngIf="isButtonEnabled(multigridDashboardButton.ChangeDecisionDate)"
    color="primary"
    mat-raised-button
  >
    Change Decision Date
  </button>
  <button
    [disabled]="disabled"
    (click)="changeExpiryDateClick.emit()"
    *ngIf="isButtonEnabled(multigridDashboardButton.ChangeExpiryDate)"
    color="primary"
    mat-raised-button
  >
    Change Expiry Date
  </button>
</div>
