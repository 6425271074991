<div mat-dialog-content>
  <p>Change Target Date</p>

  <mat-form-field appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="targetDate" />
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    (click)="changeAssessmentTargetDate()"
    [mat-dialog-close]="data.targetDate"
    cdkFocusInitial
  >
    Change
  </button>
</div>
