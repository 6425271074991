<form [formGroup]="detailsForm" novalidate>
  <section fxLayout="row wrap">
    <mat-card fxFlex="650px" fxFlex.xs="100%">
      <mat-card-title>Work Category</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div fxLayout="row" fxLayoutGap="20px" class="form-container">
          <div>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" />
              <mat-error *ngIf="hasError('name', 'required')"
                >Name is required</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Work Code</mat-label>
              <input matInput type="text" formControlName="workCode" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>chasSectors</mat-label>
              <input matInput type="text" formControlName="chasSectors" />
            </mat-form-field>
          </div>
        </div>
        <mat-card-actions align="left">
          <button
            color="primary"
            mat-raised-button
            type="button"
            (click)="onSave()"
            [disabled]="!detailsForm.valid"
          >
            <mat-icon>play_arrow</mat-icon>Save
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </section>
</form>
