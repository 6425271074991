<div class="container">
  <h2>{{ tableName }}</h2>
  <br />
  <div class="container">
    <div class="table-header d-flex justify-content-between">
      <app-data-table-button-bar
        [assignAssessor]="displayAssignButton"
        [enabledButtons]="enabledButtons"
        (assignAssessorClick)="openAssessorChangeDialog()"
      >
      </app-data-table-button-bar>
      <mat-form-field>
        <input (keyup)="quickSearch($event.target.value)" matInput placeholder="Quick Search" />
      </mat-form-field>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-data-table
    [dataRequestType]="apiRequestType"
    [columnNames]="columnNames"
    (selectionEmitter)="setSelection($event)"
    [showCheckbox]="true"
    (clickActionEmitter)="assessmentSelected($event)"
    [allowRowSelect]="displayAssignButton"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [isLoading]="isLoading"
    [enableRowClick]="enableRowClick"
  ></app-data-table>
  <!-- NEW TABLE COMPONENT -->
</div>
