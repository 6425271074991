import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  AssessorList,
  AssessorListError,
  AssessorListSuccess,
  AssessorLoad,
  AssessorLoadError,
  AssessorLoadSuccess,
  AssessorSave,
  AssessorSaveError,
  AssessorSaveSuccess,
} from '../actions/assessor.actions';
import { AssessmentHttpService } from '../../services/account/AssessmentHttpService';
import { AssessmentService } from '../../services/assessment/assessment.service';

@Injectable()
export class AssessorsEffects {
  listAssessor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssessorList),
      mergeMap(() =>
        this.assessmentService.retrieveEnabledDisabledAssessorList$().pipe(
          map((assessors) => {
            const joinedAssessors = [...assessors[0], ...assessors[1]];
            const assessorSummaries = joinedAssessors.map((row) => ({
              id: row.id,
              accountId: row.accountId.uid,
              accountName: row.accountId.name,
              fullName: row.fullName,
              email: row.email,
              statusCode: row.statusCode,
            }));

            return AssessorListSuccess({ assessors: assessorSummaries });
          }),
          catchError((error) => of(AssessorListError({ error: error })))
        )
      )
    )
  );

  loadAssessor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssessorLoad),
      mergeMap((action) =>
        this.assessmentHttpService.getAssessorById(action.assessorId).pipe(
          map((assessor) => AssessorLoadSuccess({ assessor: assessor })),
          catchError((error) => of(AssessorLoadError({ error: error })))
        )
      )
    )
  );

  createOrUpdateAssessor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssessorSave),
      mergeMap((action) =>
        this.assessmentHttpService.createOrUpdateAssessor$(action.command).pipe(
          map((assessor) => AssessorSaveSuccess({ assessor: assessor })),
          catchError((error) => of(AssessorSaveError({ error: error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private assessmentHttpService: AssessmentHttpService,
    private assessmentService: AssessmentService
  ) {}
}

export interface AssessorSummary {
  id: string;
  accountId: string;
  accountName: string;
  fullName: string;
  email: string;
  statusCode: number;
}
