import { Component, Input } from '@angular/core';
import { TabTypes } from 'src/app/shared/enums/tab-types-enum';

@Component({
  selector: 'app-get-tab',
  templateUrl: './get-tab.component.html',
  styleUrls: ['./get-tab.component.scss'],
})
export class GetTabComponent {
  @Input() type: string;
  public TabTypes = TabTypes;
}
