import { ActivatedRoute, Router } from '@angular/router';

export class Redirector {
  private static excludedUrls = ['/', '/oauthcallback', '/oauthcallback#', '/request-account'];

  public static navigateAndAppendReturnUrl(router: Router, url: string, fragment = '', queryParams: any = {}): void {
    try {
      fragment = fragment ? `#${fragment}` : `#${window.location.hash.substring(1)}`;
      let returnUrl = `${window.location.pathname}${fragment}${window.location.search}`;
      returnUrl = Redirector.filterNavigations(returnUrl);

      void router.navigate([url], { queryParams: { r: returnUrl, ...queryParams } });
    } catch (error) {
      console.error(error);
    }
  }

  public static async navigateToReturnUrl(route: ActivatedRoute, router: Router, fallbackUrl: string): Promise<boolean> {
    try {
      return await Redirector.navigateToStoredUrl(route, router, ['r'], fallbackUrl, false);
    } catch (error) {
      console.error(error);
    }
  }

  public static getStoredUrl(route: ActivatedRoute, router: Router, key: string, copyExistingQueryParams: boolean): StoredUrl {
    let storedUrl = route.snapshot.queryParamMap.get(key);
    storedUrl = Redirector.filterNavigations(storedUrl);

    const existingQueryParams = route.snapshot.queryParams;

    /*
    if (existingQueryParams[key]) {
      existingQueryParams[key] = undefined;
    }
    */

    const queryParams = copyExistingQueryParams ? { ...existingQueryParams } : {};

    if (storedUrl) {
      const url = new URL(storedUrl, window.location.origin);

      for (const [key, value] of url.searchParams.entries()) {
        queryParams[key] = value;
      }

      return new StoredUrl(url.pathname, url.hash.substring(1), queryParams);
    }
  }

  private static async navigateToStoredUrl(
    route: ActivatedRoute,
    router: Router,
    keys: string[],
    fallbackUrl: string,
    copyExistingQueryParams: boolean
  ): Promise<boolean> {
    let storedUrl: StoredUrl;

    for (const key of keys) {
      storedUrl = Redirector.getStoredUrl(route, router, key, copyExistingQueryParams);

      if (storedUrl) {
        break;
      }
    }

    if (storedUrl) {
      await router.navigate([storedUrl.pathname], { queryParams: storedUrl.queryParams, fragment: storedUrl.fragment });
      return true;
    } else {
      await router.navigate([fallbackUrl]);
      return false;
    }
  }

  private static filterNavigations(redirectedFrom: string): string {
    return !!redirectedFrom && Redirector.excludedUrls.includes(redirectedFrom.toLocaleLowerCase()) ? undefined : redirectedFrom;
  }
}

export class StoredUrl {
  constructor(public readonly pathname: string, public readonly fragment: string, public readonly queryParams: any) {}

  public get pathnameAndFragment(): string {
    return `${this.pathname}${this.fragment ? `#${this.fragment}` : ''}`;
  }
}
