import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  FIRSummaryLoad,
  FIRSummaryLoadError,
  FIRSummaryLoadSuccess,
} from "../actions/FIRSummary.actions";
import { AccountHttpService } from "../../services/account/account-http.service";

@Injectable()
export class FIRSummaryEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FIRSummaryLoad),
      mergeMap((action) =>
        this.accountService.GetFirSummary(action.accountId).pipe(
          map((b) => FIRSummaryLoadSuccess({ FIRSummary: b })),
          catchError((error) => of(FIRSummaryLoadError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountHttpService
  ) {}
}
