/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ContactViewModel {
  accountId?: string | null;
  contactId?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  telephone1?: string | null;
  mobilephone?: string | null;
  contactname?: string | null;
  isPrimaryContact?: boolean;
  position?: string | null;
  password?: any | null;
  userType?: string | null;
  accountStatus?: string | null;
  organisationId?: any | null;
  chasAlertFrequency?: any | null;
}
