<div class="layout">
  <header>
    <app-nav-bar class="w-100"></app-nav-bar>
  </header>

  <nav [class.menu--open]="isMenuVisible">
    <app-assessment-breadcrumbs></app-assessment-breadcrumbs>
  </nav>

  <section role="main" id="chas-main-content-area">
    <router-outlet></router-outlet>
  </section>

  <app-site-footer [buildVersion]="buildVersion"></app-site-footer>
</div>

<ng-template bsModal #sessionTimeoutModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Session Timeout</h4>
  </div>
  <div class="modal-body">
    Your session is about to expire. You will be logged out in
    {{ sessionTimeoutSeconds }} seconds.
  </div>
  <div class="modal-footer clearfix">
    <button
      type="button"
      (click)="logout()"
      class="btn btn-secondary float-right mr-4"
      style="margin: 0"
    >
      Log out
    </button>
    <button
      type="button"
      (click)="stayLoggedIn()"
      class="btn btn-primary float-right"
      style="margin: 0"
    >
      Stay logged in
    </button>
  </div>
</ng-template>
