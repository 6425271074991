/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Question {
  id?: string | null;
  questionNumber?: number;
  questionSetId?: string;
  dataType?: string | null;
  displayType?: string | null;
  labelText?: string | null;
  mandatory?: boolean;
  list?: string | null;
  helpText?: string | null;
  validation?: string | null;
  majorFail?: string | null;
  sortOrder?: number;
  showing?: boolean;
  responseNumber?: string | null;
  showCondition?: string | null;
  validationMessage?: string | null;
  sectionNumber?: number;
  paragraph?: number;
  sectionDescription?: string | null;
  resetCondition?: string | null;
  linkedResponseNumber?: string | null;
  defaultValue?: string | null;
  timeCriticalInfo?: boolean;
  advisoryText?: string | null;
  keyInfo?: boolean;
  relatedQuestionId?: string | null;
  symanticId?: string | null;
  addId?: number | null;
  standard?: string | null;
  guidance?: string | null;
  updateAccountField?: string | null;
  fileType?: string | null;
  documentType?: string | null;
  casNumber?: string | null;
  advisoryInfo?: string | null;
  compositeKey?: string | null;
}
