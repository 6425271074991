import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  ModuleTableLoad,
  ModuleTableLoadError,
  ModuleTableLoadSuccess,
} from "../actions/ModuleTable.actions";
import { ModuleHttpService } from "../../services/account/ModuleHttpService";

@Injectable()
export class ModuleTableEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleTableLoad),
      mergeMap((action) =>
        this.accountService.getModuleTable(action.accountId).pipe(
          map((b) =>
            ModuleTableLoadSuccess({
              modules: b,
            })
          ),
          catchError((error) => of(ModuleTableLoadError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: ModuleHttpService
  ) {}
}
