import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FIRSummaryState } from "../reducers/FIRSummary.reducer";

export const selectFIRSummaryFeatureState =
  createFeatureSelector<FIRSummaryState>("FIRSummary");

export const getFIRSummarySelector = createSelector(
  selectFIRSummaryFeatureState,
  (state) => state.FIRSummary
);

export const getFIRSummaryLoadingSelector = createSelector(
  selectFIRSummaryFeatureState,
  (state) => state.loading
);
