import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { firstValueFrom, Subject } from 'rxjs';
import { UserAuthenticationAbstract } from './core/services/authentication/user-authentication-abstract';
import { SessionManagerService } from './core/services/session-manager/session-manager.service';
import { SiteMenuService } from './core/services/site-menu/site-menu.service';
import { Environment, IEnvironment } from './shared/classes/environment';
import { FeatureFlagService } from './core/services/feature-flags/feature-flag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  config: IEnvironment;
  title = 'assessor-portal';
  @ViewChild('sessionTimeoutModal', { static: true }) sessionTimeoutModal;
  modalRef: BsModalRef;
  private $destroyed = new Subject<void>();
  public buildVersion: string;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public readonly router: Router,
    private readonly userAuthentication: UserAuthenticationAbstract,
    private readonly SiteMenu: SiteMenuService,
    private readonly sessionManagerService: SessionManagerService,
    private readonly modalService: BsModalService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  get isMenuVisible(): boolean {
    return this.SiteMenu.isVisible;
  }

  get sessionTimeoutSeconds(): number {
    return this.sessionManagerService.sessionTimeoutSeconds;
  }

  async ngOnInit(): Promise<void> {
    const env: Environment = new Environment();
    this.config = env.getConfigSync();
    await this.featureFlagService.retrieveFeatureFlags();

    this.buildVersion = await firstValueFrom(this.SiteMenu.retrieveApplicationBuildVersion$());

    this.sessionManagerService.init();
    this.sessionManagerService.onIdleStart.subscribe(() => {
      const modalOptions: ModalOptions = {
        backdrop: 'static',
        keyboard: false,
        ignoreBackdropClick: true,
      };
      this.modalRef = this.modalService.show(this.sessionTimeoutModal, modalOptions);
    });
    this.sessionManagerService.onIdleEnd.subscribe(() => {
      if (this.modalRef) {
        this.modalRef.hide();
      }
    });
  }

  logout(): void {
    this.userAuthentication.logout();
  }

  stayLoggedIn(): void {
    this.sessionManagerService.reset();
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this._destroying$.next();
  }
}
