/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AccountRequest {
  accountId?: string | null;
  companyHouseNo?: string | null;
  companyName?: string | null;
  customerType?: number;
  numberofDirectEmployees?: number;
  labourOnlySubContractors?: number;
  bonafideSubContractors?: number;
  tradingAs?: string | null;
  variant?: string | null;
  assessmentType?: number;
  abNumber?: string | null;
  acNumber?: string | null;
  isAusGstRegistered?: boolean;
  readonly totalEffectiveEmployees?: number;
}
