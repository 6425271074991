import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { SelectionType } from '@swimlane/ngx-datatable';
import { AssessmentSummary, SearchArgs } from '../../../shared';
import { SearchService } from 'src/app/core/services/search/search.service';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { ResultTableHeader } from 'src/app/shared/classes/result-table-header';
import { AssessmentProgressStatus } from 'src/app/shared/classes/assessment-progress-status';
import { AssignmentModalComponent } from '../assignment-modal/assignment-modal.component';

@Component({
  selector: 'app-search-result-table',
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.scss'],
})
export class SearchResultTableComponent implements OnInit, OnDestroy, OnChanges {
  private currentSearchServiceSubscription: Subscription;
  columns = [];
  data = [];

  @ViewChild('assignmentModal', { static: false })
  modal: AssignmentModalComponent;

  @Input() searchArgs: SearchArgs;
  @Input() parentViewEnabled: boolean;
  groupAssignment: boolean;
  loading = false;

  pageNumber: number;
  pageSize: number;
  header: string = ResultTableHeader.NoFilterApplied;

  selectedRows = [];
  SelectionType = SelectionType;
  rowSelectionEnabled = false;

  constructor(private searchService: SearchService, private router: Router, private assessmentService: AssessmentService) {
    this.parentViewEnabled = false;
    this.pageNumber = 0;
    this.pageSize = 10;
  }

  ngOnDestroy(): void {
    this.currentSearchServiceSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loading = true;

    this.currentSearchServiceSubscription = this.searchService.resultUpdateSubscription().subscribe((data: any) => {
      this.setData(data);
    });
    this.searchService.updateAssessmentResults([]);
  }

  assessmentSelected(assessmentId: string): void {
    this.assessmentService.selectAssessment(assessmentId);
    this.router.navigate([`/assessment/assess/${assessmentId}`]);
  }

  contractorSelected(assessmentId: string): void {
    var summary = this.assessmentService.getAssessmentSummary(assessmentId);
    this.router.navigate([`/assessment/companydetails/${summary.ContractorId}`]);
  }

  private setData(data: AssessmentSummary[]): void {
    this.data = data;
    this.pageNumber = 0;

    this.selectedRows = [];

    this.rowSelectionEnabled = this.data && this.data.length > 0 ? this.isAssessmentComplete(this.data[0]) : false;

    this.loading = false;
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }): void {
    const change: SimpleChange = changes.searchArgs;
    const args = change.currentValue as SearchArgs;
    this.loading = true;
    this.searchService // <<< FIX
      .searchForResults(args)
      .subscribe((data: AssessmentSummary[]) => {
        this.setData(data);
      });
  }

  onSelect({ selected }): void {
    this.selectedRows = selected;
  }

  openModal(): void {
    this.modal.show(this.selectedRows);
  }

  openModalForSingleAssessment(assessment: any): void {
    this.modal.show([assessment]);
  }

  isDateValid(date: Date): boolean {
    return !isNaN(date.getTime()) && date.getTime() !== 0;
  }

  compareDateColumns(a, b): number {
    const dateA = !a || isNaN(a.getTime()) ? null : a;
    const dateB = !b || isNaN(b.getTime()) ? null : b;

    if (dateA === dateB) {
      return 0;
    }

    if (dateA === null) {
      return 1;
    }

    if (dateB === null) {
      return -1;
    }

    return dateA.getTime() - dateB.getTime();
  }

  isAssessmentComplete(row: any): boolean {
    switch (row.Status) {
      case AssessmentProgressStatus.Complete:
      case AssessmentProgressStatus.Verified:
      case AssessmentProgressStatus.SelfCertified:
      case AssessmentProgressStatus.Accredited:
      case AssessmentProgressStatus.Failed:
      case AssessmentProgressStatus.FailedResubmit:
        return false;
      default:
        return true;
    }
  }
}
