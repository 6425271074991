import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "employee",
})
export class EmployeePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    return value < 5 ? "Less than 5" : "5 or more";
  }
}
