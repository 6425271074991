import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessmentModuleCreate } from 'src/app/shared/models/AssessmentModuleCreate';
import { ModuleTable } from 'src/app/shared/models/ModuleTable';
import { ModuleViewModel } from 'src/app/shared/models/ModuleViewModel';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class ModuleHttpService {
  constructor(private http: HttpClient, private readonly api: ApiRoutesClass) {}

  public createOrUpdateModule(module: any): Observable<any> {
    return this.http.post<any>(`${this.api.Module()}/createOrUpdateModule`, module);
  }

  public GetAssessmentModuleById(id: string): Observable<AssessmentModuleCreate> {
    return this.http.get<any>(`${this.api.Module()}/GetAssessmentModuleById/${id}`);
  }

  public getInitializeModule(): Observable<ModuleViewModel> {
    return this.http.get<ModuleViewModel>(`${this.api.Module()}/getInitializeModule`);
  }

  public getModuleTable(accountId: string): Observable<ModuleTable[]> {
    return this.http.get<ModuleTable[]>(`${this.api.Module()}/getModuleTable/${accountId}`);
  }
}
