import { Pipe, PipeTransform } from "@angular/core";
import { Environment, IEnvironment } from "../../classes/environment";

@Pipe({
  name: "accountUrl",
})
export class AccountUrlPipe implements PipeTransform {
  private config: IEnvironment;

  constructor() {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  transform(value: any, ...args: unknown[]): string {
    const crmUrl = this.config.productivityCRMUrl;
    if (value) {
      const queryParameters = `&forceUCI=1&pagetype=entityrecord&etn=account&id=${value}`;
      return `${crmUrl}${queryParameters}`;
    }
    return "";
  }
}
