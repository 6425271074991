import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AssessmentSummary } from '../../models/assessment-summary';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
  @Input() dataSource: MatTableDataSource<AssessmentSummary>;
  @Input() tableConfig: DataTableInterface;
  @Input() displayedColumns = [];
  @Input() showTime = false;
  @Input() isLoading = true;
  @Input() showCheckbox = false;
  @Input() enableRowClick = true;
  @Input() allowRowSelect = true;
  @Input() columnNames: Array<string>;
  @Input() dataRequestType: any;
  @Input() viewType: any;
  @Input() assessorFeesSaveButton = false;
  @Output() clickActionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveAssessorFeesEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort, { static: false }) set sort(sort: MatSort) {
    if (this.dataSource) this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator, { static: false }) set paginator(paginator: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = paginator;
  }
  @ViewChild(MatTableExporterDirective) exporter!: MatTableExporterDirective;

  protected readonly MultigridDashboardButton = MultigridDashboardButton;
  protected readonly parseInt = parseInt;

  public apiRequestTypes: typeof ApiRequestTypes = ApiRequestTypes;
  public selection = new SelectionModel<AssessmentSummary>(true, []);
  public dataExportFileTypes: typeof ExportFileTypesEnum = ExportFileTypesEnum;
  public updatedAssessorFees = [];

  constructor() {}

  private _dataTableId: string = (Math.random() + 1).toString(36).substring(7);

  public get dataTableId(): string {
    if (this.tableConfig) {
      return this.tableConfig.tableName.toLowerCase().replace(' ', '-');
    } else {
      return this._dataTableId;
    }
  }

  public async clickActionRequest(value: any): Promise<void> {
    if (this.enableRowClick) {
      this.clickActionEmitter.emit({ ...value, id: value.Id ?? value.id, key: value.type, dataTableId: this.dataTableId });
    }
  }

  public async selectActionRequest(): Promise<void> {
    this.selectionEmitter.emit(this.selection.selected);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public async masterToggle(): Promise<void> {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
    await this.selectActionRequest();
  }

  public updateAssessorFees(assessorFee: number, rowIndex: number): void {
    this.dataSource.data.filter((el: any, index) => {
      const recordIndex: number = this.updatedAssessorFees.findIndex((t) => t.id == el.id);
      if (index == rowIndex) {
        el.cost = assessorFee;
        if (recordIndex !== -1) {
          this.updatedAssessorFees[recordIndex] = el;
        } else {
          this.updatedAssessorFees.push(el);
        }
      }
    });
  }

  public exportTable(option: ExportFileTypesEnum): void {
    let blob: Blob = new Blob();
    const fileType: ExportFileTypesEnum = option;

    switch (option) {
      case ExportFileTypesEnum.CSV:
        const csvContent: string = this.generateContentWithHeaders(',');
        blob = new Blob([csvContent], { type: 'text/csv' });
        break;
      case ExportFileTypesEnum.JSON:
        const jsonDataWithHeaders = this.dataSource.data.map((row) => this.includeHeadersInData(row));
        const jsonContent: string = JSON.stringify(jsonDataWithHeaders, null, 2);
        blob = new Blob([jsonContent], { type: 'application/json' });
        break;
      case ExportFileTypesEnum.EXCEL:
        const excelContent: string = this.generateContentWithHeaders('\t');
        blob = new Blob([excelContent], { type: 'application/vnd.ms-excel' });
        break;
      default:
        return;
    }

    const url: string = URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = `dataReport${fileType}`;
    link.click();
    URL.revokeObjectURL(url);
  }

  private generateContentWithHeaders(delimiter: string): string {
    const headers: string[] = Object.keys(this.dataSource.data[0]);
    const contentHeader: string = headers.join(delimiter) + '\n';
    const contentRows: string = this.dataSource.data
      .map((row: AssessmentSummary) => headers.map((header: string) => row[header]).join(delimiter))
      .join('\n');
    return contentHeader + contentRows;
  }

  private includeHeadersInData(row: any): { [p: string]: any } {
    const headers: string[] = Object.keys(row);
    const rowData: { [key: string]: any } = {};
    for (const header of headers) {
      rowData[header] = row[header];
    }
    return rowData;
  }
}

export enum ExportFileTypesEnum {
  JSON = '.json',
  CSV = '.csv',
  EXCEL = '.xls',
}

export interface DataTableInterface {
  tableName: string;
  tableId: number;
}
