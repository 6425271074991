<mat-card class="example-card" *ngIf="!loaded; else showProfile">
  <mat-card-header class="center">
    <mat-card-title>Generating Profile Details</mat-card-title>
  </mat-card-header>
  <mat-card-footer>
    <mat-progress-bar mode="query"></mat-progress-bar>
  </mat-card-footer>
</mat-card>

<ng-template #showProfile>
  <div class="profile">
    <div class="profile__nav">
    </div>
    <div class="profile__header" *ngIf="contractorProfileSummary">
      <div class="profile__header-title">
        <h1>
          <strong>{{ contractorProfileSummary.name }}</strong>
          <span>|</span>
          <span>{{ contractorProfileSummary.chasId }}</span>
        </h1>
        <button
          (click)="selectedTabIndex=3"
          mat-raised-button
          color="primary"
          [matBadge]="contractorProfileSummary.alerts"
          matBadgeColor="accent"
        >
          <i class="fa-solid fa-arrow-up-right-from-square"></i> View Alerts
        </button>
        <div class="profile__nav-actions">
          <button mat-raised-button (click)="launchCrm()" color="primary">
            <i class="fas fa-arrow-right"></i>
            Login to CRM
          </button>
          <app-mychas-login></app-mychas-login>
        </div>
      </div>

      <div class="profile__header-sub-title">
        <h3>
          <span
            >Membership Type: <strong>{{ contractorProfileSummary.membershipType }}</strong></span
          >
          <span>|</span>
          <span
            >Membership Expiry: <strong>{{ contractorProfileSummary.membershipExpiry | isDate }}</strong></span
          >
          <span>|</span>
          <span>
            Status:
            <strong
              [ngClass]="{
                'u-text-green': contractorProfileSummary.status,
                'u-text-red': !contractorProfileSummary.status
              }"
            >
              {{ contractorProfileSummary.status ? 'Active' : 'Inactive' }}
            </strong>
          </span>
          <span>|</span>
        </h3>
      </div>

      <div class="profile__header-sub-title">
        <h3>
          <span>
            Accreditation Level:
            <strong
              [ngClass]="{
                'u-text-orange': contractorProfileSummary.accreditationLevel === 'In Progress'
              }"
            >
              {{ contractorProfileSummary?.accreditationLevel }}
            </strong>
          </span>
          <span>|</span>
          <span
            >Accreditation Expiry: <strong>{{ contractorProfileSummary.accreditationExpiry | isDate }}</strong></span
          >
          <span>|</span>
          <span
            >No. Of Employees <strong> {{ contractorProfileSummary.effectiveEmployee }}</strong></span
          >
        </h3>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="profile__tabs">
      <div *ngFor="let tab of tabs; let i = index" class="profile__tabs-item">
        <button
          [ngClass]="tab == currentTab ? 'profile__nav__selected-tab' : ''"
          mat-raised-button
          (click)="selectedTabIndex = i; currentTab = tab"
          color="primary"
        >
          {{ tab }}
        </button>
      </div>
    </div>

    <mat-tab-group [selectedIndex]="tabIndex">
      <mat-tab *ngFor="let tab of tabs" [label]="tab">
        <app-get-tab [type]="tab"> </app-get-tab>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>
