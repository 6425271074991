<div class="container">
    <div class="form-group row">
        <label for="areaDistance" class="form-label col-lg-8 pt-2">Distance from registered office</label>
        <div class="col-lg-3">
            <div class="input-group">
                <input type="number"
                       [disabled]="readonly"
                       id="areaDistance"
                       min="0"
                       step="10"
                       oninput="this.value=(parseInt( Math.abs(this.value))||0)"
                       class="form-control"
                       (input)="updateDistance($event)"
                       value="{{distance$ | async}}"
                       aria-describedby="distance-addon" />
                <div class="input-group-append">
                    <span class="input-group-text" id="distance-addon">km</span>
                </div>
            </div>
        </div>
    </div>

    <ng-container class="background">
        <div class="text-center" *ngIf="!isLoaded">
            <app-loading-spinner></app-loading-spinner>
        </div>
        <div class="form-group mb-0" >
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" >
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [disabled]="readonly">
                    <mat-checkbox [disabled]="readonly" class="checklist-leaf-node"
                                  [color]="theme"
                                  [checked]="checklistSelection.isSelected(node)"
                                  (change)="todoLeafItemSelectionToggle(node)">   {{node.item.name}}</mat-checkbox>

                </mat-tree-node>
                <mat-tree-node [disabled]="readonly" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                    <i matTreeNodeToggle class="fas pe-2 treearrow" [class]="getTreeIcon(node)"></i>
                    <mat-checkbox [disabled]="readonly" [checked]="descendantsAllSelected(node)"
                                  [color]="theme"
                                  [indeterminate]="descendantsPartiallySelected(node)"
                                  (change)="todoItemSelectionToggle(node)">{{node.item.name}}</mat-checkbox>
                </mat-tree-node>
            </mat-tree>

        </div>
    </ng-container>

    <div class="modal-footer" *ngIf="authenticatePermissions$() | async">
        <button mat-raised-button color="primary" *ngIf="readonly == true"
                (click)="readonly = false"
               >
            <i class="fas fa-edit"></i>
            Edit
        </button>
        <div class="panel__footer-editing" *ngIf="readonly == false">
            <button mat-raised-button color="primary" [disabled]="saving" (click)="readonly = true">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button
                    [disabled]="(!canSave || saving)"
                    mat-raised-button color="primary"
                    (click)="confirm()">
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </div>
</div>

  
  