/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum CustomerTypes {
  NUMBER_870520000 = 870520000,
  NUMBER_870520001 = 870520001,
  NUMBER_870520002 = 870520002,
  NUMBER_870520003 = 870520003,
  NUMBER_885570000 = 885570000,
  NUMBER_885570001 = 885570001,
  NUMBER_885570002 = 885570002,
}
