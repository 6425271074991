import { Injectable } from '@angular/core';
import { ApiRoutesClass, ProductivityInfo } from '../../../shared';
import { StatsDetails } from '../../../shared';
import { ProductivityAverageInfo } from '../../../shared';
import {
  ProductivityAverages,
  PickUpAverages,
  PersonalTurnaroundAverage,
  PersonalAssessmentsCount,
  CasOutstandingModules,
  LastMonthStatsDetails,
} from '../../../shared/models/productivity-averages';
import { ProductivityStatusChange } from '../../../shared';
import { AssessorInfo } from '../../../shared';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAuthenticationAbstract } from '../authentication/user-authentication-abstract';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductivityService {
  private config: IEnvironment;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: UserAuthenticationAbstract,
    private readonly api: ApiRoutesClass
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  public isAdmin(): boolean {
    const userId = this.authService.getLoggedInUserId().toLowerCase();
    const foundUserId = this.config.productivityAccess.find((u) => u.toLowerCase() === userId);
    return this.config.featureFlags.enableProductivity && foundUserId !== undefined;
  }

  public recordStatusChange(model: ProductivityInfo): Observable<any> {
    if (this.config.featureFlags.enableProductivity) {
      const url = this.api.Productivity();
      return this.http.post(url, model);
    }
  }

  public async recordStatusChangeAsync(model: ProductivityInfo): Promise<any> {
    return this.recordStatusChange(model).toPromise();
  }

  public getWaitingToBeAcceptedStats(): any {
    const url = `${this.api.Productivity()}/waiting-to-be-accepted-stats`;
    return this.http.get<StatsDetails[]>(url);
  }

  public getWaitingToBeAcceptedPerAssessorStats(assessorId: string): Observable<ProductivityInfo[]> {
    const url = `${this.api.Productivity()}/assessors/${assessorId}/waiting-to-be-accepted-per-assessor-stats`;
    return this.http.get<ProductivityInfo[]>(url);
  }

  public getAssignedNotCompleteStats(): any {
    const url = `${this.api.Productivity()}/assigned-not-yet-complete-over-number-of-days-stats`;
    return this.http.get<StatsDetails[]>(url);
  }

  public getAssignedNotCompletePerAssessorStats(assessorId: string): Observable<ProductivityInfo[]> {
    const url = `${this.api.Productivity()}/assessors/${assessorId}/assigned-not-yet-complete-over-number-of-days-stats`;
    return this.http.get<ProductivityInfo[]>(url);
  }

  public getCompletedLastMonthStats(): Observable<LastMonthStatsDetails> {
    const url = `${this.api.Productivity()}/completed-assessments-last-month-stats`;
    return this.http.get<LastMonthStatsDetails>(url);
  }

  public getCompletedCurrentMonthStats(): any {
    const url = `${this.api.Productivity()}/completed-assessments-current-month-stats`;
    return this.http.get<StatsDetails[]>(url);
  }

  public getRejectedAssessmentsStats(): any {
    const url = `${this.api.Productivity()}/rejected-assessments-stats`;
    return this.http.get<StatsDetails[]>(url);
  }

  public getRejectedAssessmentsPerAssessorStats(assessorId: string): Observable<ProductivityInfo[]> {
    const url = `${this.api.Productivity()}/assessors/${assessorId}/rejected-assessments-stats`;
    return this.http.get<ProductivityInfo[]>(url);
  }

  public getReturnedToContractorStats(): any {
    const url = `${this.api.Productivity()}/returned-assessments-over-4-times-stats`;
    return this.http.get<StatsDetails[]>(url);
  }

  public getDateSubmittedToDateAssignedStats(): any {
    const url = `${this.api.Productivity()}/date-submitted-to-date-assigned-stats`;
    return this.http.get<ProductivityStatusChange[]>(url);
  }

  public getAveragePickupTimeStats(): any {
    const url = `${this.api.Productivity()}/average-pick-up-time-stats`;
    return this.http.get<ProductivityAverageInfo[]>(url);
  }

  public getAverageTurnAroundTimeStats(): any {
    const url = `${this.api.Productivity()}/average-turn-around-time-stats`;
    return this.http.get<ProductivityAverageInfo[]>(url);
  }

  public getAveragesStats(): any {
    const url = `${this.api.Productivity()}/pick-up-turn-around-averages-stats`;
    return this.http.get<PickUpAverages[]>(url);
  }

  public getCompletedAssessmentsPerWeek(userId: string): any {
    const url = `${this.api.Productivity()}/completed-assessments-count-per-week-stats/${userId}`;
    return this.http.get<number>(url);
  }

  public getCompletedAssessmentsPerMonth(userId: string): any {
    const url = `${this.api.Productivity()}/completed-assessments-count-per-month-stats/${userId}`;
    return this.http.get<number>(url);
  }

  public getPersonalAverageTurnaroundStats(userId: string): Observable<any> {
    const url = `${this.api.Productivity()}/personal-average-turnaround-stats/${userId}`;
    return this.http.get<PersonalTurnaroundAverage>(url);
  }

  public getAveragePickUpTurnAroundTimesStats(userId: string): Observable<any> {
    const url = `${this.api.Productivity()}/personal-pick-up-turn-around-averages-stats/${userId}`;
    return this.http.get<PickUpAverages>(url);
  }

  public getPersonalAssessmentsCountStats(userId: string): Observable<any> {
    const url = `${this.api.Productivity()}/personal-completed-assessments-count-stats/${userId}`;
    return this.http.get<PersonalAssessmentsCount>(url);
  }

  public getCasOutstandingModulesStats(): Observable<any> {
    const url = `${this.api.Productivity()}/outstanding-cas-modules-stats`;
    return this.http.get<CasOutstandingModules[]>(url);
  }

  public getAverages(): any {
    const source = forkJoin({
      avgPickup: this.getAveragePickupTimeStats(),
      avgTurnAround: this.getAverageTurnAroundTimeStats(),
    }).pipe(
      map(({ avgPickup, avgTurnAround }: any) => {
        const items = new Map<string, ProductivityAverages>();
        for (const item of avgPickup) {
          items.set(item.userId, {
            userId: item.userId,
            userName: item.userName,
            average1: item.average,
          } as ProductivityAverages);
        }

        for (const item of avgTurnAround) {
          if (items.has(item.userId)) {
            const averagesItem = items.get(item.userId);
            averagesItem.average2 = item.average;
            items.set(item.userId, averagesItem);
          } else {
            items.set(item.userId, {
              userId: item.userId,
              userName: item.userName,
              average2: item.average,
            } as ProductivityAverages);
          }
        }
        return Array.from(items.values());
      })
    );
    return source;
  }

  public getAssessorTypes(): Map<number, string> {
    const assessorTypes = new Map<number, string>([
      [885570000, 'Internal'],
      [885570001, 'Freelance'],
      [885570002, 'Batch'],
    ]);

    return assessorTypes;
  }

  public getAssessors(): any {
    const assessorTypes = this.getAssessorTypes();
    const url = `${this.api.Contacts()}/contacts/assessors`;
    return this.http.get<any[]>(url).pipe(
      map((assessorsInfo) => {
        const assessors = new Array<AssessorInfo>();
        for (const assessorInfo of assessorsInfo) {
          const assessor = {
            id: assessorInfo.id,
            email: assessorInfo.email,
            firstName: assessorInfo.firstName,
            lastName: assessorInfo.lastName,
            assessorType: assessorTypes[assessorInfo.assessorType],
          } as AssessorInfo;
          assessors.push(assessor);
        }
        return assessors;
      })
    );
  }
}
