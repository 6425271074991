import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DateOption, DateOptions, Product, SearchArgs } from '../../../shared';
import { SearchService } from 'src/app/core/services/search/search.service';
import { firstValueFrom } from 'rxjs';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { Contractor } from '../../../shared/models/Contractor';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit, OnDestroy {
  @ViewChild('productfilterRef') productfilterRef: ElementRef;
  @ViewChild('assessorfilterRef') assessorfilterRef: ElementRef;
  @ViewChild('datefilterRef') datefilterRef: ElementRef;
  public selectedAssessorOption: string;
  public selectedProductOption: string;

  constructor(private readonly searchService: SearchService, private readonly assessmentService: AssessmentService) {
    this.parentViewEnabled = false;
  }

  @Output() private filterChanged: EventEmitter<SearchArgs> = new EventEmitter<SearchArgs>();
  @Input() parentViewEnabled: boolean;
  @Input() searchArgs: SearchArgs;

  products: Product[];
  dates: DateOption[];
  assessors: Contractor[];
  assessorsSubscription;
  private fastTrackFeatureFlagName: string = 'OperationsPortal__EnableFastTrackFiltering';
  public showFastTrackFilter: boolean = false;
  public filterByFastTrack: boolean = false;
  public parentViewColumnSize = 4;
  public childViewColumnSize = 6;

  public selectedDateOption: string;
  async ngOnInit(): Promise<void> {
    this.selectedDateOption = this.searchArgs?.dateOption ?? 'Filter by date';
    this.selectedAssessorOption = this.searchArgs?.assessorId ?? 'Filter by assessor';
    this.selectedProductOption = this.searchArgs?.productOption ?? 'Filter by product';

    this.dates = DateOptions.options;
    this.assessors = await firstValueFrom(this.assessmentService.GetAllAssessors$());
    this.products = await firstValueFrom(this.searchService.getProducts());
  }

  productChanged(value: any): void {
    this.copySearchArgs();
    if (value === '0') {
      this.searchArgs.productOption = undefined;
    } else {
      this.searchArgs.productOption = value;
    }
    this.filterChanged.emit(this.searchArgs);
  }

  fastTrackedChanged(value: boolean): void {
    this.copySearchArgs();

    this.filterByFastTrack = value;
    this.searchArgs.fastTrack = this.filterByFastTrack;

    this.filterChanged.emit(this.searchArgs);
  }

  assessorChanged(value: any): void {
    this.copySearchArgs();
    if (value === '0') {
      this.searchArgs.assessorId = undefined;
    } else {
      this.searchArgs.assessorId = value;
    }
    this.filterChanged.emit(this.searchArgs);
  }

  dateChanged(value: any): void {
    this.copySearchArgs();
    if (value === '0') {
      this.searchArgs.dateOption = undefined;
    } else {
      this.searchArgs.dateOption = value;
    }

    this.filterChanged.emit(this.searchArgs);
  }

  copySearchArgs(): void {
    const oldsearchArgs = this.searchArgs;
    this.resetSearchArgs();
    this.searchArgs = {
      contractorName: oldsearchArgs?.contractorName,
      productOption: oldsearchArgs?.productOption,
      fastTrack: oldsearchArgs?.fastTrack,
      assessorId: oldsearchArgs?.assessorId,
      dateOption: oldsearchArgs?.dateOption,
      assessmentType: oldsearchArgs?.assessmentType,
      assessmentState: oldsearchArgs?.assessmentState,
    };
  }

  resetSearchArgs(): void {
    this.filterByFastTrack = undefined;

    this.searchArgs = {
      contractorName: undefined,
      productOption: undefined,
      assessorId: undefined,
      dateOption: undefined,
      assessmentType: undefined,
      assessmentState: undefined,
      fastTrack: this.filterByFastTrack
    };
  }

  resetFilterSelection(): void {
    const defaultValue = '0';
    if (this.productfilterRef) {
      this.productfilterRef.nativeElement.value = defaultValue;
    }

    if (this.assessorfilterRef) {
      this.assessorfilterRef.nativeElement.value = defaultValue;
    }

    if (this.datefilterRef) {
      this.datefilterRef.nativeElement.value = defaultValue;
    }
  }

  ngOnDestroy(): void {}
}
