import { createAction, props } from '@ngrx/store';
import { FeatureFlag } from '../../services/feature-flags/feature-flag';

export enum FeatureFlagActions {
  Load = '[FeatureFlags] Load',
  LoadSuccess = '[FeatureFlags] Load Successful',
  LoadError = '[FeatureFlags] Load Error',
}

export const FeatureFlagsLoad = createAction(FeatureFlagActions.Load);

export const FeatureFlagsLoadSuccess = createAction(
  FeatureFlagActions.LoadSuccess,
  props<{
    data: FeatureFlag[];
  }>()
);
export const FeatureFlagsLoadError = createAction(FeatureFlagActions.LoadError, props<{ error: Error }>());
