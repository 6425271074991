import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  CompanyInformationLoad,
  CompanyInformationLoadError,
  CompanyInformationLoadSuccess,
  CompanyInformationSave,
  CompanyInformationSaveError,
  CompanyInformationSaveSuccess,
} from '../../actions/companyInformation.actions';
import { AccountHttpService } from '../../../services/account/account-http.service';

@Injectable()
export class CompanyInformationEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyInformationLoad),
      mergeMap((action) =>
        this.accountService.getAccountInformation(action.accountId).pipe(
          map((b) => CompanyInformationLoadSuccess({ companyInformation: b })),
          catchError((error) => of(CompanyInformationLoadError({ error })))
        )
      )
    )
  );
  SaveCompanyInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyInformationSave),
      mergeMap((action) =>
        this.accountService.SaveCompanyInformation(action.accountId, action.companyInformation).pipe(
          map((b) => CompanyInformationSaveSuccess()),
          catchError((error) => of(CompanyInformationSaveError({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private accountService: AccountHttpService) {}
}
