import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ImsAndinsurance } from 'src/app/shared/models/ImsAndinsurance';

import { ImsAndinsuranceActions } from 'src/app/core/ngrx/actions/imsAndinsurance.actions';
import { getImsAndinsuranceLoadingSelector, getImsAndinsuranceSelector } from 'src/app/core/ngrx/selectors/ImsAndinsurance.selectors';
import { Insurance } from 'src/app/shared/models/Insurance';

@Component({
  selector: 'app-ims-and-insurance',
  templateUrl: './ims-and-insurance.component.html',
  styleUrls: ['./ims-and-insurance.component.scss'],
})
export class ImsAndinsuranceComponent implements OnInit, OnDestroy {
  ImsAndinsuranceSub: Subscription;
  isLoading$ = this.store.select(getImsAndinsuranceLoadingSelector);
  detailsForm = this.initializeForm();

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.ImsAndinsuranceSub = this.store.select(getImsAndinsuranceSelector).subscribe((t) => {
      this.detailsForm = this.initializeForm(t);
    });
    this.store.dispatch({
      type: ImsAndinsuranceActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.ImsAndinsuranceSub) {
      this.ImsAndinsuranceSub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  initializeForm(account: ImsAndinsurance = {} as ImsAndinsurance): UntypedFormGroup {
    return this.fb.group({
      employersLiability: this.createInsuranceForm(account.employersLiability),
      publicLiabilityInsurance: this.createInsuranceForm(account.publicLiabilityInsurance),
      professionalIndemnity: this.createInsuranceForm(account.professionalIndemnity),
      productLiabilityInsurance: this.createInsuranceForm(account.productLiabilityInsurance),
    });
  }

  private createInsuranceForm(data: Insurance = {} as Insurance): UntypedFormGroup {
    return this.fb.group({
      active: [data.active || false],
      insurer: [data.insurer || ''],
      policyNumber: [data.policyNumber || ''],
      excess: [data.excess || ''],
      limitforaSingleEvent: [data.limitforaSingleEvent || ''],
      expiryDate: [data.expiryDate || ''],
      document: [data.document || ''],
      employersLiabilityVerificationStatus: [data.employersLiabilityVerificationStatus || ''],
      employersLiabilityRejectionReason: [data.employersLiabilityRejectionReason || ''],
    });
  }
}
