import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../State';
import {
  BundleTypeList,
  BundleTypeListSuccess,
  BundleTypeListError,
  BundleTypeLoad,
  BundleTypeLoadSuccess,
  BundleTypeLoadError,
  BundleTypeSave,
  BundleTypeSaveSuccess,
  BundleTypeSaveError,
} from '../actions/bundle-type.actions';
import { BundleType } from '../../open-api/model/bundle-type.model';

export interface State extends AppState.State {
  bundleType: BundleTypeState;
}

export interface BundleTypeState {
  bundleTypes: BundleType[];
  bundleType: BundleType;
  loading: boolean;
  error: string;
}

const initialState: BundleTypeState = {
  bundleTypes: undefined,
  bundleType: undefined,
  loading: true,
  error: '',
};

const _bundleTypeReducer = createReducer(
  initialState,

  // List
  on(BundleTypeList, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(BundleTypeListSuccess, (state, action) => ({
    ...state,
    loading: false,
    bundleTypes: action.bundleTypes,
  })),
  on(BundleTypeListError, (state, action) => ({
    ...state,
    error: action.error,
  })),

  // Load
  on(BundleTypeLoad, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(BundleTypeLoadSuccess, (state, action) => ({
    ...state,
    loading: false,
    bundleType: action.bundleType,
  })),
  on(BundleTypeLoadError, (state, action) => ({
    ...state,
    error: action.error,
  })),

  // Save
  on(BundleTypeSave, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(BundleTypeSaveSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(BundleTypeSaveError, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }))
);

export function BundleTypeReducer(state, action): BundleTypeState {
  return _bundleTypeReducer(state, action);
}
