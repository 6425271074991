import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientModuleHttpService} from "../../../core/services/Client-Module/client-module-http.service";
import {ActivatedRoute} from "@angular/router";
import {QuestionSet} from "../../../core/open-api";
import {firstValueFrom} from "rxjs";
import {ClientModuleItem} from "../../../shared/models/client-module-item";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UploadQuestionSetModalComponent} from "../upload-question-set-modal/upload-question-set-modal.component";

@Component({
  selector: 'app-client-question-set-list',
  templateUrl: './client-question-set-list.component.html',
  styleUrls: ['./client-question-set-list.component.scss']
})
export class ClientQuestionSetListComponent implements OnInit, OnDestroy {

  private id = this.route.snapshot.paramMap.get("id");

  public dataSource: QuestionSet[];

  public isLoading = true;

  public displayedColumns: string[];
  public module: ClientModuleItem;
  private dialogRef: MatDialogRef<UploadQuestionSetModalComponent, any>;

  constructor(private readonly clientModuleService: ClientModuleHttpService,
              private readonly dialog: MatDialog,
              private readonly route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.module = await firstValueFrom(this.clientModuleService.getModule$(this.id));
    this.dataSource = await firstValueFrom(this.clientModuleService.getQuestionSets$(this.id));
    this.displayedColumns = ['name', 'questionSetNumber', 'version', 'validFrom', 'expires', 'createdUtc']
    this.isLoading = false;
  }

  async ngOnDestroy(): Promise<void> {
    this.dialogRef.componentInstance.fileUploaded.unsubscribe();
  }

  public async openUploadDialog() : Promise<void> {
    this.dialogRef = this.dialog.open(UploadQuestionSetModalComponent, {
      width: '500px'
    });

    this.dialogRef.componentInstance.moduleId = this.id;
    this.dialogRef.componentInstance.fileUploaded.subscribe(result => {
      if(result)
        this.reload();
    });
  }

  public async reload() : Promise<void> {
    this.isLoading = true;
    this.dataSource = this.dataSource = await firstValueFrom(this.clientModuleService.getQuestionSets$(this.id));
    this.isLoading = false;
  }
}
