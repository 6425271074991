import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { ExpiryFilter } from '../../enums/expiry-filter';
import { ProgressCard } from '../progress-card';
import { ProgressCardData } from './progress-card-data';

export class SubmittedForQACard extends ProgressCard {
  constructor(progressCardData: ProgressCardData) {
    super();
    this.header = 'Submitted for QA';
    this.icon = 'fa-plus';
    this.stats = [
      {
        value: progressCardData.count,
        label: 'Total',
        cssClass: 'progress-blank',
        apiRequestType: ApiRequestTypes.AssessorDashboardSubmittedForQA,
        expiryFilter: ExpiryFilter.none,
      },
    ];
  }
}
