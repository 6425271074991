import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TradesState } from "../reducers/tradesTrable.reducer";

export const selectTradesFeatureState =
  createFeatureSelector<TradesState>("Trade");

export const getTradesSelector = createSelector(
  selectTradesFeatureState,
  (state) => state.trade
);

export const getTradesLoadingSelector = createSelector(
  selectTradesFeatureState,
  (state) => state.loading
);
