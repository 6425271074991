<form *ngIf="detailsForm && !isLoading; else loading" [formGroup]="detailsForm" novalidate>
    <mat-card class="d-flex flex-column col-sm-12 col-md-12 col-xl-6 justify-content-center">
      <mat-card-title *ngIf="accountDisabled" style="color: red"><i class='fas fa-lock'></i> Account Disabled</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div>
          <div class="d-flex flex-row flex-wrap ">
            <mat-form-field class="w-100 mr-1">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="bundleName" readonly />
            </mat-form-field>
            <mat-form-field class="w-100 mr-1 ">
              <mat-label>Default Assessor Company</mat-label>
              <mat-select formControlName="defaultAssessorOrganisationId">
                <mat-option *ngFor="let account of assessorCompanies" [value]="account.accountId">
                  {{ account.accountName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-card-actions align="start">
          <div>
            <button [disabled]="isLoading || detailsForm.invalid" color="primary" mat-raised-button type="button" (click)="onSave()" >
              <mat-icon>play_arrow</mat-icon>Save
            </button>
          </div>
        </mat-card-actions>
        <div *ngIf='isLoading'> 
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
      </mat-card-content>
    </mat-card>
</form>
<ng-template #loading>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
