import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-fail-to-resubmit-modal",
  templateUrl: "./fail-to-resubmit-modal.component.html",
  styleUrls: ["./fail-to-resubmit-modal.component.scss"],
})
export class FailToResubmitModalComponent implements OnInit {
  public modalComment = "";

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  public modalConfirmFailToResubmit(): void {
    this.bsModalRef.content = this.modalComment;
    this.bsModalRef.hide();
  }
}
