import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containsHtml'
})
export class ContainsHtmlPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value == undefined) return;

    if(value.includes('/>'))
      return true;

    return false;
  }
}
