import { createAction, props } from "@ngrx/store";
import { Assessment } from "src/app/shared/models/assessment";

export enum ProductAssessmentsTableActions {
  Load = "[Product Assessments Table] Load",
  LoadSuccess = "[Product Assessments Table] Load Successful",
  LoadError = "[Product Assessments Table] Load Error",
}

export const ProductAssessmentsTableLoad = createAction(
  ProductAssessmentsTableActions.Load,
  props<{ accountId: string }>()
);
export const ProductAssessmentsTableLoadSuccess = createAction(
  ProductAssessmentsTableActions.LoadSuccess,
  props<{ assessments: Assessment[] }>()
);
export const ProductAssessmentsTableLoadError = createAction(
  ProductAssessmentsTableActions.LoadError,
  props<{ error: string }>()
);
