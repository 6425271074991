import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import {
  ContractorAccountService,
  ContractorAccountServiceEnum,
} from '../../../../core/services/company-account/contractor-account.service';
import { PanelAbstract } from '../panel-abstract/panel-abstract.component';

@Component({
  selector: 'app-membership-summary-panel',
  templateUrl: './membership-summary-panel.component.html',
  styleUrls: ['../panel-abstract/panel-abstract.component.scss', './membership-summary-panel.component.scss'],
})
export class MembershipSummaryPanelComponent extends PanelAbstract implements OnInit {
  @Input() data;
  @Input() multiple = false;

  protected permissionsRequest: PermissionsEnum = PermissionsEnum.AddressSummaryEditButton;
  protected displayActionButtons = true;
  protected panelType = ContractorAccountServiceEnum.MembershipSummary;

  public readonly membershipStatusMap = new Map([
    ['885570000', 'Active'],
    ['885570001', 'DueForRenewal'],
    ['885570002', 'Expired'],
    ['885570003', 'Unregistered'],
    ['885570004', 'DueForRenewalSqc'],
  ]);

  constructor(
    protected fb: FormBuilder,
    protected readonly snackBar: MatSnackBar,
    protected readonly store: Store,
    protected readonly route: ActivatedRoute,
    protected actions: ActionsSubject,
    protected readonly permissionsService: PermissionsService,
    protected readonly contractorAccountService: ContractorAccountService
  ) {
    super(fb, snackBar, store, route, actions, permissionsService, contractorAccountService);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.overrideFormValue();
  }

  private overrideFormValue(): void {
    let status = this.membershipStatusMap.get(this.formGroup.get('statusId').value);
    this.formGroup.get('statusId').patchValue(status);
    status = this.membershipStatusMap.get(this.formGroup.get('accreditationLevelId').value);
    this.formGroup.get('accreditationLevelId').patchValue(status);
  }
}
