<div class="btn-group" dropdown>
  <button
    id="account-selection"
    dropdownToggle
    type="button"
    class="btn btn-Link dropdown-toggle"
    aria-controls="select-account"
  >
    {{ getSelectedAccount().name }} <span class="caret"></span>
  </button>
  <ul
    id="select-account"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    aria-labelledby="account-selection"
  >
    <li role="menuitem" *ngFor="let account of getAccounts()">
      <a class="dropdown-item" (click)="selectAccount(account)">{{
        account.name
      }}</a>
    </li>
  </ul>
</div>
