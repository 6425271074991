import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QuestionSetTableState } from "../reducers/QuestionSetTable.reducer";

export const selectQuestionSetTableFeatureState =
  createFeatureSelector<QuestionSetTableState>("questionSetTable");

export const getQuestionSetTableSelector = createSelector(
  selectQuestionSetTableFeatureState,
  (state) => state.modules
);

export const getQuestionSetTableLoadingSelector = createSelector(
  selectQuestionSetTableFeatureState,
  (state) => state.loading
);
