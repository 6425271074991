import { createAction, props } from "@ngrx/store";
import { ImsAndinsurance } from "src/app/shared/models/ImsAndinsurance";

export enum ImsAndinsuranceActions {
  Load = "[Ims And Ansurance] Load",
  LoadSuccess = "[Ims And Ansurance] Load Successful",
  LoadError = "[Ims And Ansurance] Load Error",
}

export const ImsAndinsuranceLoad = createAction(
  ImsAndinsuranceActions.Load,
  props<{ accountId: string }>()
);
export const ImsAndinsuranceLoadSuccess = createAction(
  ImsAndinsuranceActions.LoadSuccess,
  props<{ ImsAndinsurance: ImsAndinsurance }>()
);
export const ImsAndinsuranceLoadError = createAction(
  ImsAndinsuranceActions.LoadError,
  props<{ error: string }>()
);
