import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AssessorAssignmentDialog,
  DialogData,
} from '../../../assessor-dashboard/assessor-assignment-modal/assessor-assignment-modal.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { angularEditorConfig } from '../../../../shared/components/phone-call-accordian/phone-call-accordion.component';
import { IsDatePipe } from '../../../../shared/pipe/is-date.pipe';
import { DateTime } from 'luxon';
import { PhoneCallDirectionMap } from '../../../../shared/maps/phone-call-direction.map';
import { TimelineTypesEnum } from '../../../../shared/enums/timeline-enum';

@Component({
  selector: 'app-company-timeline-modal',
  templateUrl: './company-timeline-modal.component.html',
  styleUrls: ['./company-timeline-modal.component.scss'],
})
export class CompanyTimelineModalComponent implements OnInit {
  private dateType: typeof DateTime = DateTime;
  public timelineForm: FormGroup;
  public editorConfig: AngularEditorConfig = {
    ...angularEditorConfig,
    editable: false,
    enableToolbar: false,
    showToolbar: false,
    placeholder: '',
    sanitize: true,
    width: 'auto',
    maxHeight: undefined,
  };
  public isHtml = false;
  public timelineTypes: typeof TimelineTypesEnum = TimelineTypesEnum;

  constructor(
    public dialogRef: MatDialogRef<AssessorAssignmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly formBuilder: FormBuilder,
    private readonly isDatePipe: IsDatePipe
  ) {}

  ngOnInit(): void {
    this.hydrateForm();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  private hydrateForm(): void {
    this.timelineForm = this.formBuilder.group({});

    if (this.data)
      for (const [key, value] of Object.entries(this.data.selection)) {
        this.timelineForm?.addControl(key, new FormControl(this.isDatePipe.transform(value, this.dateType.DATETIME_FULL) || ''));
      }

    this.timelineForm.value.data.direction = PhoneCallDirectionMap.get(this.timelineForm.value.data.direction);
    if ((this.timelineForm.value.description as string).includes('<!DOCTYPE html') || '<?xml') this.isHtml = true;
  }
}
