/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AssessorAssessment {
  productFamily?: string | null;
  assessorOrganizationId?: string | null;
  accountID?: string | null;
  assessorID?: string | null;
  assessorName?: string | null;
  assessmentID?: string | null;
  assessmentNumber?: string | null;
  fullAssessmentName?: string | null;
  productName?: string | null;
  status?: string | null;
  percentageComplete?: number;
  paymentAmount?: number;
  numberOfEmployees?: number;
  assessmentType?: number;
  variant?: number;
  assessmentTypeLabel?: string | null;
  variantLabel?: string | null;
  productId?: string | null;
  decisionDate?: string | null;
  targetDecisionDate?: string | null;
  expiryDate?: string | null;
  dateEntered?: string | null;
  dateAccepted?: string | null;
  dateStarted?: string | null;
  dateSubmitted?: string | null;
  dateResubmitted?: string | null;
  dateAssigned?: string | null;
  dateAssessed?: string | null;
  dateReturnedToContractor?: string | null;
  dateReturnedToAssessor?: string | null;
  dateRequiredToRespond?: string | null;
  initialDateRequiredToRespond?: string | null;
  dateCertificateIssued?: string | null;
  orderType?: string | null;
  contractorName?: string | null;
  questionSetIds?: Array<string> | null;
  validForRenewal?: boolean;
  clonedHistoricalAnswers?: boolean;
  userApprovedHistoricalAnswers?: boolean;
  hasAlerts?: boolean;
  fastTrack?: boolean;
  incompleteSections?: number | null;
  statusId?: number;
}
