/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IdViewModel {
  uid?: string | null;
  companyHouseNo?: string | null;
  membershipId?: any | null;
  name?: string | null;
  abn?: string | null;
  acn?: string | null;
  membershipType?: string | null;
}
