<div>
  <button mat-raised-button (click)="goBack()" color="primary">Back</button>
</div>
<br />
<form *ngIf="detailsForm && !isLoading; else loading" [formGroup]="detailsForm" novalidate>
  <mat-card class="d-flex flex-column col-sm-12 col-md-12 col-xl-6 justify-content-center">
    <mat-card-title *ngIf="accountDisabled" style="color: red"><i class="fas fa-lock"></i> Account Disabled</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div>
        <div class="d-flex flex-row flex-wrap">
          <mat-form-field class="w-100 mr-1">
            <mat-label>Account</mat-label>
            <mat-select formControlName="accountId">
              <mat-option *ngFor="let account of accounts" [value]="account.accountId">
                {{ account.accountName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100 mr-1">
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName" />
          </mat-form-field>
          <mat-form-field class="w-100 mr-1">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" />
          </mat-form-field>
          <mat-form-field class="w-100 mr-1">
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="email" />
          </mat-form-field>
          <mat-form-field class="w-100 mr-1">
            <mat-label>Assessor Type</mat-label>
            <mat-select formControlName="assessorType">
              <mat-option *ngFor="let assessorType of assessorTypes" [value]="assessorType[0]">
                {{ assessorType[1] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100 mr-1">
            <mat-label>User Group</mat-label>
            <mat-select formControlName="userGroupId">
              <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">
                {{ userGroup.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100 mr-1" *ngIf="showQaSettings">
            <mat-label>Automated QA Rule</mat-label>
            <mat-select formControlName="automatedQARule" (selectionChange)="automatedQARuleSelectionChange($event)">
              <mat-option *ngFor="let rule of automatedQARules" [value]="rule.id">
                {{ rule.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100 mr-1" *ngIf="showQaSettings">
            <mat-label>QA Rule Percentage</mat-label>
            <input matInput type="percentage" formControlName="qaRulePercentage" />
          </mat-form-field>
        </div>
      </div>
      <mat-card-actions align="start">
        <div>
          <button
            [disabled]="isLoading || accountDisabled || detailsForm.invalid"
            color="primary"
            mat-raised-button
            type="button"
            (click)="onSave()"
          >
            <mat-icon>play_arrow</mat-icon>Save
          </button>
        </div>
        <div *ngIf="accountId && authenticatePermissions$() | async">
          <div *ngIf="accountDisabled; else accountNotLocked">
            <button color="primary" mat-raised-button type="button" class="ml-4" (click)="manageAccountLockState(accountStateEnum.Enable)">
              <i class="fas-lock-open"></i> Enable Account
            </button>
          </div>
          <ng-template #accountNotLocked>
            <button color="warn" mat-raised-button type="button" class="ml-4" (click)="manageAccountLockState(accountStateEnum.Disable)">
              <i class="fas fa-lock"></i> Disable Account
            </button>
          </ng-template>
        </div>
      </mat-card-actions>

      <div *ngIf="isLoading">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>
    </mat-card-content>
  </mat-card>
</form>
<ng-template #loading>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
