import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  TradesDelete,
  TradesDeleteError,
  TradesDeleteSuccess,
  TradesLoad,
  TradesLoadError,
  TradesLoadSuccess,
} from '../actions/trades.actions';
import { AccountHttpService } from '../../services/account/account-http.service';
import { TradeService } from '../../services/trades/trade.service';

@Injectable()
export class TradeEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TradesLoad),
      mergeMap((action) =>
        this.accountService.getMatchingCategories$(action.accountId).pipe(
          map((b) => {
            const y = b.map((el) => {
              return {
                ...el,
                type: 'Trade',
              };
            });
            return TradesLoadSuccess({ trade: y });
          }),
          catchError((error) => of(TradesLoadError({ error })))
        )
      )
    )
  );

  deleteTrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TradesDelete),
      mergeMap((action) =>
        this.tradeService.deleteTrade$(action.trade).pipe(
          map((b) =>
            TradesDeleteSuccess({
              trade: b,
            })
          ),
          catchError((error) => of(TradesDeleteError({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private accountService: AccountHttpService, private readonly tradeService: TradeService) {}
}
