import { createReducer, on } from "@ngrx/store";
import * as AppState from "../../../State";
import { AssessorInfo } from "src/app/shared";
import {
  AssessorList,
  AssessorListError,
  AssessorListSuccess,
  AssessorLoad,
  AssessorLoadError,
  AssessorLoadSuccess,
  AssessorSave,
  AssessorSaveError,
  AssessorSaveSuccess,
} from "../actions/assessor.actions";
import { AssessorSummary } from "../effects/assessor.effects";

export interface State extends AppState.State {
  assessor: AssessorState;
}

export interface AssessorState {
  assessors: AssessorSummary[];
  assessor: AssessorInfo;
  loading: boolean;
  error: string;
}

const initialState: AssessorState = {
  assessors: undefined,
  assessor: undefined,
  loading: true,
  error: "",
};

const _assessorReducer = createReducer(
  initialState,

  // List
  on(AssessorList, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(AssessorListSuccess, (state, action) => ({
    ...state,
    loading: false,
    assessors: action.assessors,
  })),
  on(AssessorListError, (state, action) => ({
    ...state,
    error: action.error,
  })),

  // Load
  on(AssessorLoad, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(AssessorLoadSuccess, (state, action) => ({
    ...state,
    loading: false,
    assessor: action.assessor,
  })),
  on(AssessorLoadError, (state, action) => ({
    ...state,
    error: action.error,
  })),

  // Save
  on(AssessorSave, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(AssessorSaveSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(AssessorSaveError, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }))
);

export function AssessorReducer(state, action) {
  return _assessorReducer(state, action);
}
