/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface QuoteRequest {
  accountId?: string | null;
  contactId?: string | null;
  opportunityId?: string | null;
  applicationType?: string | null;
  sectorType?: string | null;
  numberofDirectEmployees?: number;
  labourOnlySubContractors?: number;
  bonafideSubContractors?: number;
  createOpportunity?: boolean;
  customerType?: string | null;
  fastTrack?: boolean;
  firFee?: boolean;
  joiningFee?: boolean;
  upgrade?: boolean;
  dataSharingAllowed?: boolean;
  discountCode?: string | null;
  selectedBundle?: string | null;
  cancelUrl?: string | null;
  successUrl?: string | null;
  bundleNames?: Array<string> | null;
  checkoutSessionId?: string | null;
  country?: string | null;
  countryCode?: string | null;
  discountAmount?: number;
  netAmount?: number;
  vatExempt?: boolean | null;
  vatNumber?: string | null;
  readonly numberOfEffectiveEmployees?: number;
}
