import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { UIFilter } from '../../../shared/models/ui-filter';
import { ApiRequestTypes } from '../../ngrx/actions/MultigridAssessmentViewType.actions';
import { AssessmentService } from '../assessment/assessment.service';
import { ApiRoutesClass, AssessmentSummary } from '../../../shared';
import { Redirector } from 'src/app/shared/utililties/redirector';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsApiRequestTypes } from '../alerts/alerts.service';
import { ViewerType } from '../../models/viewer-type';
import { AccountAssessmentView, AccountAssessmentViewWithProgressCardData } from '../../open-api';

@Injectable({
  providedIn: 'root',
})
export class BundlesService {
  private readonly assessmentStatusOrder = [
    'Submitted',
    'Submitted - Awaiting Others',
    'Resubmitted',
    'Additional Information Required',
    'Assessment Accepted',
    'Assessment Rejected',
    'In Progress',
    'Complete',
    'Complete - Awaiting Others',
    'Failed',
  ];

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly assessmentService: AssessmentService,
    private readonly api: ApiRoutesClass
  ) {}

  /**
   * @param props object = the type of bundle requested i.e verification or assessment(elite)
   * @returns bundle request object following bundle data model
   */
  public retrieveBundles$(viewType: ApiRequestTypes | AlertsApiRequestTypes, props: UIFilter[]): Observable<any> {
    let params = null;
    if (props) {
      params = new HttpParams({
        fromString: `filter=${JSON.stringify(props)}`,
      });
    }
    return this._httpClient
      .get<AccountAssessmentViewWithProgressCardData>(`${this.api.Assessments()}/assessmentsByViewType?view=${viewType}`, {
        responseType: 'json',
        params: params ? params : null,
      })
      .pipe(
        map((bundle) => {
          return bundle.assessments.map((x: AccountAssessmentView) => {
            return { ...x, type: 'Bundle', status: x.status.label };
          });
        })
      );
  }

  public assignBundleAssessor$(bundleIds: string[], assessorId: string, reassign: boolean): Observable<any> {
    return this._httpClient.put(`${this.api.Assessors()}/bundles/${assessorId}/updateassignedassessor`, {
      reassign: reassign,
      bundleIds: bundleIds,
    });
  }

  public retrieveBundle$(id: string): Observable<Partial<AccountAssessmentView[]>> {
    try {
      return this._httpClient.get<AccountAssessmentView[]>(`${this.api.Assessments()}/bundles/${id}/assessments`, {
        params: { viewerType: ViewerType.Assessor },
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  public retrieveBundleSortedByStatus$(id: string): Observable<any> {
    return this.retrieveBundle$(id).pipe(
      map((assessments) =>
        assessments.sort((a, b) => this.assessmentStatusOrder.indexOf(a.status.label) - this.assessmentStatusOrder.indexOf(b.status.label))
      )
    );
  }

  public retrieveBundleAsAssessmentSummaries$(id: string): Observable<AssessmentSummary[]> {
    return this.retrieveBundleSortedByStatus$(id).pipe(map((a) => this.assessmentService.accountAssessmentToAssessmentSummary(a)));
  }

  public navigateToNextUrl(route: ActivatedRoute, router: Router, fallbackUrl: string): void {
    const returnUrl = Redirector.getStoredUrl(route, router, 'r', true);
    const nextUrl = Redirector.getStoredUrl(route, router, 'next', true);

    if (nextUrl?.queryParams?.next) {
      const assessmentId = nextUrl.queryParams.next.split('/').pop();
      const bundleId = nextUrl.queryParams.bundleId;
      this.retrieveBundleAsAssessmentSummaries$(bundleId).subscribe((assessments) => {
        const queryParams = this.assessmentService.getRouterLinkQueryParams(bundleId, assessments, assessmentId, returnUrl.pathname);
        queryParams.r = returnUrl.pathnameAndFragment;
        void router.navigate([nextUrl.pathname], { queryParams: queryParams });
      });
    } else {
      void Redirector.navigateToReturnUrl(route, router, fallbackUrl);
    }
  }
}
