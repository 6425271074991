import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AccreditationSummaryActions } from 'src/app/core/ngrx/actions/AccreditationSummary.actions';
import { AccreditationSummary } from 'src/app/shared/models/AccreditationSummary';
import {
  getAccreditationSummaryLoadingSelector,
  getAccreditationSummarySelector,
} from 'src/app/core/ngrx/selectors/AccreditationSummary.selectors';
import { PermissionsEnum } from '../../../shared/enums/permissions-enum';
import { Observable } from 'rxjs/internal/Observable';
import { PermissionsMap } from '../../../shared/maps/permissions-map';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';

@Component({
  selector: 'app-accreditation-summary',
  templateUrl: './accreditation-summary.component.html',
  styleUrls: ['./accreditation-summary.component.scss'],
})
export class AccreditationSummaryComponent implements OnInit, OnDestroy {
  accreditationSummarySub: Subscription;
  isLoading$ = this.store.select(getAccreditationSummaryLoadingSelector);
  detailsForm = this.initializeForm();
  readOnly: boolean = true;
  id: string;
  accreditationLevelName: string;
  public permissionsEnum: typeof PermissionsEnum = PermissionsEnum;

  options: any[] = [
    {
      value: '2D9106AA-C11F-ED11-B83D-0022481B64FB',
      name: 'Verified Contractor',
    },
    {
      value: '7836F763-C21F-ED11-B83D-0022481B64FB',
      name: 'Elite with 1 Client Connection',
    },
    {
      value: '7829EA87-C21F-ED11-B83D-0022481B64FB',
      name: 'Verified Supplier',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private store: Store,
    private readonly permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.accreditationSummarySub = this.store.select(getAccreditationSummarySelector).subscribe((t) => {
      if (!t) return;
      this.detailsForm = this.initializeForm(t);

      if (t.accreditationLevel) this.accreditationLevelName = this.getAccreditationLevelName(t.accreditationLevel);
    });
    this.store.dispatch({
      type: AccreditationSummaryActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.accreditationSummarySub) {
      this.accreditationSummarySub.unsubscribe();
    }
  }

  initializeForm(account: AccreditationSummary = {} as AccreditationSummary): UntypedFormGroup {
    return this.fb.group({
      accreditationLevel: [account.accreditationLevel || null],
      accreditationExpiry: [account.accreditationExpiry || ''],
    });
  }

  onSave(): void {
    this.store.dispatch({
      type: AccreditationSummaryActions.save,
      accountId: this.id,
      accreditationSummary: this.detailsForm.value,
    });
    this.readOnly = true;
  }

  getAccreditationLevelName(id: string): string {
    var result = null;

    this.options.forEach((element) => {
      if (element.value.toLowerCase() === id) result = element.name;
    });

    return result;
  }

  public showHideTableView(permissionName): Observable<boolean> {
    const permissionId = PermissionsMap.get(permissionName);
    return this.permissionsService.authenticateUser$(permissionId);
  }
}
