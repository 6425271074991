import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccreditationSummaryState } from "../reducers/accreditationSummary.reducer";

export const selectAccreditationSummaryFeatureState =
  createFeatureSelector<AccreditationSummaryState>("accreditationSummary");

export const getAccreditationSummarySelector = createSelector(
  selectAccreditationSummaryFeatureState,
  (state) => state.accreditationSummary
);

export const getAccreditationSummaryLoadingSelector = createSelector(
  selectAccreditationSummaryFeatureState,
  (state) => state.loading
);
