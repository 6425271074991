import { AssessmentStatus } from 'src/app/shared/classes/assessment-status';
import { AssessmentService } from '../assessment.service';
import { AssessmentAction } from './assessment-action';
import { AssessmentSummary } from '../../../../shared';
import { AssessmentProgressStatus } from '../../../../shared/classes/assessment-progress-status';

export class FailAssessmentAction extends AssessmentAction {
  constructor(
    assessmentService: AssessmentService,
    private readonly assessmentId: string,
    private readonly answers: string,
    private readonly status: AssessmentStatus,
    private readonly failureReason: string,
    private readonly assessmentSummary: AssessmentSummary,
    private readonly progressStatus: string
  ) {
    super(assessmentService);
    this.checkValidState(status);
    this.checkValidProgressStatus(progressStatus);
  }

  async run(): Promise<any> {
    await this.assessmentService.updateAssessment(this.assessmentId, {
      answers: this.answers,
      status: this.status,
      comment: this.failureReason,
    });

    const oldStatus = this.assessmentSummary.Status;
    this.assessmentSummary.Status = this.progressStatus;
    this.assessmentService.update(this.assessmentSummary, oldStatus);
  }

  private checkValidState(status: AssessmentStatus): void {
    if (status !== AssessmentStatus.Failed && status !== AssessmentStatus.FailedToResubmit) {
      throw Error('You can only fail an assessment with failed or failed to resubmit');
    }
  }

  private checkValidProgressStatus(status: AssessmentProgressStatus): void {
    if (status !== AssessmentProgressStatus.Failed && status !== AssessmentProgressStatus.FailedResubmit) {
      throw Error('You can only fail an assessment with failed or failed to resubmit status');
    }
  }
}
