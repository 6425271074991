import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ClientModuleHttpService} from "../../../core/services/Client-Module/client-module-http.service";
import {firstValueFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-upload-question-set-modal',
  templateUrl: './upload-question-set-modal.component.html',
  styleUrls: ['./upload-question-set-modal.component.scss']
})
export class UploadQuestionSetModalComponent {
  public showLoadingBar = false;

  public moduleId: string;

  @Output() fileUploaded = new EventEmitter<boolean>();
  public error: any;

  constructor(private readonly clientModuleService: ClientModuleHttpService,
              private readonly dialog: MatDialog,
              private readonly route: ActivatedRoute) { }

  public async handleChange(files: File[]) : Promise<void> {
    this.showLoadingBar = true;

    try {
      await firstValueFrom(this.clientModuleService.uploadQuestionSet$(this.moduleId, files[0]));
      this.fileUploaded.emit(true);
      this.dialog.closeAll();
    } catch(err) {
      this.error = err.error;
    }

    this.showLoadingBar = false;
  }
}
