export enum TabTypes {
    Summary = 'Summary',
    CompanyDetails = 'Company Details',
    Contacts = 'Contacts',
    Products = 'Products',
    WorkCategories = 'Work Categories',
    AuditHistory = 'Account History',
    Trades = 'Trades',
    Insurance = 'Insurance',
    Clients = 'Clients',
    TrackingHistory = "Assessment History",
}