import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActionsSubject, Store } from '@ngrx/store';
import { storeDispatchAsync } from '../../../core/ngrx/store-dispatch-async';
import { BundleTypeActions } from 'src/app/core/ngrx/actions/bundle-type.actions';
import { getBundleTypesSelector } from 'src/app/core/ngrx/selectors/bundle-type.selectors';
import { BundleType } from 'src/app/core/open-api/model/bundle-type.model';

@Component({
  selector: 'app-bundle-types-table',
  templateUrl: './bundle-types-table.component.html',
  styleUrls: ['./bundle-types-table.component.scss'],
})
export class BundleTypesTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public loading = true;
  dataSource: MatTableDataSource<BundleType>;
  displayedColumns: string[] = ['bundleName', 'defaultAssessorOrganisation', 'Actions'];
  searchKey: string;

  public get readOnly(): boolean {
    return true;
  }

  constructor(private readonly router: Router, private readonly store: Store, private readonly actions: ActionsSubject) {}

  public async ngOnInit(): Promise<void> {
    await storeDispatchAsync(this.store, this.actions, BundleTypeActions.ListSuccess, {
      type: BundleTypeActions.List,
    });
    try {
      const bundleTypes = await firstValueFrom(this.store.select(getBundleTypesSelector));
      this.dataSource = new MatTableDataSource(bundleTypes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.loading = false;
    }
  }

  public async onEdit(id: string): Promise<void> {
    await this.router.navigate([`/assessment/editRouting/${id}`]);
  }

  public onSearchClear(): void {
    this.searchKey = '';
  }

  public applyFilter(): void {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
