<div class="company-details-content">
  <div class="panel">
    <div class="panel__content">
      <b>Primary Work Category:</b> {{ (primaryWorkCategory$ | async)[0]?.name }}
      <br />
      <b>Business Summary:</b> {{ businessDescription$ | async }}
    </div>
  </div>
  <app-work-categories></app-work-categories>
</div>
