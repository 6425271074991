import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountHttpService } from 'src/app/core/services/account/account-http.service';
import { ProfileSummary } from 'src/app/shared/models/profile-summary';

@Injectable({
  providedIn: 'root',
})
export class ContractorProfileSummaryService {
  constructor(private readonly httpClient: HttpClient, private readonly accountHttpService: AccountHttpService) {}

  public getData(): Observable<ProfileSummary> {
    const currentUrl = new URL(window.location.href);
    const guid = currentUrl.pathname.split('/').pop();
    return this.accountHttpService.getProfileSummary(guid);
  }
}
