<div *ngIf='!isLoading; else showSpinner' class='container'>
	<div class='row mb-4 mt-4'>
		<ng-container *ngFor='let card of progressCards'>
			<div class='col20pc'>
				<app-progress-card (progressCardSelected)='onProgressCardSelection($event)' [progressCard]='card'>
				</app-progress-card>
			</div>
		</ng-container>
	</div>
	<div class='row mb-4'>
		<app-search-filter #searchFilterComponent (filterChanged)='runSearch($event)' [parentViewEnabled]='true' [searchArgs]="searchArgs">
		</app-search-filter>
	</div>
	<div class='table-header d-flex justify-content-between'>
		<app-data-table-button-bar (assignAssessorClick)='openAssessorChangeDialog()'
															 (assignAssessorCompanyClick)='openAssessorCompanyChangeDialog()'
															 [assignAssessor]='showAssignAssessorButton'
															 [enabledButtons]='enabledButtons'
															 assignAssessorCompany='true'>
		</app-data-table-button-bar>
		<mat-form-field>
			<input (keyup)='quickSearch($event.target.value)' matInput placeholder='Quick Search' />
		</mat-form-field>
	</div>
	<div class='container'>

		<mat-divider></mat-divider>
		<app-data-table (clickActionEmitter)='assessmentSelected($event)' (selectionEmitter)='setSelection($event)'
										[columnNames]='columnNames' [dataSource]='dataSource'
										[displayedColumns]='displayedColumns'
										[enableRowClick]='true' [isLoading]='isLoading'
										[showCheckbox]=true [viewType]='viewType'>
		</app-data-table>
	</div>
</div>

<ng-template #showSpinner class='loading-spinner_container'>
	<mat-progress-bar mode='query'></mat-progress-bar>
</ng-template>