import { Component, Input, OnInit } from "@angular/core";
import { AccountAddress } from "src/app/shared/models/company-details";

@Component({
  selector: "app-form-address-display",
  templateUrl: "./form-address-display.component.html",
  styleUrls: ["./form-address-display.component.scss"],
})
export class FormAddressDisplayComponent implements OnInit {
  @Input() address: AccountAddress;

  constructor() {}

  ngOnInit(): void {}
}
