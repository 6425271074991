export interface DateOption {
  option: string;
}

export class DateOptions {
  static options = [
    { option: "Month to date" } as DateOption,
    { option: "Last month" } as DateOption,
    { option: "Year to date" } as DateOption,
    { option: "Last year" } as DateOption
  ];
}
