<div class='table-header'>
	<mat-form-field>
		<input (keyup)='quickSearch($event.target.value)' matInput placeholder='Quick Search' />
	</mat-form-field>
</div>

<mat-divider></mat-divider>
<app-data-table (clickActionEmitter)='assessmentSelected($event)' (selectionEmitter)='setSelection($event)'
				[allowRowSelect]=displayAssignButton
				[columnNames]='columnNames' [dataRequestType]='apiRequestType'
				[dataSource]='dataSource' [displayedColumns]='displayedColumns' [enableRowClick]='enableRowClick'
				[showTime]="true"
				[isLoading]='isLoading' [showCheckbox]=true></app-data-table>
