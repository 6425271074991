import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Survey } from 'survey-angular';
import { Observable } from 'rxjs';
import { PermissionsService } from '../../../../../core/services/permissions/permissions.service';
import { PermissionsMap } from '../../../../../shared/maps/permissions-map';
import { PermissionsEnum } from '../../../../../shared/enums/permissions-enum';

/* TODO
 * This component is superceeded by FurtherInfoModalComponent
 *  but does not work in the survey, so make new changes in FurtherInfoModalComponent
 */

@Component({
  selector: 'app-further-info-survey-modal',
  templateUrl: './further-info-survey-modal.component.html',
  styleUrls: ['../further-info-modal/further-info-modal.component.scss'],
})
export class FurtherInfoSurveyModalComponent implements OnInit {
  public furtherInfoResponses: any[] = [];
  public newStatus: number;
  public survey: Survey;
  public furtherInfoHasError = false;
  public today: Date = new Date();
  public responseBy: Date | null = new Date(Date.now() + 12096e5);

  constructor(public bsModalRef: BsModalRef, private readonly permissionService: PermissionsService) {}

  public ngOnInit(): void {}

  public modalConfirmFurtherInfo(): void {
    this.bsModalRef.content = {
      responseBy: this.responseBy,
      isSubmitted: true,
    };
    this.bsModalRef.hide();
  }

  public cancel(): void {
    this.bsModalRef.content = {
      isSubmitted: false,
    };

    this.bsModalRef.hide();
  }

  public validatePermissions$(): Observable<boolean> {
    return this.permissionService.authenticateUser$(PermissionsMap.get(PermissionsEnum.ResponseRequiredBy));
  }
}
