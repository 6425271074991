import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ContractorProfileSummaryService } from '../../../../shared/classes/profile-summary.service';
import { firstValueFrom, Observable, switchMap, take, tap } from 'rxjs';
import { TabTypes } from 'src/app/shared/enums/tab-types-enum';
import { storeDispatchAsync } from '../../../../core/ngrx/store-dispatch-async';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ContractorAccountActions } from '../../../../core/ngrx/actions/contractor-account.actions';
import {
  getLoaded,
  selectContractorAccountId,
  selectContractorAccountMembership,
  selectContractorAccountTabIndex,
} from '../../../../core/ngrx/selectors/contractor-account.selectors';
import { ApiRoutesClass, featureFlagNames } from '../../../../shared';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { SnackBarClass } from '../../../../shared/components/snack-bar/snack-bar.class';
import { FeatureFlagService } from '../../../../core/services/feature-flags/feature-flag.service';
import { FeatureFlag } from '../../../../core/services/feature-flags/feature-flag';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  public loaded = false;
  public tabIndex = 0;
  public tabs: TabTypes[] = [
    TabTypes.Summary,
    TabTypes.CompanyDetails,
    TabTypes.Contacts,
    TabTypes.Products,
    TabTypes.WorkCategories,
    TabTypes.Insurance,
    TabTypes.TrackingHistory,
    TabTypes.AuditHistory,
  ];
  public contractorProfileSummary;
  public currentTab: string;
  public loading = false;
  public bundleTimesExtended: any;
  private readonly accountId: string = this.route.snapshot.paramMap.get('id');

  @ViewChild('datePickerModal') datePickerModal: TemplateRef<any>;

  constructor(
    private readonly contractorProfileSummaryService: ContractorProfileSummaryService,
    private readonly store: Store,
    private readonly actions: ActionsSubject,
    private readonly route: ActivatedRoute,
    private readonly apiRoutes: ApiRoutesClass,
    private readonly http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.tabIndex = await firstValueFrom(this.store.select(selectContractorAccountTabIndex));
    this.currentTab = this.tabs[this.tabIndex];
    await this.getReopenCount();
    this.retrieveContractorAccountData();
  }

  set selectedTabIndex(value: number) {
    storeDispatchAsync(this.store, this.actions, null, {
      type: ContractorAccountActions.UpdateIndex,
      tabIndex: value,
    });
    this.tabIndex = value;
  }

  public async getReopenCount(): Promise<void> {
    this.bundleTimesExtended = await firstValueFrom(this.http.get(`${this.apiRoutes.Accounts()}/${this.accountId}/times-reopened`));
  }

  public launchCrm(): void {
    window.open(`${this.apiRoutes.LaunchCrm()}etn=account&id=${this.accountId}`);
  }

  private retrieveContractorAccountData(): void {
    this.store
      .select(getLoaded)
      .pipe(
        switchMap(async (isLoaded) => {
          if (isLoaded) {
            const currentAccountId = await firstValueFrom(this.store.select(selectContractorAccountId));

            if (this.accountId !== currentAccountId) {
              await storeDispatchAsync(this.store, this.actions, ContractorAccountActions.LoadSuccess, {
                type: ContractorAccountActions.Load,
                accountId: this.accountId,
              });
            }

            return;
          }
          await storeDispatchAsync(this.store, this.actions, ContractorAccountActions.LoadSuccess, {
            type: ContractorAccountActions.Load,
            accountId: this.accountId,
          });
        }),
        tap(async () => {
          this.contractorProfileSummary = await firstValueFrom(this.contractorProfileSummaryService.getData());
          const membership = await firstValueFrom(this.store.select(selectContractorAccountMembership));
          this.contractorProfileSummary = {
            ...this.contractorProfileSummary,
            membershipType: membership.membershipType,
          };
        }),
        take(1)
      )
      .subscribe(() => (this.loaded = true));
  }

  protected readonly event = event;
}
