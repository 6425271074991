<button *ngIf='readOnly && authenticatePermissions$() | async'
        color="primary"
        mat-raised-button
        type="button"
        (click)="editData()"
>
    <mat-icon>edit</mat-icon>
</button>
<button
    *ngIf='!readOnly'
    color="primary"
    mat-raised-button
    type="button"
    (click)="closePanel()"
>
    <mat-icon>arrow_back</mat-icon>
</button>
<button
    *ngIf='!readOnly'
    color="primary"
    mat-raised-button
    type="button"
    (click)="emitAction('Save')"
>
    <mat-icon>check</mat-icon>
</button>