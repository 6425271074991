import { createAction, props } from '@ngrx/store';
import { ContactSummary } from 'src/app/core/open-api';

export enum ContactSummaryActions {
  Load = '[Contact Summary] Load',
  LoadSuccess = '[Contact Summary] Load Successful',
  LoadError = '[Contact Summary] Load Error',

  save = '[Contact Summary] Save',
  saveSuccess = '[Contact Summary] Save Success',
  saveError = '[Contact Summary] Save Error',
}

export const ContactSummaryLoad = createAction(ContactSummaryActions.Load, props<{ accountId: string }>());
export const ContactSummaryLoadSuccess = createAction(ContactSummaryActions.LoadSuccess, props<{ contactSummary: ContactSummary }>());
export const ContactSummaryLoadError = createAction(ContactSummaryActions.LoadError, props<{ error: string }>());

//save
export const ContactSummarySave = createAction(ContactSummaryActions.save, props<{ accountId: string; contactSummary: ContactSummary }>());
export const ContactSummarySaveSuccess = createAction(ContactSummaryActions.saveSuccess);
export const ContactSummarySaveError = createAction(ContactSummaryActions.saveError, props<{ error: string }>());
