import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { UIFilter } from '../../../shared/models/ui-filter';
import { DataRequestTypeEnum } from '../../../shared/enums/data-request-type-enum';
import { AlertsService } from '../../../core/services/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { AccountService } from '../../../core/services/account/account.service';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { DataTableAbstract } from '../../../shared/abstract-classes/data-table-abstract/data-table-abstract';
import { NewSearchService } from '../../../core/services/search/new-search.service';

@Component({
  selector: 'app-assessors-table',
  templateUrl: './assessors-table.component.html',
  styleUrls: ['./assessors-table.component.scss'],
})
export class AssessorsTableComponent extends DataTableAbstract implements OnInit {
  @Input() tableName = '';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() enableRowClick: boolean;
  @Input() uiFilter: UIFilter[];
  @Input() dataRequestType: DataRequestTypeEnum;
  @Input() displayedColumns;
  @Input() displayAssignButton: boolean;
  @Input() columnNames: string[];

  constructor(
    protected dialog: MatDialog,
    protected searchService: NewSearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected readonly store: Store,
    protected readonly permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected readonly accountService: AccountService,
    protected readonly bundleService: BundlesService,
    protected readonly alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );
  }

  async ngOnInit(): Promise<void> {
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType);
  }

  public async editUserAccount(event): Promise<void> {
    await this.router.navigate([`/assessment/editAssessor/${event.id}`]);
  }
}
