/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EmployeeInformation {
  directEmployeeNumber?: number | null;
  labourOnlySubcontractor?: number | null;
  bonafideSubcontractors?: number | null;
  totalEffectiveNumberOfEmployees?: number | null;
  turnover?: number | null;
  businessSummary?: string | null;
}
