import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  BundleTypeList,
  BundleTypeListError,
  BundleTypeListSuccess,
  BundleTypeLoad,
  BundleTypeLoadError,
  BundleTypeLoadSuccess,
  BundleTypeSave,
  BundleTypeSaveError,
  BundleTypeSaveSuccess,
} from '../actions/bundle-type.actions';
import { AssessmentService } from '../../open-api/api/assessment.service';

@Injectable()
export class BundleTypeEffects {
  listBundleType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BundleTypeList),
      mergeMap(() =>
        this.assessmentService.getBundleTypes().pipe(
          map((bundleTypes) => {
            return BundleTypeListSuccess({ bundleTypes: bundleTypes });
          }),
          catchError((error) => of(BundleTypeListError({ error: error })))
        )
      )
    )
  );

  loadBundleType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BundleTypeLoad),
      mergeMap((action) =>
        this.assessmentService.getBundleType(action.bundleTypeId).pipe(
          map((bundleType) => BundleTypeLoadSuccess({ bundleType: bundleType })),
          catchError((error) => of(BundleTypeLoadError({ error: error })))
        )
      )
    )
  );

  updateBundleType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BundleTypeSave),
      mergeMap((action) =>
        this.assessmentService.updateBundleType(action.command).pipe(
          map((bundleType) => BundleTypeSaveSuccess({ bundleType: bundleType })),
          catchError((error) => of(BundleTypeSaveError({ error: error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private assessmentService: AssessmentService) {}
}
