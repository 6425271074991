import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { AssessmentHttpService, AssignedAssessmentsSummary } from 'src/app/core/services/account/AssessmentHttpService';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';

@Component({
  selector: 'app-assessor-workload-table',
  templateUrl: './assessor-workload-table.component.html',
  styleUrls: ['./assessor-workload-table.component.scss'],
  animations: [
    trigger('expanded', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AssessorWorkloadTableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filterName = null;

  dataSource = new MatTableDataSource<AssignedAssessmentsSummaryWithPercentages>();

  isLoading = true;

  displayedColumns: string[] = ['assessor', 'assessments', 'percent'];

  expandedRow: AssignedAssessmentsSummaryWithPercentages | null;

  totalAssessments: number;

  public apiRequestTypes: typeof ApiRequestTypes = ApiRequestTypes; // NOTE: Expose enum to view

  constructor(private assessmentHttpService: AssessmentHttpService, private readonly store: Store) {}

  public ngAfterViewInit() {
    this.loadTable();
  }

  private loadTable(): void {
    this.isLoading = true;
    this.assessmentHttpService.getAssignedAssessmentsSummary().subscribe((assignedAssessmentsSummary: AssignedAssessmentsSummary[]) => {
      this.totalAssessments = assignedAssessmentsSummary.reduce((accumulator, a) => accumulator + a.bundles, 0);

      const assignedAssessmentsSummaryWithPercentages = assignedAssessmentsSummary.map((s) => ({
        ...s,
        percent: this.getPercentage(s.bundles),
      }));

      this.dataSource = new MatTableDataSource<AssignedAssessmentsSummaryWithPercentages>(assignedAssessmentsSummaryWithPercentages);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    });
  }

  public getPercentage(value: number): number {
    return this.totalAssessments > 0 ? (value / this.totalAssessments) * 100 : 0;
  }
}

export interface AssignedAssessmentsSummaryWithPercentages extends AssignedAssessmentsSummary {
  percent: number;
}
