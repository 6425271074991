import { createAction, props } from "@ngrx/store";
import { AccreditationSummary } from "src/app/shared/models/AccreditationSummary";

export enum AccreditationSummaryActions {
  Load = "[Accreditation Summary] Load",
  LoadSuccess = "[Accreditation Summary] Load Successful",
  LoadError = "[Accreditation Summary] Load Error",

  save = "[Accreditation Summary] save",
  saveSuccess = "[Accreditation Summary] save Success",
  saveError = "[Accreditation Summary] save Error",
}

export const AccreditationSummaryLoad = createAction(
  AccreditationSummaryActions.Load,
  props<{ accountId: string }>()
);
export const AccreditationSummaryLoadSuccess = createAction(
  AccreditationSummaryActions.LoadSuccess,
  props<{ accreditationSummary: AccreditationSummary }>()
);
export const AccreditationSummaryLoadError = createAction(
  AccreditationSummaryActions.LoadError,
  props<{ error: string }>()
);

//save
export const AccreditationSummarySave = createAction(
  AccreditationSummaryActions.save,
  props<{ accountId: string; accreditationSummary: AccreditationSummary }>()
);
export const AccreditationSummarySaveSuccess = createAction(
  AccreditationSummaryActions.saveSuccess
);
export const AccreditationSummarySaveError = createAction(
  AccreditationSummaryActions.saveError,
  props<{ error: string }>()
);
