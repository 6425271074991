import { AccountSelection } from 'src/app/shared/components/select-account-menu/view-models/account-selection';
import { Subject } from 'rxjs/internal/Subject';

export abstract class AccountSelector {
  // abstract cacheUserAccounts(): void;

  abstract selectAccount(id: string): void;

  abstract getSubject(): Subject<AccountSelection>;

  abstract getUserAccounts(): AccountSelection[];

  abstract getAccount(id: string): AccountSelection;
}
