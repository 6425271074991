import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { PermissionsMap } from '../../../shared/maps/permissions-map';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import {
  CompanyDetailsRequestType,
  DataTableAbstract,
} from '../../../shared/abstract-classes/data-table-abstract/data-table-abstract';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../../core/services/search/search.service';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { AccountService } from '../../../core/services/account/account.service';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { AlertsService } from '../../../core/services/alerts/alerts.service';
import { ApiRequestTypes } from '../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { UIFilter } from '../../../shared/models/ui-filter';
import { DataRequestTypeEnum } from '../../../shared/enums/data-request-type-enum';

@Component({
  selector: 'app-contacts-emails',
  templateUrl: './contacts-emails.component.html',
  styleUrls: ['./contacts-emails.component.scss'],
})
export class ContactsEmailsComponent extends DataTableAbstract implements OnInit {
  @Input() tableName = '';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() enableRowClick: boolean;
  @Input() uiFilter: UIFilter[];
  @Input() dataRequestType: DataRequestTypeEnum;
  @Input() displayedColumns = ['select', 'firstName', 'lastName', 'email', 'createdBy', 'actions']
  @Input() displayAssignButton: boolean;
  @Input() columnNames = ['select', 'First Name', 'Last Name', 'Email', 'Created On', 'Actions']
  public multigridDashboardButton: typeof MultigridDashboardButton = MultigridDashboardButton; // NOTE: Expose enum to view


  constructor(
      protected dialog: MatDialog,
      protected searchService: SearchService,
      protected router: Router,
      protected assessmentService: AssessmentService,
      protected store: Store,
      protected permissionsService: PermissionsService,
      protected actions: ActionsSubject,
      protected accountService: AccountService,
      protected bundleService: BundlesService,
      protected alertsService: AlertsService,
      protected activatedRoute: ActivatedRoute
  ) {
    super(
        dialog,
        searchService,
        router,
        assessmentService,
        store,
        permissionsService,
        actions,
        accountService,
        bundleService,
        alertsService,
        activatedRoute
    );
  }

  async ngOnInit(): Promise<void> {
    this.dataRequestType = DataRequestTypeEnum.Contacts;
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType);
    super.ngOnInit();
  }

  // ngOnInit(): void {
  //   // this.ContactsEmailsTableSub = this.store.select(getContactsEmailsSelector).subscribe((t) => {
  //   //   this.dataSource = new MatTableDataSource(t);
  //   //   setTimeout(() => {
  //   //     this.dataSource.sort = this.sort;
  //   //   });
  //   // });
  //   //
  //   // this.store.select(ContactsEmailsTableSaveSuccessSelector).subscribe((t) => {
  //   //   this.dataSource = new MatTableDataSource(t);
  //   //   setTimeout(() => {
  //   //     this.dataSource.sort = this.sort;
  //   //   });
  //   // });
  //   // this.store.dispatch({
  //   //   type: ContactsEmailsTableActions.Load,
  //   //   accountId: this.route.snapshot.params.id,
  //   // });
  // }

  // ngOnDestroy(): void {
  //   if (this.ContactsEmailsTableSub) {
  //     this.ContactsEmailsTableSub.unsubscribe();
  //   }
  // }

  // onEditMode(row: any): void {
  //   this.readOnly = false;
  //   this.selectedRow = row;
  //   this.id.setValue(row.id);
  //   this.firstName.setValue(row.firstName);
  //   this.lastName.setValue(row.lastName);
  //   this.email.setValue(row.email);
  // }
  //
  // onCancel(): void {
  //   this.readOnly = true;
  //   this.selectedRow = null;
  // }
  //
  // onSave(row: any): void {
  //   this.store.dispatch({
  //     type: ContactsEmailsTableActions.save,
  //     contactsEmail: {
  //       id: this.id.value,
  //       firstName: this.firstName.value,
  //       lastName: this.lastName.value,
  //       email: this.email.value,
  //       createdUtc: row.createdUtc,
  //     },
  //   });
  //   this.readOnly = true;
  //   this.selectedRow = null;
  // }

  public showHideTableView(permissionName): Observable<boolean> {
    const permissionId = PermissionsMap.get(permissionName);
    return this.permissionsService.authenticateUser$(permissionId);
  }
}
