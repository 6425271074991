import { createReducer, on } from "@ngrx/store";
import { QuestionSetTable } from "src/app/shared/models/QuestionSetTable";
import * as AppState from "../../../State";
import {
  QuestionSetTableLoad,
  QuestionSetTableLoadError,
  QuestionSetTableLoadSuccess,
} from "../actions/QuestionSetTable.actions";

export interface State extends AppState.State {
  modules: QuestionSetTable[];
}

export interface QuestionSetTableState {
  modules: QuestionSetTable[];
  loading: boolean;
  error: string;
}

const initialState: QuestionSetTableState = {
  modules: undefined,
  loading: true,
  error: "",
};

const _QuestionSetTableReducer = createReducer(
  initialState,
  on(QuestionSetTableLoad, (state, action) => ({
    ...state,
    loading: true,
    error: "",
  })),
  on(
    QuestionSetTableLoadSuccess,
    (state, action): QuestionSetTableState => ({
      ...state,
      loading: false,
      error: "",
      modules: action.modules,
    })
  ),
  on(
    QuestionSetTableLoadError,
    (state, action): QuestionSetTableState => ({
      ...state,
      error: action.error,
    })
  )
);

export function QuestionSetTableReducer(state, action) {
  return _QuestionSetTableReducer(state, action);
}
