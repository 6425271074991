import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as Survey from 'survey-angular';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AssessmentData } from 'src/app/shared/models/AssessmentData';
import { AssessorAlert } from 'src/app/shared/models/AssessorAssessmentAlert';
import { AssessmentPageInfo } from 'src/app/shared/models/assessment-page-info';
import { ApiRoutesClass, Assessor } from 'src/app/shared';
import { AccountSelector } from '../account-selection/account-selector';
import { firstValueFrom, forkJoin, Observable, of, throwError } from 'rxjs';
import { AssessmentSummary } from '../../../shared';
import { FirAccreditationLevel } from '../../../shared/classes/fir-accreditation-level';
import { ApiRequestTypes } from '../../ngrx/actions/MultigridAssessmentViewType.actions';
import { AssessmentProgressStatus } from '../../../shared/classes/assessment-progress-status';
import { Assessment } from '../../../shared/models/assessment';
import { catchError, map, tap } from 'rxjs/operators';
import { ProgressCardData } from 'src/app/shared/models/progressCards/progress-card-data';
import { UIFilter } from '../../../shared/models/ui-filter';
import { AccountAssessmentViewWithProgressCardData } from '../../../shared/models/account-assessment-view-with-progress-card-data';
import { AssessmentListScope } from '../../../shared/enums/assessment-list-scope';
import { ExpiryFilter } from 'src/app/shared/enums/expiry-filter';
import { ViewerType } from '../../models/viewer-type';
import { AccountAssessmentView } from '../../open-api';

export enum OpsReportingStatus {
  New = 'New',
  InProgress = 'InProgress',
  AdditionalInfoRequired = 'AdditionalInfoRequired',
  Resubmitted = 'Resubmitted',
  Completed = 'Completed',
  SubmittedForQA = 'SubmittedForQA',
  ToBeAssigned = 'ToBeAssigned',
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private readonly selectedAssessmentId: ReplaySubject<string>;
  private selectedQuestionSetData: BehaviorSubject<AssessmentData>;
  private acceptAssessmentData: Subject<any>;
  private readonly assessorsSubject: ReplaySubject<Assessor[]>;
  private readonly selectedAssessmentPages: BehaviorSubject<AssessmentPageInfo[]>;
  private readonly currentAssessmentPage: BehaviorSubject<AssessmentPageInfo>;
  private currentAssessor: any;
  private assessmentLevels = [];
  private assessmentsArray: Array<AssessmentSummary> = [];
  private assessmentProgressGroup: Map<string, AssessmentSummary[]>;
  private readonly assessmentsUpdated: ReplaySubject<Map<string, ProgressCardData>>;
  private isInitialised = new BehaviorSubject<boolean>(false);
  private currentUrl: string = null;

  constructor(
    private readonly http: HttpClient,
    private readonly accountSelector: AccountSelector,
    private accountSelectService: AccountSelector,
    private readonly api: ApiRoutesClass
  ) {
    this.selectedAssessmentId = new ReplaySubject<string>(1);
    this.assessorsSubject = new ReplaySubject<Assessor[]>(1);
    this.selectedQuestionSetData = new BehaviorSubject<any>(null);
    this.acceptAssessmentData = new Subject<any>();
    this.selectedAssessmentPages = new BehaviorSubject<AssessmentPageInfo[]>([]);
    this.currentAssessmentPage = new BehaviorSubject<AssessmentPageInfo>(null);
    this.accountSelector.getSubject().subscribe((x: any) => {
      this.currentAssessor = x;
    });

    this.assessmentProgressGroup = new Map<string, AssessmentSummary[]>();
    this.assessmentsUpdated = new ReplaySubject(1);
    this.resetAssessmentCategories();
  }

  public async retrieveAssessorAccount(assessorRole: string): Promise<void> {
    this.accountSelectService.getSubject().subscribe((account) => {
      const assessorAccount = account;
      if (assessorRole === 'child' && assessorAccount.id !== '') {
        this.currentUrl = assessorAccount.id ? `${this.api.Assessors()}/${assessorAccount.id}/assessorsassessments` : null;
      } else if (assessorRole === 'parent') {
        this.currentUrl = assessorAccount.id ? `${this.api.Assessors()}/account/${assessorAccount.id}/parentassessments` : null;
      }
      this.getAssessorAssessments(this.currentUrl);
    });
  }

  get observeQuestionSetData(): Observable<AssessmentData> {
    return this.selectedQuestionSetData.asObservable();
  }

  get observeAcceptAssessmentData(): Observable<any> {
    return this.acceptAssessmentData.asObservable();
  }

  public getSelectedAssessmentPagesSubscription(): BehaviorSubject<AssessmentPageInfo[]> {
    return this.selectedAssessmentPages;
  }

  public getAssessmentSelectedSubscription(): Subject<string> {
    return this.selectedAssessmentId;
  }

  public getCurrentAssessmentPage(): BehaviorSubject<AssessmentPageInfo> {
    return this.currentAssessmentPage;
  }

  public setSelectedAssessmentPages(pages: AssessmentPageInfo[]): void {
    this.selectedAssessmentPages.next(pages);
  }

  public setCurrentAssessmentPage(page: AssessmentPageInfo): void {
    this.currentAssessmentPage.next(page);
  }

  public async selectAssessment(assessmentId: string): Promise<void> {
    this.assessmentLevels = [];
    this.setSelectedAssessmentPages([]);
    this.selectedAssessmentId.next(assessmentId);
  }

  public getAssessmentSummary(assessmentId: string): AssessmentSummary {
    let assessmentSummary;
    const retrievedAssessment = JSON.parse(sessionStorage.getItem('Assessment-' + assessmentId));
    if (retrievedAssessment != null && retrievedAssessment.Id == assessmentId) {
      assessmentSummary = retrievedAssessment;
    } else {
      assessmentSummary = this.assessmentsArray?.find((assessment) => assessment.Id === assessmentId);
      if (assessmentSummary) sessionStorage.setItem('Assessment-' + assessmentId, JSON.stringify(assessmentSummary));
    }
    return assessmentSummary;
  }

  public async getAssessmentSummaryAsync(id: string, viewerType = ViewerType.Any): Promise<AssessmentSummary> {
    return await firstValueFrom(this.getAssessment(id, viewerType));
  }

  public removeSelectedAssessmentState(): void {
    sessionStorage.removeItem('Assessment');
  }

  public async loadSelectedQuestionSetAsync(assessmentId): Promise<boolean> {
    const assessmentSummary = await this.getAssessmentSummaryAsync(assessmentId);
    const internalId = assessmentSummary?.Id ?? assessmentId;

    try {
      const response = await firstValueFrom(this.http.get<AssessmentData>(`${this.api.Assessors()}/assessments/${internalId}/questionset`));
      this.selectedQuestionSetData.next(response);
      return true;
    } catch (ex) {
      console.error(ex);
      this.selectedQuestionSetData.next(null);
      return false;
    }
  }

  public getIncompleteSectionCount(survey: Survey.SurveyModel): number {
    const questions = survey.getAllQuestions();
    let incompleteSections = 0;

    for (const question of questions) {
      if (question.title === 'Compliance' && question.visible) {
        const value = question.value;
        if (!value || value.indexOf('Non-Compliant') > -1) {
          incompleteSections += 1;
        }
      }
    }
    return incompleteSections;
  }
  /**
   * Returns assessments for specified view type, with optional params to get information for a specified company
   * @param view specified model to be returned
   * @param filter contains company name and account id
   */
  public async getAssessments(
    view: ApiRequestTypes,
    filter?: UIFilter[],
    assessmentListScope: AssessmentListScope = AssessmentListScope.None,
    expiryFilter: ExpiryFilter = ExpiryFilter.none
  ): Promise<AssessmentSummary[]> {
    let params = null;
    if (filter) {
      let body = JSON.stringify(filter);
      body = body.replace('&', '%26');
      params = new HttpParams({
        fromString: `filter=${body}`,
      });
    }

    try {
      this.assessmentsUpdated.next(undefined);
      const gridAndTileData = await firstValueFrom(
        this.http.get<AccountAssessmentViewWithProgressCardData>(
          `${this.api.Assessments()}/assessmentsByViewType/?view=${view}&assessmentListScope=${assessmentListScope}&expiryFilter=${expiryFilter}`,
          {
            responseType: 'json',
            params: params ? params : null,
          }
        )
      );
      const gridAssessments = gridAndTileData.assessments;
      const mappedAssessmentsArray = [];
      const mappedAssessments = this.accountAssessmentToAssessmentSummary(gridAssessments);
      for (const assessment in mappedAssessments) {
        mappedAssessmentsArray.push(mappedAssessments[assessment]);
      }

      this.storeValues(mappedAssessmentsArray);
      this.assessmentsUpdated.next(gridAndTileData.progressCardData);
      return this.accountAssessmentToAssessmentSummary(gridAssessments);
    } catch (exception) {
      throw new Error(exception);
    }
  }

  public getAssessment(assessmentId: string, viewerType = ViewerType.Any): Observable<AssessmentSummary> {
    const options = {
      params: {
        viewerType: viewerType,
      },
    };

    return this.http.get<AccountAssessmentView>(`${this.api.Assessments()}/${assessmentId}`, options).pipe(
      map((a) => this.accountAssessmentToAssessmentSummary([a])),
      tap((a) => this.storeValues(a)),
      map((a) => a[0]),
      catchError((err) => throwError(err))
    );
  }

  public getAssessmentsCategories(): Observable<AssessmentSummary[]> {
    return of(this.assessmentProgressGroup.get(OpsReportingStatus.New));
  }

  public getAssessmentProgress(): Subject<Map<string, ProgressCardData>> {
    return this.assessmentsUpdated;
  }

  public getProductAssessmentsTable(accountId: string): Observable<Assessment[]> {
    return this.http.get<Assessment[]>(`${this.api.Assessments()}/accounts/${accountId}/assessments`);
  }

  private getAssessorAssessments(url: string, filter?: UIFilter[]): void {
    let params = null;
    if (filter) {
      params = new HttpParams({
        fromString: `filter=${JSON.stringify(filter)}`,
      });
    }

    this.http
      .get(url, {
        responseType: 'json',
        params: params ? params : null,
      })
      .pipe(
        map((assessments: any) => {
          return this.assessorAssessmentToAssessmentSummary(assessments);
        }),
        catchError((error) => {
          return of(error);
        })
      )
      .subscribe((assessments: AssessmentSummary[]) => {
        this.resetAssessmentCategories();
        this.groupAssessmentsInCategories(assessments);
        this.isInitialised.next(true);
      });
  }

  private assessorAssessmentToAssessmentSummary(assessmentResponse: any): AssessmentSummary[] {
    const assessmentDetails: AssessmentSummary[] = [];

    try {
      /* Be very careful here.
       * Any mis-mapping may cause a "Cannot read properties of undefined" error
       * The exception handling is here because this mapper is called within a pipe,
       * and any exceptions will normally be hidden if the pipe does not have a catchError operator
       */
      assessmentResponse.forEach((assessment) => {
        assessmentDetails.push({
          Id: assessment.assessmentID,
          AssessmentName: assessment.fullAssessmentName,
          ContractorName: assessment.contractorName,
          ContractorId: assessment.accountID,
          InternalId: assessment.assessmentID,
          Product: assessment.productName,
          Submissiondate: assessment.dateSubmitted,
          Status: assessment.status,
          CreatedDate: assessment.created,
          EnterDate: assessment.dateEntered,
          ExpiryDate: assessment.expiryDate,
          TargetDecisionDate: assessment.targetDecisionDate,
          DateRequiredToRespond: assessment.dateRequiredToRespond,
          ResubmissionDate: assessment.dateResubmitted,
          DateReturnedToAssessor: assessment.dateReturnedToAssessor,
          DecisionDate: assessment.decisionDate,
          NoOfEmployees: assessment.numberOfEmployees,
          FastTrack: assessment.fastTrack,
          AssessmentType: assessment.assessmentTypeLabel,
          Variant: assessment.variantLabel,
          AssignedAssessor: assessment.assessorName,
          AssignedAssessorId: assessment.assessorID,
          IncompleteSections: assessment.incompleteSections,
          AverageAccreditedLevel: assessment.averageAccreditedLevel,
          DateAssigned: assessment.dateAssigned,
          Validity: assessment.validForRenewal,
          TimesReturned: assessment.timesReturned,
          FailureReason: assessment.failureReason,
        });
      });
    } catch (error) {
      throw new Error(error);
    }

    return assessmentDetails;
  }

  public accountAssessmentToAssessmentSummary(assessmentResponse: any): AssessmentSummary[] {
    const assessmentDetails: AssessmentSummary[] = [];
    assessmentResponse.forEach((assessment) => {
      assessmentDetails.push({
        Id: assessment.assessmentID ?? assessment.id,
        AssessmentName: assessment.fullAssessmentName ?? assessment.name,
        ContractorName: assessment.contractorName,
        ContractorId: assessment.accountId,
        InternalId: assessment.id,
        Product: assessment.productName ?? assessment.product?.name,
        Submissiondate: assessment.dateSubmitted,
        Status: assessment.status.label ?? assessment.status,
        DisplayStatus: assessment.displayStatus?.label ?? assessment.status?.label,
        CreatedDate: assessment.created,
        EnterDate: assessment.dateEntered,
        ExpiryDate: assessment.expiryDate,
        TargetDecisionDate: assessment.targetDecisionDate,
        DateRequiredToRespond: assessment.dateRequiredToRespond,
        ResubmissionDate: assessment.resubmissionDate,
        DateReturnedToAssessor: assessment.dateReturnedToAssessor,
        DecisionDate: assessment.decisionDate,
        NoOfEmployees: assessment.numberOfEmployees,
        FastTrack: assessment.fastTrack,
        AssessmentType: assessment.assessmentType?.label,
        Variant: assessment.variant?.label,
        AssignedAssessor: assessment.assessorName,
        AssignedAssessorId: assessment.assessorId,
        IncompleteSections: assessment.incompleteSections,
        AverageAccreditedLevel: assessment.averageAccreditedLevel,
        DateAssigned: assessment.dateAssigned,
        Validity: assessment.validForRenewal,
        AssessorCompanyName: assessment.assessorCompanyName,
        TimesReturned: assessment.timesReturned,
        BundleId: assessment.bundle?.id ?? null,
        FailureReason: assessment.failureReason,
      });
    });
    return assessmentDetails;
  }

  get initialisedStatus(): Observable<boolean> {
    return this.isInitialised.asObservable();
  }

  public update(assessment: AssessmentSummary, oldStatus: string): void {
    this.removeAssessmentFromCategory(assessment, oldStatus);
    this.groupAssessmentsInCategories([assessment]);
  }

  public resetAssessmentCategories(): void {
    this.assessmentProgressGroup.set(OpsReportingStatus.ToBeAssigned, []);
    this.assessmentProgressGroup.set(OpsReportingStatus.New, []);
    this.assessmentProgressGroup.set(OpsReportingStatus.InProgress, []);
    this.assessmentProgressGroup.set(OpsReportingStatus.AdditionalInfoRequired, []);
    this.assessmentProgressGroup.set(OpsReportingStatus.Resubmitted, []);
    this.assessmentProgressGroup.set(OpsReportingStatus.Completed, []);
  }

  private removeAssessmentFromCategory(assessmentSummary: AssessmentSummary, oldStatus: string): void {
    const assessments = this.getAssessmentGroup(assessmentSummary, oldStatus);
    this.deleteAssessment(assessments, assessmentSummary.Id);
  }

  private deleteAssessment(assessments, id): void {
    if (!assessments) {
      return;
    }

    let assessmentIndex = -1;
    for (let i = 0; i < assessments.length; i++) {
      if (assessments[i].Id === id) {
        assessmentIndex = i;
        break;
      }
    }
    if (assessmentIndex !== -1) {
      assessments.splice(assessmentIndex, 1);
    }
  }

  private getAssessmentGroup(assessmentSummary: AssessmentSummary, oldStatus: string): AssessmentSummary[] {
    if (!oldStatus) {
      oldStatus = assessmentSummary.Status;
    }
    if (this.isAssessmentsUnassigned(assessmentSummary, oldStatus)) {
      return this.assessmentProgressGroup.get(OpsReportingStatus.ToBeAssigned);
    } else {
      switch (oldStatus) {
        case AssessmentProgressStatus.Rejected:
          return this.assessmentProgressGroup.get(OpsReportingStatus.ToBeAssigned);
        case AssessmentProgressStatus.Submitted:
        case AssessmentProgressStatus.AwaitingAcceptance:
          return this.assessmentProgressGroup.get(OpsReportingStatus.New);
        case AssessmentProgressStatus.InProgress:
        case AssessmentProgressStatus.UnderAssessment:
        case AssessmentProgressStatus.Accepted:
          return this.assessmentProgressGroup.get(OpsReportingStatus.InProgress);
        case AssessmentProgressStatus.Resubmitted:
          return this.assessmentProgressGroup.get(OpsReportingStatus.Resubmitted);
        case AssessmentProgressStatus.OnHold:
        case AssessmentProgressStatus.AdditionalInfoRequired:
          return this.assessmentProgressGroup.get(OpsReportingStatus.AdditionalInfoRequired);
        case AssessmentProgressStatus.Complete:
        case AssessmentProgressStatus.Verified:
        case AssessmentProgressStatus.SelfCertified:
        case AssessmentProgressStatus.Accredited:
        case AssessmentProgressStatus.Failed:
        case AssessmentProgressStatus.FailedResubmit:
          return this.assessmentProgressGroup.get(OpsReportingStatus.Completed);
      }
    }
  }

  private groupAssessmentsInCategories(assessmentSummaries: AssessmentSummary[]): void {
    assessmentSummaries.forEach((x) => {
      const assessmentGroup = this.getAssessmentGroup(x, null);
      if (assessmentGroup) {
        assessmentGroup.push(x);
      }
    });
  }

  private isAssessmentsUnassigned(assessmentSummary: AssessmentSummary, status: string): boolean {
    if (assessmentSummary.AssignedAssessor === null) {
      if (
        status !== AssessmentProgressStatus.Complete &&
        status !== AssessmentProgressStatus.SelfCertified &&
        status !== AssessmentProgressStatus.Accredited &&
        status !== AssessmentProgressStatus.Failed &&
        status !== AssessmentProgressStatus.Verified &&
        status !== AssessmentProgressStatus.FailedResubmit &&
        status !== AssessmentProgressStatus.Resubmitted &&
        status !== AssessmentProgressStatus.AdditionalInfoRequired
      ) {
        return true;
      }
    }
    return false;
  }

  public async updateAssessment(assessmentId: string, payload: any): Promise<any> {
    const summary = this.getAssessmentSummary(assessmentId);
    const internalId = summary.InternalId;
    payload.assessmentId = summary.InternalId;
    payload.averageFirLevel = summary.AverageAccreditedLevel;

    if (!payload.comment) payload.comment = '';

    return this.http.post<void>(`${this.api.Assessors()}/assessments/${internalId}/updatequestionset`, payload).toPromise();
  }

  public async acceptAssessment(assessmentId: string, accept: boolean): Promise<any> {
    const body = undefined;

    if (accept) {
      const response = await firstValueFrom(
        this.http.put<any>(`${this.api.Assessors()}/${this.currentAssessor.contactid}/assessments/${assessmentId}/Accept`, null)
      );
      this.updateLocalAssessmentStatus(assessmentId, AssessmentProgressStatus.Accepted);
      this.acceptAssessmentData.next(response);
    } else {
      await firstValueFrom(this.http.put(`${this.api.Assessors()}/${this.currentAssessor.id}/assessments/${assessmentId}/Reject`, body));
      this.updateLocalAssessmentStatus(assessmentId, AssessmentProgressStatus.Rejected);
      this.acceptAssessmentData.next(null);
    }
  }

  public purgeAssessmentState(assessmentId): void {
    console.warn(`Assessment-${assessmentId} Removed from state`);
    sessionStorage.removeItem(`Assessment-${assessmentId}`);
  }

  private updateLocalAssessmentStatus(itemKey: string, newStatus: string): void {
    const assessment = JSON.parse(sessionStorage.getItem('Assessment-' + itemKey));
    const newAssessment = {
      ...assessment,
      Status: newStatus,
    };

    sessionStorage.setItem('Assessment-' + itemKey, JSON.stringify(newAssessment));
  }

  public assignAssessments$(assessmentIds: string[], assessorId: string, reassign: boolean): Observable<any> {
    const url = `${this.api.Assessors()}/assessments/${assessorId}/updateassignedassessor`;
    return this.http.put(url, { assessmentIds: assessmentIds, reassign: reassign });
  }
  public assignAssessmentsToAssessorCompany(assessmentIds: string[], accountId: string, reassign: boolean): Observable<any> {
    const url = `${this.api.Assessors()}/assessments/${accountId}/updateassignedassessorcompany`;
    return this.http.put(url, { assessmentIds: assessmentIds, reassign: reassign });
  }

  public updateAssessmentsStatus$(assessmentIds: string[], newStatus: string): Observable<any> {
    const url = `${this.api.Assessments()}/updatestatus`;
    return this.http.put(url, { assessmentIds: assessmentIds, newStatus: newStatus });
  }

  public updateAssessmentsTargetDate(assessmentIds: string[], newResponseDate: Date): Observable<any> {
    const url = `${this.api.Assessments()}/updateresponsedate`;
    return this.http.put(url, { assessmentIds: assessmentIds, newResponseDate: newResponseDate });
  }

  public updateAssessmentsDecisionDate(assessmentIds: string[], newDecisionDate: Date): Observable<any> {
    const url = `${this.api.Assessments()}/updatedecisiondate`;
    return this.http.put(url, { assessmentIds: assessmentIds, newDecisionDate: newDecisionDate });
  }

  public updateAssessmentsExpiryDate(assessmentIds: string[], newExpiryDate: Date): Observable<any> {
    const url = `${this.api.Assessments()}/updateexpirydate`;
    return this.http.put(url, { assessmentIds: assessmentIds, newExpiryDate: newExpiryDate });
  }

  public getAssessorsSubject(): Subject<Assessor[]> {
    return this.assessorsSubject;
  }

  public GetAllAssessors$(): Observable<any> {
    return this.http.get(this.api.Assessors());
  }

  public retrieveEnabledDisabledAssessorList$(): Observable<any> {
    return forkJoin([
      this.http.get(this.api.Assessors()).pipe(catchError((error) => of({}))),
      this.http.get(this.api.DisabledAssessors()).pipe(catchError((error) => of({}))),
    ]);
  }

  public async getAssessorAssessmentAlertsAsync(assessmentId: string): Promise<Array<AssessorAlert>> {
    const summary: AssessmentSummary = await this.getAssessmentSummaryAsync(assessmentId);
    const internalId: string = summary.InternalId;

    return await this.http.get<Array<AssessorAlert>>(`${this.api.Assessors()}/assessments/${internalId}/alerts`).toPromise();
  }

  public isNonCompliant(summary: AssessmentSummary, panel: Survey.Panel, survey: Survey.Survey): boolean {
    if (summary && summary.AssessmentName.toLowerCase().indexOf('fairness, inclusion and respect') > -1) {
      const levelQuestion = panel.questions.find((q) => q.title === 'Level achieved for this section');
      if (levelQuestion === undefined || levelQuestion === null) {
        return true;
      }

      const answer = survey.getValue(levelQuestion.name);
      return !answer || answer.length === 0;
    }

    const complianceQuestion = panel.questions.find((q) => q.title === 'Compliance');
    if (complianceQuestion === undefined || complianceQuestion === null) {
      return true;
    }

    const matchingAnswer = survey.getValue(complianceQuestion.name);
    return matchingAnswer === 'Non-Compliant' || matchingAnswer === undefined;
  }

  public isCompliant(summary: AssessmentSummary, panel: Survey.Panel, survey: Survey.Survey): boolean {
    if (summary && summary.AssessmentName.toLowerCase().indexOf('fairness, inclusion and respect') > -1) {
      const levelQuestion = panel.questions.find((q) => q.title === 'Level achieved for this section');
      if (levelQuestion === undefined || levelQuestion === null) {
        return true;
      }

      const answer = survey.getValue(levelQuestion.name);
      const hasAnswer = answer && answer.length > 0;
      if (hasAnswer) {
        this.updateAssessmentLevel(levelQuestion.name, answer.toLowerCase());
      }
      return answer && answer.length > 0;
    }

    const complianceQuestion = panel.questions.find((q) => q.title === 'Compliance');
    if (complianceQuestion === undefined || complianceQuestion === null) {
      return true;
    }

    const matchingAnswer = survey.getValue(complianceQuestion.name);
    return matchingAnswer === 'Compliant';
  }

  public calculateAverageLevel(): number {
    const sum = this.assessmentLevels.reduce((a, b) => a + b.value, 0);
    const avg = sum / this.assessmentLevels.length || 0;
    return this.resolveAverageLevel(avg);
  }

  // We need to maintain a list of all the assessments, even if they come from different grids
  public storeValues(assessmentList: AssessmentSummary[]): void {
    for (const assessment of assessmentList) {
      const existingIndex = this.assessmentsArray.findIndex(
        (assessmentSummary: AssessmentSummary) => assessmentSummary.Id === assessment.Id
      );

      if (existingIndex !== -1) {
        this.assessmentsArray.splice(existingIndex, 1, assessment);
      }

      this.assessmentsArray.push(assessment);
    }
  }

  private resolveAverageLevel(avg: number): number {
    if (avg <= 2 && avg >= 1) {
      return 1;
    }

    if (avg <= 3 && avg > 2) {
      return 2;
    }

    if (avg <= 4 && avg > 3) {
      return 3;
    }

    if (avg <= 4.5 && avg > 4) {
      return 4;
    }

    if (avg <= 5 && avg > 4.5) {
      return 5;
    }

    return 0;
  }

  private updateAssessmentLevel(panelId: string, answer: string): void {
    const parsedValue = FirAccreditationLevel.resolveLevel(answer);
    if (parsedValue) {
      const index = this.assessmentLevels.findIndex((l) => l.panelId === panelId);
      if (index > -1) {
        this.assessmentLevels[index].value = parsedValue;
      } else {
        this.assessmentLevels.push({ panelId: panelId, value: parsedValue });
      }
    }
  }

  public getRouterLinkQueryParams(bundleId: string, assessments: AssessmentSummary[], assessmentId: string, returnUrl: string): any {
    const assessmentIndex = assessments.findIndex((a) => a.Id === assessmentId);

    let nextAssessmentId: string = undefined;

    if (assessmentIndex != assessments.length - 1) {
      // If the next assessment has a different status to this one, don't add a next parameter
      if (assessments[assessmentIndex].Status == assessments[assessmentIndex + 1].Status) {
        nextAssessmentId = assessments[assessmentIndex + 1].Id;
      }
    }

    return {
      bundleId: bundleId,
      next: nextAssessmentId ? `/assessment/assess/${nextAssessmentId}` : undefined,
      r: returnUrl,
    };
  }
}
