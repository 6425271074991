import { Component, Input, OnInit } from "@angular/core";
import { REGEX_EMAIL, REGEX_WEBSITE } from "../../constants/regex-validators";

@Component({
  selector: "app-read-only-text-field",
  templateUrl: "./read-only-text-field.component.html",
  styleUrls: ["./read-only-text-field.component.scss"],
})
export class ReadOnlyTextFieldComponent implements OnInit {
  constructor() {}

  @Input() name: string;
  @Input() value: string;
  @Input() type: string;

  ngOnInit(): void {}

  get validateEmail(): boolean {
    const match = new RegExp(REGEX_EMAIL);
    return match.test(this.value.toLowerCase());
  }

  get validateWebsite(): boolean {
    const match = new RegExp(REGEX_WEBSITE);

    const httpString = "http://";
    const httpsString = "https://";

    if (
      this.value.substr(0, httpString.length).toLowerCase() !== httpString &&
      this.value.substr(0, httpsString.length).toLowerCase() !== httpsString
    ) {
      this.value = httpsString + this.value;
    }

    return match.test(this.value.toLowerCase());
  }
}
