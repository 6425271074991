import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CountdownTimerModel } from '../../../shared/models/assessment-countdown.model';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { CountdownTimerActionsEnum } from '../actions/assessmentCountdownTimer.actions';
import * as countdownTimerActions from '../actions/assessmentCountdownTimer.actions';
import { ApiRoutesClass } from '../../../shared';

@Injectable()
export class CountdownTimerEffects {
  loadCountdownTimers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountdownTimerActionsEnum.Load),
      mergeMap((action: any) =>
        this.http.get(`${this.api.Notifications()}/accounts/${action.accountId}/getaccountbundledeadlines`).pipe(
          map((b: CountdownTimerModel) => {
            return countdownTimerActions.countdownTimerLoadSuccess({
              verification: b[0],
              assessment: b[1],
            });
          }),
          catchError((error) => of(countdownTimerActions.countdownTimerLoadError({ error })))
        )
      )
    )
  );

  private readonly config: IEnvironment;

  constructor(private readonly actions$: Actions, private readonly http: HttpClient, private readonly api: ApiRoutesClass) {}
}
