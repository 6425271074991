import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import {
  ContractorAccountService,
  ContractorAccountServiceEnum,
} from '../../../../core/services/company-account/contractor-account.service';
import { PanelAbstract } from '../panel-abstract/panel-abstract.component';
import { AccountService } from 'src/app/core/open-api';
import { SnackBarClass } from 'src/app/shared/components/snack-bar/snack-bar.class';

@Component({
  selector: 'app-business-details-panel',
  templateUrl: './business-details-panel.component.html',
  styleUrls: ['../panel-abstract/panel-abstract.component.scss', './business-details-panel.component.scss'],
})
export class BusinessDetailsPanelComponent extends PanelAbstract implements OnInit {
  @Input() data;
  @Input() multiple = false;

  protected permissionsRequest: PermissionsEnum = PermissionsEnum.Summary;
  protected displayActionButtons = true;
  protected panelType = ContractorAccountServiceEnum.BusinessDetails;

  public formGroup: UntypedFormGroup;
  public readonly = true;

  public readonly organisationTypesMap = new Map([
      [885570000, 'Private Limited Company'],
      [885570001, 'Public Limited Company'],
      [885570002, 'Limited Liability Partnership'],
      [885570003, 'Sole Trader'],
      [885570004, 'Registered Charity'],
      [885570005, 'Local Authority'],
      [885570006, 'Charity - Other'],
      [885570007, 'Fund'],
      [885570008, 'Trust'],
      [885570009, 'Other'],
  ]);
  public readonly customerTypesMap = new Map([
    [870520000, 'Client'],
    [870520001, 'Contractor'],
    [870520002, 'Assessor'],
    [870520003, 'Supplier'],
    [885570000, 'Employee'],
    [885570001, 'Trade Association'],
    [870520000, 'PLC'],
    [870520001, 'LTD'],
    [870520002, 'LLP'],
    [870520003, 'Other']
  ]);

  constructor(
    protected fb: FormBuilder,
    protected readonly snackBar: MatSnackBar,
    protected readonly store: Store,
    protected readonly route: ActivatedRoute,
    protected actions: ActionsSubject,
    protected readonly permissionsService: PermissionsService,
    protected readonly contractorAccountService: ContractorAccountService,
    private accountService: AccountService,
    private snackBarComponent: SnackBarClass
  ) {
    super(fb, snackBar, store, route, actions, permissionsService, contractorAccountService);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.overrideFormValue();
  }

  private overrideFormValue(): void {
    let patchValue: string = this.mapOrganisationalType();
    this.formGroup.get('organisationTypeId').patchValue(patchValue);
    patchValue = this.mapCustomerType();
    this.formGroup.get('customerTypeId').patchValue(patchValue);
  }
  private mapOrganisationalType(): string {
    return this.organisationTypesMap.get(this.formGroup.get('organisationTypeId').value);
  }
  private mapCustomerType(): string {
    return this.customerTypesMap.get(this.formGroup.get('customerTypeId').value);
  }

  public initializeCreditChecks() {
    this.accountService.initializeCreditChecks(this.accountId).subscribe(() => {
      this.snackBarComponent.displaySnackbar(
        'Account is now registered for Credit Checks',
        'Dismiss',
        'center',
        'bottom',
        'centeredSnackbar'
      );
    });
  }
}
