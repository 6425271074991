import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CurrentUserActions from '../actions/currentUser.Actions';
import { AccountHttpService } from '../../services/account/account-http.service';
import { CurrentUserState } from '../reducers/CurrentUser.reducer';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesClass } from '../../../shared';

@Injectable()
export class CurrentUserEffects {
  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CurrentUserActions.loadCurrentUser),
      concatMap(() => {
        return this.http.get<CurrentUserState>(this.api.Users()).pipe(
          map((data) => {
            return CurrentUserActions.loadCurrentUserSuccess({
              data: data,
            });
          }),
          catchError((error) => {
            return of(CurrentUserActions.loadCurrentUserFailure({ error: error }));
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private readonly accountsService: AccountHttpService,
    private readonly http: HttpClient,
    private readonly api: ApiRoutesClass
  ) {}
}
