<div *ngFor='let gridView of gridViews'>
    <div *ngIf='showHideTableView(gridView.permissionRequestName) | async'>
        <app-audit-history
                [columnNames]='gridView.columnNames'
                [dataRequestType]='gridView.dataRequestType'
                [displayAssignButton]='gridView.displayAssignButton'
                [displayedColumns]='gridView.displayedColumns'
                [enableRowClick]='gridView.enableRowClick'
                [enabledButtons]='gridView.enabledButtons'
                [apiRequestType]='gridView.apiRequestType'
                [tableName]='gridView.tableName'
                [uiFilter]='gridView.uiFilter'>
        </app-audit-history>
    </div>
</div>

