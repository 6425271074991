import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../State';
import { TradesDelete, TradesDeleteSuccess, TradesLoad, TradesLoadError, TradesLoadSuccess } from '../actions/trades.actions';

export interface State extends AppState.State {
  trade: any;
}

export interface TradesState {
  trade: any;
  loading: boolean;
  error: string;
}

const initialState: TradesState = {
  trade: undefined,
  loading: true,
  error: '',
};

const _TradesReducer = createReducer(
  initialState,
  on(TradesLoad, (state, action) => ({
    ...state,
    loading: true,
    error: '',
  })),
  on(
    TradesLoadSuccess,
    (state, action): TradesState => ({
      ...state,
      loading: false,
      error: '',
      trade: action.trade,
    })
  ),
  on(
    TradesLoadError,
    (state, action): TradesState => ({
      ...state,
      error: action.error,
    })
  ),
  on(
    TradesDelete,
    (state, action): TradesState => ({
      ...state,
      trade: action,
    })
  ),
  on(
    TradesDeleteSuccess,
    (state, action): TradesState => ({
      ...state,
      trade: action,
    })
  )
);

export function TradesReducer(state, action) {
  return _TradesReducer(state, action);
}
