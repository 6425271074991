import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WorkCategory } from 'src/app/core/open-api/model/work-category.model';
import { WorkCategories } from 'src/app/shared';

@Component({
  selector: 'app-work-categories-modal',
  templateUrl: './work-categories-modal.component.html',
  styleUrls: ['./work-categories-modal.component.scss'],
})
export class WorkCategoriesModalComponent implements OnInit {
  public workCategories: WorkCategories[];

  public displayedColumns: string[] = ['name', 'segment', 'family'];
  public dataSource: MatTableDataSource<WorkCategory>;

  constructor(public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    const levels: WorkCategory[] = [];

    for (const workCategory of this.workCategories) {
      levels.push(
        ...workCategory.subCategories.map((s) => {
          const subCategories = s.split(' - ');

          return {
            name: workCategory.name,
            segmentTitle: subCategories[0],
            familyTitle: subCategories[1],
          };
        })
      );
    }

    this.dataSource = new MatTableDataSource<WorkCategory>(levels);
  }
}
