import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '../../../../material/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [LoadingSpinnerComponent, LoadingOverlayComponent],
  exports: [LoadingSpinnerComponent, LoadingOverlayComponent],
  imports: [CommonModule, MatDialogModule, MaterialModule, MatFormFieldModule, MatInputModule],
})
export class LoadingSpinnerModule {}
