<mat-accordion class='example-headers-align' multi>
	<mat-expansion-panel (afterExpand)="accordionState(true)" (afterCollapse)="accordionState(false)">
		<mat-expansion-panel-header>
			<mat-panel-title>
				Create new note
			</mat-panel-title>
		</mat-expansion-panel-header>
		<form [formGroup]='notesForm' class='example-form mb-2'>
			<mat-form-field class='w-100'>
				<mat-label>Title</mat-label>
				<input formControlName='title' matInput min='1' type='text'>
			</mat-form-field>
			<br />
			<mat-label>Note</mat-label>
			<div>
				<angular-editor formControlName="note" [config]="editorConfig"></angular-editor>
			</div>
		</form>
		<button
				(click)='resetState()'
				color='warn'
				mat-raised-button
				type='submit'
		>
			<mat-icon>cancel</mat-icon>
			Cancel
		</button>
		<button
				style="float: right"
			(click)='addNewNote()'
			[disabled]='notesForm.invalid'
			color='primary'
			mat-raised-button
			type='submit'
		>
			<mat-icon>add</mat-icon>
			Add
		</button>
		<div *ngIf='requestPending'>
			<mat-progress-bar mode='query'></mat-progress-bar>
		</div>
	</mat-expansion-panel>
</mat-accordion>