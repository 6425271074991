import { createAction, props } from "@ngrx/store";
import { EmployeeInformation } from "src/app/shared/models/EmployeeInformation";

export enum EmployeeInformationActions {
  Load = "[Contacts And Employees] Load",
  LoadSuccess = "[Contacts And Employees] Load Successful",
  LoadError = "[Contacts And Employees] Load Error",

  save = "[Employee Information] save",
  saveSuccess = "[Employee Information] save Success",
  saveError = "[Employee Information] save Error",
}

export const EmployeeInformationLoad = createAction(
  EmployeeInformationActions.Load,
  props<{ accountId: string }>()
);
export const EmployeeInformationLoadSuccess = createAction(
  EmployeeInformationActions.LoadSuccess,
  props<{ EmployeeInformation: EmployeeInformation }>()
);
export const EmployeeInformationLoadError = createAction(
  EmployeeInformationActions.LoadError,
  props<{ error: string }>()
);

//save
export const EmployeeInformationSave = createAction(
  EmployeeInformationActions.save,
  props<{ accountId: string; employeeInformation: EmployeeInformation }>()
);
export const EmployeeInformationSaveSuccess = createAction(
  EmployeeInformationActions.saveSuccess
);
export const EmployeeInformationSaveError = createAction(
  EmployeeInformationActions.saveError,
  props<{ error: string }>()
);
