<div class="panel">
    <div class="panel__content" >
    <div *ngIf="formGroup" [formGroup]="formGroup">
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>No. of Direct Employees</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="directEmployees" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Labour Only Sub Contractors</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="labourOnlySubContractors" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Total Effective No. of Employees</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="effectiveNumberOfEmployees" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Bonafide Sub Contractors</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="bonafideSubContractors" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Turnover</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="turnover" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Business Summary</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="businessSummary" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel__footer" *ngIf="displayActionButtons">
    <div class="panel__footer" *ngIf="displayActionButtons">
      <app-panel-action-buttons
        (buttonStateEmitter)="editing = $event"
        [loading]="loading"
        (submitEmitter)="submit()"
        [editState]="editing"
      ></app-panel-action-buttons>
    </div>
    <div *ngIf="loading" class="panel__loading">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div>
