import { createAction, props } from '@ngrx/store';

export enum TradesActions {
  Load = '[Trades] Load',
  LoadSuccess = '[Trades] Load Successful',
  LoadError = '[Trades] Load Error',
  Delete = '[Trades] Delete Trade',
  DeleteSuccess = '[Trades] Delete Trade Successful',
  DeleteError = '[Trades] Delete Trade Error'
}

export const TradesLoad = createAction(TradesActions.Load, props<{ accountId: string }>());
export const TradesLoadSuccess = createAction(TradesActions.LoadSuccess, props<{ trade: any }>());
export const TradesLoadError = createAction(TradesActions.LoadError, props<{ error: string }>());
export const TradesDelete = createAction(TradesActions.Delete, props<{ trade: any }>());
export const TradesDeleteSuccess = createAction(TradesActions.DeleteSuccess, props<{ trade: any }>());
export const TradesDeleteError = createAction(TradesActions.DeleteError, props<{ error: any }>());
