import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, from } from 'rxjs';
import { AccountHttpService } from '../../services/account/account-http.service';
import { AssessmentsActions, StoreAssessments } from '../actions/assessments.actions';
import { AssessmentService } from '../../services/assessment/assessment.service';
import { ApiRequestTypes } from '../actions/MultigridAssessmentViewType.actions';
import { UIFilter } from 'src/app/shared/models/ui-filter';
import { AssessmentListScope } from 'src/app/shared/enums/assessment-list-scope';

@Injectable()
export class AssessmentEffects {
  getAssessments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssessmentsActions.GetAssessments),
      mergeMap((action: { view: ApiRequestTypes; filter?: UIFilter[], assessmentListScope: AssessmentListScope }) => {
        return from(this.assessmentsService.getAssessments(action.view, action.filter, action.assessmentListScope)).pipe(
          map((result) => StoreAssessments({ assessments: result })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  constructor(private actions$: Actions, private readonly assessmentsService: AssessmentService) {}
}
