import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'isDate',
})
export class IsDatePipe implements PipeTransform {
  transform(value, ...args: unknown[]): unknown {
    if (value == undefined) return null;

    let utcDateTime = DateTime.fromISO(value);

    if (!utcDateTime.isValid || typeof value == 'number' || typeof value == 'boolean') {
      return value;
    }

    if (this.isDSTPeriod(utcDateTime)) {
      const dstOffset = 1;
      utcDateTime = utcDateTime.plus({ hours: dstOffset });
    }

    if (args[0]) {
      return utcDateTime.setLocale('en-GB').toLocaleString(args[0]); // DateTime.DATETIME_SHORT
    }

    return utcDateTime.setLocale('en-GB').toLocaleString();
  }

  isDSTPeriod(dateTime: DateTime): boolean {
    const year = dateTime.year;
    const dstStart = DateTime.fromObject({ year, month: 3, day: 26 }).set({ hour: 1 });
    const dstEnd = DateTime.fromObject({ year, month: 10, day: 29 }).set({ hour: 1 });
  
    return dateTime >= dstStart && dateTime < dstEnd;
  }
}
