import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, firstValueFrom, map, Observable, tap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { AssessmentHttpService } from 'src/app/core/services/account/AssessmentHttpService';
import { ProductivityService } from 'src/app/core/services/productivity/productivity.service';
import { AccountHttpService } from 'src/app/core/services/account/account-http.service';
import { Contractor } from 'src/app/shared/models/Contractor';
import { UserGroup, UserGroupHttpService } from 'src/app/core/services/account/user-group-http.service';
import { Store } from '@ngrx/store';
import { AssessorAutomatedQARule, AssessorAutomatedQARules } from 'src/app/shared/models/assessor-automated-qa-rules';
import { MatSelectChange } from '@angular/material/select';
import { AccountStatus, ApiRoutesClass, AssessorInfo, featureFlagNames } from '../../../shared';
import { HttpClient } from '@angular/common/http';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { PermissionsEnum } from '../../../shared/enums/permissions-enum';
import { PermissionsMap } from '../../../shared/maps/permissions-map';
import { Location } from '@angular/common';
import { FeatureFlagService } from '../../../core/services/feature-flags/feature-flag.service';
import { selectFeatureFlagsIsLoaded } from '../../../core/ngrx/selectors/feature-flag.selectors';
import { Subscription } from 'rxjs/internal/Subscription';
import { switchMap } from 'rxjs/operators';

enum AccountState {
  Enable = 'Enable',
  Disable = 'Disable',
}

@Component({
  selector: 'app-assessor-add-or-update',
  templateUrl: './assessor-add-or-update.component.html',
  styleUrls: ['./assessor-add-or-update.component.scss'],
})
export class AssessorAddOrUpdateComponent implements OnInit, OnDestroy {
  private id: string = this.route.snapshot.paramMap.get('id');
  private qaRulePercentageLast = 0;
  private subscriptions$: Subscription[] = [];

  protected showQaSettings: boolean;

  public accountStateEnum: typeof AccountState = AccountState;
  public isLoading: boolean;
  public accounts: Contractor[];
  public assessorTypes: [number, string][];
  public userGroups: UserGroup[] = [];
  public automatedQARules = AssessorAutomatedQARules;
  public accountDisabled: boolean;
  public accountId: string;
  public detailsForm: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly assessmentService: AssessmentHttpService,
    private readonly productivityService: ProductivityService,
    private readonly accountHttpService: AccountHttpService,
    private readonly userGroupHttpService: UserGroupHttpService,
    private readonly store: Store,
    private readonly apiRoutes: ApiRoutesClass,
    private readonly http: HttpClient,
    private readonly permissionsService: PermissionsService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly location: Location,
    private readonly featureFlags: FeatureFlagService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.getSubscriptions();
    this.assessorTypes = Array.from(this.productivityService.getAssessorTypes().entries());
    this.accounts = await firstValueFrom(this.accountHttpService.getAllAssessors());
    this.userGroups = await firstValueFrom(this.userGroupHttpService.getAllUserGroups());
    const assessor = await this.retrieveUserAccount();
    this.initializeForm(assessor);
  }

  public ngOnDestroy() {
    if (this.subscriptions$ === null || this.subscriptions$.length === 0) {
      return;
    }
    this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
  }

  public authenticatePermissions$(): Observable<boolean> {
    const authToken = PermissionsMap.get(PermissionsEnum.EnableDisableAccount);
    return this.permissionsService.authenticateUser$(authToken);
  }

  public goBack(): void {
    this.location.back();
    this.changeDetector.detectChanges();
  }

  private async retrieveUserAccount(): Promise<any> {
    if (this.id) {
      return await firstValueFrom(
        this.assessmentService.getAssessorById(this.id).pipe(
          tap((account: AssessorInfo) => {
            this.accountDisabled = account.statusCode != AccountStatus.Valid;
            this.accountId = account.id;
          }),
          map((assessor) => ({
            id: assessor.id,
            accountId: assessor.accountId.uid,
            firstName: assessor.firstName,
            lastName: assessor.lastName,
            email: assessor.email,
            assessorType: assessor.assessorTypeId,
            userGroupId: assessor.userGroupId,
            automatedQARule: assessor.properties?.assessorProperties?.automatedQARule,
            qaRulePercentage: assessor.properties?.assessorProperties?.qaRulePercentage,
          }))
        )
      );
    }
  }

  private getSubscriptions(): void {
    this.subscriptions$ = [
      this.featureFlagSubscription$()
    ];
  }

  private featureFlagSubscription$(): Subscription {
    return this.store.select(selectFeatureFlagsIsLoaded)
      .pipe(
          filter(isLoaded => isLoaded),
          switchMap(() => this.featureFlags.isFeatureEnabled$(featureFlagNames.QA_PERCENT_SETTINGS)),
          tap(isEnabled => this.showQaSettings = isEnabled)
      )
      .subscribe();
  }

  private initializeForm(assessor): void {
    this.detailsForm = this.formBuilder.group({
      id: [null],
      accountId: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      assessorType: ['', Validators.required],
      userGroupId: ['', Validators.required],
      automatedQARule: this.showQaSettings ? ['', Validators.required] : ['1'],
      qaRulePercentage: this.showQaSettings ? ['', Validators.required] : ['0'],
    });

    if (assessor) {
      this.detailsForm = this.formBuilder.group({});
      for (const [key, value] of Object.entries(assessor)) {
        if (key === 'qaRulePercentage' && (value === 0.0 || !this.showQaSettings)) {
          // Handle the case when qaRulePercentage is 0.0
          this.detailsForm.addControl(key, new FormControl('0', Validators.required));
        } else {
          this.detailsForm.addControl(key, new FormControl(value || '', Validators.required));
        }
      }
    }
    this.isLoading = false;
  }  
  
  public async manageAccountLockState(flag: string): Promise<void> {
    try {
      this.isLoading = true;
      const param: string = flag === AccountState.Enable ? AccountState.Enable : AccountState.Disable;
      await firstValueFrom(this.http.post(`${this.apiRoutes.BaseUrl()}/assessors/${param}?assessorId=${this.accountId}`, null));
      await this.router.navigate(['/assessment/user-management']);
    } catch (e) {
      this.isLoading = false;
      throw new Error(e);
    }
  }

  private initialiseRelatedFields(form: UntypedFormGroup, value: number, restoreLastValue = false): void {
    if (value == AssessorAutomatedQARule.overallPercentage) {
      form.controls.qaRulePercentage.enable();

      if (restoreLastValue) {
        form.controls.qaRulePercentage.setValue(this.qaRulePercentageLast);
      }
    } else {
      this.qaRulePercentageLast = this.detailsForm.controls.qaRulePercentage.value;

      if (restoreLastValue) {
        form.controls.qaRulePercentage.setValue(0);
      }
      form.controls.qaRulePercentage.disable();
    }
  }

  public automatedQARuleSelectionChange(event: MatSelectChange): void {
    this.initialiseRelatedFields(this.detailsForm, event.value, true);
  }

  public async onSave(): Promise<void> {
    this.isLoading = true;
    if (this.id != null) {
      this.detailsForm.patchValue({ id: this.id });
    }
    try {
      await firstValueFrom(this.assessmentService.createOrUpdateAssessor$(this.detailsForm.value));
      await this.router.navigate(['/assessment/user-management']);
    } catch (e) {
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
