import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { AssessmentSummary, Assessor } from '../../../shared';
import { lastValueFrom, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { Contractor } from '../../../shared/models/Contractor';
import { AlertsService } from '../../../core/services/alerts/alerts.service';
import { SnackBarClass } from 'src/app/shared/components/snack-bar/snack-bar.class';

@Component({
  selector: 'assessor-assignment-modal.component',
  templateUrl: './assessor-assignment-modal.component.html',
})
export class AssessorAssignmentDialog implements OnInit {
  public assessors: Assessor[] = [];
  public assessments: any;
  public assessorsSubscription: Subscription;
  public selectedAssessor: string;
  public showLoadingBar = false;

  constructor(
    public dialogRef: MatDialogRef<AssessorAssignmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly assessmentService: AssessmentService,
    private readonly bundleService: BundlesService,
    protected readonly alertsService: AlertsService,
    private readonly snackBarComponent: SnackBarClass
  ) {}

  ngOnInit(): void {
    this.assessments = this.data.selection.selected;
    this.assessorsSubscription = this.assessmentService.GetAllAssessors$().subscribe((contractors: Contractor[]) => {
      contractors.forEach((contact: any) => {
        this.assessors.push({
          name: contact.fullName,
          id: contact.id,
        });
      });
      return this.assessors.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public assignAssessments(): void {
    this.showLoadingBar = true;
    const bundle: AssessmentSummary[] = this.data.selection.selected[0] as unknown as AssessmentSummary[];
    bundle.map(async (a: AssessmentSummary): Promise<void> => {
      try {
        if (a.docVerificationId) await lastValueFrom(this.alertsService.assignAlertToAdvisor$(this.selectedAssessor, a.docVerificationId));
        await lastValueFrom(
          this.bundleService.assignBundleAssessor$(
            bundle.map((a: AssessmentSummary) => a.BundleId || a.Id || a.id),
            this.selectedAssessor,
            true
          )
        );
      } catch (error: any) {
        this.snackBarComponent.displaySnackbar(error.error, 'Dismiss', 'center', 'bottom', 'centeredSnackbar');
        throw new Error(error);
      } finally {
        this.showLoadingBar = false;
        this.dialogRef.close();
      }
    });
  }
}

export interface DialogData {
  assessorName: string;
  selection: SelectionModel<AssessmentSummary>;
}
