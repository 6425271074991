import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class UserGroupHttpService {
  constructor(private readonly http: HttpClient, private readonly api: ApiRoutesClass) {}

  public getAllUserGroups(): Observable<UserGroup[]> {
    return this.http.get<any>(`${this.api.MyChasPortalApi()}/usergroups`);
  }
}

export interface Permission {
  id: string;
  name: string;
}

export interface UserGroup {
  id: string;
  name: string;
  permissions: Permission[];
}
