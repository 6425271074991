import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  ProductCurrentMembershipTableLoad,
  ProductCurrentMembershipTableLoadError,
  ProductCurrentMembershipTableLoadSuccess,
} from "../actions/productCurrentMembershipTable.actions";
import { AccountHttpService } from "../../services/account/account-http.service";

@Injectable()
export class ProductCurrentMembershipEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCurrentMembershipTableLoad),
      mergeMap((action) =>
        this.accountService.getProductCurrentMembership(action.accountId).pipe(
          map((b) =>
            ProductCurrentMembershipTableLoadSuccess({
              productCurrentMembership: b,
            })
          ),
          catchError((error) =>
            of(ProductCurrentMembershipTableLoadError({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountHttpService
  ) {}
}
