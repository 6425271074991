import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesClass } from '../../../shared';
import * as FeatureFlagActions from '../actions/feature-flag.actions';
import { FeatureFlag } from '../../services/feature-flags/feature-flag';

@Injectable()
export class FeatureFlagEffects {
  loadFeatureFlags$$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeatureFlagActions.FeatureFlagsLoad),
      concatMap(() => {
        return this.http.get<FeatureFlag[]>(this.api.FeatureFlag()).pipe(
          map((data) => {
            return FeatureFlagActions.FeatureFlagsLoadSuccess({
              data: data,
            });
          }),
          catchError((error) => {
            return of(FeatureFlagActions.FeatureFlagsLoadError({ error: error }));
          })
        );
      })
    );
  });

  constructor(private actions$: Actions, private readonly http: HttpClient, private readonly api: ApiRoutesClass) {}
}
