import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentProgressStatus } from '../../../../../shared/classes/assessment-progress-status';
import { AssessmentStatus } from '../../../../../shared/classes/assessment-status';
import { AssessmentService } from '../../../../../core/services/assessment/assessment.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-change-assessment-status',
  templateUrl: './change-assessment-status.component.html',
  styleUrls: ['./change-assessment-status.component.scss'],
})
export class ChangeAssessmentStatusComponent implements OnInit {
  @Input() assessmentId: string;
  public AssessmentStatus: typeof AssessmentProgressStatus = AssessmentProgressStatus; // expose to view
  public isLoading: boolean;
  @Output() statusEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private readonly assessmentService: AssessmentService) {}

  ngOnInit(): void {
    this.retrieveOldStatus;
  }

  private async changeStatus(newStatus: AssessmentStatus): Promise<void> {
    this.isLoading = true;

    await firstValueFrom(this.assessmentService.updateAssessmentsStatus$([this.assessmentId], newStatus as string));
    const updatedStatus = {
      ...this.retrieveOldStatus,
      Status: newStatus,
    };
    sessionStorage.removeItem(`Assessment-${this.assessmentId}`);
    sessionStorage.setItem(`Assessment-${this.assessmentId}`, JSON.stringify(updatedStatus));
    this.isLoading = false;
    this.statusEmitter.emit(newStatus);
  }

  /**
   * FROM submitted TO in progress
   * FROM additional information required TO submitted
   * FROM additional information required TO complete
   * FROM submitted, resubmitted and fail to resubmit TO additional information required
   * FROM any status TO withdrawn - permission locked to management user accounts.
   * @param newStatus
   */
  public async requestStatusChange(newStatus: AssessmentStatus): Promise<void> {
    switch (newStatus) {
      case AssessmentProgressStatus.InProgress:
        this.retrieveOldStatus.DisplayStatus == AssessmentProgressStatus.Submitted || AssessmentProgressStatus.SubmittedAwaitingOthers
          ? await this.changeStatus(newStatus)
          : null; // in progress
        break;
      case AssessmentProgressStatus.Submitted:
        this.retrieveOldStatus.DisplayStatus == AssessmentProgressStatus.AdditionalInfoRequired ? await this.changeStatus(newStatus) : null; // submitted
        break;
      case AssessmentProgressStatus.Complete:
        this.retrieveOldStatus.DisplayStatus == AssessmentProgressStatus.AdditionalInfoRequired ? await this.changeStatus(newStatus) : null; // complete
        break;
      case AssessmentProgressStatus.FailedResubmit:
        this.retrieveOldStatus.DisplayStatus == AssessmentProgressStatus.FailedResubmit ? await this.changeStatus(newStatus) : null; // additional information required
        break;
      case AssessmentProgressStatus.AdditionalInfoRequired:
        this.retrieveOldStatus.DisplayStatus == AssessmentProgressStatus.Submitted ||
        AssessmentProgressStatus.Resubmitted ||
        AssessmentProgressStatus.FailedResubmit
          ? await this.changeStatus(newStatus)
          : null;
        break;
      default:
        break;
    }
  }

  public get retrieveOldStatus(): { DisplayStatus: any } {
    return JSON.parse(sessionStorage.getItem(`Assessment-${this.assessmentId}`));
  }
}
