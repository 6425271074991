import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { AccountViewModel, Contact, ContactSummary, WorkRegion } from 'src/app/core/open-api';
import { AccreditationSummary } from 'src/app/shared/models/AccreditationSummary';
import { Assessment } from 'src/app/shared/models/Assessment';
import { CompanyDetails, GetAccountForAssessment } from 'src/app/shared/models/company-details';
import { CompanyInformation } from 'src/app/shared/models/CompanyInformation';
import { CompanyKeys } from 'src/app/shared/models/CompanyKeys';
import { CompanySummary1 } from 'src/app/shared/models/CompanySummary1';
import { CompanySummary2 } from 'src/app/shared/models/CompanySummary2';
import { CompanySummary3 } from 'src/app/shared/models/CompanySummary3';
import { ContactsEmail } from 'src/app/shared/models/ContactsEmail';
import { Contractor } from 'src/app/shared/models/Contractor';
import { EmployeeInformation } from 'src/app/shared/models/EmployeeInformation';
import { FIRSummary } from 'src/app/shared/models/FIRSummary';
import { MembershipSummary } from 'src/app/shared/models/MembershipSummary';
import { ParentWorkCategory } from 'src/app/shared/models/ParentWorkCategory';
import { ProductCurrentMembership } from 'src/app/shared/models/ProductCurrentMembership';
import { ProductTable } from 'src/app/shared/models/ProductTable';
import { QuestionSetTable } from 'src/app/shared/models/QuestionSetTable';
import { TradeView } from 'src/app/shared/models/TradeView';
import { Opportunity } from '../../../shared/models/Opportunity';
import { CurrentUserState } from '../../ngrx/reducers/CurrentUser.reducer';
import { AccountSelectorService } from '../account-selection/account-selector.service';
import { AzureUserAuthenticationService } from '../azure-authentication/user-authentication/azure-user-authentication.service';
import { ApiRoutesClass } from '../../../shared';
import { ProfileSummary } from 'src/app/shared/models/profile-summary';

@Injectable({
  providedIn: 'root',
})
export class AccountHttpService {
  private readonly currentUserState: CurrentUserState;
  private accountId: string;

  constructor(
    private readonly accountSelectorService: AccountSelectorService,
    private readonly http: HttpClient,
    private readonly store: Store,
    private authService: AzureUserAuthenticationService,
    private readonly router: Router,
    private readonly api: ApiRoutesClass
  ) {
    this.currentUserState = this.accountSelectorService.getCurrentActiveUser() as any;

    if (this.currentUserState && this.currentUserState.currentAccount && this.currentUserState.currentAccount.accountId) {
      this.accountId = this.currentUserState.currentAccount.accountId;

      this.getAccountDetails(this.currentUserState.currentAccount.accountId).subscribe(
        (response) => (this.accountId = response.account.id.uid)
      );
    }
  }

  public async refreshPage(): Promise<void> {
    await this.accountSelectorService.getCurrentActiveUser();
  }

  public getAccountDetails(accountId: string): Observable<GetAccountForAssessment> {
    return this.http.get<GetAccountForAssessment>(`${this.api.Assessors()}/account/${accountId}`);
  }

  public SaveWebsite(accountId: string, website: string): Observable<string> {
    return this.http.post<string>(`${this.api.Assessors()}/account/${accountId}`, website);
  }

  public getCompanySummary1(accountId: string): Observable<CompanySummary1> {
    return this.http.get<CompanySummary1>(`${this.api.Accounts()}/getCompanySummary1/${accountId}`);
  }

  public SaveAccountRegisteredAddress(accountId: string, address: any): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/saveRegisteredAddress/${accountId}`, address);
  }

  public SaveAccountcorrespondenceAddress(accountId: string, address: any): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/savecorrespondenceAddress/${accountId}`, address);
  }

  public SaveCompanySummary1(accountId: string, accreditationSummary: CompanySummary1): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveCompanySummary1/${accountId}`, accreditationSummary);
  }

  public SaveCompanySummary2(accountId: string, accreditationSummary: CompanySummary2): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveCompanySummary2/${accountId}`, accreditationSummary);
  }

  public getCompanySummary2(accountId: string): Observable<CompanySummary2> {
    return this.http.get<CompanySummary2>(`${this.api.Accounts()}/getCompanySummary2/${accountId}`);
  }

  public getCompanySummary3(accountId: string): Observable<CompanySummary3> {
    return this.http.get<CompanySummary3>(`${this.api.Accounts()}/getCompanySummary3/${accountId}`);
  }

  public GetFirSummary(accountId: string): Observable<FIRSummary> {
    return this.http.get<FIRSummary>(`${this.api.Accounts()}/GetFirSummary/${accountId}`);
  }

  public GetContacts(accountId: string): Observable<ContactsEmail[]> {
    return this.http.get<ContactsEmail[]>(`${this.api.Contacts()}/${accountId}/contacts`);
  }

  public SaveContact(contact: ContactsEmail): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveContact`, contact);
  }

  public GetEmployeeInfo(accountId: string): Observable<EmployeeInformation> {
    return this.http.get<EmployeeInformation>(`${this.api.Accounts()}/GetEmployeeInfo/${accountId}`);
  }

  public SaveEmployeeInformation(accountId: string, accreditationSummary: EmployeeInformation): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveEmployeeInformation/${accountId}`, accreditationSummary);
  }

  public GetCompanySummary3(accountId: string): Observable<CompanySummary3> {
    return this.http.get<CompanySummary3>(`${this.api.Accounts()}/GetCompanySummary3/${accountId}`);
  }

  public SaveCompanySummary3(accountId: string, accreditationSummary: CompanySummary3): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveCompanySummary3/${accountId}`, accreditationSummary);
  }

  public GetCompanySummary2(accountId: string): Observable<CompanySummary2> {
    return this.http.get<CompanySummary2>(`${this.api.Accounts()}/GetCompanySummary2/${accountId}`);
  }

  public GetCompanySummary1(accountId: string): Observable<CompanySummary1> {
    return this.http.get<CompanySummary1>(`${this.api.Accounts()}/GetCompanySummary1/${accountId}`);
  }

  public GetCompanyKeys(accountId: string): Observable<CompanyKeys> {
    return this.http.get<CompanyKeys>(`${this.api.Accounts()}/GetCompanyKeys/${accountId}`);
  }

  public SaveCompanyKeys(accountId: string, companyKeys: CompanyKeys): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveCompanyKeys/${accountId}`, companyKeys);
  }

  public GetContactSummary(accountId: string): Observable<ContactSummary> {
    return this.http.get<ContactSummary>(`${this.api.Accounts()}/GetContactSummary/${accountId}`);
  }

  public SaveContactSummary(accountId: string, companyinformation: ContactSummary): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveContactSummary/${accountId}`, companyinformation);
  }

  public getAccreditationSummary(accountId: string): Observable<AccreditationSummary> {
    return this.http.get<AccreditationSummary>(`${this.api.Accounts()}/GetAccreditationSummary/${accountId}`);
  }

  public SaveAccreditationSummary(accountId: string, accreditationSummary: AccreditationSummary): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveAccreditationSummary/${accountId}`, accreditationSummary);
  }

  public getAccountInformation(accountId: string): Observable<CompanyInformation> {
    return this.http.get<CompanyInformation>(`${this.api.Accounts()}/${accountId}/GetAccountInformation`);
  }

  public SaveAccountInformation(accountId: string, companyInfo: CompanyInformation): Observable<CompanyInformation> {
    return this.http.post<CompanyInformation>(`${this.api.Accounts()}/${accountId}/GetAccountInformation`, companyInfo);
  }

  public SaveCompanyInformation(accountId: string, companyinformation: CompanyInformation): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveCompanyInformation/${accountId}`, companyinformation);
  }

  public getMembershipSummary(accountId: string): Observable<MembershipSummary> {
    return this.http.get<MembershipSummary>(`${this.api.Accounts()}/${accountId}/GetMembershipSummary`);
  }

  public SaveMembershipSummary(accountId: string, membershipSummary: MembershipSummary): Observable<any> {
    return this.http.post<any>(`${this.api.Accounts()}/SaveMembershipSummary/${accountId}`, membershipSummary);
  }

  public getProductCurrentMembership(accountId: string): Observable<ProductCurrentMembership[]> {
    return this.http.get<ProductCurrentMembership[]>(`${this.api.Accounts()}/${accountId}/getProductCurrentMembership`);
  }

  public getProductTable(): Observable<ProductTable[]> {
    return this.http.get<ProductTable[]>(`${this.api.Accounts()}/getProducts`);
  }

  public getProductAssessmentsTable(accountId: string): Observable<Assessment[]> {
    return this.http.get<Assessment[]>(`${this.api.Accounts()}/getProductAssessmentsTable/${accountId}`);
  }

  public getQuestionSetTable(): Observable<QuestionSetTable[]> {
    return this.http.get<QuestionSetTable[]>(`${this.api.Accounts()}/getQuestionSetTable/`);
  }

  public getAccountWorkCategoriesInformation(accountId: string): Observable<TradeView> {
    return this.http.get<TradeView>(`${this.api.MyChasEndpoint()}${accountId}/getaccountworkcategorieshierarchyall/`);
  }

  public getAccount(accountId: string): Observable<CompanyDetails> {
    return this.http.get<CompanyDetails>(`${this.api.Accounts()}/account/${accountId}`);
  }

  public getContractors(contractorId: string): Observable<any> {
    return this.http.get(`${this.api.Accounts()}/getcontractors/${contractorId}`);
  }

  public getContactsFromAccount(contractorId: string): Observable<any> {
    return this.http.get(`${this.api.Contacts()}/${contractorId}/contacts`);
  }

  public getContacts(contractorId: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${this.api.Contacts()}/account/${contractorId}/contacts`);
  }

  public getBranchAddresses(contractorId: string): Observable<any> {
    return this.http.get(`${this.api.Accounts()}/accounts/${contractorId}/branch-addresses`);
  }

  public getWorkRegions(contractorId: string): Observable<WorkRegion> {
    return this.http.get(`${this.api.WorkArea()}/work-regions/${contractorId}`);
  }

  public saveWorkRegion(contractorId: string, workRegion: WorkRegion): Observable<any> {
    return this.http.post(`${this.api.WorkArea()}/work-regions/${contractorId}`, workRegion);
  }

  public getBusinessSize(contractorId: string, id: string): Observable<any> {
    return this.http.get(`${this.api.Accounts()}/getbusinesssize/${contractorId}/${id}`);
  }

  public getBusinessSize_(): string {
    const size = this.currentUserState.companyDetails.size;

    return size;
  }

  public getMatchingCategories$(accountId: string): Observable<ParentWorkCategory[]> {
    const companyCategories = `${this.api.WorkCategories()}/account/${accountId}/getaccountworkcategorieshierarchyall`;
    return this.http.get<ParentWorkCategory[]>(companyCategories);
  }

  getOpportunties(): Observable<Opportunity[]> {
    const url = `${this.api.Accounts()}/GetOpportunityListAsync`;
    return this.http.get<Opportunity[]>(url);
  }

  public getPurchaseHistory(accountId: string): Observable<any> {
    const url = `${this.api.Accounts()}/${accountId}/getPurchaseHistoryAsync`;
    return this.http.get<Opportunity[]>(url);
  }

  public getAllContractors(): Observable<Contractor[]> {
    const url = `${this.api.Accounts()}/contractors`;
    return this.http.get<Contractor[]>(url);
  }

  public getAllAssessors(): Observable<Contractor[]> {
    const url = `${this.api.Accounts()}/assessors`;
    return this.http.get<Contractor[]>(url);
  }

  public getAllClients$(): Observable<AccountViewModel[]> {
    const url = `${this.api.Accounts()}/clients`;
    return this.http.get<AccountViewModel[]>(url);
  }

  public getAllWorkRegions(): Observable<any[]> {
    const url = `${this.api.WorkArea()}/work-regions/`;
    return this.http.get<Contractor[]>(url);
  }

  public saveAccountWorkRegions(accountId: string, workRegions: Array<WorkRegion>, distance: number): Observable<any[]> {
    const url = `${this.api.WorkArea()}/${accountId}/saveaccountworklocations/?distance=${distance}`;
    return this.http.post<Contractor[]>(url, workRegions).pipe(take(1));
  }

  public getContact$(contactId: string): Observable<Contact> {
    const url = `${this.api.Contacts()}/${contactId}`;
    return this.http.get<Contact>(url);
  }

  public getProfileSummary(accountId: string): Observable<ProfileSummary> {
    const url = `${this.api.Profile()}/summary/${accountId}`;
    return this.http.get<ProfileSummary>(url);
  }
}
