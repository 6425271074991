import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleQuestionSet } from 'src/app/shared/models/ModuleQuestionSet';
import { QuestionSetTable } from 'src/app/shared/models/QuestionSetTable';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class QuestionSetHttpService {
  constructor(private http: HttpClient, private readonly api: ApiRoutesClass) {}

  public uploadCSV(request: FormData): Observable<any> {
    return this.http.post<any>(this.api.QuestionSets(), request);
  }

  public getQuestionSetTable(): Observable<QuestionSetTable[]> {
    return this.http.get<QuestionSetTable[]>(`${this.api.QuestionSets()}/getQuestionSetTable`);
  }

  public getQuestionSetTableByModuleId(moduleId: string): Observable<QuestionSetTable[]> {
    return this.http.get<QuestionSetTable[]>(`${this.api.QuestionSets()}/getQuestionSetTableByModuleId/${moduleId}`);
  }

  public SaveModuleQuestionSet(ModuleQuestionSet: ModuleQuestionSet): Observable<any> {
    return this.http.post<any>(`${this.api.QuestionSets()}/SaveModuleQuestionSet/`, ModuleQuestionSet);
  }

  public DeleteModuleQuestionSet(id: string): Observable<any> {
    return this.http.post<any>(`${this.api.QuestionSets()}/DeleteModuleQuestionSet/${id}`, null);
  }
}
