import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { CompanyDetailsRequestType, DataTableAbstract } from '../../../shared/abstract-classes/data-table-abstract/data-table-abstract';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../../core/services/search/search.service';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { DataRequestTypeEnum } from '../../../shared/enums/data-request-type-enum';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { AccountService } from '../../../core/services/account/account.service';
import { AlertsService } from '../../../core/services/alerts/alerts.service';
import { PermissionsMap } from 'src/app/shared/maps/permissions-map';
import { PermissionsEnum } from 'src/app/shared/enums/permissions-enum';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ProductCurrentMembership } from 'src/app/core/open-api/model/product-current-membership.model';
import { AccountService as AccountHttpService } from 'src/app/core/open-api/api/account.service';

@Component({
  selector: 'app-product-currentMembership',
  templateUrl: './product-currentMembership.component.html',
  styleUrls: ['./product-currentMembership.component.scss'],
})
export class ProductCurrentMembershipComponent extends DataTableAbstract implements OnInit {
  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute,
    private readonly accountHttpService: AccountHttpService
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );
  }

  async ngOnInit(): Promise<void> {
    this.dataRequestType = DataRequestTypeEnum.Products;
    this.displayedColumns = [
      'select',
      'name',
      'bundle',
      'membershipExpiry',
      'estRevenue',
      'paymentAmount',
      'paymentReceiptNumber',
      'paymentDate',
      'created',
    ];
    this.columnNames = [
      '',
      'Name',
      'Bundle',
      'Membership Expiry',
      'Est Revenue',
      'Payment Amount',
      'Payment Receipt Number',
      'Payment Date',
      'Created',
    ];
    await this.getCompanyId().then((companyDetails: CompanyDetailsRequestType) => {
      void this.retrieveDataRequest();
    });
    super.ngOnInit();
  }

  get readOnly(): boolean {
    return true;
  }

  public showHideWithdrawButton$(): Observable<boolean> {
    const permissionId = PermissionsMap.get(PermissionsEnum.WithdrawAssessment);
    return this.permissionsService.authenticateUser$(permissionId);
  }

  public withdrawClick(): void {
    const selected = this.selection.selected[0] as unknown as ProductCurrentMembership[];

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Withdraw ?',
        question: 'Do you want to withdraw this opportunity and associated assessments ?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.accountHttpService.withdrawOpportunities(selected.map((p) => p.opportunityId)).subscribe();
      }
    });
  }
}
