/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface WorkCategory {
  id?: string | null;
  parentId?: number;
  workCode?: number;
  workType?: string | null;
  subHeader?: string | null;
  name?: string | null;
  chasSectors?: Array<string> | null;
  matchesAccountVariant?: boolean;
  proClass?: string | null;
  cpv?: string | null;
  isActive?: boolean;
  isSelected?: boolean;
  segment?: number;
  segmentTitle?: string | null;
  family?: number;
  familyTitle?: string | null;
  _class?: number;
  classTitle?: string | null;
  createdUtc?: string;
  updatedUtc?: string | null;
}
