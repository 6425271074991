<div class="modal-header">
  <h4 class="modal-title pull-left">Business Summary</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ contractorDetails.summary }}</p>
</div>
