import { Injectable } from '@angular/core';
import { Trade } from '../../open-api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Environment, IEnvironment } from '../../../shared/classes/environment';

@Injectable({
  providedIn: 'root'
})
export class TradeService {
  private config: IEnvironment

  constructor(private readonly httpService: HttpClient) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  public deleteTrade$(trade: Trade): Observable<any> {
    return this.httpService.delete('', { body: trade });
  }
}
