import { createAction, props } from "@ngrx/store";
import { AssessorInfo, Contact } from "src/app/shared";
import { AssessorSummary } from "../effects/assessor.effects";
import { AssessorCreateOrUpdateCommand } from "../../services/account/AssessmentHttpService";

export enum AssessorActions {
  List = "[Assessor] List",
  ListSuccess = "[Assessor] List Successful",
  ListError = "[Assessor] List Error",
  Load = "[Assessor] Load",
  LoadSuccess = "[Assessor] Load Successful",
  LoadError = "[Assessor] Load Error",
  Save = "[Assessor] Save",
  SaveSuccess = "[Assessor] Save Success",
  SaveError = "[Assessor] Save Error",
}

// List
export const AssessorList = createAction(AssessorActions.List);
export const AssessorListSuccess = createAction(
  AssessorActions.ListSuccess,
  props<{ assessors: AssessorSummary[] }>()
);
export const AssessorListError = createAction(
  AssessorActions.ListError,
  props<{ error: string }>()
);

// Load
export const AssessorLoad = createAction(
  AssessorActions.Load,
  props<{ assessorId: string }>()
);
export const AssessorLoadSuccess = createAction(
  AssessorActions.LoadSuccess,
  props<{ assessor: AssessorInfo }>()
);
export const AssessorLoadError = createAction(
  AssessorActions.LoadError,
  props<{ error: string }>()
);

// Save
export const AssessorSave = createAction(
  AssessorActions.Save,
  props<{ command: AssessorCreateOrUpdateCommand }>()
);
export const AssessorSaveSuccess = createAction(
  AssessorActions.SaveSuccess,
  props<{ assessor: Contact }>()
);
export const AssessorSaveError = createAction(
  AssessorActions.SaveError,
  props<{ error: string }>()
);
