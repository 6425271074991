/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CurrentUserBundle {
  bundleName?: string | null;
  bundleId?: string | null;
  bundleExpiry?: string | null;
  numberOfDirectEmployees?: number | null;
  numberOfLabourOnlySubcontractors?: number | null;
  numEmployees?: number | null;
  numberOfBonaFideSubcontractors?: number | null;
  variant?: number;
  productAssessmentType?: number | null;
  referenceCode?: any | null;
}
