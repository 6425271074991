<footer class="footer">
  <div>&copy; CHAS 2013 Ltd. All rights reserved {{ currentYear }}.</div>
  <div>
    <a>Build Version: {{ buildVersion }}</a>
  </div>
  <div>
    Need help?
    <a href="mailto:{{email}}">
      <i class="fa fa-envelope"></i> {{ email }}
    </a>
    <a href="tel:{{telephone}}">
      <i class="fa fa-phone"></i> {{ telephone }}
    </a>
  </div>
</footer>;
