import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  AccreditationSummaryLoad,
  AccreditationSummaryLoadError,
  AccreditationSummaryLoadSuccess,
  AccreditationSummarySave,
  AccreditationSummarySaveError,
  AccreditationSummarySaveSuccess,
} from '../actions/AccreditationSummary.actions';
import { AccountHttpService } from '../../services/account/account-http.service';

@Injectable()
export class AccreditationSummaryEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccreditationSummaryLoad),
      mergeMap((action) =>
        this.accountService.getAccreditationSummary(action.accountId).pipe(
          map((b) => AccreditationSummaryLoadSuccess({ accreditationSummary: b })),
          catchError((error) => of(AccreditationSummaryLoadError({ error: error })))
        )
      )
    )
  );
  Save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccreditationSummarySave),
      mergeMap((action) =>
        this.accountService.SaveAccreditationSummary(action.accountId, action.accreditationSummary).pipe(
          map((b) => AccreditationSummarySaveSuccess()),
          catchError((error) => of(AccreditationSummarySaveError({ error: error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private accountService: AccountHttpService) {}
}
