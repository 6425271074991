import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentUserState } from '../reducers/CurrentUser.reducer';

export const selectCurrentUserFeatureState = createFeatureSelector<CurrentUserState>('users');

export const selectCurrentUser = createSelector(selectCurrentUserFeatureState, (state) => state);

export const selectCurrentUserCurrentAccount = createSelector(selectCurrentUserFeatureState, (state) => state.currentAccount);

export const selectCurrentUserBundles = createSelector(selectCurrentUserFeatureState, (state) => state.bundles);

export const selectCurrentUserCompanyDetails = createSelector(selectCurrentUserFeatureState, (state) => state.companyDetails);

export const selectCurrentUserPermissions = createSelector(selectCurrentUserFeatureState, (state) => state.permissions);

export const selectCurrentUserAccountId = createSelector(selectCurrentUserFeatureState, (state) => state?.currentAccount?.accountId);

export const selectCurrentUserContactId = createSelector(selectCurrentUserFeatureState, (state) => state?.currentAccount.currentContactId);

export const selectCurrentUserDefaultRoute = createSelector(selectCurrentUserFeatureState, (state) => state?.opsPortalDefaultRoute);

export const selectCurrentUserAccountType = createSelector(selectCurrentUserFeatureState, (state) => state.currentAccount.accountName);

export const selectCurrentUserContactData = createSelector(selectCurrentUserFeatureState, (state) => state.contacts[0]);

export const selectCurrentUserName = createSelector(
  selectCurrentUserFeatureState,
  (state) => state?.currentAccount?.currentContactFullName
);
