<div class="panel">
    <div class="panel__content">
        <div *ngIf="formGroup" [formGroup]="formGroup">
            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Organisation Type</mat-label>
                </div>
                <mat-form-field appearance="fill">
                    <input formControlName="organisationTypeId" matInput [readonly]="readonly" />
                </mat-form-field>
            </div>

            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Immediate Parent Company</mat-label>
                </div>
                <div class="form-field__input">
                    <div>
                        <mat-form-field appearance="fill">
                            <input formControlName="immediateParentCompanyName" matInput [readonly]="readonly" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Customer Type</mat-label>
                </div>
                <mat-form-field appearance="fill">
                    <input formControlName="customerTypeId" matInput [readonly]="readonly" />
                </mat-form-field>

            </div>

            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Previous Business Name</mat-label>
                </div>
                <div class="form-field__input">
                    <div>
                        <mat-form-field appearance="fill">
                            <input formControlName="previousBusinessName" matInput [readonly]="readonly" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div disabled="disabled" class="panel__footer">
        <button mat-raised-button color="primary" (click)="initializeCreditChecks()">
            <i class="fas fa-credit-card"></i>
            Initialize Credit Checks
        </button>
    </div>
    <div *ngIf="loading" class="panel__loading">
        <app-loading-spinner></app-loading-spinner>
    </div>
</div>