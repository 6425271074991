/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AccountViewModel {
  contactId?: string | null;
  accountId?: string | null;
  accountName?: string | null;
  fullName?: string | null;
  userType?: string | null;
  address?: string | null;
  membershipId?: string | null;
  membershipTypeId?: string | null;
  position?: string | null;
  aquipaUserKey?: string | null;
  tikforceUserKey?: string | null;
  thirdPartyCasProvider?: string | null;
  casDataSharingEnabled?: boolean;
  acceptedTermsAndConditions?: string | null;
  accreditationExpiry?: string | null;
  membershipExpiry?: string | null;
  incompleteAssessments?: number;
  companyHouseNo?: string | null;
  distance?: number;
  currentContactId?: string | null;
  currentContactFullName?: string | null;
  isActive?: boolean;
}
