import { createAction, props } from '@ngrx/store';
import { BundleType } from '../../open-api/model/bundle-type.model';
import { BundleTypeUpdateCommand } from '../../open-api/model/bundle-type-update-command.model';

export enum BundleTypeActions {
  List = '[Bundle Type] List',
  ListSuccess = '[Bundle Type] List Successful',
  ListError = '[Bundle Type] List Error',
  Load = '[Bundle Type] Load',
  LoadSuccess = '[Bundle Type] Load Successful',
  LoadError = '[Bundle Type] Load Error',
  Save = '[Bundle Type] Save',
  SaveSuccess = '[Bundle Type] Save Success',
  SaveError = '[Bundle Type] Save Error',
}

// List
export const BundleTypeList = createAction(BundleTypeActions.List);
export const BundleTypeListSuccess = createAction(BundleTypeActions.ListSuccess, props<{ bundleTypes: BundleType[] }>());
export const BundleTypeListError = createAction(BundleTypeActions.ListError, props<{ error: string }>());

// Load
export const BundleTypeLoad = createAction(BundleTypeActions.Load, props<{ bundleTypeId: string }>());
export const BundleTypeLoadSuccess = createAction(BundleTypeActions.LoadSuccess, props<{ bundleType: BundleType }>());
export const BundleTypeLoadError = createAction(BundleTypeActions.LoadError, props<{ error: string }>());

// Save
export const BundleTypeSave = createAction(BundleTypeActions.Save, props<{ command: BundleTypeUpdateCommand }>());
export const BundleTypeSaveSuccess = createAction(BundleTypeActions.SaveSuccess, props<{ bundleType: BundleType }>());
export const BundleTypeSaveError = createAction(BundleTypeActions.SaveError, props<{ error: string }>());
