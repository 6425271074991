export interface AssessorInfo {
  statusCode: number;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  assessorType: string;
  assessorTypeId: number;
  userGroupId: string;
  accountId: {
    uid: string;
  };
  properties: {
    assessorProperties: {
      automatedQARule: number;
      qaRulePercentage: number;
    };
  };
}

export enum AccountStatus {
  Valid = 0,
  Invalid = 1,
}
