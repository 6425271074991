import { createAction, props } from "@ngrx/store";
import { ModuleTable } from "src/app/shared/models/ModuleTable";

export enum ModuleTableActions {
  Load = "[Module Table] Load",
  LoadSuccess = "[Module Table] Load Successful",
  LoadError = "[Module Table] Load Error",
  Create = "[Module Create] Create",
}

export const ModuleTableLoad = createAction(
  ModuleTableActions.Load,
  props<{ accountId: string }>()
);
export const ModuleTableLoadSuccess = createAction(
  ModuleTableActions.LoadSuccess,
  props<{ modules: ModuleTable[] }>()
);
export const ModuleTableLoadError = createAction(
  ModuleTableActions.LoadError,
  props<{ error: string }>()
);

export const ModuleCreateSuccess = createAction(
  ModuleTableActions.Create,
  props<{ module: ModuleTable }>()
);
