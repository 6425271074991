import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { ExpiryFilter } from '../../enums/expiry-filter';
import { ProgressCard } from '../progress-card';
import { ProgressCardData } from './progress-card-data';

export class CompletedAssessmentsCard extends ProgressCard {
  constructor(progressCardData: ProgressCardData) {
    super();
    this.header = 'Completed assessments';
    this.icon = 'fa-check';
    this.stats = [
      {
        label: 'Total',
        value: progressCardData.count,
        cssClass: 'progress-success',
        apiRequestType: ApiRequestTypes.AssessorDashboardCompleted,
        expiryFilter: ExpiryFilter.none,
      },
    ];
  }
}
