import { Component } from '@angular/core';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { Observable } from 'rxjs';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';
import { selectContractorAccountInsurance } from '../../../../core/ngrx/selectors/contractor-account.selectors';
import { Store } from '@ngrx/store';
import { InsuranceDetails } from '../../../../shared';

@Component({
  selector: 'app-insurance-grid',
  templateUrl: './contractor-account-insurance-grid.component.html',
  styleUrls: ['./contractor-account-insurance-grid.component.scss'],
})
export class ContractorAccountInsuranceGrid {
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum;

  public insuranceList$: Observable<InsuranceDetails[]> = this.store.select(selectContractorAccountInsurance);
  constructor(private readonly permissionsService: PermissionsService, private readonly store: Store) {}

  public authenticatePermissions$(permissionID: PermissionsEnum): Observable<boolean> {
    const permissionId: string = PermissionsMap.get(permissionID);
    return this.permissionsService.authenticateUser$(permissionId);
  }
}
