export default class SurveyValidators {
  static validateMajorFail(params: any): boolean {
    try {
      const answerValue: string = params[0];
      const failValues: Array<string> = params[1];

      const valueIsNotFail = failValues.indexOf(answerValue) === -1;

      return valueIsNotFail;
    } catch (_) {
      return true;
    }
  }
}
