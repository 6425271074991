import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContractorAccountState } from '../reducers/contractor-account.reducer';

export const selectContractorAccountFeatureState = createFeatureSelector<ContractorAccountState>('contractorAccount');
export const selectContractorAccountContact = createSelector(selectContractorAccountFeatureState, (state) => state.contact);
export const selectContractorAccountAddress = createSelector(selectContractorAccountFeatureState, (state) => state.address);
export const selectContractorAccountMembership = createSelector(selectContractorAccountFeatureState, (state) => state.membership);
export const selectContractorAccountCompanyDetails = createSelector(selectContractorAccountFeatureState, (state) => state.companyDetails);
export const selectContractorAccountWorkRegions = createSelector(selectContractorAccountFeatureState, (state) => state.workRegions);
export const selectContractorAccountEmployee = createSelector(selectContractorAccountFeatureState, (state) => state.employee);
export const selectContractorAccountTimeline = createSelector(selectContractorAccountFeatureState, (state) => state.timeline);
export const selectContractorAccountId = createSelector(selectContractorAccountFeatureState, (state) => state.accountId);
export const selectContractorAccountName = createSelector(selectContractorAccountFeatureState, (state) => state.summary?.name);
export const selectContractorAccountSummary = createSelector(selectContractorAccountFeatureState, (state) => state.summary);
export const getLoaded = createSelector(selectContractorAccountFeatureState, (state) => state.loaded);
export const selectContractorAccountWorkCategories = createSelector(selectContractorAccountFeatureState, (state) => state.workCategories);
export const selectContractorAccountDistanceToOffice = createSelector(
  selectContractorAccountFeatureState,
  (state) => state.distanceToOffice
);
export const selectContractorAccountCompany = createSelector(selectContractorAccountFeatureState, (state) => state.company);
export const selectContractorAccountInsurance = createSelector(selectContractorAccountFeatureState, (state) => state.insurance);
export const selectContractorAccountBusinessSummary = createSelector(
  selectContractorAccountFeatureState,
  (state) => state.employee.businessSummary
);
export const selectContractorAccountTabIndex = createSelector(selectContractorAccountFeatureState, (state) => state.tabIndex);
