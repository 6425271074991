<div class='tradeContainer'>
	<div class='detailsContainer'>
		<div>
			<mat-card>
				<mat-card-header>
					<mat-card-title>Primary Work Category</mat-card-title>
				</mat-card-header>
				<mat-card-subtitle>
					<p>Brick Laying</p>
				</mat-card-subtitle>
			</mat-card>
		</div>

		<div>
			<mat-card>
				<mat-card-header>
					<mat-card-title>Business Summary</mat-card-title>
				</mat-card-header>
				<mat-card-subtitle>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.Aspernatur assumenda commodi culpa
						debitis
						inventore nam possimus praesentium quibusdam quidem reiciendis.</p>
				</mat-card-subtitle>
			</mat-card>
		</div>
	</div>
	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
		<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
			<mat-checkbox></mat-checkbox>
			{{node.name}}
		</mat-tree-node>
		<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
			<div class="mat-tree-node">

				<button mat-icon-button matTreeNodeToggle
						[attr.aria-label]="'Toggle ' + node.name">
					<mat-icon class="mat-icon-rtl-mirror">
						{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
					</mat-icon>
				</button>
				<mat-checkbox></mat-checkbox>
				{{node.name}}
			</div>
			<div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
				 role="group">
				<ng-container matTreeNodeOutlet></ng-container>
			</div>
		</mat-nested-tree-node>
	</mat-tree>
</div>

