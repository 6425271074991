import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs';
import {
  ContactSummaryLoad,
  ContactSummaryLoadError,
  ContactSummaryLoadSuccess,
  ContactSummarySave,
  ContactSummarySaveError,
  ContactSummarySaveSuccess,
} from '../actions/ContactSummary.actions';
import { AccountService } from '../../open-api';

@Injectable()
export class ContactSummaryEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactSummaryLoad),
      mergeMap((action) =>
        this.accountService.getContactSummary(action.accountId).pipe(
          map((b) => ContactSummaryLoadSuccess({ contactSummary: b })),
          catchError((error) => of(ContactSummaryLoadError({ error: error })))
        )
      )
    )
  );
  Save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactSummarySave),
      mergeMap((action) =>
        this.accountService.saveContactSummary(action.accountId, action.contactSummary).pipe(
          map((b) => ContactSummarySaveSuccess()),
          catchError((error) => of(ContactSummarySaveError({ error: error })))
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly accountService: AccountService) {}
}
