import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  ProductAssessmentsTableLoad,
  ProductAssessmentsTableLoadError,
  ProductAssessmentsTableLoadSuccess,
} from "../actions/productAssessmentsTable.actions";
import { AssessmentHttpService } from "../../services/account/AssessmentHttpService";

@Injectable()
export class ProductAssessmentsTableEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductAssessmentsTableLoad),
      mergeMap((action) =>
        this.accountService.getProductAssessmentsTable(action.accountId).pipe(
          map((b) =>
            ProductAssessmentsTableLoadSuccess({
              assessments: b,
            })
          ),
          catchError((error) => of(ProductAssessmentsTableLoadError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AssessmentHttpService
  ) {}
}
