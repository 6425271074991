import { FeatureFlag } from "../../services/feature-flags/feature-flag";
import { createReducer, on } from '@ngrx/store';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  AccountContact,
  CheckNewAuthenticationResponse,
  CompanyDetails,
  ContactRole,
  LatestBundleResponse,
  PriceList,
} from 'src/app/shared/models/CheckNewAuthenticationResponse';
import * as CurrentUserActions from '../actions/currentUser.Actions';
import * as AppState from '../../../State';

export interface State extends AppState.State {
  currentUser: CurrentUserState;
}

export interface CurrentUserState {
  loaded: boolean;
  allAccounts: CheckNewAuthenticationResponse[];
  currentAccount: CheckNewAuthenticationResponse;
  contacts: AccountContact[];
  roles: ContactRole[];
  companyDetails: CompanyDetails;
  bundles: LatestBundleResponse[];
  priceLists: PriceList[];
  isFreeMembership: boolean;
  featureFlags: FeatureFlag[];
  permissions: ApplicationPermission[];
  opsPortalDefaultRoute: OpsPortalRoute;
}

export const initialState: CurrentUserState = {
  loaded: false,
  allAccounts: [],
  currentAccount: undefined,
  contacts: [],
  roles: [],
  companyDetails: undefined,
  bundles: [],
  priceLists: [],
  isFreeMembership: false,
  featureFlags: [],
  permissions: [],
  opsPortalDefaultRoute: {
    id: '',
    name: '',
  },
};

export const reducer = createReducer(
  initialState,
  on(CurrentUserActions.loadCurrentUser, (state) => state),
  on(CurrentUserActions.loadCurrentUserSuccess, (state, action) => {
    return {
      ...state,
      ...action.data,
      loaded: true,
    };
  }),
  on(CurrentUserActions.loadCurrentUserFailure, (state, action) => state),
  on(CurrentUserActions.storeCurrentUser, (state, action) => {
    return {
      ...state,
      ...action.currentUser,
    };
  })
);

export function CurrentUserReducer(state, action) {
  return reducer(state, action);
}

export type OpsPortalRoute = {
  id: string;
  name: string;
};
