<div mat-dialog-content>
  <p>Change Status</p>

  <mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select [(ngModel)]="selectedStatus">
      <mat-option value="Submitted">Submitted</mat-option>
      <mat-option value="Assessment Accepted">Assessment Accepted</mat-option>
      <mat-option value="Failed">Failed</mat-option>
      <mat-option value="Additional Information Required">Additional Information Required</mat-option>
      <mat-option value="Complete">Complete</mat-option>
      <mat-option value="Submitted - Awaiting Others">Submitted - Awaiting Others</mat-option>
      <mat-option value="Complete - Awaiting Others">Complete - Awaiting Others</mat-option>
      <mat-option value="Resubmitted - Awaiting Others">Resubmitted - Awaiting Others</mat-option>
      <mat-option value="Fail Resubmit">Fail Resubmit</mat-option>
      <mat-option value="Resubmitted">Resubmitted</mat-option>
      <mat-option value="In Progress">In Progress</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <mat-form-field appearance="fill" *ngFor="let assessorsof assessors">
      <mat-label>{{asessmentsFilter.name}}</mat-label>
      <mat-select [(value)]="asessmentsFilter.defaultValue" (selectionChange)="applyAsessmentsFilter($event,asessmentsFilter)">
        <mat-option *ngFor="let op of asessmentsFilter.options" [value]="op">
          {{op}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    (click)="changeAssessmentStatus()"
    [mat-dialog-close]="data.status"
    cdkFocusInitial
  >
    Change
  </button>
</div>
