import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectContractorAccountAddress,
  selectContractorAccountCompany,
  selectContractorAccountCompanyDetails,
  selectContractorAccountEmployee,
  selectContractorAccountMembership,
  selectContractorAccountWorkRegions,
} from '../../../../core/ngrx/selectors/contractor-account.selectors';

@Component({
  selector: 'app-company-details-tab',
  templateUrl: './company-details-tab.component.html',
  styleUrls: ['./company-details-tab.component.scss'],
})
export class CompanyDetailsTabComponent implements OnInit {
  public companySummary$ = this.store.select(selectContractorAccountCompany);
  public address$ = this.store.select(selectContractorAccountAddress);
  public businessDetails$ = this.store.select(selectContractorAccountCompanyDetails);
  public membershipSummary$ = this.store.select(selectContractorAccountMembership);
  public companySummaryTwo$ = this.store.select(selectContractorAccountEmployee);
  public workAreas$ = this.store.select(selectContractorAccountWorkRegions);

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
