import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AssessmentSummary } from "../../../shared";
import { Subscription } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { AssessmentService } from "src/app/core/services/assessment/assessment.service";

@Component({
  selector: "status-change-modal.component",
  templateUrl: "./status-change-modal.component.html",
})
export class StatusChangeDialog {
  assessments: AssessmentSummary[];
  assessorsSubscription: Subscription;
  selectedStatus: string;
  @Output() newItemEvent = new EventEmitter<string>();
  refresh: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<StatusChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private assessmentService: AssessmentService
  ) {}

  ngOnInit(): void {
    this.assessments = this.data.selection.selected;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeAssessmentStatus(): void {
    this.assessmentService
      .updateAssessmentsStatus$(
        this.assessments.map((a) => a.InternalId),
        this.selectedStatus
      )
      .subscribe(() => this.dialogRef.close());
  }
}

export interface DialogData {
  status: string;
  selection: SelectionModel<AssessmentSummary>;
}
