import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AssessmentSummary } from "../../../shared";
import { Subscription } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { AssessmentService } from "src/app/core/services/assessment/assessment.service";

@Component({
  selector: "expiry-date-change-modal.component",
  templateUrl: "./expiry-date-change-modal.component.html",
})
export class ExpiryDateChangeDialog {
  assessments: AssessmentSummary[];
  assessorsSubscription: Subscription;
  expiryDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ExpiryDateChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private assessmentService: AssessmentService
  ) {}

  ngOnInit(): void {
    this.assessments = this.data.selection.selected;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeAssessmentExpiryDate(): void {
    this.assessmentService
      .updateAssessmentsExpiryDate(
        this.assessments.map((a) => a.InternalId),
        this.expiryDate
      )
      .subscribe(() => this.dialogRef.close());
  }
}

export interface DialogData {
  expiryDate: string;
  selection: SelectionModel<AssessmentSummary>;
}
