import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssessmentBreadcrumbsComponent } from "./assessment-breadcrumbs.component";
import { RouterModule } from "@angular/router";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import {AngularMaterialModule} from "../../../core/angular-material.module";

@NgModule({
  declarations: [AssessmentBreadcrumbsComponent],
  exports: [AssessmentBreadcrumbsComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    AngularMaterialModule,
  ],
})
export class AssessmentBreadcrumbsModule {}
