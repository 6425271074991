import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { SearchArgs, AssessmentSummary, Product } from '../../../shared';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AccountSelector } from '../account-selection/account-selector';
import { AssessmentService } from '../assessment/assessment.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

enum ProductOptions {
  HealthAndSafety = 'Health & Safety',
  Environmental = 'Environmental',
  Quality = 'Quality'
}

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy {
  private config: IEnvironment;
  private currentAccount: any;
  private readonly resultsUpdate: Subject<AssessmentSummary[]>;
  private selectedCard: string;
  private selectedCardLabel: string;

  constructor(
    private readonly http: HttpClient,
    private readonly accountSelectorService: AccountSelector,
    private readonly assessmentService: AssessmentService
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
    this.resultsUpdate = new Subject();
    this.accountSelectorService.getSubject().subscribe((x) => {
      this.currentAccount = x.id;
    });
  }

  ngOnDestroy(): void {
    this.currentAccount.unsubscribe();
  }

  public getProducts(): Observable<Product[]> {
    return of([
      {
        id: ProductOptions.HealthAndSafety,
        name: ProductOptions.HealthAndSafety,
      } as Product,
      {
        id: ProductOptions.Environmental,
        name: ProductOptions.Environmental,
      } as Product,
      {
        id: ProductOptions.Quality,
        name: ProductOptions.Quality,
      } as Product,
    ]);
  }

  public setSelectedCard(name: string, label: string): void {
    this.selectedCard = name;
    this.selectedCardLabel = label;
  }

  public getSelectedProgressCard(): string {
    return this.selectedCard;
  }

  public getSelectedCardLabel(): string {
    return this.selectedCardLabel;
  }

  private getListOfAssessments(assessmentResponse: any): AssessmentSummary[] {
    const assessmentDetails: AssessmentSummary[] = [];
    assessmentResponse.forEach((assessment) => {
      assessmentDetails.push({
        Id: assessment.assessmentNumber,
        AssessmentName: assessment.fullAssessmentName ?? assessment.assessmentName,
        ContractorName: assessment.contractorName,
        ContractorId: assessment.accountID,
        InternalId: assessment.assessmentID,
        Product: assessment.productName,
        Submissiondate: assessment.dateSubmitted,
        Status: assessment.status ?? assessment.status.label,
        CreatedDate: assessment.created,
        EnterDate: assessment.dateEntered,
        ExpiryDate: assessment.expiryDate,
        TargetDecisionDate: assessment.targetDecisionDate,
        DateRequiredToRespond: assessment.dateRequiredToRespond,
        ResubmissionDate: assessment.resubmissionDate,
        DateReturnedToAssessor: assessment.dateReturnedToAssessor,
        DecisionDate: assessment.decisionDate,
        NoOfEmployees: assessment.numberOfEmployees,
        FastTrack: assessment.fastTrack,
        AssessmentType: assessment.assessmentTypeLabel,
        Variant: assessment.variantLabel,
        AssignedAssessor: assessment.assessorName,
        AssignedAssessorId: assessment.assessorID,
        IncompleteSections: assessment.incompleteSections,
        AverageAccreditedLevel: assessment.averageAccreditedLevel,
        DateAssigned: assessment.dateAssigned,
        Validity: assessment.validForRenewal,
        FailureReason: assessment.failureReason,
      });
    });
    return assessmentDetails;
  }

  public updateAssessmentResults(assessmentSummary: AssessmentSummary[]): void {
    this.resultsUpdate.next(assessmentSummary);
    this.assessmentService.storeValues(assessmentSummary);
  }

  public searchForResults(searchArgs: SearchArgs): Observable<AssessmentSummary[]> {
    if (searchArgs === undefined) {
      return of([]);
    }
    let assessmentSummaries: AssessmentSummary[] = [];

    if (searchArgs.assessmentState !== undefined) {
      return this.assessmentService.getAssessmentsCategories();
    }

    if (searchArgs.assessorId !== undefined) {
      assessmentSummaries = assessmentSummaries.filter((x) => x.AssignedAssessorId === searchArgs.assessorId);
    }

    if (searchArgs.productOption !== undefined) {
      assessmentSummaries = assessmentSummaries.filter((x) => {
        const productOption = searchArgs.productOption;
        if (productOption == '870520003') return x.Product.startsWith('Supplier');
        else return x.Product === productOption;
      });
    }

    if (searchArgs.dateOption !== undefined) {
      assessmentSummaries = this.getAssessmentsBasedOnDates(assessmentSummaries, searchArgs.dateOption);
    }

    if (searchArgs.fastTrack !== undefined && searchArgs.fastTrack) {
      assessmentSummaries = assessmentSummaries.filter((x) => x.FastTrack === searchArgs.fastTrack);
    }

    return of(assessmentSummaries);
  }

  private getAssessmentsBasedOnDates(assessmentSummaries: AssessmentSummary[], dateOption: string): AssessmentSummary[] {
    let startDate: Date;
    let endDate: Date;
    const now = new Date();
    switch (dateOption) {
      case 'Month to date':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = now;
        return assessmentSummaries.filter((x) => x.DecisionDate > startDate && x.DecisionDate < endDate);
      case 'Year to date':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = now;
        return assessmentSummaries.filter((x) => x.DecisionDate > startDate && x.DecisionDate < endDate);
      case 'Last month':
        const year = new Date().getFullYear();
        startDate = new Date(year, now.getMonth() - 1, 1);
        endDate = new Date(year, now.getMonth(), 1);
        return assessmentSummaries.filter((x) => x.DecisionDate > startDate && x.DecisionDate < endDate);
      case 'Last year':
        startDate = new Date(now.getFullYear() - 1, 0, 1);
        endDate = new Date(now.getFullYear(), 0, 1);
        return assessmentSummaries.filter((x) => x.DecisionDate > startDate && x.DecisionDate < endDate);
      default:
        return assessmentSummaries;
    }
  }

  public resultUpdateSubscription(): Subject<AssessmentSummary[]> {
    return this.resultsUpdate;
  }
}
