<div class="panel">
    <div class="panel__content" >
    <div class="panel__form" *ngIf="formGroup" [formGroup]="formGroup">
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Registered Address</mat-label>
        </div>
        <div class="form-field__input">
          <mat-form-field appearance="fill">
                            <input formControlName="lineOne" matInput placeholder="Address" [readonly]="!editing"/>
          </mat-form-field>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label></mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input matInput formControlName="lineTwo" placeholder="Address" [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label></mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="cityId" matInput placeholder="City" [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label></mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="countyId" matInput placeholder="County/State"  [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label></mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
              <input formControlName="postcode" matInput placeholder="Zip/Postcode" [readonly]="!editing" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label></mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
              <input formControlName="countryId" matInput placeholder="Country" [readonly]="!editing" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel__footer" *ngIf="displayActionButtons">
    <app-panel-action-buttons
      (buttonStateEmitter)="editing = $event"
      [loading]="loading"
      (submitEmitter)="submit()"
      [editState]="editing"
    ></app-panel-action-buttons>
  </div>
  <div *ngIf="loading" class="panel__loading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
