<h2>Contact</h2>
<div *ngIf="isLoading; else displayForm">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #displayForm>
    <form [formGroup]="detailsForm" class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-between">
            <mat-form-field class="w-50 mr-1">
                <mat-label>First name</mat-label>
                <input matInput type="text" formControlName="firstName" />
                <mat-error>First name is required</mat-error>
            </mat-form-field>
            <mat-form-field class="w-50 ml-1">
                <mat-label>Last name</mat-label>
                <input matInput type="text" formControlName="lastName" />
                <mat-error>Last name is required</mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex flex-row justify-content-between">
        <mat-form-field class="w-50 mr-1">
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="email" />
            <mat-error>Email is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-50 ml-1">
            <mat-label>Mobile Phone</mat-label>
            <input matInput type="text" formControlName="mobilePhone" />
        </mat-form-field>
        </div>
        <div class="d-flex flex-row justify-content-between">
            <mat-form-field class="w-50 mr-1">
                <mat-label>Position</mat-label>
                <input matInput type="text" formControlName="position" />
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
            <button color="secondary" mat-raised-button class="mr-2" [routerLink]="['/assessment/clients', routeParams.clientId, 'contacts']">Cancel</button>
            <button color="primary" mat-raised-button type="button" (click)="onSave()" >
                Save
            </button>
        </div>
    </form>
</ng-template>