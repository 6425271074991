import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AssessmentSummary } from 'src/app/shared';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  constructor(private documentsService: DocumentsService, private assessmentService: AssessmentService, private route: ActivatedRoute) {}

  @ViewChild('documentSearch') searchInput: ElementRef;

  isLoading: boolean;
  assessmentInitStatusSub: Subscription;
  selectedAssessment: AssessmentSummary;
  selectedTab: string;

  columns = [
    { prop: 'product', name: 'Product' },
    { prop: 'type', name: 'Document Type' },
    { prop: 'name', name: 'File Name' },
    { prop: 'expiryDate', name: 'Expiry Date' },
    { prop: 'uploadDate', name: 'Date Uploaded' },
  ];

  currentDocuments = [];
  previousDocuments = [];

  filteredCurrentDocuments = [];
  filteredPreviousDocuments = [];

  allDocumentsUrl: string = undefined;
  config: IEnvironment;

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();

    this.isLoading = true;
    const assessmentId = this.route.snapshot.params.id;
    this.assessmentService.selectAssessment(assessmentId);
    this.selectedTab = 'Current Documents';

    this.assessmentInitStatusSub = this.assessmentService.initialisedStatus.subscribe((isInitialised) => {
      if (!isInitialised) {
        return;
      }
      this.selectedAssessment = this.assessmentService.getAssessmentSummary(assessmentId);
      // this.allDocumentsUrl = `${this.config.assessorApi.documents}api/assessment/downloaddocumentszip/${this.selectedAssessment.ContractorId}?assessmentId=${this.selectedAssessment.InternalId}`; COME BACK TO, URL LOOKS DEAD
      this.documentsService
        .getAssessmentDocuments(this.selectedAssessment.ContractorId, this.selectedAssessment.InternalId)
        .subscribe((x) => {
          this.currentDocuments = x[0];
          this.previousDocuments = x[1];

          this.filteredCurrentDocuments = this.currentDocuments;
          this.filteredPreviousDocuments = this.previousDocuments;

          this.isLoading = false;
        });
    });
  }

  onTabSelected($event: TabDirective): void {
    this.selectedTab = $event.heading;
    this.searchInput.nativeElement.value = '';

    this.filteredCurrentDocuments = this.currentDocuments;
    this.filteredPreviousDocuments = this.previousDocuments;

    // this is to fix ngx-datatable blank view issue
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 50);
  }

  runDocumentSearch(document: string): void {
    if (this.selectedTab === 'Current Documents') {
      if (document) {
        this.filteredCurrentDocuments = this.documentsService.searchForDocuments(document, this.currentDocuments);
      } else {
        this.filteredCurrentDocuments = this.currentDocuments;
      }
    } else if (this.selectedTab === "Previous Years' Documents") {
      if (document) {
        this.filteredPreviousDocuments = this.documentsService.searchForDocuments(document, this.previousDocuments);
      } else {
        this.filteredPreviousDocuments = this.previousDocuments;
      }
    }
  }

  get areAnyDocumentsPresent(): boolean {
    return true;
  }

  downloadAllDocuments(): void {
    this.documentsService
      .downloadAllDocumentsForAssessment(this.selectedAssessment.ContractorId, this.selectedAssessment.InternalId)
      .subscribe((x) => {
        this.download(x, 'application/zip', `${this.selectedAssessment.ContractorName}-documents.zip`);
      });
  }

  download(data: any, contentType: string, fileName: string): void {
    const blob = new Blob([data], { type: contentType });

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.style.visibility = 'hidden';
    anchor.click();
    window.URL.revokeObjectURL(url);
    anchor.remove();
  }

  downloadFile(row: any): void {
    const contentUrl =
      row.url.indexOf('downloadfile') > -1 ? this.documentsService.rewriteUrl(this.selectedAssessment.ContractorId, row.url) : row.url;
    this.documentsService.download(contentUrl).subscribe((x) => {
      this.download(x, 'octet/stream', row.name);
    });
  }

  ngOnDestroy(): void {
    if (this.assessmentInitStatusSub) {
      this.assessmentInitStatusSub.unsubscribe();
    }
  }
}
