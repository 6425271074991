import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { ExpiryFilter } from '../../enums/expiry-filter';
import { ProgressCard } from '../progress-card';
import { ProgressCardData } from './progress-card-data';

export class AssessmentToBeAssignedCard extends ProgressCard {
  constructor(progressCardData: ProgressCardData) {
    super();
    this.header = 'Assessments to be assigned';
    this.icon = 'fa-exchange';
    this.stats = [
      {
        label: 'Unassigned',
        value: progressCardData.unassigned,
        cssClass: 'progress-blank',
        apiRequestType: ApiRequestTypes.AssessorDashboardNew,
        expiryFilter: ExpiryFilter.none,
      },
      {
        label: 'Rejected',
        value: progressCardData.rejected,
        cssClass: 'progress-alert',
        apiRequestType: ApiRequestTypes.AssessorDashboardNew,
        expiryFilter: ExpiryFilter.none,
      },
    ];
  }
}
