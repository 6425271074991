import { Pipe, PipeTransform } from '@angular/core';
import { IsCurrencyPipe } from './is-currency.pipe';
import { IsDatePipe } from './is-date.pipe';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dataFormatter',
})
export class DataFormatterPipe implements PipeTransform {
  constructor(private currencyPipe: IsCurrencyPipe, private datePipe: IsDatePipe) {}
  private dateType: typeof DateTime = DateTime;

  transform(value: unknown, columnName: string): unknown {
    switch (columnName) {
      case 'Estimated Revenue':
      case 'Payment Amount':
      case 'Limit':
        return this.currencyPipe.transform(value);
      case 'Date':
      case 'Membership Expiry':
      case 'Created':
      case 'Creation':
      case 'Expiry Date':
      case 'Payment Date':
      case 'Created Date':
      case 'Decision Date':
      case 'Date Fee Entered':
      case 'Date Submitted':
      case 'Submission Date':
        return this.datePipe.transform(value, this.dateType.DATETIME_FULL);
      case 'Old Value':
      case 'New Value':
        const result = (value as string[]).join('');
        return result;
      default:
        return value;
    }
  }
}
