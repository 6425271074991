<div class="d-flex">
    <h2 class="mr-auto">{{this.client?.companyName }} Contacts</h2>
    <button mat-raised-button color="secondary" class="mr-2" [routerLink]="['/assessment', 'clients' ]">Back</button>
    <button mat-raised-button color="primary" [routerLink]="['/assessment/clients', clientId, 'contacts', 'add']">Add</button>
</div>
<div *ngIf="isLoading; else displayTable" class="mt-3">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #displayTable>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="w-100" >
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.fullName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.email }}
                </td>
            </ng-container>
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Position</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.position }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mobilePhone">
                <th mat-header-cell *matHeaderCellDef>Mobile Phone</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.mobilePhone }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button [routerLink]='["/assessment/clients", clientId, "contacts", "edit", row.id]' >
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" role="button"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</ng-template>