import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import {
  WorkCategoriesLoad,
  WorkCategoriesLoadError,
  WorkCategoriesLoadSuccess,
} from "../actions/WorkCategory.actions";
import { WorkCategoryHttpService } from "../../services/account/work-category-http.service";

@Injectable()
export class WorkCategoriesEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkCategoriesLoad),
      mergeMap((action) =>
        this.accountService.getWorkCategoriesList().pipe(
          map((b) =>
            WorkCategoriesLoadSuccess({
              WorkCategories: b,
            })
          ),
          catchError((error) => of(WorkCategoriesLoadError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: WorkCategoryHttpService
  ) {}
}
