import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "businessSize",
})
export class BusinessSizePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    return "small";
  }
}
