import { Injectable } from '@angular/core';
import { UserAuthenticationAbstract } from '../authentication/user-authentication-abstract';
import { ProductivityService } from '../productivity/productivity.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class SiteMenuService {
  constructor(
    private authService: UserAuthenticationAbstract,
    private productivityService: ProductivityService,
    private readonly http: HttpClient,
    private readonly api: ApiRoutesClass
  ) {}

  private visible: boolean;

  get isVisible(): boolean {
    return this.visible;
  }

  toggle(): void {
    this.visible = !this.visible;
  }

  public retrieveApplicationBuildVersion$(): Observable<string> {
    return this.http.get(this.api.Diagnostics(), { responseType: 'text' });
  }
}
