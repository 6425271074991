import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { BundlesService } from 'src/app/core/services/bundles/bundles.service';
import { storeDispatchAsync } from '../../../core/ngrx/store-dispatch-async';
import { ActionsSubject, Store } from '@ngrx/store';
import { CountdownTimerActionsEnum } from '../../../core/ngrx/actions/assessmentCountdownTimer.actions';
import { firstValueFrom, Observable } from 'rxjs';
import { CountdownState } from '../../../core/ngrx/reducers/assessmentCountdownTimer.reducer';
import { getCountdownState } from '../../../core/ngrx/selectors/assessmentCountdownTimer.selectors';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-assessment-page',
  templateUrl: './assessment-page.component.html',
  styleUrls: ['./assessment-page.component.scss'],
})
export class AssessmentPageComponent implements OnInit, OnDestroy {
  isLoading = false;
  errorMessage: string = null;
  assessmentId!: string;
  accountId: string;
  assessmentInitStatusSub: Subscription;
  //@ts-ignore
  public countdownState: Observable<CountdownState> = this.store.select(getCountdownState);
  public today = DateTime.fromISO(DateTime.local().toISODate());
  public assessmentCount = {
    timeRemaining: null,
  };
  public verificationCount = {
    timeRemaining: null,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assessmentService: AssessmentService,
    private bundlesService: BundlesService,
    private readonly store: Store,
    private readonly actions: ActionsSubject
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      await this.loadAssessment(params.id);
      this.clearTimerState();
      await this.loadAssessmentCountdownTimer();
      await this.calculateAssessmentTimer();
    });

    this.assessmentInitStatusSub = this.assessmentService.initialisedStatus.subscribe((isInitialised) => {
      this.isLoading = true;
      if (!isInitialised) {
        return;
      }
    });
  }

  private async loadAssessmentCountdownTimer() {
    await storeDispatchAsync(this.store, this.actions, CountdownTimerActionsEnum.LoadSuccess, {
      type: CountdownTimerActionsEnum.Load,
      accountId: this.accountId,
    });
  }
  private async calculateAssessmentTimer(): Promise<any> {
    const count: CountdownState = await firstValueFrom(this.countdownState);
    if (count?.assessment?.assessmentIds?.includes(this.assessmentId)) {
      this.assessmentCount.timeRemaining = count.assessment.respondByDateTime;
    }
    if (count?.verification?.assessmentIds?.includes(this.assessmentId)) {
      this.verificationCount.timeRemaining = count.verification.respondByDateTime;
    }
  }

  private clearTimerState(): void {
    this.verificationCount = { timeRemaining: null };
    this.assessmentCount = { timeRemaining: null };
  }

  private loadAssessment(assessmentId: string): void {
    this.assessmentId = assessmentId;
    void this.assessmentService.selectAssessment(this.assessmentId);

    const assessmentSummary = this.assessmentService.getAssessmentSummary(this.assessmentId);
    this.accountId = assessmentSummary?.ContractorId;

    this.assessmentService
      .loadSelectedQuestionSetAsync(this.assessmentId)
      .then((success) => (this.errorMessage = success ? null : 'Error: Could not load the assessment.'))
      .catch((error) => console.error(error))
      .finally(() => (this.isLoading = false));
  }

  ngOnDestroy(): void {
    if (this.assessmentInitStatusSub) {
      this.assessmentInitStatusSub.unsubscribe();
    }
  }

  onSuccessfulUpdate(): void {
    // Delay to allow the user time to read the success message.
    setTimeout(() => {
      void this.bundlesService.navigateToNextUrl(this.route, this.router, '/dashboard/assessor');
    }, 1000);
  }
}
