import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserAuthenticationAbstract } from '../../authentication/user-authentication-abstract';
import { ErrorFactory } from '../../errorHandler/error-factory';
import { ApiRoutesClass } from '../../../../shared';
import { AzureUserAuthenticationService } from '../user-authentication/azure-user-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  public static AccessToken: string;

  constructor(
    private authService: AzureUserAuthenticationService,
    private readonly errorFactory: ErrorFactory,
    private readonly api: ApiRoutesClass
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const anonymousEndpoints = [this.api.Diagnostics(), this.api.FeatureFlag(), this.api.featureFlags()];

    if (anonymousEndpoints.includes(request.url)) {
      return next.handle(request);
    } else if (this.authService.isLoggedIn()) {
      return this.authService.acquireToken().pipe(
        switchMap((data: any) => {
          if (data !== null) {
            const authToken = data.accessToken;
            AuthInterceptor.AccessToken = authToken;
            const updatedRequest: HttpRequest<any> = request.clone({
              headers: request.headers.append('Authorization', `Bearer ${authToken}`),
            });
            return next.handle(updatedRequest);
          }
          return next.handle(request);
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorFactory.formatErrorToResponse(error);
          return throwError(() => error);
        })
      );
    } else {
      return throwError(() => 'User not logged in');
    }
  }
}
