import { createAction, props } from '@ngrx/store';
import { CurrentUserState } from '../reducers/CurrentUser.reducer';

export const CurrentUserActionsEnum = {
  LoadCurrentUser: '[Data] Load CurrentUser',
  UpdateCurrentUser: '[Data] Update CurrentUser',
  LoadCurrentUserSuccess: '[Data] Load CurrentUser Success',
  LoadCurrentUserFailure: '[Data] Load CurrentUser Failure',
  StoreCurrentUser: '[Current User] Store Current User',
};
export const loadCurrentUser = createAction('[Data] Load CurrentUser', props<{ null }>());

export const loadCurrentUserSuccess = createAction('[Data] Load CurrentUser Success', props<{ data: CurrentUserState }>());

export const loadCurrentUserFailure = createAction('[Data] Load CurrentUser Failure', props<{ error: any }>());

export const storeCurrentUser = createAction('[Current User] Store Current User', props<{ currentUser: CurrentUserState }>());
