<h2>Client Modules</h2>
<div *ngIf="isLoading; else displayForm">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #displayForm>
<form [formGroup]="detailsForm" novalidate class="d-flex flex-column w-50">
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Client</mat-label>
        <mat-select formControlName="clientAccountId">
            <mat-option *ngFor="let option of clientOptions" [value]="option.accountId">
                {{ option.accountName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Assessment type</mat-label>
        <mat-select formControlName="assessmentType">
            <mat-option *ngFor="let option of assessmentTypeOptions" [value]="option.id">
                {{ option.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <button [routerLink]="['/', 'client-modules' ]" color="secondary" mat-raised-button>Cancel</button>
        <button color="primary" mat-raised-button type="button" (click)="onSave()" >
            Save
        </button>
    </div>
</form>
</ng-template>