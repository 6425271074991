import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUserContactData, selectCurrentUserContactId } from '../../../core/ngrx/selectors/currentUser.selectors';
import { CompanyTimelineService, PhoneCall } from '../../../core/services/company-timeline/company-timeline.service';
import { Store } from '@ngrx/store';
import { ContactsEmail } from '../../models/ContactsEmail';
import { Observable } from 'rxjs/internal/Observable';
import { CompanyTimelineComponent } from '../../../features/assessment/company-timeline/company-timeline.component';
import { SnackBarClass } from '../snack-bar/snack-bar.class';
import { selectContractorAccountContact, selectContractorAccountId } from '../../../core/ngrx/selectors/contractor-account.selectors';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AccountContact } from '../../models/CheckNewAuthenticationResponse';

export const angularEditorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '300px',
  maxHeight: '300px',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter text here...',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  fonts: [
    { class: 'arial', name: 'Arial' },
    { class: 'times-new-roman', name: 'Times New Roman' },
    { class: 'calibri', name: 'Calibri' },
    { class: 'comic-sans-ms', name: 'Comic Sans MS' },
  ],
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText',
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [['bold', 'italic'], ['fontSize'], ['insertImage', 'insertVideo']],
};

@Component({
  selector: 'app-phone-call-accordion',
  templateUrl: './phone-call-accordion.component.html',
  styleUrls: ['./phone-call-accordion.component.scss'],
})
export class PhoneCallAccordionComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public phoneCallForm: FormGroup;
  public requestPending = false;
  public contactIds: Observable<Array<ContactsEmail>> = this.store.select(selectContractorAccountContact);
  public currentUser: Observable<AccountContact> = this.store.select(selectCurrentUserContactData);
  public editorConfig = angularEditorConfig;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly timelineService: CompanyTimelineService,
    private readonly store: Store,
    private readonly snackbarClass: SnackBarClass,
    private readonly companyTimeline: CompanyTimelineComponent
  ) {}

  ngOnInit(): void {
    this.hydrateForm();
  }

  public async createNewPhoneCall(): Promise<void> {
    this.requestPending = true;
    // Refactor store calls through async piping - Lewis 12/10/23
    const accountId: string = await firstValueFrom(this.store.select(selectContractorAccountId));
    const currentUser: string = await firstValueFrom(this.store.select(selectCurrentUserContactId));

    const phoneCall: PhoneCall = {
      phoneCallDirection: this.phoneCallForm.get('direction').value,
      subject: this.phoneCallForm.get('subject').value,
      description: this.phoneCallForm.get('description').value,
      regardingAccountId: accountId,
      createdById: currentUser,
      createdBy: 'System',
      fromContactId: this.phoneCallForm.get('from').value,
      toContactId: this.phoneCallForm.get('to').value,
    };

    try {
      await firstValueFrom(this.timelineService.createTimelinePhoneCall$(phoneCall));
      this.hydrateForm();
    } catch (e) {
      this.snackbarClass.displaySnackbar('Error: Please contact your system administrator', 'Close');
      throw new Error(e.message);
    } finally {
      this.requestPending = false;
      this.resetState();
      await this.refreshTimeLine();
    }
  }

  public resetState = () => this.companyTimeline.resetState(this.phoneCallForm, this.accordion);
  public refreshTimeLine = () => this.companyTimeline.refreshTimelineData();

  public accordionState(value: boolean): void {
    this.companyTimeline.pauseTimelineRefresh = value;
  }

  private hydrateForm(): void {
    this.phoneCallForm = this.formBuilder.group({
      subject: new FormControl('', Validators.required),
      from: new FormControl('', Validators.required),
      to: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      direction: new FormControl('', Validators.required),
    });
  }
}
