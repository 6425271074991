<div class="text-center" *ngIf="loading; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
  <div>
    <div class="search-div">
      <mat-form-field class="search-form-field" floatLabel="never">
        <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()" trim />
        <button mat-button matSuffix aria-label="Clear" (click)="onSearchClear()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="bundleName">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ row.bundleName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="defaultAssessorOrganisation">
          <th mat-header-cell *matHeaderCellDef>Assessor Company</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <i *ngIf='row.statusCode == 1' style="color: red" class='fas fa-lock'></i> {{ row.defaultAssessorOrganisation?.id?.name }} ({{
            row.defaultAssessorOrganisationId }})
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button mat-icon-button (click)="onEdit(row.id)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[12, 25, 100]"></mat-paginator>
    </div>
  </div>
</ng-template>