import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountHttpService } from 'src/app/core/services/account/account-http.service';
import { Contractor } from 'src/app/shared/models/Contractor';
import { AssessmentService } from 'src/app/core/open-api/api/assessment.service';
import { BundleType } from 'src/app/core/open-api';

@Component({
  selector: 'app-bundle-type-update',
  templateUrl: './bundle-type-update.component.html',
  styleUrls: ['./bundle-type-update.component.scss'],
})
export class BundleTypeUpdateComponent implements OnInit {
  private id: string = this.route.snapshot.paramMap.get('id');
  public isLoading: boolean;
  public assessorCompanies: Contractor[];

  public detailsForm: FormGroup = this.formBuilder.group({
    id: [null],
    bundleName: [null],
    defaultAssessorOrganisationId: [null],
    defaultAssessorOrganisation: [null],
  });

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly assessmentService: AssessmentService,
    private readonly accountHttpService: AccountHttpService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.assessorCompanies = await firstValueFrom(this.accountHttpService.getAllAssessors());
    const bundleType = await this.retrieveUserAccount();
    this.initializeForm(bundleType);
  }

  private async retrieveUserAccount(): Promise<BundleType> {
    if (this.id) {
      return await firstValueFrom(this.assessmentService.getBundleType(this.id));
    }
  }

  private initializeForm(bundleType: BundleType): void {
    if (bundleType) {
      this.detailsForm = this.formBuilder.group({});
      for (const [key, value] of Object.entries(bundleType)) {
        this.detailsForm?.addControl(key, new FormControl(value || ''));
      }
    }

    this.isLoading = false;
  }

  public async onSave(): Promise<void> {
    this.isLoading = true;
    if (this.id != null) {
      this.detailsForm.patchValue({ id: this.id });
    }
    try {
      await firstValueFrom(this.assessmentService.updateBundleType(this.detailsForm.value));
      await this.router.navigate(['/assessment/routing']);
    } catch (e) {
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
