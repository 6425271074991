import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/azure-authentication/auth-interceptor/auth-interceptor.service';
import { AccountSelector } from './services/account-selection/account-selector';
import { AssessmentService } from './services/assessment/assessment.service';
import { UserAuthenticationAbstract } from './services/authentication/user-authentication-abstract';
import { AccountSelectorService } from './services/account-selection/account-selector.service';
import { AzureUserAuthenticationService } from './services/azure-authentication/user-authentication/azure-user-authentication.service';
import { Authenticator } from './services/authentication/authenticator';
import { AccountService } from './services/account/account.service';
import { AccountHttpService } from './services/account/account-http.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CompanyInformationEffects } from './ngrx/effects/company-information/companyInformationEffects';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AccreditationSummaryEffects } from './ngrx/effects/AccreditationSummaryEffects';
import { ContactSummaryEffects } from './ngrx/effects/ContactSummaryEffects';
import { ImsAndinsuranceReducer } from './ngrx/reducers/ImsAndinsurance.reducer';
import { ImsAndinsuranceEffects } from './ngrx/effects/ImsAndinsuranceEffects';
import { ProductAssessmentsTableEffects } from './ngrx/effects/ProductAssessmentsTableEffects';
import { ProductAssessmentsTableReducer } from './ngrx/reducers/ProductAssessmentsTable.reducer';
import { EmployeeInformationEffects } from './ngrx/effects/employeeInformationEffects';
import { FIRSummaryReducer } from './ngrx/reducers/FIRSummary.reducer';
import { FIRSummaryEffects } from './ngrx/effects/FIRSummaryEffects';
import { TradesReducer } from './ngrx/reducers/tradesTrable.reducer';
import { TradeEffects } from './ngrx/effects/tradeEffects';
import { ProductCurrentMembershipEffects } from './ngrx/effects/ProductCurrentMembershipTableEffects';
import { ContactsEmailsTableEffects } from './ngrx/effects/ContactsEmailsTableEffects';
import { ModuleTableReducer } from './ngrx/reducers/ModuleTable.reducer';
import { ModuleTableEffects } from './ngrx/effects/ModuleTableEffects';
import { QuestionSetTableReducer } from './ngrx/reducers/QuestionSetTable.reducer';
import { QuestionSetTableEffects } from './ngrx/effects/QuestionSetTableEffects';
import { ProductTableEffects } from './ngrx/effects/ProductTableEffects';
import { AngularMaterialModule } from './angular-material.module';
import { ProductTableReducer } from './ngrx/reducers/ProductTable.reducer';
import { WorkCategoriesEffects } from './ngrx/effects/WorkCategoriesEffects';
import { WorkCategoriesReducer } from './ngrx/reducers/WorkCategories.reducer';
import { CurrentUserEffects } from './ngrx/effects/currentUser.effects';
import { AssessorsEffects } from './ngrx/effects/assessor.effects';
import { AssessorReducer } from './ngrx/reducers/assessor.reducer';
import { CurrentUserReducer } from './ngrx/reducers/CurrentUser.reducer';
import { AssessmentsReducer } from './ngrx/reducers/assessments.reducer';
import { AssessmentEffects } from './ngrx/effects/assessment.effects';
import { countdownTimerReducer } from './ngrx/reducers/assessmentCountdownTimer.reducer';
import { CountdownTimerEffects } from './ngrx/effects/assessmentCountdownTimerEffects';
import { ContractorAccountReducer } from './ngrx/reducers/contractor-account.reducer';
import { ContractorAccountEffect } from './ngrx/effects/contractor-account.effect';
import { BundleTypeReducer } from './ngrx/reducers/bundle-type.reducer';
import { BundleTypeEffects } from './ngrx/effects/bundle-type.effects';
import { FeatureFlagReducer } from './ngrx/reducers/feature-flag.reducer';
import { FeatureFlagEffects } from './ngrx/effects/feature-flag.effects';

/**
 * Leave reducers commented out for now, still investigating if they are required or legacy
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularMaterialModule,
    StoreModule.forRoot({
      imsAndinsurance: ImsAndinsuranceReducer,
      productAssessmentsTable: ProductAssessmentsTableReducer,
      FIRSummary: FIRSummaryReducer,
      WorkCategory: WorkCategoriesReducer,
      Trade: TradesReducer,
      moduleTable: ModuleTableReducer,
      questionSetTable: QuestionSetTableReducer,
      ProductTable: ProductTableReducer,
      assessor: AssessorReducer,
      bundleType: BundleTypeReducer,
      users: CurrentUserReducer,
      assessments: AssessmentsReducer,
      countdownTimer: countdownTimerReducer,
      contractorAccount: ContractorAccountReducer,
      featureFlags: FeatureFlagReducer,
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      CompanyInformationEffects,
      AccreditationSummaryEffects,
      ContactSummaryEffects,
      ImsAndinsuranceEffects,
      ProductAssessmentsTableEffects,
      EmployeeInformationEffects,
      FIRSummaryEffects,
      WorkCategoriesEffects,
      TradeEffects,
      ProductCurrentMembershipEffects,
      ContactsEmailsTableEffects,
      ModuleTableEffects,
      QuestionSetTableEffects,
      ProductTableEffects,
      CurrentUserEffects,
      AssessorsEffects,
      BundleTypeEffects,
      AssessmentEffects,
      CountdownTimerEffects,
      ContractorAccountEffect,
      FeatureFlagEffects,
    ]),
  ],
  providers: [
    AssessmentService,
    AccountService,
    AccountHttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: UserAuthenticationAbstract,
      useExisting: AzureUserAuthenticationService,
    },
    { provide: Authenticator, useExisting: AzureUserAuthenticationService },
    { provide: AccountSelector, useExisting: AccountSelectorService },
  ],
})
export class CoreModule {}
