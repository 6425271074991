/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddressViewModel {
  id?: any | null;
  name?: any | null;
  latitude?: number;
  longitude?: number;
  street1?: string | null;
  street2?: any | null;
  street3?: any | null;
  postCode?: string | null;
  county?: any | null;
  state?: any | null;
  town?: any | null;
  country?: string | null;
  countryCode?: string | null;
  city?: string | null;
}
