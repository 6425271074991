<div *ngFor="let gridView of gridViews">
    <h1>{{gridView.tableName}}</h1>
    <div *ngIf="showHideTableView$(gridView.permissionRequestName) | async">
      <div *ngIf="gridView.tableName === 'Assessments'">
        <button *ngIf="featureFlagEnabled$(featureFlagNames.RE_OPEN_BUNDLE_BUTTON) | async" mat-raised-button (click)="matDialog.open(datePickerModal)" color="primary">
          <i class="fas fa-clock"></i>
          Re-open Bundle
        </button>
        <ng-template #datePickerModal>
          <h1 mat-dialog-title>Extend Bundle Deadline</h1>
          <div mat-dialog-content>
            <label>Times Re-opened: {{ (bundleTimesExtended | async) || 0 }}</label
            ><br />
            <mat-form-field>
              <mat-label>Enter a date</mat-label>
              <input (dateChange)="selectedDate = $event.value" matInput [matDatepicker]="picker" />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-between align-content-center">
            <button mat-raised-button mat-dialog-close color="warn">Close</button>
            <button mat-raised-button [disabled]="loading || !selectedDate" color="primary" (click)="updateBundleDeadline()">Save</button>
          </div>
          <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
        </ng-template>
      </div>
        <app-product-assessment (newItemEvent)="refreshTable($event)"
                                            [enabledButtons]="gridView.enabledButtons"
                                            [apiRequestType]="gridView.apiRequestType"
                                            [enableRowClick]="gridView.enableRowClick"
                                            [tableName]="gridView.tableName"
                                            [displayAssignButton]="gridView.displayAssignButton"
                                            [dataRequestType]='gridView.dataRequestType'
                                            [uiFilter]='gridView.uiFilter'
                                            [displayedColumns]='gridView.displayedColumns'
                                            [columnNames]='gridView.columnNames'>
        </app-product-assessment>
    </div>
</div>
