import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AccountHttpService } from '../../services/account/account-http.service';
import {
  ContractorAccountLoad,
  ContractorAccountLoadError,
  ContractorAccountLoadSuccess,
  RefreshContractorAccountTimeline,
  RefreshContractorAccountTimelineSuccess,
  RefreshContacts,
  RefreshContactsSuccess,
} from '../actions/contractor-account.actions';
import { ContractorAccountService } from '../../services/company-account/contractor-account.service';

@Injectable()
export class ContractorAccountEffect {
  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorAccountLoad),
      mergeMap((action) =>
        this.companyAccountService.retrieveData$(action.accountId).pipe(
          map((b) => {
            const mappedWorkCategories: { isSelected: true }[] = [];
            b[8]
              .flatMap(({ subCategories }) =>
                subCategories.flatMap(({ workCategories }) => workCategories.filter(({ isSelected }) => isSelected))
              )
              .forEach((i) => {
                mappedWorkCategories.push(i);
              });

            return ContractorAccountLoadSuccess({
              summary: b[0],
              contact: b[1],
              address: b[2],
              membership: b[3],
              companyDetails: b[4],
              workRegions: b[5],
              employee: b[6],
              timeline: b[7],
              workCategories: mappedWorkCategories,
              company: b[9],
              insurance: b[10],
              distanceToOffice: b[11],
              accountId: action.accountId,
            });
          }),
          catchError((error) => of(ContractorAccountLoadError({ error: error })))
        )
      )
    )
  );

  refreshTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshContractorAccountTimeline),
      mergeMap((action) =>
        this.companyAccountService.refreshTimeline$(action.accountId).pipe(
          map((b) => {
            return RefreshContractorAccountTimelineSuccess({
              timeline: b,
            });
          }),
          catchError((error) => of(ContractorAccountLoadError({ error: error })))
        )
      )
    )
  );

  refreshContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshContacts),
      mergeMap((action) =>
        this.companyAccountService.retrieveContacts$(action.accountId).pipe(
          map((b) => {
            return RefreshContactsSuccess({
              contacts: b,
            });
          }),
          catchError((error) => of(ContractorAccountLoadError({ error: error })))
        )
      )
    )
  );
  constructor(
    private readonly actions$: Actions,
    private readonly accountService: AccountHttpService,
    private readonly companyAccountService: ContractorAccountService
  ) {}
}
