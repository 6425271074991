import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { map, Subscription } from 'rxjs';
import { AccountHttpService } from 'src/app/core/services/account/account-http.service';
import { SelectItem } from '../select-value-modal/select-item';
import { HasId } from 'src/app/shared/models/has-id';
import { HasIdDialogData } from '../select-value-modal/has-id-dialog-data';
import { AssessmentSummary } from '../../../shared';

@Component({
  selector: 'app-assessor-company-assign-modal.component',
  templateUrl: '../select-value-modal/select-value-modal.component.html',
})
export class AssessorCompanyAssignmentDialog {
  header = 'Choose assessor company';

  items: SelectItem[] = [];
  itemsSubscription: Subscription;
  selectedValue: string;

  assessments: HasId[];

  constructor(
    public dialogRef: MatDialogRef<AssessorCompanyAssignmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assessmentService: AssessmentService,
    private accountHttpService: AccountHttpService
  ) {}

  ngOnInit(): void {
    this.assessments = this.data.selection.selected;

    this.itemsSubscription = this.accountHttpService
      .getAllAssessors()
      .pipe(
        map((accounts: any[]) =>
          accounts.map((account) => ({
            id: account.accountId,
            value: account.accountName,
          }))
        )
      )
      .subscribe((items: SelectItem[]) => {
        this.items.push(...items);
        return this.items;
      });
  }

  ngOnDestroy(): void {
    this.assessmentService.getAssessorsSubject().unsubscribe();
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  assignClick(): void {
    this.assessmentService
      .assignAssessmentsToAssessorCompany(
        //@ts-ignore
        this.data.selection.selected[0].map((a) => a.Id),
        this.selectedValue,
        true
      )
      .subscribe(() => this.dialogRef.close());
  }
}
