<div class="panel">
  <div class="panel__content">
    <div *ngIf="formGroup" [formGroup]="formGroup">
      <div>
        <h4><b><span *ngIf="contactIsPrimaryContact">Primary </span>Contact</b></h4>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Contact Number</mat-label>
        </div>
        <div class="form-field__input">
          <mat-form-field appearance="fill">
            <input formControlName="telephone" matInput [readonly]="!editing" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Email Address</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
              <input formControlName="email" matInput [readonly]="!editing" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>First Name</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
              <input formControlName="firstName" matInput [readonly]="!editing" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Last Name</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
              <input formControlName="lastName" matInput [readonly]="!editing" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Job Title</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
              <input formControlName="position" matInput [readonly]="!editing" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field" *ngIf="editing && !contactIsPrimaryContact">
        <div class="form-field__label">
          <mat-label>Primary Contact</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-checkbox formControlName="isPrimaryContact" [disabled]="!editing"></mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel__footer" *ngIf="displayActionButtons">
    <app-panel-action-buttons
      (buttonStateEmitter)="editing = $event"
      [loading]="loading"
      (submitEmitter)="saveContact()"
      [editState]="editing"
    ></app-panel-action-buttons>
  </div>
  <div *ngIf="loading" class="panel__loading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
