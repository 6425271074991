import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Address, AssessmentSummary, Contact, ContractorAccount, Region, WorkCategories } from 'src/app/shared';
import { AccountHttpService } from './account-http.service';
import { GetAccountForAssessment } from 'src/app/shared/models/company-details';
import { CompanyTimeline } from 'src/app/shared/models/CompanyTimeline';
import { ImsAndinsurance } from 'src/app/shared/models/ImsAndinsurance';
import { ParentWorkCategory } from 'src/app/shared/models/ParentWorkCategory';
import { AccountSelectorService } from '../account-selection/account-selector.service';
import { Store } from '@ngrx/store';
import { Opportunity } from '../../../shared/models/Opportunity';
import { Contractor } from 'src/app/shared/models/Contractor';
import { AssessmentService } from '../assessment/assessment.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private accountHttpService: AccountHttpService,
    private accountSelectorService: AccountSelectorService,
    private readonly assessmentService: AssessmentService,
    private readonly store: Store
  ) {}

  public getAccountForAssessment(assessmentId: string): Observable<GetAccountForAssessment> {
    const assessmentSummary = this.assessmentService.getAssessmentSummary(assessmentId);
    const contractorId = assessmentSummary.ContractorId;
    return this.accountHttpService.getAccountDetails(contractorId);
  }

  public getAccountForAssessment_orig(assessmentId: string): Observable<GetAccountForAssessment> {
    const assessmentSummary = this.getAssessmentSummary(assessmentId);
    const contractorId = assessmentSummary.ContractorId;

    const response = forkJoin([
      this.accountHttpService.getContractors(contractorId),
      this.accountHttpService.getContacts(contractorId),
      this.accountHttpService.getBranchAddresses(contractorId),
      this.accountHttpService.getWorkRegions(contractorId),
      this.accountHttpService.getBusinessSize(contractorId, assessmentSummary.Id),
      this.accountHttpService.getAccount(contractorId),
    ]).pipe(
      map((x) => {
        const contractorsResponse = x[0];
        const contactsResponse = this.getContacts(x[1]);
        const branchAddressesResponse = this.getAccountsAddresses(x[2]);
        const workRegionsResponse = this.getWorkRegions(x[3]);
        const businessDetailsResponse = x[4];
        const accountDetailsResponse = x[5];

        const contactsData: ContactsData = {
          phoneNumber: this.getPrimaryContactNumber(contactsResponse),
          position: this.getPrimaryContactPosition(contactsResponse),
        };

        const account = this.getContractAccount(
          contractorsResponse,
          contactsData,
          branchAddressesResponse,
          workRegionsResponse,
          businessDetailsResponse,
          accountDetailsResponse
        );

        const result = {
          account: account,
          contacts: contactsResponse,
        };

        return result;
      })
    );

    return response;
  }

  public getPurchaseHistory(accountId: string): Observable<any> {
    return this.accountHttpService.getPurchaseHistory(accountId);
  }

  public getInsurance(accountId: string): Observable<ImsAndinsurance> {
    const response = forkJoin([this.accountHttpService.getContractors(accountId)]).pipe(
      map((x) => {
        const insurances = x[0].Details.Insurances;
        const result: ImsAndinsurance = {
          employersLiability: {
            active: insurances.Employers_Liability,
            insurer: insurances.Employers_Liability_Insurer,
            policyNumber: insurances.Employers_Liability_PolicyNumber,
            excess: insurances.Employers_Liability_Excess,
            limitforaSingleEvent: insurances.Employers_Liability_Limit,
            expiryDate: insurances.Employers_Liability_Expiry,
            document: '',
            employersLiabilityVerificationStatus: '',
            employersLiabilityRejectionReason: '',
          },
          publicLiabilityInsurance: {
            active: insurances.Public_Liability,
            insurer: insurances.Public_Liability_Insurer,
            policyNumber: insurances.Public_Liability_PolicyNumber,
            excess: insurances.Public_Liability_Excess,
            limitforaSingleEvent: insurances.Public_Liability_Limit,
            expiryDate: insurances.Public_Liability_Expiry,
            document: '',
            employersLiabilityVerificationStatus: '',
            employersLiabilityRejectionReason: '',
          },
          professionalIndemnity: {
            active: insurances.Professional_Indemnity,
            insurer: insurances.Professional_Indemnity_Insurer,
            policyNumber: insurances.Professional_Indemnity_PolicyNumber,
            excess: insurances.Professional_Indemnity_Excess,
            limitforaSingleEvent: insurances.Professional_Indemnity_Limit,
            expiryDate: insurances.Professional_Indemnity_Expiry,
            document: '',
            employersLiabilityVerificationStatus: '',
            employersLiabilityRejectionReason: '',
          },
          productLiabilityInsurance: {
            active: insurances.Product_Liability,
            insurer: insurances.Product_Liability_Insurer,
            policyNumber: insurances.Product_Liability_PolicyNumber,
            excess: insurances.Product_Liability_Excess,
            limitforaSingleEvent: insurances.Product_Liability_Limit,
            expiryDate: insurances.Product_Liability_Expiry,
            document: '',
            employersLiabilityVerificationStatus: '',
            employersLiabilityRejectionReason: '',
          },
        };
        return result;
      })
    );
    return response;
  }

  public getTrades(accountId: string): Observable<ParentWorkCategory[]> {
    return forkJoin([this.accountHttpService.getMatchingCategories$(accountId)]).pipe(map((x) => x[0]));
  }

  public getCompanyTimeline(assessmentId: string): Observable<CompanyTimeline> {
    return of();
  }

  public getOpportunties(): Observable<Opportunity[]> {
    return forkJoin([this.accountHttpService.getOpportunties()]).pipe(map((x) => x[0]));
  }

  public getContractors(): Observable<Contractor[]> {
    return forkJoin([this.accountHttpService.getAllContractors()]).pipe(map((x) => x[0]));
  }

  private getAssessmentSummary(assessmentId: string): AssessmentSummary {
    return this.assessmentService.getAssessmentSummary(assessmentId);
  }

  private getWorkRegions(workRegionsResponse: any): Region[] {
    const regions: Region[] = [];
    workRegionsResponse.forEach((workRegionResponse: any) => {
      let subRegion: Region[] = [];
      if (workRegionResponse.child !== null && workRegionResponse.child !== undefined) {
        subRegion = this.getWorkRegions(workRegionResponse.child);
      }
      regions.push({ name: workRegionResponse.name, subRegion });
    });

    return regions;
  }

  private getPrimaryContactPosition(contacts: Contact[]): string {
    if (contacts.length <= 0) {
      return '';
    }

    return contacts[0].position;
  }

  private getPrimaryContactNumber(contacts: Contact[]): string {
    if (contacts.length <= 0) {
      return '';
    }

    return contacts[0].phone;
  }

  private getContacts(contactResponse: any): Contact[] {
    const contacts: Contact[] = [];
    contactResponse.forEach((contact) => {
      contacts.push({
        name: contact.contactName,
        phone: contact.telephone,
        email: contact.email,
        position: contact.position,
      });
    });
    return contacts;
  }

  private getAccountsAddresses(branchAddressResponse: any): Address[] {
    const addresses: Address[] = [];
    branchAddressResponse.forEach((branchAddress: any) => {
      addresses.push({
        Line1: branchAddress.street1,
        Line2: branchAddress.street2,
        Line3: branchAddress.street3,
        city: branchAddress.city,
        postCode: branchAddress.postCode,
        siteName: branchAddress.name,
      });
    });
    return addresses;
  }

  private getContractAccount(
    account: any,
    contactsData: ContactsData,
    branchAddresses: Address[],
    regions: Region[],
    businessDetails: any,
    accountDetails: any
  ): ContractorAccount {
    return {
      ...accountDetails,
      businessSize: businessDetails['Company Business Details'].Business_Size,
      emailAddress: account.Details.Contractor.Contractor_Email_Address,
      position: contactsData.position,
      phoneNumber: contactsData.phoneNumber,
      fastTrack: account.FastTrack,
      status: account.Status,
      branchAddresses,
      workCategories: this.getWorkCategories(account.Details.Contractor.Contractor_Work_Categories),
      workAreas: {
        workRadius: account.Details.Contractor.Contractor_WorkDistance,
        regions,
      },
    } as ContractorAccount;
  }

  private getWorkCategories(workcategoryResponse: any): WorkCategories[] {
    const workCategoriesMap = new Map();
    workcategoryResponse.forEach((workCategory) => {
      const workNames = workCategory.Name.split(' - ');
      let subcategories: string[] = workCategoriesMap.get(workNames[0]);
      if (subcategories === undefined) {
        subcategories = [];
        workCategoriesMap.set(workNames[0], subcategories);
      }
      subcategories.push(`${workCategory.Subheader} - ${workCategory.Type}`);
    });

    const workCategories: WorkCategories[] = [];
    workCategoriesMap.forEach((subcategories, category) => {
      workCategories.push({
        name: category,
        subCategories: subcategories,
      });
    });
    return workCategories;
  }

  public getAllWorkRegions(): Observable<any[]> {
    return forkJoin([this.accountHttpService.getAllWorkRegions()]).pipe();
  }
}

type ContactsData = {
  phoneNumber: string;
  position: string;
};
