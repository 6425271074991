import { Component } from '@angular/core';
import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { MultigridDashboardButton } from 'src/app/core/ngrx/actions/MultigridDashboardButton.actions';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { Observable } from 'rxjs';
import { UIFilter } from '../../../../shared/models/ui-filter';
import { AlertsApiRequestTypes, AlertsFilterTypes } from '../../../../core/services/alerts/alerts.service';
import { DataRequestTypeEnum } from '../../../../shared/enums/data-request-type-enum';
import { BundleAPIFilter } from '../../../../shared/enums/bundle-api-filter-enum';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';

@Component({
  selector: 'app-your-dashboard-table-controller',
  templateUrl: './your-dashboard-table-controller.component.html',
  styleUrls: ['./your-dashboard-table-controller.component.scss'],
})
export class YourDashboardTableControllerComponent {
  public filter: UIFilter[];
  public gridViews: GridView[] = [
    new GridView(
      'Active Verification Bundles',
      PermissionsEnum.ActiveVerificationBundles,
      ApiRequestTypes.YourActiveBundles,
      [],
      true,
      [
        'contractorName',
        'name',
        'incompleteSections',
        'dateSubmitted',
        'status',
        'assessorName',
        'previousAssessorName',
        'latestQAReviewerName',
        'latestQAReviewStatus',
      ],
      [{ property: BundleAPIFilter.BundleRequestType, value: BundleAPIFilter.BundleTypeVerification }],
      DataRequestTypeEnum.Bundle,
      false,
      [
          'Contractor Name',
          'Bundle',
          'Incomplete Sections',
          'Date Submitted',
          'Status',
          'Assessor Name',
          'Previous Assessor',
          'QA Assessor',
          'QA Result'
      ]
    ),
    new GridView(
      'QA Verification Bundles',
      PermissionsEnum.YourQAVerificationBundles,
      ApiRequestTypes.YourQAVerificationBundles,
      [],
      true,
      ['contractorName', 'name', 'incompleteSections', 'dateSubmitted', 'status', 'assessorName'],
      [{ property: BundleAPIFilter.BundleRequestType, value: BundleAPIFilter.BundleTypeVerification }],
      DataRequestTypeEnum.Bundle,
      false,
      ['Contractor Name', 'Bundle', 'Incomplete Sections', 'Date Submitted', 'Status', 'Assessor Name']
    ),
    new GridView(
      'Active Alerts',
      PermissionsEnum.DocumentVerifications,
      AlertsApiRequestTypes.GetMyAlerts,
      [],
      true,
      ['accountName', 'assessmentName', 'bundle', 'createdDateTime'],
      [{ property: 'statusId', value: AlertsFilterTypes.Submitted }],
      DataRequestTypeEnum.Alert,
      false,
      ['Account Name', 'Assessment Name', 'Bundle', 'Creation Date']
    ),
    new GridView(
      'Recent Verification Bundles',
      PermissionsEnum.RecentAssessments,
      ApiRequestTypes.RecentVerificationBundles,
      [],
      true,
      ['contractorName', 'name', 'incompleteSections', 'status', 'assessorName', 'decisionDate'],
      [{ property: BundleAPIFilter.BundleRequestType, value: BundleAPIFilter.BundleTypeVerification }],
      DataRequestTypeEnum.Bundle,
      false,
      ['Contractor Name', 'Bundle', 'Incomplete Sections', 'Status', 'Assessor Name', 'Decision Date']
    ),
    new GridView(
      'Alert History',
      PermissionsEnum.DocumentVerifications,
      AlertsApiRequestTypes.GetAllAlerts,
      [],
      false,
      ['accountName', 'assessmentName', 'bundle', 'createdDateTime', 'statusDesc'],
      [],
      DataRequestTypeEnum.Alert,
      false,
      ['Account Name', 'Assessment Name', 'Bundle', 'Creation', 'Status']
    ),
  ];

  public permissionEnum: typeof PermissionsEnum = PermissionsEnum;

  constructor(private readonly permissionsService: PermissionsService) {}

  public showHideTableView(permissionId): Observable<boolean> {
    return this.permissionsService.authenticateUser$(PermissionsMap.get(permissionId));
  }
}

export class GridView {
  constructor(
    public tableName: string,
    public permissionRequestName: string,
    public apiRequestType: ApiRequestTypes | AlertsApiRequestTypes,
    public enabledButtons: MultigridDashboardButton[] = null,
    public enableRowClick: boolean = true,
    public displayedColumns: string[] = [],

    public uiFilter: UIFilter[],
    public dataRequestType: DataRequestTypeEnum,
    public displayAssignButton: boolean = true,
    public columnNames: string[] = []
  ) {}
}
