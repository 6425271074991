<div class="nav">
  <mat-sidenav mode="side" opened>
    <mat-nav-list>
      <div *ngIf="(isAssessment$ | async) !== true; else isAssessment">
        <a *ngIf="authenticateUserPermissions(permissionEnum.VerificationDashboard) | async" mat-list-item
          routerLink="/dashboard/customer-service" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-chart-bar"></i></mat-icon>
          <mat-list-text>Your Dashboard</mat-list-text>
        </a>

        <a *ngIf="authenticateUserPermissions(permissionEnum.MembershipNav) | async" mat-list-item
          routerLink="/membership" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-chart-bar"></i></mat-icon>
          <mat-list-text>Membership Dashboard</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <a *ngIf="(authenticateUserPermissions(permissionEnum.AssessmentAdminNav) | async) && isAssessorProductivityEnabled"
          mat-list-item routerLink="/dashboard/assessmentadmin" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-th"></i></mat-icon>
          <mat-list-text>Assessment Admin</mat-list-text>
        </a>

        <a *ngIf="(authenticateUserPermissions(permissionEnum.AssessorCompanyDashboard) | async) && isAssessorProductivityEnabled"
          mat-list-item routerLink="/dashboard/parentAssessor" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-th"></i></mat-icon>
          <mat-list-text>Assessor Company Dashboard</mat-list-text>
        </a>

        <a *ngIf="authenticateUserPermissions(permissionEnum.AssessorDashboard) | async" mat-list-item
          routerLink="/dashboard/assessor" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-th"></i></mat-icon>
          <mat-list-text>Assessor Dashboard</mat-list-text>
        </a>

        <a *ngIf="authenticateUserPermissions(permissionEnum.FinanceDashboard) | async" mat-list-item
          routerLink="/finance" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-th"></i></mat-icon>
          <mat-list-text>Finance</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <a *ngIf="authenticateUserPermissions(permissionEnum.DocumentsNav) | async"
          [routerLink]="['/assessment/documents', assessmentId]" mat-list-item routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-file"></i></mat-icon>
          <mat-list-text>Documents</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <a *ngIf="authenticateUserPermissions(permissionEnum.CreditSafeToolNav) | async" mat-list-item
          routerLink="/matching-tool" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-toolbox"></i></mat-icon>
          <mat-list-text>Credit Safe Tool</mat-list-text>
        </a>

        <a *ngIf="isSupplyChainImporterToolEnabled && (authenticateUserPermissions(permissionEnum.ClientFilesNav) | async)"
          mat-list-item routerLink="/client-files" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-upload"></i></mat-icon>
          <mat-list-text>Client Files</mat-list-text>
        </a>

        <a *ngIf="authenticateUserPermissions(permissionEnum.ClientModules) | async" mat-list-item
          routerLink="/client-modules" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fa fa-file-text"></i></mat-icon>
          <mat-list-text>Client Modules</mat-list-text>
        </a>

        <a *ngIf="authenticateUserPermissions(permissionEnum.OpportunitiesNav) | async" mat-list-item
          routerLink="/opportunties" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fa fa-money"></i></mat-icon>
          <mat-list-text>Opportunities</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <a (click)="onReport()" *ngIf="authenticateUserPermissions(permissionEnum.ReportsNav) | async" class="nav-link"
          mat-list-item rel="noreferrer noopener" routerLinkActive="is-active" target="_blank">
          <mat-icon matListIcon><i class="fa fa-file-text"></i></mat-icon>
          <mat-list-text>Reports</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <a *ngIf="isProductivityEnabled && authenticateUserPermissions(permissionEnum.ProductivityStatsNav) | async"
          mat-list-item routerLink="/productivity" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-chart-bar"></i></mat-icon>
          <mat-list-text>Productivity Stats</mat-list-text>
        </a>

        <a *ngIf="isAssessorProductivityEnabled && authenticateUserPermissions(permissionEnum.ProductivityNav) | async"
          mat-list-item routerLink="/assessor-productivity" routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-chart-bar"></i></mat-icon>
          <mat-list-text>Your Productivity</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <mat-accordion *ngIf="authenticateUserPermissions(permissionEnum.AdminNav) | async">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon matListIcon><i class="fas fa-wrench"></i></mat-icon>
                <mat-list-text>Admin</mat-list-text>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminModulesNav) | async" mat-list-item
                routerLink="/assessment/module" routerLinkActive="is-active">
                <mat-list-text>Modules</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminQuestionSets) | async" mat-list-item
                routerLink="/assessment/questionSet']" routerLinkActive="is-active">
                <mat-list-text>Question Sets</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminProductsNav) | async" mat-list-item
                routerLink="/assessment/products']" routerLinkActive="is-active">
                <mat-list-text>Products</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminSupplyChainNav) | async" mat-list-item
                routerLink="/assessment" routerLinkActive="is-active">
                <mat-list-text>Supply Chain</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminRegionsNav) | async" mat-list-item
                routerLink="/assessment" routerLinkActive="is-active">
                <mat-list-text>Regions</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminWorkCategoriesNav) | async" mat-list-item
                routerLink="/assessment/workCategories" routerLinkActive="is-active">
                <mat-list-text>Work Categories</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminAssessorNav) | async" mat-list-item
                routerLink="/assessment/user-management" routerLinkActive="is-active">
                <mat-list-text>User Management</mat-list-text>
              </a>
              <a mat-list-item routerLink="/assessment/clients" routerLinkActive="is-active">
                <mat-list-text>Clients</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.AdminRoutingNav) | async" mat-list-item
                routerLink="/assessment/routing" routerLinkActive="is-active">
                <mat-list-text>Bundle Routing</mat-list-text>
              </a>
              <a *ngIf="authenticateUserPermissions(permissionEnum.Admin_HangfireDashboardNav) | async" mat-list-item
                href="{{hangfireDashboardUrl}}"
                routerLinkActive="is-active" target="_blank">
                <mat-list-text>Hangfire Dashboard</mat-list-text>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-divider></mat-divider>
      </div>

      <ng-template #isAssessment>
        <a (click)="returnHome()" mat-list-item routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fas-regular fa-house-user"></i></mat-icon>
          <mat-list-text>Home</mat-list-text>
        </a>
        <mat-divider></mat-divider>
        <a (click)="goBack()" mat-list-item routerLinkActive="is-active">
          <mat-icon matListIcon><i class="fas fa-arrow-alt-circle-left"></i></mat-icon>
          <mat-list-text>Back</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <a [routerLink]="['/', 'assessment', 'companydetails', accountId]" mat-list-item routerLinkActive="is-active">
          <mat-icon matListIcon><i class="far fa-building"></i></mat-icon>
          <mat-list-text>Company Details</mat-list-text>
        </a>

        <mat-divider></mat-divider>

        <div class="breadcrumb">
          <mat-divider></mat-divider>

          <mat-nav-list *ngFor="let status of assessmentGroups" class="status">
            <mat-icon matListIcon><i class="fas fa-tasks"></i></mat-icon>
            <mat-list-text>{{ status }}</mat-list-text>
            <mat-nav-list class="assessment">
              <div *ngFor="let assessment of getAssessmentGroup(status)">
                <a [ngClass]="{ active: assessment.Id === assessmentId }"
                  [queryParams]="getRouterLinkQueryParams(assessment.Id)"
                  [routerLink]="['/', 'assessment', 'assess', assessment.Id]" mat-list-item routerLinkActive="active">
                  <mat-icon matListIcon><i class="far fa-edit"></i></mat-icon>
                  <mat-list-text>{{ assessment.AssessmentName }}</mat-list-text>
                </a>
                <mat-nav-list *ngIf="assessment.Id === assessmentId" class="assessmentPage">
                  <a (click)="selectAssessmentPage(page)" *ngFor="let page of assessmentPages; let i = index"
                    [ngClass]="{ active: isAssessmentPageSelected(page, i), complete: page.complete }" mat-list-item>
                    <mat-icon matListIcon><i class="far fa-edit"></i></mat-icon>
                    <mat-list-text>{{ page.title }}</mat-list-text>
                  </a>
                </mat-nav-list>
              </div>
            </mat-nav-list>
          </mat-nav-list>
        </div>
      </ng-template>

      <div>
        <mat-divider></mat-divider>

        <a (click)="logout()" class="nav-link" mat-list-item>
          <mat-icon matListIcon><i class="fa fa-sign-out"></i></mat-icon>
          <span class="mat-list-text">Log out</span>
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
</div>