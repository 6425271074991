<div class="container">
  <h2>{{ tableName }}</h2>
  <div class="container">
    <app-data-table-button-bar [enabledButtons]=enabledButtons [assignAssessor]="displayAssignButton"
      (assignAssessorClick)="openAssessorChangeDialog()" [changeStatus]="showChangeStatusButton"
      (changeStatusClick)="openStatusChangeDialog()" [changeTargetDate]="showChangeTargetDateButton"
      (changeTargetDateClick)="openTargetDateChangeDialog()" [changeDecisionDate]="showChangeDecisionDateButton"
      (changeDecisionDateClick)="openDecisionDateChangeDialog()" [changeExpiryDate]="showChangeExpiryDateButton"
      (changeExpiryDate)="openExpiryDateChangeDialog()">
    </app-data-table-button-bar>
    <div class="table-header">
      <!-- NOTE: Possible abstraction here? Reusable components? -->
      <mat-form-field>
        <input (keyup)="quickSearch($event.target.value)" matInput placeholder="Quick Search" />
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <app-data-table [columnNames]='columnNames' (selectionEmitter)='setSelection($event)'
      [showCheckbox]=true (clickActionEmitter)='assessmentSelected($event)' [dataSource]='dataSource'
      [displayedColumns]='displayedColumns' [isLoading]='isLoading' [enableRowClick]="enableRowClick">
    </app-data-table>
  </div>
</div>