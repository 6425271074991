import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../../core/services/search/search.service';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { MultigridDashboardButton } from '../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { AssessorDashboardAbstract } from '../../../shared/abstract-classes/assessor-dashboard-abstract/assessor-dashboard-abstract';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ApiRequestTypes } from 'src/app/core/ngrx/actions/MultigridAssessmentViewType.actions';
import { AssessmentListScope } from 'src/app/shared/enums/assessment-list-scope';
import { AccountService } from '../../../core/services/account/account.service';
import { BundlesService } from '../../../core/services/bundles/bundles.service';
import { AlertsService } from '../../../core/services/alerts/alerts.service';

@Component({
  selector: 'app-assessment-admin-dashboard',
  templateUrl: './assessment-admin-dashboard.component.html',
  styleUrls: ['./assessment-admin-dashboard.component.scss'],
})
export class AssessmentAdminDashboardComponent extends AssessorDashboardAbstract implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );
  }

  ngOnInit(): void {
    this.apiRequestType = ApiRequestTypes.AssessorDashboardNew;
    this.assessmentListScope = AssessmentListScope.Global;
    this.enabledButtons = [MultigridDashboardButton.AssignAssessor];
    this.retrieveAssessorLevel('parent');
    this.displayedColumns = [
      'select',
      'ContractorName',
      'AssessorCompanyName',
      'Product',
      'Submissiondate',
      'Status',
      'IncompleteSections',
      'AssignedAssessor',
    ];
    this.columnNames = [
      'select',
      'Contractor Name',
      'Assessor Company Name',
      'Product',
      'Date Submitted',
      'Status',
      'Incomplete Sections',
      'Assigned Assessor',
    ];
    super.ngOnInit();
  }
}
