import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProgressCard, ProgressStats } from "../../../shared";
import { SearchService } from "src/app/core/services/search/search.service";

@Component({
  selector: "app-progress-card",
  templateUrl: "./progress-card.component.html",
  styleUrls: ["./progress-card.component.scss"],
})
export class ProgressCardComponent implements OnInit {
  @Input()
  progressCard: ProgressCard;

  @Output()
  private progressCardSelected: EventEmitter<ProgressStats> = new EventEmitter<ProgressStats>();

  constructor(private searchService: SearchService) { }

  ngOnInit(): void { }

  get headerIcon(): string {
    return this.progressCard.icon;
  }

  get header(): string {
    return this.progressCard.header;
  }

  get stats(): ProgressStats[] {
    return this.progressCard.stats;
  }

  onRowClicked(progressStat: ProgressStats): void {
    this.searchService.setSelectedCard(this.header, progressStat.label);
    this.progressCardSelected.emit(progressStat);
  }

  public getSelected(): string {
    if (this.searchService.getSelectedProgressCard() === this.header) {
      return this.searchService.getSelectedCardLabel();
    }
  }
}
