<div class="container">
  <div class="d-inline-flex">
    <h2>{{ tableName }}</h2>
    <i *ngIf="uiFilter[0].property == 'enabledAccounts'" style="color: green" class="fas fa-lock-open ml-2"></i>
    <i *ngIf="uiFilter[0].property == 'disabledAccounts'" style="color: red" class="fas fa-lock ml-2"></i>
  </div>

  <br />
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="table-header align-content-around col-md-12 d-inline-flex">
        <mat-form-field class="col-md-6">
          <input (keyup)="quickSearch($event.target.value)" matInput placeholder="Quick Search" />
        </mat-form-field>
        <div *ngIf="!tableName.includes('Disabled')" class="col-md-6">
          <button type="button" color="primary" mat-raised-button [routerLink]="['/', 'assessment', 'createAssessor']" class="col-md-3">
            <mat-icon>add</mat-icon>Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-data-table
    [dataRequestType]="apiRequestType"
    [columnNames]="columnNames"
    (selectionEmitter)="setSelection($event)"
    (clickActionEmitter)="editUserAccount($event)"
    [allowRowSelect]="displayAssignButton"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [isLoading]="isLoading"
    [enableRowClick]="enableRowClick"
  ></app-data-table>
</div>
