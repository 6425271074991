import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

import { SharedModule } from '../../shared/shared.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { AssessorDashboardComponent } from './assessor-dashboard/assessor-dashboard.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { SearchResultTableComponent } from './search-result-table/search-result-table.component';
import { ProgressCardComponent } from './progress-card/progress-card.component';
import { AssessorDashboardRoutingModule } from './assessor-dashboard-routing.module';
import { AssessorCompanyDashboardComponent } from './assessor-company-dashboard/assessor-company-dashboard.component';
import { AssignmentModalComponent } from './assignment-modal/assignment-modal.component';
import { EmployeePipe } from './search-result-table/pipe/employee-pipe/employee.pipe';
import { AssessorAssignmentDialog } from './assessor-assignment-modal/assessor-assignment-modal.component';
import { StatusChangeDialog } from './status-change-modal/status-change-modal.component';
import { TargetDateChangeDialog } from './target-date-change-modal/target-date-change-modal.component';
import { DecisionDateChangeDialog } from './decision-date-change-modal/decision-date-change-modal.component';
import { ExpiryDateChangeDialog } from './expiry-date-change-modal/expiry-date-change-modal.component';

import { MaterialModule } from '../../../material/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';

import { YourDashboardTableControllerComponent } from './your-dashboard/your-dashboard-table-controller/your-dashboard-table-controller.component';
import { YourDashboardComponent } from './your-dashboard/your-dashboard.component';
import { AssessorWorkloadTableComponent } from './assessor-workload-table/assessor-workload-table.component';
import { AssessorCompanyAssignmentDialog } from './assessor-company-assignment-modal/assessor-company-assignment-modal.component';
import { AssessmentAdminDashboardComponent } from './assessment-admin-dashboard/assessment-admin-dashboard.component';
import { AssessorWorkloadDetails } from './assessor-workload-table/assessor-workload-details.component';
import { TradesComponent } from '../assessment/trades/trades.component';

const cookieConsentConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#2d1144',
      text: '#fff',
      link: '#fff',
    },
    button: {
      background: '#fff',
      text: '#2d1144',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'OK',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: 'https://www.chas.co.uk/privacy-policy/',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [
    AssessorDashboardComponent,
    SearchFilterComponent,
    SearchResultTableComponent,
    ProgressCardComponent,
    AssessorCompanyDashboardComponent,
    AssessmentAdminDashboardComponent,
    AssignmentModalComponent,
    EmployeePipe,
    AssessorAssignmentDialog,
    AssessorCompanyAssignmentDialog,
    StatusChangeDialog,
    TargetDateChangeDialog,
    DecisionDateChangeDialog,
    ExpiryDateChangeDialog,
    YourDashboardTableControllerComponent,
    YourDashboardComponent,
    AssessorWorkloadTableComponent,
    AssessorWorkloadDetails,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    RouterModule,
    ModalModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConsentConfig),
    SharedModule,
    LoadingSpinnerModule,
    AssessorDashboardRoutingModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCardModule,
  ],
  exports: [AssessorDashboardComponent, MatFormFieldModule, MatDialogModule, YourDashboardComponent, AssessorWorkloadTableComponent],
})
export class AssessorDashboardModule {}
