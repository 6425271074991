<div class="panel">
  <div class="mb-3 align-content-start">
    <button (click)="closeModal()" color="primary" mat-raised-button style="float: left">Close</button>
  </div>
  <div class="panel__content">
    <form class="d-flex flex-row" [formGroup]="timelineForm" *ngIf="timelineForm">
      <div class="col-6" id="formFields">
        <div class="form-field">
          <div class="form-field__label">
            <mat-label>Created By</mat-label>
          </div>
          <mat-form-field appearance="fill">
            <input class="disabled" [readonly]="true" matInput formControlName="createdBy" min="1" type="text" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <div class="form-field__label">
            <mat-label>Created On</mat-label>
          </div>
          <mat-form-field appearance="fill">
            <input class="disabled" [readonly]="true" formControlName="createdOn" matInput />
          </mat-form-field>
        </div>
        <div class="form-field">
          <div class="form-field__label">
            <mat-label>Subject</mat-label>
          </div>
          <mat-form-field appearance="fill">
            <input class="disabled" [readonly]="true" formControlName="subject" matInput min="1" type="text" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <div class="form-field__label">
            <mat-label>Type</mat-label>
          </div>
          <mat-form-field appearance="fill">
            <input class="disabled" [readonly]="true" formControlName="type" matInput min="1" type="text" />
          </mat-form-field>
        </div>
        <div *ngIf="timelineForm.value.type === timelineTypes.PhoneCall">
          <div class="form-field">
            <div class="form-field__label">
              <mat-label>Call From</mat-label>
            </div>
            <mat-form-field appearance="fill">
              <input class="disabled" [readonly]="true" [value]="timelineForm.value.data.callFrom" matInput min="1" type="text" />
            </mat-form-field>
          </div>
          <div class="form-field">
            <div class="form-field__label">
              <mat-label>Call To</mat-label>
            </div>
            <mat-form-field appearance="fill">
              <input class="disabled" [readonly]="true" [value]="timelineForm.value.data.callTo" matInput min="1" type="text" />
            </mat-form-field>
          </div>
          <div class="form-field">
            <div class="form-field__label">
              <mat-label>Direction</mat-label>
            </div>
            <mat-form-field appearance="fill">
              <input class="disabled" [readonly]="true" [value]="timelineForm.value.data.direction" matInput min="1" type="text" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div id="emailContainer" class="col-6 flex-wrap">
        <angular-editor *ngIf="isHtml; else format" formControlName="description" [config]="editorConfig"></angular-editor>
        <ng-template #format>
          <pre>{{timelineForm.value.description}}</pre>
        </ng-template>
      </div>
    </form>
  </div>
</div>
