import { Directive, Injectable, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';
import { ContractorAccountServiceEnum } from '../../../../core/services/company-account/contractor-account.service';

@Injectable()
@Directive()
export abstract class PanelAbstract implements OnInit {
  @Input() data;
  @Input() multiple = false;
  @Input() public editable = true;
  @Input() public readonly = true;

  public formGroup: FormGroup;

  protected permissionsRequest: PermissionsEnum = PermissionsEnum.ContactSummaryEditButton;
  protected displayActionButtons = true;
  protected form: FormGroup = this.fb.group({});
  protected editing = false;
  protected loading = true;
  protected subscription: Subscription | undefined;
  protected accountId: string = this.route.snapshot.paramMap.get('id');
  protected panelType: ContractorAccountServiceEnum;

  protected constructor(
    protected fb,
    protected readonly snackBar,
    protected readonly store,
    protected readonly route,
    protected actions,
    protected readonly permissionsService,
    protected readonly contractorAccountService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.initialisePanel();
  }

  public async permissionsCheck(permissionId: PermissionsEnum): Promise<boolean> {
    const getPermissionGUID: string = PermissionsMap.get(permissionId);
    return await firstValueFrom(this.permissionsService.authenticateUser$(getPermissionGUID));
  }

  private async initialisePanel(): Promise<void> {
    this.loading = true;
    this.displayActionButtons = await this.permissionsCheck(this.permissionsRequest);
    this.formGroup = this.fb.group({});
    if (await this.data)
      for (const [key, value] of Object.entries(this.data)) {
        this.formGroup?.addControl(key, new FormControl(value || ''));
      }
    this.loading = false;
  }

  protected async submit(): Promise<void> {
    this.loading = true;
    try {
      await firstValueFrom(this.contractorAccountService.updateDetails$(this.accountId, this.formGroup.value, this.panelType));
    } catch (e) {
      alert(`Something went wrong ${e.status}`);
      throw new Error(e);
    } finally {
      this.loading = false;
      this.editing = false;
    }
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.initialisePanel();
  }
}
