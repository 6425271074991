import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { AzureUserAuthenticationService } from '../azure-authentication/user-authentication/azure-user-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  private config: IEnvironment;

  constructor(private idle: Idle, private authService: AzureUserAuthenticationService, private router: Router) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  sessionTimeoutSeconds: number;

  get onIdleStart(): EventEmitter<any> {
    return this.idle.onIdleStart;
  }

  get onIdleEnd(): EventEmitter<any> {
    return this.idle.onIdleEnd;
  }

  public init(): void {
    console.log('Setting up session timeout manager');
    if (!this.authService.isLoggedIn()) {
      console.log('User is not logged in yet, exiting');
      return;
    }

    console.log('Setting up session timeout values');
    console.log('Idle timeout: ' + this.config.session.idleTimeout);
    console.log('Notification timeout: ' + this.config.session.timeoutNotification);

    this.idle.setIdle(this.config.session.idleTimeout);
    this.idle.setTimeout(this.config.session.timeoutNotification);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      console.log('Idle state has ended');
      this.reset();
    });

    this.idle.onTimeout.subscribe(async (): Promise<void> => {
      console.log('Session time out! Logging out user!');
      await this.logout();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      console.log('Timeout warning ' + countdown);
      this.sessionTimeoutSeconds = countdown;
    });

    console.log('Starting session manager service');
    this.reset();
  }

  async logout(): Promise<void> {
    sessionStorage.setItem('currentuserstate', null);
    console.log('session-manager.service.logout()');
    await this.authService.azureLogout();
  }

  reset(): void {
    this.idle.watch();
  }
}
