import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { CurrentUserActionsEnum } from '../../../core/ngrx/actions/currentUser.Actions';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  selectCurrentUser,
  selectCurrentUserDefaultRoute,
  selectCurrentUserPermissions,
} from '../../../core/ngrx/selectors/currentUser.selectors';
import { Redirector } from '../../utililties/redirector';
import { AzureUserAuthenticationService } from '../../../core/services/azure-authentication/user-authentication/azure-user-authentication.service';
import { PermissionsEnum } from '../../enums/permissions-enum';
import { RoutingMap } from '../../maps/routing-map';
import { CurrentUserState, OpsPortalRoute } from '../../../core/ngrx/reducers/CurrentUser.reducer';
import { storeDispatchAsync } from '../../../core/ngrx/store-dispatch-async';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  public displayMessage = 'Loading your account';
  public subscriptions$ = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private actions: ActionsSubject,
    private readonly accountService: AzureUserAuthenticationService
  ) {}

  public ngOnInit(): void {
    this.chasLogin(); // unable to await without causing issues in timing execution.
    this.redirect();
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  private async chasLogin(): Promise<void> {
    await storeDispatchAsync(this.store, this.actions, CurrentUserActionsEnum.LoadCurrentUserSuccess, {
      type: CurrentUserActionsEnum.LoadCurrentUser,
    });

    const currentUserData: CurrentUserState = await firstValueFrom(this.store.select(selectCurrentUser));
    this.buildAccountSummary(currentUserData);
  }

  private buildAccountSummary(data: any): void {
    const summary: CurrentUserState = {
      loaded: data.loaded,
      allAccounts: data.allAccounts,
      currentAccount: data.currentAccount,
      contacts: data.contacts,
      roles: data.roles,
      companyDetails: data.companyDetails,
      bundles: data.bundles,
      priceLists: null,
      isFreeMembership: data.isFreeMembership,
      featureFlags: null,
      permissions: data.permissions,
      opsPortalDefaultRoute: data.opsPortalDefaultRoute,
    };

    sessionStorage.setItem('currentuserstate', JSON.stringify(summary));
  }

  public redirect(): void {
    this.subscriptions$ = this.actions.subscribe(async (action: any): Promise<void> => {
      const route: string = await this.retrieveUserDefaultRoute();
      const hasPermission: object[] = await this.retrieveUserPermissions();

      if (action?.error?.status == 401) {
        this.displayMessage = 'Account Error: Your account has been disabled';
        this.forceLogout();
        return;
      }
      if (action.type === CurrentUserActionsEnum.LoadCurrentUserFailure) {
        this.displayMessage = 'Account Error: Please contact your system administrator';
        this.forceLogout();
      }
      if (action.type === CurrentUserActionsEnum.LoadCurrentUserSuccess && hasPermission.length <= 0) {
        this.displayMessage = 'Account Error: You are not authorised to enter the application';
        this.forceLogout();
      }
      if (action.type === CurrentUserActionsEnum.LoadCurrentUserSuccess && hasPermission.length > 0) {
        await Redirector.navigateToReturnUrl(this.route, this.router, route);
      }
    });
  }

  private forceLogout(): void {
    setTimeout(async () => {
      await this.accountService.azureLogout();
    }, 3000);
  }

  private async retrieveUserDefaultRoute(): Promise<string> {
    const userRoute: OpsPortalRoute = await firstValueFrom(this.store.select(selectCurrentUserDefaultRoute));
    const fallbackRoute: PermissionsEnum = userRoute?.name as PermissionsEnum;
    return RoutingMap.get(fallbackRoute);
  }

  private async retrieveUserPermissions(): Promise<Array<object>> {
    const userPermissionsList = await firstValueFrom(this.store.select(selectCurrentUserPermissions));
    return userPermissionsList.filter((perm) => perm.name == PermissionsEnum.OpsPortalAccess);
  }
}
