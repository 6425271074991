import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { PermissionsMap } from '../../maps/permissions-map';
import { PermissionsEnum } from '../../enums/permissions-enum';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
  @Input() readOnly: boolean;
  @Input() authentication: PermissionsEnum;
  @Output() saveDataEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() isReadOnly: EventEmitter<any> = new EventEmitter<any>();
  public permissionsEnum: typeof PermissionsEnum = PermissionsEnum;

  constructor(private readonly permissionsService: PermissionsService) {}

  public emitAction(value: string): void {
    this.saveDataEmitter.emit(value);
  }

  public authenticatePermissions$(): Observable<boolean> {
    const authId = PermissionsMap.get(this.authentication);
    return this.permissionsService.authenticateUser$(authId);
  }

  public editData(): void {
    this.isReadOnly.emit(false);
    this.readOnly = false;
  }

  public closePanel(): void {
    this.isReadOnly.emit(true);
    this.readOnly = true;
  }
}
