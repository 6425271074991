/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum CreditRating {
  NUMBER_0 = 0,
  NUMBER_100 = 100,
  NUMBER_200 = 200,
  NUMBER_300 = 300,
  NUMBER_400 = 400,
  NUMBER_500 = 500,
  NUMBER_501 = 501,
  NUMBER_600 = 600,
  NUMBER_700 = 700,
  NUMBER_800 = 800,
  NUMBER_801 = 801,
  NUMBER_900 = 900,
  NUMBER_1000 = 1000,
  NUMBER_1100 = 1100,
  NUMBER_1101 = 1101,
  NUMBER_1200 = 1200,
  NUMBER_1300 = 1300,
  NUMBER_1400 = 1400,
  NUMBER_1401 = 1401,
}
