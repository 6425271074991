import { Injectable } from '@angular/core';
import { FeatureFlag } from './feature-flag';
import { Observable, take } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { storeDispatchAsync } from '../../ngrx/store-dispatch-async';
import { map } from 'rxjs/operators';
import { FeatureFlagActions } from '../../ngrx/actions/feature-flag.actions';
import { selectEnabledFeatureFlags } from '../../ngrx/selectors/feature-flag.selectors';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private readonly store: Store, private readonly actions: ActionsSubject) {}

  public async retrieveFeatureFlags(): Promise<void> {
    await storeDispatchAsync(this.store, this.actions, FeatureFlagActions.LoadSuccess, {
      type: FeatureFlagActions.Load,
    });
  }

  public isFeatureEnabled$(featureFlagRequest: string): Observable<boolean> {
    return this.store.select(selectEnabledFeatureFlags).pipe(
      map((flag: any) => {
        return !!flag.find((featureFlag: FeatureFlag) => featureFlag.name === featureFlagRequest && featureFlag.enabled);
      }),
      take(1)
    );
  }
}
