import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../State';
import {
  ContractorAccountLoad,
  ContractorAccountLoadSuccess,
  RefreshContractorAccountTimeline,
  RefreshContractorAccountTimelineSuccess,
  RefreshContacts,
  RefreshContactsSuccess,
  UpdateTabIndex,
} from '../actions/contractor-account.actions';
import { InsuranceDetails } from '../../../shared';

export interface State extends AppState.State {
  contractorAccount: ContractorAccountState;
  loaded: false;
}

export interface ContractorAccountState {
  summary;
  contact;
  address;
  membership;
  companyDetails;
  workRegions;
  employee;
  timeline;
  workCategories;
  company;
  insurance: InsuranceDetails[];
  distanceToOffice;
  accountId: string;
  loaded;
  tabIndex;
}

const initialState: ContractorAccountState = {
  summary: null,
  contact: null,
  address: null,
  membership: null,
  companyDetails: null,
  workRegions: null,
  employee: null,
  timeline: null,
  workCategories: null,
  company: null,
  insurance: null,
  distanceToOffice: null,
  accountId: null,
  loaded: false,
  tabIndex: 0,
};

const _contractorAccountReducer = createReducer(
  initialState,
  on(ContractorAccountLoad, (state, action) => ({
    ...state,
  })),

  on(ContractorAccountLoadSuccess, (state, action) => ({
    ...state,
    summary: action.summary,
    contact: action.contact,
    address: action.address,
    membership: action.membership,
    companyDetails: action.companyDetails,
    workRegions: action.workRegions,
    employee: action.employee,
    timeline: action.timeline,
    workCategories: action.workCategories,
    company: action.company,
    insurance: action.insurance,
    distanceToOffice: action.distanceToOffice,
    accountId: action.accountId,
    loaded: true,
    tabIndex: 0,
  })),

  on(RefreshContractorAccountTimeline, (state, action) => ({
    ...state,
  })),

  on(RefreshContractorAccountTimelineSuccess, (state, action) => ({
    ...state,
    timeline: action.timeline,
  })),

  on(UpdateTabIndex, (state, action) => ({
    ...state,
    tabIndex: action.tabIndex,
  })),

  on(RefreshContacts, (state, action) => ({
    ...state,
  })),

  on(RefreshContactsSuccess, (state, action) => ({
    ...state,
    contact: action.contacts,
  }))
);

export function ContractorAccountReducer(state, action): ContractorAccountState {
  return _contractorAccountReducer(state, action);
}
