import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClientModulesComponent} from "./client-modules.component";
import {RouterModule, Routes} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { AddEditClientModuleComponent } from './add-edit-client-module/add-edit-client-module.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularMaterialModule} from "../../core/angular-material.module";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import { ClientQuestionSetListComponent } from './client-question-set-list/client-question-set-list.component';
import { UploadQuestionSetModalComponent } from './upload-question-set-modal/upload-question-set-modal.component';
import { MatDialogModule} from "@angular/material/dialog";

const routes: Routes = [
    { path: "", component: ClientModulesComponent },
    { path: "add", component: AddEditClientModuleComponent },
    { path: "edit/:id", component: AddEditClientModuleComponent },
    { path: "question-sets/:id", component: ClientQuestionSetListComponent }
];

@NgModule({
  declarations: [ AddEditClientModuleComponent, ClientModulesComponent, ClientQuestionSetListComponent, UploadQuestionSetModalComponent ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatProgressBarModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
      MatDialogModule,
      MatTableModule,
      MatPaginatorModule
  ],
  exports: [RouterModule]
})
export class ClientModulesModule { }
