import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutesClass, AssessorInfo, Contact } from 'src/app/shared';
import { Assessment } from 'src/app/shared/models/Assessment';

@Injectable({
  providedIn: 'root',
})
export class AssessmentHttpService {
  constructor(private readonly http: HttpClient, private readonly api: ApiRoutesClass) {}

  public getProductAssessmentsTable(accountId: string): Observable<Assessment[]> {
    return this.http.get<Assessment[]>(`${this.api.Assessments()}/getProductAssessmentsTable/${accountId}`);
  }

  public getAssessorById(id: string): Observable<AssessorInfo> {
    return this.http.get<AssessorInfo>(`${this.api.BaseUrl()}/assessors/${id}?includeDeactive=true`);
  }

  public createOrUpdateAssessor$(assessorCreateOrUpdateCommand: AssessorCreateOrUpdateCommand): Observable<Contact> {
    return this.http.post<Contact>(`${this.api.BaseUrl()}/assessors/createOrUpdate`, assessorCreateOrUpdateCommand);
  }

  public getAssignedAssessmentsSummary(): Observable<AssignedAssessmentsSummary[]> {
    return this.http.get<AssignedAssessmentsSummary[]>(`${this.api.Assessors()}/assignedsummary`);
  }
}

export interface AssessorCreateOrUpdateCommand {
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  assessorType: number;
  userGroupId: string;
  automatedQARule: number;
  qaRulePercentage: number;
}

export interface AssignedAssessmentsSummary {
  assessorId: string;
  assessor: string;
  bundles: number;
}
