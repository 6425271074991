import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { AccountService } from 'src/app/core/services/account/account.service';
import { Store } from '@ngrx/store';
import { AccountHttpService } from '../../../../core/services/account/account-http.service';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import {
  selectContractorAccountDistanceToOffice,
  selectContractorAccountId,
  selectContractorAccountWorkRegions,
} from '../../../../core/ngrx/selectors/contractor-account.selectors';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';

export interface WorkRegion {
  id: string;
  name: string;
  code: string;
  level: number;
  areas: WorkRegion[];
  parentCode?: string;
  hasChildren: boolean;
}

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: WorkRegion;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: WorkRegion;
  level: number;
  expandable: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  setData(x: WorkRegion[]) {
    this.dataChange.next(
      x.map((item) => {
        return {
          item: item,
          children: item.areas.map((child) => {
            return {
              item: child,
              children: child.areas.map((gchild) => {
                return { item: gchild, children: [] };
              }),
            };
          }),
        };
      })
    );
  }
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {}
}

@Component({
  selector: 'app-work-region-selecter',
  templateUrl: './work-region-selecter.component.html',
  styleUrls: ['./work-region-selecter.component.scss'],
  providers: [ChecklistDatabase],
})
export class WorkRegionSelecterComponent implements OnInit, OnDestroy {
  public readonly = true;
  public selectedWorkRegions$ = this.store.select(selectContractorAccountWorkRegions);
  public distance$ = this.store.select(selectContractorAccountDistanceToOffice);
  public updatedDistance: number;
  public permissionsEnum: typeof PermissionsEnum = PermissionsEnum;
  public permissionsRequest: PermissionsEnum = PermissionsEnum.WorkRegionsSummaryEditButton;
  public accountId: string;
  public hasSelectedWorkRegion: boolean;
  public workRegionsToBroadCast: WorkRegion[];
  public workStatesToBroadCast: WorkRegion[];
  public error: string;
  public isLoaded = false;
  public theme: ThemePalette = 'primary';
  public saving = false;

  private $destroyed = new Subject<void>();

  constructor(
    private _database: ChecklistDatabase,
    private accountService: AccountService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private readonly store: Store,
    private readonly accountHttpService: AccountHttpService,
    private readonly permissionsService: PermissionsService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  public ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public async ngOnInit(): Promise<void> {
    const companyWorkRegions = await firstValueFrom(this.selectedWorkRegions$);
    this.isLoaded = true;
    this.accountService
      .getAllWorkRegions()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((allRegions) => {
        if (allRegions.length == 0) return;
        this.isLoaded = true;
        this._database.setData(allRegions[0]);

        if (companyWorkRegions?.length > 0) {
          companyWorkRegions.forEach((preSelected) => {
            this.nestedNodeMap.forEach((element) => {
              if (element.item.code === preSelected.code && !this.checklistSelection.isSelected(element)) {
                this.todoItemSelectionToggle(element);
              }
            });
          });
        }
      });
  }

  public flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  public nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  public treeControl: FlatTreeControl<TodoItemFlatNode>;

  public treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  public dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  public checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  public getLevel = (node: TodoItemFlatNode) => node.level;

  public isExpandable = (node: TodoItemFlatNode) => node.expandable;

  public getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  public hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  public transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  public descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      })
    );
  }

  /** Whether part of the descendants are selected */
  public descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  public todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.broadcastSelection();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  public todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.broadcastSelection();
  }

  public broadcastSelection(): void {
    const selectionNodes: TodoItemFlatNode[] = this.checklistSelection.selected.sort((a, b) => (a.level < b.level ? -1 : 1));
    const nodesToBroadCast: TodoItemFlatNode[] = [];
    const stateNodesToBroadcast: TodoItemFlatNode[] = [];
    selectionNodes.forEach((x) => {
      if (x.level === 0) {
        nodesToBroadCast.push(x);
        stateNodesToBroadcast.push(x);
      }
      if (x.level === 1) {
        if (nodesToBroadCast.filter((node) => node.item.code === x.item.parentCode).length === 0) {
          nodesToBroadCast.push(x);
          const parent = this.getParentNode(x);
          if (stateNodesToBroadcast.filter((s) => s.item.id === parent.item.id).length === 0) {
            stateNodesToBroadcast.push(parent);
          }
        }
      }
    });
    this.hasSelectedWorkRegion = nodesToBroadCast.length > 0;
    this.workRegionsToBroadCast = nodesToBroadCast.map((x) => x.item);

    this.workStatesToBroadCast = stateNodesToBroadcast.map((x) => x.item);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  public checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  public checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  public getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  public getTreeIcon(node: TodoItemFlatNode): string {
    return this.treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right';
  }

  public updateDistance($event: any): void {
    $event.preventDefault();
    this.updatedDistance = $event.target.value;
  }

  get canSave(): boolean {
    return this.hasSelectedWorkRegion && this.updatedDistance > 0;
  }

  public async confirm(): Promise<void> {
    this.saving = true;
    await this.updateWorkAreas();
  }

  /**
   * Updates the account work regions with the selected value and the work distance number.
   */
  public async updateWorkAreas(): Promise<void> {
    const accountId: any = await firstValueFrom(this.store.select(selectContractorAccountId));
    try {
      await firstValueFrom(this.accountHttpService.saveAccountWorkRegions(accountId, this.workStatesToBroadCast, this.updatedDistance));
    } catch (e) {
      throw new Error(e);
    } finally {
      this.saving = false;
    }
  }

  public authenticatePermissions$(): Observable<boolean> {
    const permission = PermissionsMap.get(PermissionsEnum.WorkRegionsSummaryEditButton);
    return this.permissionsService.authenticateUser$(permission);
  }
}
