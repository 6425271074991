<div
  bsModal
  #assignAssessmentModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ assignLabel }}
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          *ngIf="
            isBulkAssignFeatureEnabled && assessments && assessments.length > 1
          "
          class="my-2 pb-2"
        >
          <div class="mh-250">
            <p>Selected assessments: {{ assessments.length }}</p>
            <table class="w-100">
              <colgroup>
                <col class="w-20pct" />
                <col class="w-40pct" />
                <col class="w-40pct" />
              </colgroup>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Contractor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    <div class="mh-200">
                      <table class="w-100">
                        <colgroup>
                          <col class="w-20pct" />
                          <col class="w-40pct" />
                          <col class="w-40pct" />
                        </colgroup>

                        <tr *ngFor="let assessment of assessments">
                          <td>{{ assessment.Id }}</td>
                          <td>{{ assessment.Product }}</td>
                          <td>{{ assessment.ContractorName }}</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="assessments && assessments.length === 1">
          <app-read-only-text-field
            name="Id"
            [value]="assessments[0].Id"
          ></app-read-only-text-field>
          <app-read-only-text-field
            name="Name"
            [value]="assessments[0].Product"
          ></app-read-only-text-field>
          <app-read-only-text-field
            name="Contractor"
            [value]="assessments[0].ContractorName"
          ></app-read-only-text-field>
        </div>

        <app-single-option-select-field
          name="Assessor"
          [options]="getAssessors()"
          (selectChange)="selectChange($event)"
          #assessorSelections
        ></app-single-option-select-field>

        <div class="container">
          <div class="row">
            <button
              class="btn btn-primary float-right"
              (click)="assign()"
              [class.is-loading]="isUpdating"
              [disabled]="disableAssign"
            >
              Assign
            </button>
          </div>
          <div class="row" *ngIf="successMessage">
            <div class="alert alert-success mt-3">{{ successMessage }}</div>
          </div>
          <div class="row" *ngIf="errorMessage">
            <div class="alert alert-danger mt-3">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
