import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { Document } from 'src/app/shared/models/document';
import { Observable } from 'rxjs/internal/Observable';
import * as Fuse from 'fuse.js';
import { ApiRoutesClass } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private httpClient: HttpClient, private readonly api: ApiRoutesClass) {}

  private documents: Document[];

  getAssessmentDocuments(accountId: string, assessmentId: string): Observable<[Document[], Document[]]> {
    return this.httpClient.get(`${this.api.Documents()}/accounts/${accountId}/assessments/${assessmentId}/documents`).pipe(
      map((categories: any) => {
        const currentDocuments: Document[] = [];
        const previousDocuments: Document[] = [];

        categories[0].documents.forEach((assessmentDocument: any) => {
          currentDocuments.push({
            name: assessmentDocument.name,
            type: assessmentDocument.type,
            product: assessmentDocument.module,
            uploadDate: new Date(assessmentDocument.uploaded),
            expiryDate: assessmentDocument.expiryDate ? new Date(assessmentDocument.expiryDate) : undefined,
            url: assessmentDocument.uri,
          });
        });

        categories[1].documents.forEach((assessmentDocument: any) => {
          previousDocuments.push({
            name: assessmentDocument.name,
            type: assessmentDocument.type,
            product: assessmentDocument.module,
            uploadDate: new Date(assessmentDocument.uploaded),
            expiryDate: assessmentDocument.expiryDate ? new Date(assessmentDocument.expiryDate) : undefined,
            url: assessmentDocument.uri,
          });
        });
        return [currentDocuments, previousDocuments];
      })
    );
  }

  searchForDocuments(documentName: string, documents: Document[]): Document[] {
    if (documentName.length === 0) {
      return this.documents;
    }
    //@ts-ignore
    const fuzzySearch = new Fuse(documents, {
      keys: ['name'],
    });

    return fuzzySearch.search(documentName);
  }

  downloadAllDocumentsForAssessment(contractorId: string, assessmentId: string): Observable<any> {
    return this.httpClient.get(`${this.api.Documents()}/contractors/${contractorId}/assessments/${assessmentId}/documents/zip`, {
      responseType: 'blob',
    });
  }

  download(documentUri: string): Observable<Blob> {
    return this.httpClient.get(documentUri, { responseType: 'blob' });
  }

  rewriteUrl(accountId: string, documentUri: string): string {
    const fileNameEncoded = documentUri.split('/').pop();
    const fileName = atob(fileNameEncoded);
    return `${this.api.Documents()}/contractors/${accountId}/documents/${fileName}`;
  }

  async downloadFileAsync(url: string, type: string = null): Promise<Blob> {
    return this.download(url)
      .toPromise()
      .then((data) => {
        let contentType = 'application/octet-stream';
        if (type) {
          contentType = type;
        }

        const blob = new Blob([data], { type: contentType });
        return blob;
      });
  }
}
