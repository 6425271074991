import { Component, Input } from '@angular/core';
import { ApiRequestTypes } from '../../../../core/ngrx/actions/MultigridAssessmentViewType.actions';
import { MultigridDashboardButton } from '../../../../core/ngrx/actions/MultigridDashboardButton.actions';
import { UIFilter } from '../../../../shared/models/ui-filter';
import { DataRequestTypeEnum } from '../../../../shared/enums/data-request-type-enum';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../../../core/services/search/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../../core/services/assessment/assessment.service';
import { ActionsSubject, Store } from '@ngrx/store';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { AccountService } from '../../../../core/services/account/account.service';
import { BundlesService } from '../../../../core/services/bundles/bundles.service';
import { AlertsService } from '../../../../core/services/alerts/alerts.service';
import { DataTableAbstract } from '../../../../shared/abstract-classes/data-table-abstract/data-table-abstract';

@Component({
  selector: 'app-insurance-table',
  templateUrl: './insurance-table.component.html',
  styleUrls: ['./insurance-table.component.scss'],
})
export class InsuranceTableComponent extends DataTableAbstract {
  @Input() tableName = '';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() enableRowClick: boolean;
  @Input() uiFilter: UIFilter[];
  @Input() dataRequestType: DataRequestTypeEnum;
  @Input() displayedColumns;
  @Input() displayAssignButton: boolean;
  @Input() columnNames: string[];
  public multigridDashboardButton: typeof MultigridDashboardButton = MultigridDashboardButton;

  constructor(
    protected dialog: MatDialog,
    protected searchService: SearchService,
    protected router: Router,
    protected assessmentService: AssessmentService,
    protected store: Store,
    protected permissionsService: PermissionsService,
    protected actions: ActionsSubject,
    protected accountService: AccountService,
    protected bundleService: BundlesService,
    protected alertsService: AlertsService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      dialog,
      searchService,
      router,
      assessmentService,
      store,
      permissionsService,
      actions,
      accountService,
      bundleService,
      alertsService,
      activatedRoute
    );
  }
  async ngOnInit(): Promise<void> {
    await this.getCompanyId();
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType);
  }
}
