/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AssessorCostSummary {
  id?: string;
  accountName?: string | null;
  bundleId?: string;
  bundleType?: string | null;
  assessorId?: string;
  assessorOrganisationName?: string | null;
  assessorName?: string | null;
  cost?: number | null;
  actionedById?: string | null;
  actionedBy?: string | null;
  actionedDate?: string | null;
}
