import { createAction, props } from "@ngrx/store";
import { ProductTable } from "src/app/shared/models/ProductTable";

export enum ProductTableActions {
  Load = "[Product Table] Load",
  LoadSuccess = "[Product Table] Load Successful",
  LoadError = "[Product Table] Load Error",
  Create = "[Product Create] Create",
}

export const ProductTableLoad = createAction(
  ProductTableActions.Load,
  props<{ accountId: string }>()
);
export const ProductTableLoadSuccess = createAction(
  ProductTableActions.LoadSuccess,
  props<{ Products: ProductTable[] }>()
);
export const ProductTableLoadError = createAction(
  ProductTableActions.LoadError,
  props<{ error: string }>()
);

export const ProductCreateSuccess = createAction(
  ProductTableActions.Create,
  props<{ Product: ProductTable }>()
);
