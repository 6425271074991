import { Component } from '@angular/core';
import { UIFilter } from '../../../../shared/models/ui-filter';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { GridView } from '../../../assessor-dashboard/your-dashboard/your-dashboard-table-controller/your-dashboard-table-controller.component';
import { DataRequestTypeEnum } from '../../../../shared/enums/data-request-type-enum';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { Observable } from 'rxjs';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';

@Component({
  selector: 'app-assessors-table-controller',
  templateUrl: './assessors-table-controller.component.html',
  styleUrls: ['./assessors-table-controller.component.scss'],
})
export class AssessorsTableControllerComponent {
  public filter: UIFilter[];
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum;

  public gridViews: GridView[] = [
    new GridView(
      'Enabled User Accounts',
      PermissionsEnum.ViewEnabledAccountsGrid,
      null,
      [],
      true,
      ['accountName', 'fullName', 'email'],
      [{ value: 0, property: 'enabledAccounts' }],
      DataRequestTypeEnum.UserAccounts,
      false,
      ['Account', 'Full Name', 'Email']
    ),
    new GridView(
      'Disabled User Accounts',
      PermissionsEnum.ViewDisabledAccountsGrid,
      null,
      [],
      true,
      ['accountName', 'fullName', 'email'],
      [{ value: 1, property: 'disabledAccounts' }],
      DataRequestTypeEnum.UserAccounts,
      false,
      ['Account', 'Full Name', 'Email']
    ),
  ];

  constructor(private readonly permissionsService: PermissionsService) {}

  public showHideTableView(gridview): Observable<boolean> {
    const permissionId: string = PermissionsMap.get(gridview);
    return this.permissionsService.authenticateUser$(permissionId);
  }
}
