<div class="search-results">
  <ng-container *ngIf='!loading else display'>

  </ng-container>
  <div class="mb-2" *ngIf="parentViewEnabled">
    <button
      class="btn btn-primary"
      (click)="openModal()"
      [disabled]="selectedRows?.length == 0"
      *ngIf="groupAssignment"
    >
      Assign Selected
    </button>
  </div>
  <div
    class="search-results-table-header d-flex align-items-center justify-content-left"
  >
    {{ header }}
  </div>
  <ngx-datatable
    [rows]="data"
    [columns]="columns"
    columnMode="force"
    rowHeight="auto"
    [headerHeight]="50"
    [footerHeight]="50"
    [loadingIndicator]="loading"
    [limit]="pageSize"
    [selectionType]="SelectionType.checkbox"
    [selectAllRowsOnPage]="false"
    [selected]="selectedRows"
    (select)="onSelect($event)"
    [scrollbarH]="true"
    class="material"
  >
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="false"
      [checkboxable]="true"
    >
    </ngx-datatable-column>
<ng-template #display let-row let-value="value">
  <ngx-datatable-column
    name="ID"
    prop="data"
  >
    <ng-container  ngx-datatable-cell-template>
      <button
        class="btn btn-link"
        (click)="assessmentSelected(row.InternalId)"
      >
        {{ row.Id }}
      </button>
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    name="Assigned Assessor"
    prop="AssignedAssessor"
    *ngIf="parentViewEnabled"
  >
    <ng-container ngx-datatable-cell-template>
      {{ row.AssignedAssessor }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Assign"
    [sortable]="true"
    *ngIf="parentViewEnabled"
  >
    <ng-container ngx-datatable-cell-template>
      <button
        type="button"
        class="btn btn-primary"
        (click)="assignmentModal.show([row])"
        *ngIf="isAssessmentComplete(row)"
      >
        {{ row.AssignedAssessorId === null ? "Assign" : "Re-Assign" }}
      </button>
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column name="Name" prop="ContractorName" [sortable]="true">
    <ng-container ngx-datatable-cell-template>
      <button
        class="btn btn-link"
        (click)="contractorSelected(row.InternalId)"
      >
        {{ row.ContractorName }}
      </button>
      <span class="fastTrack" *ngIf="row.FastTrack">
          <br /><strong>Fast Track</strong>
        </span>
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column name="Product" [sortable]="true" prop="Product">
    <ng-container ngx-datatable-cell-template>
      <button
        class="btn btn-link"
        (click)="assessmentSelected(row.InternalId)"
      >
        {{ row.AssessmentName }}<br />
        {{ row.Variant }}
      </button>
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Submitted"
    [sortable]="true"
    [comparator]="compareDateColumns"
    prop="Submissiondate"
  >
    <ng-container ngx-datatable-cell-template>
      {{
      isDateValid(row.Submissiondate)
        ? (row.Submissiondate | date: "dd/MM/yyyy")
        : ""
      }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column [sortable]="true" name="Status" prop="Status">
    <ng-container ngx-datatable-cell-template>
      {{ row.Status | assessmentDisplay }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    name="Incomplete Sections"
    prop="IncompleteSections"
  >
    <ng-container ngx-datatable-cell-template>
      {{ row.IncompleteSections === null ? "" : row.IncompleteSections }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    [comparator]="compareDateColumns"
    name="Assigned"
    prop="DateAssigned"
    [width]="200"
  >
    <ng-container ngx-datatable-cell-template>
      {{
      isDateValid(row.DateAssigned)
        ? (row.DateAssigned | date: "dd/MM/yyyy")
        : ""
      }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    [comparator]="compareDateColumns"
    name="Date Returned To Assessor"
    prop="DateReturnedToAssessor"
    [width]="200"
  >
    <ng-container ngx-datatable-cell-template>
      {{
      isDateValid(row.DateReturnedToAssessor)
        ? (row.DateReturnedToAssessor | date: "dd/MM/yyyy")
        : ""
      }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    [comparator]="compareDateColumns"
    prop="DateRequiredToRespond"
    name="Contractor Required Response Date"
    [width]="200"
  >
    <ng-container ngx-datatable-cell-template>
      {{
      isDateValid(row.DateRequiredToRespond)
        ? (row.DateRequiredToRespond | date: "dd/MM/yyyy")
        : ""
      }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    [comparator]="compareDateColumns"
    name="Assessor Target Decision Date"
    prop="TargetDecisionDate"
    [width]="200"
  >
    <ng-container ngx-datatable-cell-template>
      {{
      isDateValid(row.TargetDecisionDate)
        ? (row.TargetDecisionDate | date: "dd/MM/yyyy")
        : ""
      }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    [comparator]="compareDateColumns"
    name="Decision Date"
    prop="DecisionDate"
  >
    <ng-container ngx-datatable-cell-template>
      {{
      isDateValid(row.DecisionDate)
        ? (row.DecisionDate | date: "dd/MM/yyyy")
        : ""
      }}
    </ng-container>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    name="Employees"
    prop="NoOfEmployees"
  >
    <ng-container ngx-datatable-cell-template>
      {{ row.NoOfEmployees | employee }}
    </ng-container>
  </ngx-datatable-column>

</ng-template>

  </ngx-datatable>
</div>

<app-assignment-modal #assignmentModal></app-assignment-modal>
