import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'isCurrency',
})
export class IsCurrencyPipe implements PipeTransform {
  constructor(private pipe: CurrencyPipe) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'number' && value >= 50) {
      return this.pipe.transform(value, 'USD');
    }
    return value;
  }
}
