/**
 * Chas.MyChas.Portal.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CreateMultiQuoteCommand } from '../model/models';
import { CreateQuoteCommand } from '../model/models';
import { QuoteResult } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      httpParams = httpParams.append(key, JSON.stringify(value));
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param createQuoteCommand
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuote(
    createQuoteCommand?: CreateQuoteCommand,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<QuoteResult>;
  public getQuote(
    createQuoteCommand?: CreateQuoteCommand,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<HttpResponse<QuoteResult>>;
  public getQuote(
    createQuoteCommand?: CreateQuoteCommand,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<HttpEvent<QuoteResult>>;
  public getQuote(
    createQuoteCommand?: CreateQuoteCommand,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<QuoteResult>(`${this.configuration.basePath}/api/v1/pricing/quote`, createQuoteCommand, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param createMultiQuoteCommand
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuotes(
    createMultiQuoteCommand?: CreateMultiQuoteCommand,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<Array<QuoteResult>>;
  public getQuotes(
    createMultiQuoteCommand?: CreateMultiQuoteCommand,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<QuoteResult>>>;
  public getQuotes(
    createMultiQuoteCommand?: CreateMultiQuoteCommand,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<QuoteResult>>>;
  public getQuotes(
    createMultiQuoteCommand?: CreateMultiQuoteCommand,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<QuoteResult>>(`${this.configuration.basePath}/api/v1/pricing/quotes`, createMultiQuoteCommand, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param accountId
   * @param contactId
   * @param applicationType
   * @param assessmentType
   * @param sectorType
   * @param membershipLevel
   * @param isFIRFee
   * @param fastTrack
   * @param joiningFee
   * @param bundleNames
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuotesOnly(
    accountId?: string,
    contactId?: string,
    applicationType?: number,
    assessmentType?: number,
    sectorType?: number,
    membershipLevel?: string,
    isFIRFee?: boolean,
    fastTrack?: boolean,
    joiningFee?: boolean,
    bundleNames?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<Array<QuoteResult>>;
  public getQuotesOnly(
    accountId?: string,
    contactId?: string,
    applicationType?: number,
    assessmentType?: number,
    sectorType?: number,
    membershipLevel?: string,
    isFIRFee?: boolean,
    fastTrack?: boolean,
    joiningFee?: boolean,
    bundleNames?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<QuoteResult>>>;
  public getQuotesOnly(
    accountId?: string,
    contactId?: string,
    applicationType?: number,
    assessmentType?: number,
    sectorType?: number,
    membershipLevel?: string,
    isFIRFee?: boolean,
    fastTrack?: boolean,
    joiningFee?: boolean,
    bundleNames?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<QuoteResult>>>;
  public getQuotesOnly(
    accountId?: string,
    contactId?: string,
    applicationType?: number,
    assessmentType?: number,
    sectorType?: number,
    membershipLevel?: string,
    isFIRFee?: boolean,
    fastTrack?: boolean,
    joiningFee?: boolean,
    bundleNames?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (accountId !== undefined && accountId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>accountId, 'AccountId');
    }
    if (contactId !== undefined && contactId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>contactId, 'ContactId');
    }
    if (applicationType !== undefined && applicationType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>applicationType, 'ApplicationType');
    }
    if (assessmentType !== undefined && assessmentType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assessmentType, 'AssessmentType');
    }
    if (sectorType !== undefined && sectorType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sectorType, 'SectorType');
    }
    if (membershipLevel !== undefined && membershipLevel !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>membershipLevel, 'MembershipLevel');
    }
    if (isFIRFee !== undefined && isFIRFee !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isFIRFee, 'IsFIRFee');
    }
    if (fastTrack !== undefined && fastTrack !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fastTrack, 'FastTrack');
    }
    if (joiningFee !== undefined && joiningFee !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>joiningFee, 'JoiningFee');
    }
    if (bundleNames) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>bundleNames, 'BundleNames');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' = 'json';
    if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<QuoteResult>>(`${this.configuration.basePath}/api/v1/pricing/quotesonly`, null, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
