import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { AssessmentModuleCreate } from 'src/app/shared/models/AssessmentModuleCreate';
import { ModuleHttpService } from 'src/app/core/services/account/ModuleHttpService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QuestionSetTable } from 'src/app/shared/models/QuestionSetTable';
import { QuestionSetHttpService } from 'src/app/core/services/account/QuestionSetHttpService';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuestionSetDialogComponent } from '../question-set-dialog/question-set-dialog.component';
import { getLoaded } from '../../../core/ngrx/selectors/contractor-account.selectors';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-module-add-or-update',
  templateUrl: './module-add-or-update.component.html',
  styleUrls: ['./module-add-or-update.component.scss'],
})
export class ModuleAddOrUpdateComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<QuestionSetTable>;
  displayedColumns: string[] = ['name', 'version', 'questionSetNumber', 'validFrom', 'expires', 'created', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  CompanySummary3Sub: Subscription;
  isLoading$ = this.store.select(getLoaded);
  detailsForm = this.initializeForm();
  moduleTypes: any[];
  assessmentTypes: any[];
  variants: any[];
  id: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store,
    private accountService: ModuleHttpService,
    private questionSetService: QuestionSetHttpService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.accountService.GetAssessmentModuleById(this.id).subscribe((t) => {
        this.detailsForm = this.initializeForm(t);
      });
      this.questionSetService.getQuestionSetTableByModuleId(this.id).subscribe((t) => {
        this.dataSource = new MatTableDataSource(t);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    }
    this.accountService.getInitializeModule().subscribe((t) => {
      this.moduleTypes = t.moduleTypes;
      this.assessmentTypes = t.assessmentTypes;
      this.variants = t.variants;
    });
  }

  ngOnDestroy(): void {
    if (this.CompanySummary3Sub) {
      this.CompanySummary3Sub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  initializeForm(account: AssessmentModuleCreate = {} as AssessmentModuleCreate): UntypedFormGroup {
    return this.fb.group({
      id: [account.id || ''],
      name: [account.name || '', Validators.required],
      moduleType: [(account.moduleType as any)?.id || '', Validators.required],
      assessmentType: [account.assessmentType || ''],
      moduleNumber: [account.moduleNumber || ''],
      variant: [account.variant || ''],
      employeeLower: [account.employeeLower || 0],
      employeeUpper: [account.employeeUpper || 0],
    });
  }

  create(): void {
    if (this.id != null) {
      this.detailsForm.patchValue({ id: this.id });
    }
    if (!this.detailsForm.value.assessmentType) {
      this.detailsForm.patchValue({ assessmentType: null });
    }
    if (this.detailsForm.value.moduleType) {
      this.detailsForm.patchValue({
        moduleType: { id: this.detailsForm.value.moduleType },
      });
    }
    if (!this.detailsForm.value.variant) {
      this.detailsForm.patchValue({ variant: null });
    }
    this.accountService.createOrUpdateModule(this.detailsForm.value).subscribe((t) => {
      this.router.navigate(['/assessment/module']);
    });
  }

  dialogRef: any;
  dialogConfig: MatDialogConfig = {
    width: '1200px',
    panelClass: 'confirm-dialog-container1',
    disableClose: true,
  };

  onAdd() {
    this.dialogConfig.data = {
      moduleId: this.id,
    };
    const dialogRef = this.dialog.open(QuestionSetDialogComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      this.questionSetService.getQuestionSetTableByModuleId(this.id).subscribe((t) => {
        this.dataSource = new MatTableDataSource(t);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    });
  }

  onDelete(selectedRow: QuestionSetTable): void {
    this.questionSetService.DeleteModuleQuestionSet(selectedRow.id).subscribe((t) => {
      this.questionSetService.getQuestionSetTableByModuleId(this.id).subscribe((t) => {
        this.dataSource = new MatTableDataSource(t);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    });
  }

  public hasError = (controlName: string, errorName: string) => this.detailsForm.controls[controlName].hasError(errorName);
}
