import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReadOnlyTextFieldComponent } from './components/read-only-text-field/read-only-text-field.component';
import { SingleOptionSelectFieldComponent } from './components/single-option-select-field/single-option-select-field.component';
import { WorkRegionDisplayPipe } from './pipe/work-region-display/work-region-display.pipe';
import { AssessmentDisplayPipe } from './pipe/assessment-display/assessment-display.pipe';
import { AccountUrlPipe } from './pipe/account-url-pipe/account-url.pipe';
import { DataTableComponent } from './components/data-table/data-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MaterialModule } from '../../material/material.module';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { DataTableButtonBarComponent } from './components/data-table-button-bar/data-table-button-bar.component';
import { IsDatePipe } from './pipe/is-date.pipe';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { NotesAccordionComponent } from './components/notes-accordian/notes-accordion.component';
import { PhoneCallAccordionComponent } from './components/phone-call-accordian/phone-call-accordion.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TruncatePipe } from './pipe/truncate-pipe/truncate.pipe';
import { NgxEditorModule } from 'ngx-editor';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { IsLinkPipe } from './pipe/is-link.pipe';
import { IsCurrencyPipe } from './pipe/is-currency.pipe';
import { ContainsHtmlPipe } from './pipe/contains-html.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DataFormatterPipe } from './pipe/data-formatter-pipe';
import { FilterByPipe } from './pipe/filter-by.pipe';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AccountUrlPipe,
    AssessmentDisplayPipe,
    ConfirmModalComponent,
    DataTableButtonBarComponent,
    DataTableComponent,
    LoadingPageComponent,
    ReadOnlyTextFieldComponent,
    SingleOptionSelectFieldComponent,
    WorkRegionDisplayPipe,
    IsDatePipe,
    ActionButtonsComponent,
    NotesAccordionComponent,
    PhoneCallAccordionComponent,
    TruncatePipe,
    IsLinkPipe,
    IsCurrencyPipe,
    DataFormatterPipe,
    ContainsHtmlPipe,
    FilterByPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    RouterModule,
    MatTableExporterModule,
    NgxEditorModule,
    MatSelectModule,
    MatSnackBarModule,
    AngularEditorModule,
  ],
  exports: [
    AccountUrlPipe,
    AssessmentDisplayPipe,
    DataTableButtonBarComponent,
    DataTableComponent,
    ReadOnlyTextFieldComponent,
    SingleOptionSelectFieldComponent,
    WorkRegionDisplayPipe,
    MatDialogModule,
    MatFormFieldModule,
    ActionButtonsComponent,
    NotesAccordionComponent,
    PhoneCallAccordionComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    IsDatePipe,
    TruncatePipe,
    IsCurrencyPipe,
    DataFormatterPipe,
    FilterByPipe,
  ],
  providers: [CurrencyPipe, IsCurrencyPipe],
})
export class SharedModule {}
