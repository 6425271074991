<div *ngIf='!alert.summary; else display'>
      <mat-progress-bar mode="query"></mat-progress-bar>
</div>

<ng-template #display  class='further-info-modal-section'>
  <div class='questionHeader'>
    <h1>{{alert.assessmentName}} Alert</h1>
  </div>
  <div class='questions'>
    <div *ngFor='let question of alert.summary'>
      <p class='info-heading'>{{question.questionDesc}}:</p>
      <p class='info-response'>{{question.answer}}</p>
      <div *ngFor='let document of question.documentLinks'>
        <button mat-stroked-button color="primary"> <a [href]='document'>View Document</a></button>
      </div><br/>
    </div>
  </div>
  <div *ngIf='data.selection?.actionableContent'>
   <div>
     <textarea cols='80' rows='10' [(ngModel)]='comments' ></textarea>
   </div>
    <button mat-stroked-button color="primary" (click)='close()'> Cancel </button>
    <button [disabled]='isDisabled || !comments' mat-stroked-button color="warn" (click)='actionVerificationAlert(false, alert.docVerificationId)'>Request Additional Information</button>
    <button [disabled]='isDisabled' mat-stroked-button color="primary" (click)='actionVerificationAlert(true, alert.docVerificationId)'> Approve </button>
  </div>
</ng-template>



