<ng-content></ng-content>
<form [formGroup]="parent" novalidate>
  <div class="form-group row">
    <label class="col-md-5"> Liability Insurance</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else companyName">
        <input
          id="companyName"
          class="form-control"
          type="text"
          formControlName="companyName"
          title="companyName"
        />
      </div>
      <ng-template #companyName>
        {{ parent.value.companyName }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5">Insurer</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else insurer">
        <input
          id="insurer"
          class="form-control"
          type="text"
          formControlName="insurer"
          title="insurer"
        />
      </div>
      <ng-template #insurer>
        {{ parent.value.insurer }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5"> Policy Number</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else policyNumber">
        <input
          id="policyNumber"
          class="form-control"
          type="text"
          formControlName="policyNumber"
          title="policyNumber"
        />
      </div>
      <ng-template #policyNumber>
        {{ parent.value.policyNumber }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5">Excess</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else excess">
        <input
          id="excess"
          class="form-control"
          type="text"
          formControlName="excess"
          title="excess"
        />
      </div>
      <ng-template #excess>
        {{ parent.value.excess }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5"> Limit for Single Event</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else limitforaSingleEvent">
        <input
          id="limitforaSingleEvent"
          class="form-control"
          type="text"
          formControlName="limitforaSingleEvent"
          title="limitforaSingleEvent"
        />
      </div>
      <ng-template #limitforaSingleEvent>
        {{ parent.value.limitforaSingleEvent }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5">Expiry Date</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else expiryDate">
        <input
          id="expiryDate"
          class="form-control"
          type="text"
          formControlName="expiryDate"
          title="expiryDate"
        />
      </div>
      <ng-template #expiryDate>
        {{ parent.value.expiryDate }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5"> Document</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else membershipId">
        <input
          id="membershipId"
          class="form-control"
          type="text"
          formControlName="membershipId"
          title="membershipId"
        />
      </div>
      <ng-template #membershipId>
        {{ parent.value.membershipId }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5">Verification Status</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else employersLiabilityVerificationStatus">
        <input
          id="employersLiabilityVerificationStatus"
          class="form-control"
          type="text"
          formControlName="employersLiabilityVerificationStatus"
          title="employersLiabilityVerificationStatus"
        />
      </div>
      <ng-template #employersLiabilityVerificationStatus>
        {{ parent.value.employersLiabilityVerificationStatus }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-5"> Rejection Reason</label>
    <div class="col-md-7">
      <div *ngIf="!readOnly; else employersLiabilityRejectionReason">
        <input
          id="employersLiabilityRejectionReason"
          class="form-control"
          type="text"
          formControlName="employersLiabilityRejectionReason"
          title="employersLiabilityRejectionReason"
        />
      </div>
      <ng-template #employersLiabilityRejectionReason>
        {{ parent.value.employersLiabilityRejectionReason }}
      </ng-template>
    </div>
  </div>
</form>
<!-- <pre>{{ parent.value | json }}</pre> -->
