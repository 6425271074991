import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { QuestionSetTable } from "src/app/shared/models/QuestionSetTable";
import { QuestionSetHttpService } from "src/app/core/services/account/QuestionSetHttpService";
import {
  getQuestionSetTableLoadingSelector,
  getQuestionSetTableSelector,
} from "src/app/core/ngrx/selectors/QuestionSetTable.selectors";
import { QuestionSetTableActions } from "src/app/core/ngrx/actions/QuestionSetTable.actions";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-question-set-dialog",
  templateUrl: "./question-set-dialog.component.html",
  styleUrls: ["./question-set-dialog.component.scss"],
})
export class QuestionSetDialogComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<QuestionSetTable>;
  displayedColumns: string[] = [
    "name",
    "version",
    "questionSetNumber",
    "validFrom",
    "expires",
    "created",
    "actions",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchKey: string;

  QuestionSetTableSub: Subscription;
  isLoading$ = this.store.select(getQuestionSetTableLoadingSelector);
  selectedRow: QuestionSetTable;
  moduleId: string;
  mappedQuestionSetTable: QuestionSetTable[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store,
    private accountService: QuestionSetHttpService,
    private dialogRef: MatDialogRef<QuestionSetDialogComponent>,
    private questionSetService: QuestionSetHttpService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.moduleId = data.moduleId;
  }

  ngOnInit(): void {
    this.QuestionSetTableSub = this.store
      .select(getQuestionSetTableSelector)
      .subscribe((t) => {
        this.dataSource = new MatTableDataSource(t);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    this.questionSetService
      .getQuestionSetTableByModuleId(this.moduleId)
      .subscribe((t) => {
        this.mappedQuestionSetTable = t;
      });
    this.store.dispatch({
      type: QuestionSetTableActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.QuestionSetTableSub) {
      this.QuestionSetTableSub.unsubscribe();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    const findMappedQuestionSet = this.mappedQuestionSetTable.find(
      (t) => t.id == this.selectedRow.id
    );
    if (findMappedQuestionSet) {
      return alert("Already linked");
    }
    this.accountService
      .SaveModuleQuestionSet({
        questionSetId: this.selectedRow.id,
        moduleId: this.moduleId,
      })
      .subscribe((t) => {
        this.dialogRef.close();
        this.router.navigate([`/assessment/editModule/${this.moduleId}`]);
      });
  }

  onSearchClear(): void {
    this.searchKey = "";
  }

  applyFilter(): void {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

  onSelect(row: QuestionSetTable): void {
    this.selectedRow = row;
  }
}
