<div *ngIf="loading; else loaded">
  <mat-progress-bar mode="query"></mat-progress-bar>
  <p class="text-center">Loading Timeline</p>
</div>

<ng-template #loaded>
  <div class="timeline px-5 h-100">
    <h3><b>Timeline</b></h3>
    <mat-form-field appearance="fill" class="example-form-field">
      <mat-label>Search Timeline</mat-label>
      <input matInput type="text" [(ngModel)]="searchArgs">
    </mat-form-field>
    <app-notes-accordion></app-notes-accordion>
    <br />
    <app-phone-call-accordion></app-phone-call-accordion>
    <br />
    <mat-divider></mat-divider>
    <div class="d-flex flex-column overflow-auto timelineItem">
      <div *ngFor="let cardData of timelineData | filterBy:  searchArgs" >
        <mat-card  class="my-2 timelineCard">
          <h4><strong>Author:</strong> {{ cardData.createdBy }}</h4>
          <h4><strong>Date:</strong>{{ cardData.createdOn | isDate : dateType.DATETIME_FULL }}</h4>
          <h4><strong>Type:</strong>{{ cardData.type}}</h4>
          <h4><strong>Subject:</strong>{{cardData.subject}}</h4>
          <button type="button" class="btn btn-primary float-right" (click)="displayModal(cardData)">Open {{cardData.type}}</button>
        </mat-card>
      </div>

    </div>

  </div>
</ng-template>
