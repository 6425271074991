<div class="text-center" *ngIf="isLoading$ | async; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
  <div fxLayout="column wrap" fxLayoutGap="10px" class="panel-container">
    <div class="panel-top-container" fxLayout="column">
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <div class="panel-top-left" fxLayoutAlign="start end">
          <app-insurance [parent]="detailsForm.get('employersLiability')"
            >Employers Liability Insurance</app-insurance
          >
        </div>
        <div class="panel-top-left" fxLayoutAlign="start end">
          <app-insurance [parent]="detailsForm.get('publicLiabilityInsurance')"
            >Public Liability Insurance</app-insurance
          >
        </div>
        <div class="panel-top-left" fxLayoutAlign="start end">
          <app-insurance [parent]="detailsForm.get('professionalIndemnity')"
            >Professional Indemnity Insurance</app-insurance
          >
        </div>
        <div class="panel-top-left" fxLayoutAlign="start end">
          <app-insurance [parent]="detailsForm.get('productLiabilityInsurance')"
            >Product Liability Insurance</app-insurance
          >
        </div>
        <div class="panel-top-left" fxLayoutAlign="start end">
          <app-insurance [parent]="detailsForm.get('productLiabilityInsurance')"
            >Fleet Insurance</app-insurance
          >
        </div>
        <div class="panel-top-left" fxLayoutAlign="start end">
          <app-insurance [parent]="detailsForm.get('productLiabilityInsurance')"
            >Contractor All Risk Insurance</app-insurance
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>
