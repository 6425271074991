import { Component, OnInit } from '@angular/core';
import {AddClientSpecificModuleCommand} from "../../../shared/models/add-client-specific-module-command";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountViewModel} from "../../../core/open-api";
import {AccountHttpService} from "../../../core/services/account/account-http.service";
import {firstValueFrom} from "rxjs";
import {ClientModuleHttpService} from "../../../core/services/Client-Module/client-module-http.service";

@Component({
  selector: 'app-add-edit-client-module',
  templateUrl: './add-edit-client-module.component.html',
  styleUrls: ['./add-edit-client-module.component.scss']
})

export class AddEditClientModuleComponent implements OnInit {
  public detailsForm: UntypedFormGroup;
  public command: AddClientSpecificModuleCommand;
  public assessmentTypeOptions = [{ id: 885570000, name: "Assessment" },
    { id: 885570001, name: "Self-Certify" },
    { id: 885570002, name: "Deemed to Satisfy" },
    { id: 885570003, name: "Verification" },
    { id: 885570004, name: "Onsite" },
    { id: 885570005, name: "Assured" }
  ];

  public clientOptions: AccountViewModel[];
  private id = this.route.snapshot.paramMap.get("id");
  public isLoading = true;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly route: ActivatedRoute,

              private readonly router: Router,
              private readonly accountHttpService: AccountHttpService,
              private readonly clientModuleService: ClientModuleHttpService) { }

  async ngOnInit(): Promise<void> {
    await this.initializeForm();
    this.clientOptions = await firstValueFrom(this.accountHttpService.getAllClients$());
    this.isLoading = false;
  }

  private async initializeForm() : Promise<void> {
    this.detailsForm = this.formBuilder.group({
      name: [ null, Validators.required],
      clientAccountId: [ null, Validators.required],
      assessmentType: [ null, Validators.required]
    });

    if (this.id) {
      let module = await firstValueFrom(this.clientModuleService.getModule$(this.id));
      this.detailsForm.setValue({
        name: module.name,
        clientAccountId: module.clientAccountId,
        assessmentType: module.assessmentTypeId
      })
    }
  }

  async onSave() : Promise<void> {
    this.command = {
      name: this.detailsForm.get('name').value,
      clientAccountId: this.detailsForm.get('clientAccountId').value,
      assessmentType: this.detailsForm.get('assessmentType').value
    }

    await firstValueFrom(this.clientModuleService.addOrUpdateClientModule$(this.command, this.id));

    await this.router.navigate(['/', 'client-modules']);
  }
}
