import { createReducer, on } from "@ngrx/store";
import { WorkCategory } from "src/app/shared/models/Work-Category";
import * as AppState from "../../../State";
import {
  WorkCategoriesLoad,
  WorkCategoriesLoadError,
  WorkCategoriesLoadSuccess,
} from "../actions/WorkCategory.actions";

export interface State extends AppState.State {
  tradeWorkcategory: WorkCategory[];
}

export interface WorkCategoriesState {
  workcategory: WorkCategory[];
  loading: boolean;
  error: string;
}

const initialState: WorkCategoriesState = {
  workcategory: undefined,
  loading: true,
  error: "",
};

const _WorkCategoriesReducer = createReducer(
  initialState,
  on(WorkCategoriesLoad, (state, action) => ({
    ...state,
    loading: true,
    error: "",
  })),
  on(
    WorkCategoriesLoadSuccess,
    (state, action): WorkCategoriesState => ({
      ...state,
      loading: false,
      error: "",
      workcategory: action.WorkCategories,
    })
  ),
  on(
    WorkCategoriesLoadError,
    (state, action): WorkCategoriesState => ({
      ...state,
      error: action.error,
    })
  )
);

export function WorkCategoriesReducer(state, action) {
  return _WorkCategoriesReducer(state, action);
}
