<div class="container-fluid insurance-table-container" *ngIf="authenticatePermissions$(permissionEnum.CompanyInformation) | async">
    <div class="d-flex flex-column" *ngIf="insuranceList$ | async as insuranceArray; else isLoading">
        <div class="d-flex flex-row mt-3 mb-2">
            <div class="flex-col"><strong>Insurance Type</strong></div>
            <div class="flex-col"><strong>Insurer</strong></div>
            <div class="flex-col"><strong>Expiry Date</strong></div>
            <div class="flex-col"><strong>Limit of Indemnity</strong></div>
            <div class="flex-col"><strong>Status</strong></div>
            <div class="flex-col"><strong>Policies</strong></div>
        </div>
        <div *ngFor="let insurance of insuranceArray" class='d-flex flex-row'>
            <div class="flex-col">
                <div class="px-0">
                    <div class="d-flex col-12 px-0 mr-0 mb-1 py-1">

                        <div class="col-2 px-0 mt-4">{{insurance.type}}</div>
                        <div class="col-2 px-0 mt-4">{{insurance.insurer}}</div>
                        <div class="col-2 px-0 mt-4">{{insurance.expiryDate | isDate}}</div>
                        <div class="col-2 px-0 mt-4">${{insurance.limitOfIndemnity}}</div>
                        <div class="col-2 px-0 mt-4">{{insurance.statusText}}</div>

                        <div class="col-2 px-0">
                            <mat-form-field>
                                <mat-label>Documents</mat-label>
                                <mat-select>
                                    <mat-option *ngFor="let document of insurance.uploadsInfo" class=" py-1">
                                        <a target="_blank" [href]="document.uri"> {{document.fileName}}</a>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<ng-template #isLoading>
    <mat-progress-bar class='loading-bar' mode='indeterminate'></mat-progress-bar>
</ng-template>