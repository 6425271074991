import { Injectable } from '@angular/core';
import { ApiRoutesClass, AssessmentSummary, Assessor } from '../../../shared';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UIFilter } from '../../../shared/models/ui-filter';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  assessors: Assessor[] = [];
  assessments: AssessmentSummary[];

  constructor(private readonly _httpClient: HttpClient, private api: ApiRoutesClass) {}

  public retrieveAlerts$(viewType, filter?: UIFilter): Observable<Alert[]> {
    let params = null;
    if (filter) {
      params = new HttpParams({
        fromString: `filter=${JSON.stringify(filter)}`,
      });
    }
    return this._httpClient

      .get<Alert>(`${this.api.Alerts()}?view=${viewType}`, {
        responseType: 'json',
        params: params ? params : null,
      })
      .pipe(
        map((alerts: any) => {
          return alerts.map((alert) => {
            this.calculateExpiryState(alert.documentExpiryDate);
            return {
              ...alert,
              type: 'alert',
              expirationState: this.calculateExpiryState(alert.documentExpiryDate),
            };
          });
        })
      );
  }

  public retrieveOutstandingAlerts$(accountId): Observable<OutstandingAlert[]> {
    let params = null;

    return this._httpClient
      .get<OutstandingAlert>(`${this.api.Notifications()}/accounts/${accountId}/getoutstandingalerts`, {
        responseType: 'json',
        params: params ? params : null,
      })
      .pipe(
        map((alerts: any) => {
          return alerts.map((alert) => {            
            return {
              ...alert,
              type: 'alert' 
            };
          });
        })
      );
  }

  /**
   * Calculate state of document expiry
   * @param expiryDate
   * @private
   * @returns string
   */
  private calculateExpiryState(expiryDate: number): string {
    const todaysDate = DateTime.now();
    const documentExpiration = DateTime.fromISO(expiryDate as any);
    const diffInWeeks = todaysDate.diff(documentExpiration, 'weeks').toObject();

    if (documentExpiration > todaysDate) {
      return 'Valid';
    } else if (documentExpiration < todaysDate && diffInWeeks.weeks > -4.0 && diffInWeeks.weeks < 0) {
      return 'Expiring';
    } else {
      return 'Expired';
    }
  }

  /**
   * Method to assign an alert to an advisor
   * @param assignedAssessor
   * @param docVerificationId
   */
  public assignAlertToAdvisor$(assignedAssessor: string, docVerificationId: string): Observable<HttpResponse<any>> {
    const payload = {
      docVerificationId,
      assigneeId: assignedAssessor,
    };

    return this._httpClient.post<any>(this.api.AssignAlert(), payload);
  }

  /**
   * Method to control approving or rejecting a verification alert
   * @param payload - Containing approved or rejected status with a message
   * @HTTP GET
   */
  public actionAlert(payload: AlertAction): Observable<any> {
    try {
      return this._httpClient.post<any>(`${this.api.Notifications()}/validateunverifiedanswersAync`, payload);
    } catch (e) {
      console.error(e);
    }
  }
}

export enum AlertsApiRequestTypes {
  GetAllAlerts = 1,
  GetUnassignedAlerts = 2,
  GetMyAlerts = 3,
  AssignAlert = 4,
  CompanyAlerts = 5,
}

export enum AlertsFilterTypes {
  Submitted = 0,
  Approved = 1,
  Rejected = 2,
}

export type AlertAction = {
  approve: boolean;
  comments: string;
  docVerificationId: string;
};

export type Alert = {
  accountId: string;
  accountName: string;
  assessmentId: string;
  assessmentName: string;
  assigneeId: string;
  bundle: string;
  createdDateTime: string;
  docVerificationId: string;
  documentId: string;
  documentTitle: string;
  statusDesc: string;
  statusId: string;
  type: string;
  summary: AlertSummary[];
};

export type OutstandingAlert = { 
  assessmentName: string;
  questionDesription: string;
  content: string;
  expiryDateTime: string;
};

type AlertSummary = {
  questionDesc: string;
  answer: string;
  documentLinks: [];
};
