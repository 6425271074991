 <div class="panel">
    <div class="panel__content">
        <div *ngIf="formGroup" [formGroup]="formGroup">
            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Membership Type</mat-label>
                </div>
                    <mat-form-field appearance="fill" >
                            <input formControlName="membershipType" matInput [readonly]="true" />
                    </mat-form-field>
            </div>

            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Membership Expiry</mat-label>
                </div>
                <div class="form-field__input">
                    <mat-form-field appearance="fill">
                        <mat-label>Month and Year</mat-label>
                        <input matInput [matDatepicker]="dp" formControlName="membershipExpiry" [readonly]="!editing">
                        <mat-datepicker-toggle [disabled]="!editing" matIconSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp
                                        startView="multi-year"
                                        panelClass="example-month-picker">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Status</mat-label>
                </div>
                    <mat-form-field appearance="fill">
                        <input formControlName="statusId" matInput [readonly]="true" />
                    </mat-form-field>
            </div>
            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Current Opportunity Owner</mat-label>
                </div>
                <div class="form-field__input">
                        <mat-form-field appearance="fill">
                            <input formControlName="opportunityOwnerName" matInput [readonly]="true"  />
                        </mat-form-field>
                    </div>
                </div>

            <div class="form-field">
                <div class="form-field__label">
                    <mat-label>Accreditation Expiry</mat-label>
                </div>
                <div class="form-field__input">
                    <mat-form-field appearance="fill">
                        <input matInput [matDatepicker]="picker1" formControlName="accreditationExpiry" [readonly]="!editing">
                        <mat-datepicker-toggle [disabled]="!editing" matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </div>
<!--    <div class="panel__footer" *ngIf="displayActionButtons">-->
<!--        <app-panel-action-buttons (buttonStateEmitter)="editing = $event" [loading]="loading" (submitEmitter)="submit()" ></app-panel-action-buttons>-->
<!--    </div>-->
    <div *ngIf="loading" class="panel__loading">
        <app-loading-spinner></app-loading-spinner>
    </div>
</div>
