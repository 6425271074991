import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, firstValueFrom } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ViewerType } from 'src/app/core/models/viewer-type';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AssessmentAction } from 'src/app/core/services/assessment/actions/assessment-action';
import { CompleteAssessmentAction } from 'src/app/core/services/assessment/actions/complete-assessment-action';
import { FailAssessmentAction } from 'src/app/core/services/assessment/actions/fail-assessment-action';
import { ProgressiveSaveAction } from 'src/app/core/services/assessment/actions/progressive-save-action';
import { RequireAdditionalInformationAction } from 'src/app/core/services/assessment/actions/require-additional-information-action';
import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { BundlesService } from 'src/app/core/services/bundles/bundles.service';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { AssessmentSummary, ContractorAccount, WorkCategories } from 'src/app/shared';
import { AssessmentStatus } from 'src/app/shared/classes/assessment-status';
import { AssessmentData } from 'src/app/shared/models/AssessmentData';
import { AssessorAlert } from 'src/app/shared/models/AssessorAssessmentAlert';
import { AssessmentPageInfo } from 'src/app/shared/models/assessment-page-info';
import * as Survey from 'survey-angular';
import { Question } from 'survey-angular';
import {
  selectCurrentUserAccountType,
  selectCurrentUserContactId
} from '../../../../core/ngrx/selectors/currentUser.selectors';
import { PermissionsService } from '../../../../core/services/permissions/permissions.service';
import { ProductivityService } from '../../../../core/services/productivity/productivity.service';
import { AssessmentProgressStatus } from '../../../../shared/classes/assessment-progress-status';
import { SnackBarClass } from '../../../../shared/components/snack-bar/snack-bar.class';
import { PermissionsEnum } from '../../../../shared/enums/permissions-enum';
import { PermissionsMap } from '../../../../shared/maps/permissions-map';
import { ProductivityAssessmentStatus, ProductivityInfo } from '../../../../shared/models/productivity-info';
import { HelptipComponent } from '../helptip/helptip.component';
import { BusinessSummaryModalComponent } from '../modals/business-summary-modal/business-summary-modal.component';
import { FailModalComponent } from '../modals/fail-modal/fail-modal.component';
import { FailToResubmitModalComponent } from '../modals/fail-to-resubmit-modal/fail-to-resubmit-modal.component';
import { FurtherInfoSurveyModalComponent } from '../modals/further-info-survey-modal/further-info-survey-modal.component';
import { WorkCategoriesModalComponent } from '../modals/work-categories-modal/work-categories-modal.component';
import SurveyValidators from './survey-validators';
import jsonSurveyJSCSS from './surveyjs-css.json';
import { PassedManualQAAction } from 'src/app/core/services/assessment/actions/passed-manual-qa-action';
import { FailedManualQAAction } from 'src/app/core/services/assessment/actions/failed-manual-qa-action';

@Component({
  selector: 'app-survey-js-assessment',
  templateUrl: './survey-js-assessment.component.html',
  styleUrls: ['./survey-js-assessment.component.scss'],
})
export class SurveyJsAssessmentComponent implements OnInit, OnDestroy {
  @Output() updateSuccess: EventEmitter<any> = new EventEmitter();
  @ViewChild('furtherInfoModal', { static: false })
  public furtherInfoModal: TemplateRef<any>;
  @ViewChild('failModal') public failModal: TemplateRef<any>;
  @ViewChild('workCategoriesModal')
  public workCategoriesModal: TemplateRef<any>;
  @ViewChild('businessSummaryModal')
  public businessSummaryModal: TemplateRef<any>;
  @ViewChild('failToResubmitModal')
  public failToResubmitModal: TemplateRef<any>;
  public userAccountType: string;
  subscriptions: Subscription[];
  assessmentId: string;
  assessmentSummary: AssessmentSummary;
  modalRef: BsModalRef;
  questionSetData: AssessmentData;
  survey: Survey.Survey;
  hasBeenSubmitted = false;
  valuesHaveBeenUpdated = false;
  modalComment: string;
  newStatus: number;
  backgroundSaveInterval: any;
  lastBackgroundSaveTimestamp = 0;
  assessmentOutcomeTitle = 'Assessment Outcome';
  showErrorsInNavbar = false;
  alerts: Array<AssessorAlert> = [];
  clearedCommentKeys: Array<string> = [];
  contractorDetails: ContractorAccount;
  assessmentPages: AssessmentPageInfo[] = [];
  isLoading = true;
  workCategories: WorkCategories[];
  businessSummaryText: string;
  workCategoriesText: string;
  maxCharacters = 125;
  furtherInfoResponses: Array<any> = [];
  public responseBy: Date = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);
  public oldStatus: string;
  public permissionsEnum: typeof PermissionsEnum = PermissionsEnum;
  public isAssignedAssessor = false;

  constructor(
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private modalService: BsModalService,
    private assessmentService: AssessmentService,
    private accountService: AccountService,
    private documentService: DocumentsService,
    private productivityService: ProductivityService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly store: Store,
    private readonly bundlesService: BundlesService,
    private readonly permissionsService: PermissionsService,
    private readonly snackBarComponent: SnackBarClass
  ) {}

  get isAssessmentDisabled(): boolean {
    return (
      this.isAssessmentAwaitingContractorResponse ||
      this.isAssessmentAwaitingAcceptance ||
      this.isAssessmentComplete ||
      !this.assessmentSummary.AssignedAssessor
    );
  }

  get isAssessmentComplete(): boolean {
    switch (this.assessmentSummary?.Status) {
      case AssessmentProgressStatus.Complete:
      case AssessmentProgressStatus.CompleteAwaitingOthers:
      case AssessmentProgressStatus.Verified:
      case AssessmentProgressStatus.SelfCertified:
      case AssessmentProgressStatus.Accredited:
      case AssessmentProgressStatus.Failed:
      case AssessmentProgressStatus.FailedResubmit:
        return true;
      default:
        return false;
    }
  }

  get isSubmittedForQA(): boolean {
    return this.assessmentSummary?.Status === AssessmentProgressStatus.SubmittedForQA;
  }

  get isAssessmentAwaitingContractorResponse(): boolean {
    const result = this.assessmentSummary?.Status.includes('Additional Information Required');
    return result;
  }

  get showAcceptRejectButtons(): boolean {
    return (
      this.isAssessmentAwaitingAcceptance &&
      !this.isAssessmentRejected &&
      this.assessmentSummary.AssessmentType === 'Assessment' &&
      this.assessmentSummary.AssignedAssessor !== null &&
      this.isAssignedAssessor
    );
  }

  get showFailedQAReasons(): boolean {
    return (
      this.assessmentSummary.FailureReason &&
      !this.isAssessmentComplete
    );
  }

  get isAssessment(): boolean {
    return this.assessmentSummary.AssessmentType === 'Assessment';
  }

  get isAssessmentAwaitingAcceptance(): boolean {
    return (
      this.assessmentSummary?.Status === 'Submitted' ||
      this.assessmentSummary?.Status === 'Assessment Rejected' ||
      this.assessmentSummary?.Status === 'Awaiting Acceptance'
    );
  }

  get isAssessmentRejected(): boolean {
    return this.assessmentSummary?.Status === 'Assessment Rejected';
  }

  get isAnyAssessmentNonCompliant(): boolean {
    this.assessmentService.getSelectedAssessmentPagesSubscription().subscribe((data) => {
      if (data) {
        this.assessmentPages = data;
      }
    });
    return this.assessmentPages.filter((x) => x.nonCompliant && x.title.toLowerCase() !== 'declaration').length > 0;
  }

  get averageAccreditedLevel(): number | null {
    const isFir = this.assessmentSummary.AssessmentName.toLowerCase().indexOf('fairness, inclusion and respect') > -1;

    if (isFir && !this.isAnyAssessmentNonCompliant) {
      return this.assessmentService.calculateAverageLevel();
    }

    return null;
  }

  get title(): string {
    if (this.questionSetData?.survey?.pages == null || this.questionSetData.survey.pages.length === 0) {
      return null;
    }

    return this.questionSetData.survey.pages[0].title;
  }

  get furtherInfoHasError(): boolean {
    return this.furtherInfoResponses.find((x) => x.sectionCompliant === true);
  }

  get isOnActionPage(): boolean {
    if (!this.survey) {
      return false;
    }
    return this.survey.currentPage === this.survey.pages[this.survey.pages.length - 1];
  }

  get isOnFirstPage(): boolean {
    if (!this.survey) {
      return false;
    }
    return this.survey.currentPage === this.survey.pages[0];
  }

  get assessor(): string {
    const assessorCompany = this.assessmentSummary.AssessorCompanyName ? ` (${this.assessmentSummary.AssessorCompanyName})` : '';
    return `${this.assessmentSummary.AssignedAssessor ?? ''}${assessorCompany}`;
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.assessmentId = await params.id;
      await this.getAssessmentSummary(this.assessmentId);
      void this.initialise();
    });
  }

  private async initialise(): Promise<void> {
    await this.retrieveUserAccountType();
    this.getSubscriptions();
    this.oldStatus = this.assessmentSummary?.Status;
    this.hasBeenSubmitted = false;
  }

  public async retrieveUserAccountType() {
    this.userAccountType = await firstValueFrom(this.store.select(selectCurrentUserAccountType));
  }

  getAccountWorkCategories(): void {
    if (this.contractorDetails.workCategories !== undefined || this.contractorDetails.workCategories !== null) {
      this.workCategories = this.contractorDetails.workCategories;
      this.formatWorkCategoriesText();
    }
  }

  public isDateValid(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  async loadAssessmentAlertsAsync(): Promise<void> {
    this.alerts = await this.assessmentService.getAssessorAssessmentAlertsAsync(this.assessmentId);
  }

  ngOnDestroy(): void {
    Survey.FunctionFactory.Instance.unregister('questionsAreVisible');
    this.stopBackgroundSave();
    this.backgroundSave();
    if (this.subscriptions === null) {
      return;
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.snackBarComponent.dismissSnackbar();
  }

  initialiseSurvey(): void {
    // Register Properties
    Survey.Serializer.addProperty('question', 'standard:text');
    Survey.Serializer.addProperty('question', 'guidance:text');

    this.registerSurveyValidation();

    // Create Survey
    this.questionSetData.survey.textUpdateMode = 'onTyping';
    this.survey = new Survey.Model(this.questionSetData.survey);

    // Register Functions
    Survey.FunctionFactory.Instance.register('questionsAreVisible', (params) => this.questionsAreVisible(params, this.survey));

    // Settings
    this.survey.showQuestionNumbers = 'off';
    this.survey.completedHtml = `<h4 class='mb-4'>${this.title}</h4>`;
    this.survey.questionsOnPageMode = 'questionPerPage';
    this.survey.showNavigationButtons = false;
    this.survey.showTitle = false;
    this.survey.showPageTitles = false;

    // Register Events
    this.survey.onAfterRenderQuestion.add((s, o) => this.onAfterRenderQuestion(s, o));
    this.survey.onComplete.add((s, o) => this.onSubmitForm(s, o));
    this.survey.onDownloadFile.add(async (s, o) => await this.onDownloadFile(s, o));
    this.survey.onValueChanged.add((s, o) => this.onValueChanged(s, o));
    this.survey.onAfterRenderSurvey.add((s, o) => this.onAfterRenderSurvey(s, o));
    this.survey.onCurrentPageChanged.add((s, o) => this.onCurrentPageChanged(s, o));
    this.survey.onCurrentPageChanging.add((s, o) => this.onCurrentPageChanging(s, o));

    // Render Survey
    this.survey.data = this.questionSetData.answers.data;
    Survey.StylesManager.applyTheme('bootstrap');

    Survey.SurveyNG.render('surveyElement', {
      model: this.survey,
      css: jsonSurveyJSCSS,
    });
  }

  registerSurveyValidation(): void {
    const alwaysValid = (valueToTest: any): boolean => true;

    const contractorQuestionSetValidationMethods = [
      'valid_postcode',
      'validateAlpha',
      'validateEmail',
      'validateNumber',
      'validatePassword',
      'validatePhone',
      'validatePosition',
      'futureDate',
      'pastDate',
    ];

    contractorQuestionSetValidationMethods.forEach((methodName) => {
      Survey.FunctionFactory.Instance.register(methodName, alwaysValid);
    });

    Survey.FunctionFactory.Instance.register('validateMajorFail', SurveyValidators.validateMajorFail);
  }

  preProcessQuestionData(assessmentData: AssessmentData): AssessmentData {
    if (assessmentData === null) {
      return assessmentData;
    }

    const actionsHtmlQuestion = {
      type: 'html',
      name: 'actionsHtml',
      html: 'actionsHtml',
    };
    const actionsPanel = {
      type: 'panel',
      name: 'ActionsPanel',
      title: this.assessmentOutcomeTitle,
      elements: [actionsHtmlQuestion],
    };

    if (assessmentData.survey && assessmentData.survey.pages && assessmentData.survey.pages.length >= 1) {
      if (assessmentData.survey.pages[0].elements.findIndex((e) => e.name === actionsPanel.name) === -1) {
        assessmentData.survey.pages[0].elements.push(actionsPanel);
      }
    }

    return assessmentData;
  }

  onCurrentPageChanged(survey: Survey.SurveyModel, options: any): void {
    const pageInfo = this.generatePageInfo(survey.currentPage);
    this.assessmentService.setCurrentAssessmentPage(pageInfo);
  }

  onCurrentPageChanging(survey: Survey.SurveyModel, options: any): void {
    if (this.valuesHaveBeenUpdated) {
      this.backgroundSave();
    }
  }

  onAfterRenderSurvey(survey: Survey.SurveyModel, options: any): void {
    this.registerAssessmentPages(survey);
  }

  registerAssessmentPages(survey: Survey.SurveyModel): void {
    const listOfPages: AssessmentPageInfo[] = survey.visiblePages.map((page) => this.generatePageInfo(page));
    this.assessmentService.setSelectedAssessmentPages(listOfPages);
  }

  generatePageInfo(page: Survey.PageModel): AssessmentPageInfo {
    const panel = page.elements[0] as Survey.Panel;
    const pageInfo: AssessmentPageInfo = {
      id: page.id,
      title: panel.title,
      complete: this.getCompleteStatus(panel),
      hasErrors: this.showErrorsInNavbar && page.hasErrors(false),
      nonCompliant: this.getNonComplianceStatus(panel),
    };

    return pageInfo;
  }

  getNonComplianceStatus(panel: Survey.Panel): boolean {
    if (panel === undefined || panel === null) {
      return false;
    }
    if (panel.questions === undefined || panel.questions === null) {
      return false;
    }
    if (panel.title === this.assessmentOutcomeTitle) {
      return false;
    }

    return this.assessmentService.isNonCompliant(this.assessmentSummary, panel, this.survey);
  }

  getCompleteStatus(panel: Survey.Panel): boolean {
    if (panel === undefined || panel === null) {
      return false;
    }
    if (panel.questions === undefined || panel.questions === null) {
      return false;
    }
    if (panel.title === this.assessmentOutcomeTitle) {
      return false;
    }

    return this.assessmentService.isCompliant(this.assessmentSummary, panel, this.survey);
  }

  questionsAreVisible(params: Array<any>, survey: Survey.Model): boolean {
    const questionNames: Array<string> = params[0];

    let isVisible = false;

    questionNames.forEach((questionName) => {
      const question: Survey.Question = survey.getQuestionByName(questionName);
      if (!!question && question.isVisible) {
        isVisible = true;
      }
    });

    return isVisible;
  }

  onValueChanged(survey: Survey.SurveyModel, options: any): any {
    this.valuesHaveBeenUpdated = true;
    this.registerAssessmentPages(survey);

    const question: Survey.Question = options.question;
    question.hasErrors(true);

    if (question.getType() === 'comment' && !options.value) {
      const alreadyHasKey = this.clearedCommentKeys.findIndex((x) => x === question.name) > -1;
      if (!alreadyHasKey) {
        this.clearedCommentKeys.push(question.name);
      }
    } else if (question.getType() === 'comment' && options.value) {
      const alreadyHasKey = this.clearedCommentKeys.findIndex((x) => x === question.name) > -1;
      if (alreadyHasKey) {
        this.clearedCommentKeys.forEach((x, i) => {
          if (x == question.name) this.clearedCommentKeys.splice(i, 1);
        });
      }
    }
  }

  async onAfterRenderQuestion(survey: Survey.SurveyModel, options: any): Promise<void> {
    const question: Survey.Question = options.question;

    const isAssessorQuestion: boolean = this.isAssessorQuestion(options.question.name);

    const isVirtualRoutingButton = question.title === 'Next' || question.title === 'Finish' || question.title === 'Submit';

    const isSectionHeading = question.name === 'section-heading';

    const isHistorySection = question.name.endsWith('.history');

    const hasHelptip = options.question.guidance || options.question.standard;

    switch (question.getType()) {
      case 'checkbox':
      case 'radiogroup':
        const labelElements = options.htmlElement.querySelectorAll('label');
        labelElements.forEach((label: HTMLLabelElement) => {
          const input = label.querySelector('input');
          label.parentElement.prepend(input);
          label.setAttribute('for', input.id);
        });
        break;
    }

    await this.isReadOnlyMode(question, isAssessorQuestion);

    if (isVirtualRoutingButton) {
      this.createVirtualRoutingButton(options, question);
    }

    if (isSectionHeading) {
      const labels: Array<HTMLLabelElement> = options.htmlElement.querySelectorAll('label');
      if (labels.length > 0) {
        labels[0].className = 'section-heading';
      }
    }

    this.convertTitleToHtml(options);

    if (hasHelptip) {
      this.createHelpTips(options);
    }
  }

  /**
   * Check if logged-in user matches the assigned user to the assessment/verification
   * Check if the current user has permission to edit anything (super-user) for example
   * use permission-mapper for tables to achieve this
   * If statement to control (question.readOnly) true/false based on above.
   */
  private async isReadOnlyMode(question: Question, isAssessorQuestion: boolean): Promise<void> {
    const hasPermission: boolean = await firstValueFrom(this.authenticatePermissions$(PermissionsEnum.EditAnyAssessment));
    const currentUserId = await firstValueFrom(this.store.select(selectCurrentUserContactId));

    if (this.isAssessmentComplete) {
      this.snackBarComponent.displaySnackbar('This assessment is no longer editable', '', 'center', 'top', 'readonlySnackbar');
      question.readOnly = true;
      this.isAssignedAssessor = false;
      return;
    }

    if (
      (this.assessmentSummary.AssignedAssessorId === currentUserId && isAssessorQuestion) ||
      (hasPermission && isAssessorQuestion) ||
      this.isOnActionPage
    ) {
      this.snackBarComponent.dismissSnackbar();
      question.readOnly = false;
      this.isAssignedAssessor = true;
    } else {
      this.snackBarComponent.displaySnackbar(
        'You cannot edit this Assessment unless it is assigned to you',
        '',
        'center',
        'top',
        'readonlySnackbar'
      );
      question.readOnly = true;
      this.isAssignedAssessor = false;
    }

    if (this.isAssessmentDisabled || !isAssessorQuestion) {
      question.readOnly = true;
    }
  }

  public authenticatePermissions$(permissionName: PermissionsEnum): Observable<boolean> {
    return this.permissionsService.authenticateUser$(PermissionsMap.get(permissionName));
  }

  createVirtualRoutingButton(options: any, question: Survey.Question): void {
    options.htmlElement.className = 'd-none';
    const defaultValue = options.question.title;
    question.defaultValue = defaultValue;

    if (question.value !== null && question.value !== defaultValue) {
      question.value = defaultValue;
    }
  }

  createHelpTips(options: any): void {
    const standardHelpText = options.question.standard;
    const guidanceHelpText = options.question.guidance;

    let titleElements: Array<HTMLElement> = options.htmlElement.querySelectorAll('h5');
    if (titleElements.length === 0) {
      titleElements = options.htmlElement.querySelectorAll('label');
    }

    if (titleElements.length === 0) {
      return;
    }

    const titleElement: HTMLElement = titleElements[0];

    if (standardHelpText) {
      this.addHelptipToHtmlElement(titleElement, 'Standard', standardHelpText);
    }

    if (guidanceHelpText) {
      this.addHelptipToHtmlElement(titleElement, 'Guidance', guidanceHelpText);
    }
  }

  addHelptipToHtmlElement(htmlElement: HTMLElement, displayText: string, popoverText: string): void {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(HelptipComponent).create(this.injector);
    componentRef.instance.displayText = displayText;
    componentRef.instance.popoverText = popoverText;
    componentRef.changeDetectorRef.detectChanges();
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    htmlElement.appendChild(domElem);
  }

  async onDownloadFile(survey: Survey.SurveyModel, options: any): Promise<void> {
    const contentUrl =
      options.content.indexOf('downloadfile') > -1
        ? this.documentService.rewriteUrl(this.assessmentSummary.ContractorId, options.content)
        : options.content;

    try {
      const blob = await this.documentService.downloadFileAsync(contentUrl, options.fileValue?.type);
      const url = window.URL.createObjectURL(blob);
      options.callback('success', url);
    } catch (ex) {
      // Failed download, display non-hyperlinked filename.
      options.callback('success');
    }
  }

  isAssessorQuestion(questionName: any): boolean {
    return questionName.split('.').length >= 3 && questionName.split('.')[2] >= 3000;
  }

  public requestFurtherInfo(): void {
    if (this.validateAndHasErrors(true)) {
      return;
    }
    this.modalComment = null;
    this.furtherInfoResponses = this.getFurtherInfoResponses();
    this.modalRef = this.modalService.show(FurtherInfoSurveyModalComponent, {
      class: 'modal_padding',
      initialState: {
        furtherInfoResponses: this.furtherInfoResponses,
      },
    });

    this.modalRef.onHide.subscribe(() => {
      const response = this.modalRef.content;

      if (response.isSubmitted) {
        this.responseBy = response.responseBy;
        this.newStatus = AssessmentStatus.RequestFurtherInformation;
        this.survey.completeLastPage();
      }
    });
  }

  approve(): void {
    if (this.validateAndHasErrors()) {
      return;
    }
    this.newStatus = AssessmentStatus.AssessmentComplete;
    this.modalComment = null;
    this.survey.completeLastPage();
  }

  failedToResubmit(): void {
    if (this.validateAndHasErrors(true)) {
      return;
    }
    this.modalComment = null;
    this.modalRef = this.modalService.show(FailToResubmitModalComponent, {
      initialState: {},
    });

    this.modalRef.onHide.subscribe(() => {
      this.modalComment = this.modalRef.content;
      this.newStatus = AssessmentStatus.FailedToResubmit;
      this.survey.completeLastPage();
    });
  }

  fail(): void {
    if (this.validateAndHasErrors(true)) {
      return;
    }
    this.modalComment = null;
    this.modalRef = this.modalService.show(FailModalComponent, {
      initialState: {},
    });
    this.modalRef.onHide.subscribe(() => {
      this.modalComment = this.modalRef.content;
      this.newStatus = AssessmentStatus.Failed;
      this.survey.completeLastPage();
    });
  }

  passedQA(): void {
    if (this.validateAndHasErrors(true)) {
      return;
    }

    this.newStatus = AssessmentStatus.PassedManualQA;
    this.survey.completeLastPage();
  }

  failedQA(): void {
    if (this.validateAndHasErrors(true)) {
      return;
    }

    if (this.assessmentSummary.AssessmentType !== 'Assessment') {
      this.newStatus = AssessmentStatus.FailedManualQA;
      this.survey.completeLastPage();
      return;
    }

    this.modalComment = null;
    this.modalRef = this.modalService.show(FailModalComponent, {
      initialState: {},
    });

    this.modalRef.onHide.subscribe(() => {
      this.modalComment = this.modalRef.content;
      this.newStatus = AssessmentStatus.FailedManualQA;
      this.survey.completeLastPage();
    });
  }

  public showWorkCategories(): void {
    this.modalRef = this.modalService.show(WorkCategoriesModalComponent, {
      class: 'modal-lg',
      initialState: {
        workCategories: this.workCategories,
      },
    });
  }

  showBusinessSummary(): void {
    this.modalRef = this.modalService.show(BusinessSummaryModalComponent, {
      initialState: {
        contractorDetails: this.contractorDetails,
      },
    });
  }

  async onSubmitForm(survey: Survey.SurveyModel, options: any): Promise<void> {
    this.hasBeenSubmitted = true;
    options.showDataSaving('Saving, please wait...');

    const sectionsCount = this.assessmentService.getIncompleteSectionCount(survey);
    this.assessmentSummary.IncompleteSections = sectionsCount;
    this.assessmentSummary.AverageAccreditedLevel = this.averageAccreditedLevel;

    try {
      let action: AssessmentAction;
      switch (this.newStatus) {
        case AssessmentStatus.RequestFurtherInformation:
          action = this.UpdateRequiredAdditionalInformation(this.assessmentSummary);
          break;

        case AssessmentStatus.FailedToResubmit:
          action = this.UpdateFail(AssessmentProgressStatus.FailedResubmit, this.assessmentSummary);
          break;
        case AssessmentStatus.Failed:
          action = this.UpdateFail(AssessmentProgressStatus.Failed, this.assessmentSummary);
          break;

        case AssessmentStatus.AssessmentComplete:
          action = this.UpdateComplete(this.assessmentSummary);
          break;

        case AssessmentStatus.PassedManualQA:
          action = this.PassedManualQA(this.assessmentSummary);
          break;
        case AssessmentStatus.FailedManualQA:
          action = this.FailedManualQA(this.assessmentSummary);
          break;
      }

      await action.run();
      options.showDataSavingSuccess('Successfully Updated');
      this.updateSuccess.emit(null);
    } catch (error) {
      options.showDataSavingError('Error: Could not be saved.');
    }
  }

  updateStatus(param) {
    this.assessmentSummary.Status = param;
  }

  getAssessorAnswers(): any {
    const answerKeys: string[] = Object.keys(this.survey.data).filter((key) => this.isAssessorQuestion(key));
    const assessorAnswers: any = {};
    answerKeys.forEach((key) => (assessorAnswers[key] = this.survey.data[key]));

    this.clearedCommentKeys.forEach((key) => (assessorAnswers[key] = ''));

    return assessorAnswers;
  }

  async acceptAssessment(): Promise<void> {
    try {
      await this.assessmentService.acceptAssessment(this.assessmentSummary.InternalId, true);
      const oldStatus = this.assessmentSummary.Status;
      this.assessmentSummary.Status = AssessmentProgressStatus.Accepted;

      this.assessmentService.update(this.assessmentSummary, oldStatus);

      this.initialiseSurvey();
      const model = {
        assessmentId: this.assessmentSummary.InternalId,
        assessmentName: this.assessmentSummary.AssessmentName,
        userId: this.assessmentSummary.AssignedAssessorId,
        userName: this.assessmentSummary.AssignedAssessor,
        status: ProductivityAssessmentStatus.Accepted,
        contractorId: this.assessmentSummary.ContractorId,
        contractorName: this.assessmentSummary.ContractorName,
      } as ProductivityInfo;
      await this.productivityService.recordStatusChangeAsync(model);
    } catch (ex) {
      console.error('Error Accepting assessment');
    }
  }

  async rejectAssessment(): Promise<void> {
    try {
      await this.assessmentService.acceptAssessment(this.assessmentSummary.InternalId, false);
      const oldStatus = this.assessmentSummary.Status;
      this.assessmentSummary.Status = AssessmentProgressStatus.Rejected;
      this.assessmentService.update(this.assessmentSummary, oldStatus);

      const model = {
        assessmentId: this.assessmentSummary.InternalId,
        assessmentName: this.assessmentSummary.AssessmentName,
        userId: this.assessmentSummary.AssignedAssessorId,
        userName: this.assessmentSummary.AssignedAssessor,
        status: ProductivityAssessmentStatus.Rejected,
        contractorId: this.assessmentSummary.ContractorId,
        contractorName: this.assessmentSummary.ContractorName,
      } as ProductivityInfo;
      await this.productivityService.recordStatusChangeAsync(model);
    } catch (error) {
      console.error('Error Rejecting assessment');
      throw new Error(error);
    } finally {
      this.bundlesService.navigateToNextUrl(this.route, this.router, '/dashboard/assessor');
    }
  }

  getFurtherInfoResponses(): Array<any> {
    const responseList: Array<any> = [];
    this.questionSetData.survey.pages.forEach((page) => {
      page.elements.forEach((panel) => {
        panel.elements.forEach((question) => {
          if (question.title !== 'Further Information') {
            return;
          }

          const value = this.survey.data[question.name];
          if (value === undefined || value === null || value.length === 0) {
            return;
          }

          const complianceQuestion: Survey.Question = panel.elements.find((x) => x.title === 'Compliance');
          let sectionCompliant = false;
          if (complianceQuestion) {
            const complianceValue = this.survey.data[complianceQuestion.name];
            sectionCompliant = complianceValue === 'Compliant';
          }

          responseList.push({
            id: question.name,
            section: panel.title,
            value: value,
            sectionCompliant: sectionCompliant,
          });
        });
      });
    });
    return responseList;
  }

  backgroundSave(): void {
    const count = this.assessmentService.getIncompleteSectionCount(this.survey);
    const summary = this.assessmentService.getAssessmentSummary(this.assessmentId);
    summary.IncompleteSections = count;

    if (this.hasBeenSubmitted || !this.valuesHaveBeenUpdated) {
      return;
    }

    const updateAction = new ProgressiveSaveAction(
      this.assessmentService,
      this.assessmentId,
      this.getAssessorAnswers(),
      summary.IncompleteSections
    );

    updateAction
      .run()
      .then(() => {
        this.lastBackgroundSaveTimestamp = new Date().getTime();
        this.valuesHaveBeenUpdated = false;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  startBackgroundSave(): void {
    const timeDifferenceMs = 30000;
    const intervalCheckMs = 20000;

    this.stopBackgroundSave();

    this.backgroundSaveInterval = setInterval(() => {
      const now = new Date().getTime();
      if (this.lastBackgroundSaveTimestamp + timeDifferenceMs < now) {
        this.backgroundSave();
      }
    }, intervalCheckMs);
  }

  stopBackgroundSave(): void {
    if (this.backgroundSaveInterval === null) {
      return;
    }
    clearInterval(this.backgroundSaveInterval);
  }

  selectCurrentPage(pageInfo: AssessmentPageInfo): void {
    if (pageInfo === null) {
      return;
    }

    const page = this.survey.visiblePages.find((p) => p.id === pageInfo.id);
    if (page === null) {
      return;
    }

    if (this.survey.currentPage === page) {
      return;
    }
    this.survey.currentPage = page;
  }

  private getSubscriptions(): void {
    this.subscriptions = [
      this.idSubscription(),
      this.dataSubscription(),
      this.assessmentDataSubscription(),
      this.currentAssessmentPageSubscription(),
    ];
  }

  private idSubscription(): Subscription {
    const subscription = this.assessmentService.getAssessmentSelectedSubscription().subscribe(async (id) => {
      await this.getAssessmentSummary(id);
      this.getAccountForAssessment(id);
    });

    return subscription;
  }

  private dataSubscription(): Subscription {
    const subscription = this.assessmentService.observeQuestionSetData.subscribe((data) => {
      this.questionSetData = this.preProcessQuestionData(data);
      if (this.questionSetData) {
        try {
          this.initialiseSurvey();
          this.startBackgroundSave();
          this.loadAssessmentAlertsAsync();
        } catch (error) {
          console.error(error);
        }
      }
    });

    return subscription;
  }

  private assessmentDataSubscription(): Subscription {
    const subscription = this.assessmentService.observeAcceptAssessmentData.subscribe((data) => {
      this.assessmentSummary.TargetDecisionDate = data;
    });

    return subscription;
  }

  private currentAssessmentPageSubscription(): Subscription {
    const subscription = this.assessmentService.getCurrentAssessmentPage().subscribe((page) => this.selectCurrentPage(page));

    return subscription;
  }

  private async getAssessmentSummary(id: string): Promise<void> {
    this.assessmentId = id;
    this.assessmentSummary = await this.assessmentService.getAssessmentSummaryAsync(id, ViewerType.Assessor);
  }

  private getAccountForAssessment(id: string) {
    this.accountService.getAccountForAssessment(id).subscribe((data) => {
      this.contractorDetails = data.account;
      this.getAccountWorkCategories();
      this.formatBusinessSummaryText();
      this.isLoading = false;
    });
  }

  private formatWorkCategoriesText() {
    let value = '';

    for (const category of this.workCategories) {
      const subCategories = category.subCategories;
      value += `${category.name} - `;

      for (let index = 0; index < subCategories.length; index++) {
        const subCategory = category.subCategories[index];

        value += `${subCategory}, `;

        if (subCategories.length - 1 === index) {
          value = value.slice(0, -2);
          value += '. ';
        }
      }
    }

    value = this.limitText(value);

    this.workCategoriesText = value;
  }

  private formatBusinessSummaryText() {
    this.businessSummaryText = this.limitText(this.contractorDetails.summary);
  }

  private limitText(value: string | null): string {
    if (value === null) {
      return '';
    } else if (value.length > this.maxCharacters) {
      value = value.slice(0, this.maxCharacters);
      value += '...';
    }

    return value;
  }

  private convertTitleToHtml(options: any): void {
    const titleElements = options.htmlElement.querySelectorAll('h5');
    titleElements.forEach((title: HTMLTitleElement) => {
      const titleSpan = title.querySelector('span');
      if (titleSpan === null || options.question.title === options.question.name) {
        return;
      }
      titleSpan.innerHTML = options.question.title;
    });
  }

  private validateAndHasErrors(ignoreValidation = false): boolean {
    this.survey.ignoreValidation = ignoreValidation;
    this.showErrorsInNavbar = !ignoreValidation;

    if (ignoreValidation) {
      this.registerAssessmentPages(this.survey);
      return false;
    } else {
      const hasErrors = this.survey.hasErrors(true, true);
      this.registerAssessmentPages(this.survey);
      return hasErrors;
    }
  }

  private UpdateRequiredAdditionalInformation(assessmentSummary: AssessmentSummary): AssessmentAction {
    return new RequireAdditionalInformationAction(
      this.assessmentService,
      this.assessmentId,
      this.responseBy,
      this.getAssessorAnswers(),
      this.modalComment,
      assessmentSummary
    );
  }

  private UpdateFail(status: string, assessmentSummary: AssessmentSummary): AssessmentAction {
    return new FailAssessmentAction(
      this.assessmentService,
      this.assessmentId,
      this.getAssessorAnswers(),
      this.newStatus,
      this.modalComment,
      assessmentSummary,
      status
    );
  }

  private UpdateComplete(assessmentSummary: AssessmentSummary): AssessmentAction {
    return new CompleteAssessmentAction(this.assessmentService, this.assessmentId, this.getAssessorAnswers(), assessmentSummary);
  }

  private PassedManualQA(assessmentSummary: AssessmentSummary): AssessmentAction {
    return new PassedManualQAAction(this.assessmentService, this.assessmentId, this.getAssessorAnswers(), assessmentSummary);
  }

  private FailedManualQA(assessmentSummary: AssessmentSummary): AssessmentAction {
    return new FailedManualQAAction(this.assessmentService, this.assessmentId, this.getAssessorAnswers(), this.modalComment, assessmentSummary);
  }
}
