import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Environment, IEnvironment } from "src/app/shared/classes/environment";

@Injectable({
  providedIn: "root",
})
export class MonitorService {
  private config: IEnvironment;

  constructor() {
    const env = new Environment();
    this.config = env.getConfigSync();

    this.appInsights = new ApplicationInsights({
      config: this.config.monitorConfig,
    });
    this.appInsights.loadAppInsights();
  }

  private appInsights: ApplicationInsights;

  logPageView(name?: string, url?: string): void {
    this.appInsights.trackPageView({
      name,
      uri: url,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name }, properties);
  }

  public logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  public logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message }, properties);
  }
}
