<div class="text-center" *ngIf="isLoading$ | async; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #displayForm>
  <div>
    <button type="button" mat-raised-button (click)="csvReader.click()">
      Choose Questionset File
    </button>
    <input
      hidden
      (change)="uploadListener($event)"
      type="file"
      #csvReader
      name="Upload CSV"
      id="txtFileUpload"
      accept=".csv"
    />
    <span class="file-name">{{ selectedFile?.name }}</span>
  </div>
  <div class="search-div">
    <mat-form-field class="search-form-field" floatLabel="never">
      <input
        matInput
        [(ngModel)]="searchKey"
        placeholder="Search"
        autocomplete="off"
        (keyup)="applyFilter()"
        trim
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearchClear()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="created"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ row.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef>Version</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ row.version }}
        </td>
      </ng-container>
      <ng-container matColumnDef="questionSetNumber">
        <th mat-header-cell *matHeaderCellDef>question SetNumber</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ row.questionSetNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="validFrom">
        <th mat-header-cell *matHeaderCellDef>Valid From</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ row.validFrom | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expires">
        <th mat-header-cell *matHeaderCellDef>Expires</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ row.expires | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ row.created | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button (click)="assessmentSelected(row.id)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="onDelete(row)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pazeSize]="12"
      [pageSizeOptions]="[12, 100]"
    ></mat-paginator>
  </div>
</ng-template>
