import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { OauthCallbackComponent } from './features/OAuth/oauth-callback/oauth-callback.component';
import { FlexLayoutModule, FlexModule, GridModule } from '@angular/flex-layout';
import { UserRolesService } from './core/services/user-roles/user-roles.service';
import { SiteFooterComponent } from './shared/components/site-footer/site-footer.component';
import { NavBarComponent } from './shared/components/nav-bar/nav-bar.component';
import { AssessmentBreadcrumbsModule } from './features/assessment/assessment-breadcrumbs/assessment-breadcrumbs.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { SelectAccountMenuComponent } from './shared/components/select-account-menu/select-account-menu.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '../material/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiModule, Configuration } from './core/open-api';
import { Environment } from './shared/classes/environment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ClientModulesModule } from './features/client-modules/client-modules.module';
import { ClientsComponent } from './features/assessment/clients/clients.component';
import { PanelsModule } from './features/contractor-dashboard/panels/panels.module';
import { TabsModule } from './features/contractor-dashboard/tabs/tabs.module';
import { MsalService } from '@azure/msal-angular';

@NgModule({
  declarations: [AppComponent, OauthCallbackComponent, SiteFooterComponent, NavBarComponent, SelectAccountMenuComponent, ClientsComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ApiModule.forRoot(() => {
      const env = new Environment();
      const config = env.getConfigSync();

      // TODO: Put this directly in the environment.json
      const rootUrl = new URL(config.baseApiUrl);
      const basePath = `${rootUrl.protocol}//${rootUrl.host}`;

      return new Configuration({
        basePath: basePath,
      });
    }),
    AppRoutingModule,
    FlexLayoutModule,
    FlexModule,
    GridModule,
    AssessmentBreadcrumbsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule,
    NgIdleModule.forRoot(),
    MatCardModule,
    MatDialogModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableExporterModule,
    ClientModulesModule,
    PanelsModule,
    TabsModule,
  ],
  bootstrap: [AppComponent],
  providers: [UserRolesService, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, MsalService],
  exports: [MatDialogModule, MatFormFieldModule, MatTableExporterModule],
})
export class AppModule {}
