import { ApiRequestTypes } from "src/app/core/ngrx/actions/MultigridAssessmentViewType.actions";
import { ExpiryFilter } from "../enums/expiry-filter";

export class ProgressStats {
  value: number;
  label: string;
  cssClass: string;
  apiRequestType: ApiRequestTypes;
  expiryFilter: ExpiryFilter;
}

