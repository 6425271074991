<ng-content></ng-content>
<form [formGroup]="parent" novalidate>
  <div class="form-group row">
    <label class="col-md-4">Address1</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showAddress1">
        <input
          id="Address1"
          class="form-control"
          type="text"
          formControlName="street1"
          title="Address1"
        />
      </div>
      <ng-template #showAddress1>
        <ng-container>
          {{ parent.value.street1 }}
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4">Address2</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showAddress2">
        <input
          id="Address2"
          class="form-control"
          type="text"
          formControlName="street2"
          title="Address2"
        />
      </div>
      <ng-template #showAddress2>
        <ng-container>
          {{ parent.value.street2 }}
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4">Address3</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showAddress3">
        <input
          id="Address3"
          class="form-control"
          type="text"
          formControlName="street3"
          title="Address3"
        />
      </div>
      <ng-template #showAddress3>
        <ng-container>
          {{ parent.value.street3 }}
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4">City</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showCity">
        <input
          id="city"
          class="form-control"
          type="text"
          formControlName="city"
          title="city"
        />
      </div>
      <ng-template #showCity>
        {{ parent.value.city }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4">State/Province</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showState">
        <input
          id="state"
          class="form-control"
          type="text"
          formControlName="state"
          title="state"
        />
      </div>
      <ng-template #showState>
        {{ parent.value.state }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4">ZIP/Postal Code</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showPostCode">
        <input
          id="PostCode"
          class="form-control"
          type="postCode"
          formControlName="postCode"
          title="PostCode"
        />
      </div>
      <ng-template #showPostCode>
        {{ parent.value.postCode }}
      </ng-template>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4">Country/Region</label>
    <div class="col-md-8">
      <div *ngIf="!readOnly; else showCounty">
        <input
          id="county"
          class="form-control"
          type="text"
          formControlName="county"
          title="county"
        />
      </div>
      <ng-template #showCounty>
        {{ parent.value.county }}
      </ng-template>
    </div>
  </div>
</form>
