import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactPanelComponent } from './contact-panel/contact-panel.component';
import { MaterialModule } from 'src/material/material.module';
import { AddressPanelComponent } from './address-panel/address-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MychasLoginComponent } from '../mychas-login/mychas-login.component';
import { BusinessDetailsPanelComponent } from './business-details-panel/business-details-panel.component';
import { MembershipSummaryPanelComponent } from './membership-summary-panel/membership-summary-panel.component';
import { CompanySummaryPanelComponent } from './company-summary-panel/company-summary-panel.component';
import { CompanySummaryTwoPanelComponent } from './company-summary-two-panel/company-summary-two-panel.component';
import { WorkAreasPanelComponent } from './work-areas-panel/work-areas-panel.component';
import { ThirdPartyPanelComponent } from './third-party-panel/third-party-panel.component';
import { ThirdPartyKeysPanelComponent } from './third-party-keys-panel/third-party-keys-panel.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { AngularMaterialModule } from '../../../core/angular-material.module';
import { AssessmentModule } from '../../assessment/assessment.module';
import { ContractDashboardModule } from '../contractor-dashboard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelActionButtonsComponent } from './panel-action-buttons/panel-action-buttons.component';
import { MatSelectModule } from '@angular/material/select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const panels = [
  ContactPanelComponent,
  AddressPanelComponent,
  MychasLoginComponent,
  BusinessDetailsPanelComponent,
  MembershipSummaryPanelComponent,
  CompanySummaryPanelComponent,
  CompanySummaryTwoPanelComponent,
  WorkAreasPanelComponent,
  ThirdPartyPanelComponent,
  ThirdPartyKeysPanelComponent,
];

@NgModule({
  declarations: [...panels, MychasLoginComponent, MembershipSummaryPanelComponent, PanelActionButtonsComponent],
  exports: [...panels, MatFormFieldModule, MatDialogModule],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatSelectModule,
    SharedModule,
    LoadingSpinnerModule,
    AngularMaterialModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCardModule,
    MatProgressBarModule,
    AssessmentModule,
    ContractDashboardModule,
    TooltipModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class PanelsModule {}
