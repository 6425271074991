import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { AssessmentService } from 'src/app/core/services/assessment/assessment.service';
import { UserAuthenticationAbstract } from '../../../core/services/authentication/user-authentication-abstract';
import { AssessmentPageInfo } from 'src/app/shared/models/assessment-page-info';

import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { RouteDetectionService } from '../../../core/services/route-detection/route-detection.service';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import { filter, firstValueFrom, map, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AssessmentSummary } from 'src/app/shared';
import { BundlesService } from 'src/app/core/services/bundles/bundles.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserDefaultRoute } from '../../../core/ngrx/selectors/currentUser.selectors';
import { PermissionsEnum } from '../../../shared/enums/permissions-enum';
import { PermissionsMap } from '../../../shared/maps/permissions-map';
import { RoutingMap } from '../../../shared/maps/routing-map';
import { AuthInterceptor } from 'src/app/core/services/azure-authentication/auth-interceptor/auth-interceptor.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { AzureUserAuthenticationService } from '../../../core/services/azure-authentication/user-authentication/azure-user-authentication.service';

@Component({
  selector: 'app-assessment-breadcrumbs',
  templateUrl: './assessment-breadcrumbs.component.html',
  styleUrls: ['./assessment-breadcrumbs.component.scss'],
})
export class AssessmentBreadcrumbsComponent implements OnInit, OnDestroy {
  assessmentId: string;
  accountId: string;
  assessmentName: string;
  currentAssessment: AssessmentSummary;
  assessmentSelectedSubscription: Subscription;
  assessmentPagesSubscription: Subscription;
  currentPageSubscription: Subscription;
  assessmentPages: AssessmentPageInfo[];
  currentAssessmentPage: AssessmentPageInfo;
  isProductivityEnabled = true;
  isAssessorProductivityEnabled = true;
  isDataMatchingEnabled = true;
  isSupplyChainImporterToolEnabled = true;

  assessments: AssessmentSummary[];
  assessmentGroups: string[];

  public isAssessment$ = this.routeDetectionService.isAssessmentRoute$();
  public permissionEnum: typeof PermissionsEnum = PermissionsEnum; // NOTE: Expose enum to view
  public bundleId: string;
  public returnUrl: string;
  public config: IEnvironment;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userAuthentication: AzureUserAuthenticationService,
    private readonly permissionsService: PermissionsService,
    private readonly assessmentService: AssessmentService,
    private readonly bundlesService: BundlesService,
    private readonly routeDetectionService: RouteDetectionService,
    private readonly location: Location,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly store: Store
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  get hangfireDashboardUrl(): string {
    const baseApiUrl2 = new URL(this.config.baseApiUrl);
    const hangfireDashboard = `${baseApiUrl2.protocol}//${baseApiUrl2.hostname}:${baseApiUrl2.port}/scheduler?access_token=${AuthInterceptor.AccessToken}`;

    return hangfireDashboard;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.assessmentId = route.paramMap.get('id');
        this.bundleId = route.queryParamMap.get('bundleId');
        this.returnUrl = route.queryParamMap.get('r');

        if (this.bundleId) {
          this.bundlesService.retrieveBundleAsAssessmentSummaries$(this.bundleId).subscribe((assessments) => {
            this.processAssessments(assessments);
          });
        } else {
          if (this.currentAssessment) {
            this.processAssessments([this.currentAssessment]);
          }
        }
      });

    this.assessmentService.initialisedStatus.subscribe((isInitialised) => {
      if (!isInitialised) {
        // return;
      }

      this.assessmentSelectedSubscription = this.assessmentService.getAssessmentSelectedSubscription().subscribe((currentAssessmentId) => {
        this.assessmentId = currentAssessmentId;
        this.currentAssessment = this.assessmentService.getAssessmentSummary(currentAssessmentId);
        this.assessmentName = this.currentAssessment.Product;
        this.accountId = this.currentAssessment.ContractorId;
      });

      this.assessmentPagesSubscription = this.assessmentService.getSelectedAssessmentPagesSubscription().subscribe((pages) => {
        this.assessmentPages = pages;
      });
      this.currentPageSubscription = this.assessmentService.getCurrentAssessmentPage().subscribe((page) => {
        this.currentAssessmentPage = page;
      });
    });
  }

  private processAssessments(assessments: AssessmentSummary[]): void {
    this.assessments = assessments;
    this.assessmentGroups = Array.from(new Set(this.assessments.map((a) => a.DisplayStatus)));
  }

  public getAssessmentGroup(displayStatus: string): AssessmentSummary[] {
    return this.assessments.filter((a) => a.DisplayStatus === displayStatus);
  }

  public goBack(): void {
    // use until deep-linking is built
    this.location.back();
    this.changeDetector.detectChanges();
  }

  public async returnHome(): Promise<void> {
    const userRoutes = (await firstValueFrom(this.store.select(selectCurrentUserDefaultRoute))) as any;
    const defaultRoute = RoutingMap.get(userRoutes.name);
    await this.router.navigateByUrl(defaultRoute as any);
  }

  public getRouterLinkQueryParams(assessmentId: string): any {
    return this.assessmentService.getRouterLinkQueryParams(this.bundleId, this.assessments, assessmentId, this.returnUrl);
  }

  ngOnDestroy(): void {
    if (this.assessmentSelectedSubscription) {
      this.assessmentSelectedSubscription.unsubscribe();
    }
    if (this.assessmentPagesSubscription) {
      this.assessmentPagesSubscription.unsubscribe();
    }
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
  }

  /** Retrieve user permissions
   * Called inside template, subscription stream managed via async pipe
   * @param permissionRequestId the permissionId requested to be checked against
   */
  public authenticateUserPermissions(permissionRequestId: PermissionsEnum): Observable<any> {
    const permissionId = PermissionsMap.get(permissionRequestId);
    return this.permissionsService.authenticateUser$(permissionId);
  }

  public async logout(): Promise<void> {
    await this.userAuthentication.azureLogout();
  }

  goTo(url: string): void {
    void this.router.navigateByUrl(url);
  }

  selectAssessmentPage(page: AssessmentPageInfo): void {
    this.assessmentService.setCurrentAssessmentPage(page);
  }

  isAssessmentPageSelected(page: AssessmentPageInfo, index: number): boolean {
    if (this.currentAssessmentPage === undefined || this.currentAssessmentPage === null) {
      if (index === 0) {
        return true;
      }
      return false;
    }
    if (page === undefined || page === null) {
      return false;
    }
    return this.currentAssessmentPage.id === page.id;
  }

  onReport(): void {
    window.open('https://chasgroup.sharepoint.com/sites/OperationsMI', '_blank');
  }
}
