import { Environment, IEnvironment } from './environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiRoutesClass {
  private readonly config: IEnvironment;

  constructor() {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  public BaseUrl = () => `${this.config.baseApiUrl}`;
  public CompanySearch = () => `${this.config.baseApiUrl}/companySearch/api`;
  public Users = (): string => `${this.config.baseApiUrl}/currentuser/operations`;
  public Assessments = () => `${this.config.baseApiUrl}/assessments`;
  public Assessors = () => `${this.config.baseApiUrl}/assessors`;
  public DisabledAssessors = () => `${this.config.baseApiUrl}/assessors/GetDisabledAssessorsAsync`;
  public Documents = () => `${this.config.baseApiUrl}/documents`;
  public Diagnostics = () => `${this.config.baseApiUrl}/diagnostics/BuildNumber`;
  public Contacts = () => `${this.config.baseApiUrl}/contacts`;
  public Accounts = () => `${this.config.baseApiUrl}/accounts`;
  public QuestionSets = () => `${this.config.baseApiUrl}/questionsets`;
  public WorkArea = () => `${this.config.baseApiUrl}/worklocations`;
  public WorkCategories = () => `${this.config.baseApiUrl}/workcategories`;
  public Crm = () => `${this.config.baseApiUrl}/crm`;
  public Module = () => `${this.config.baseApiUrl}/module`;
  public ClientModule = () => `${this.config.baseApiUrl}/clientmodules`;
  public Productivity = () => `${this.config.baseApiUrl}/productivity`;
  public FeatureFlag = () => `${this.config.baseApiUrl}/featureflags/section/OperationsPortal/featureFlags`;
  public Notifications = () => `${this.config.baseApiUrl}/notifications`;
  public Profile = () => `${this.config.baseApiUrl}/contractorProfile`;
  public Audit = () => `${this.config.baseApiUrl}/audit`;
  public MyChasEndpoint = () => `${this.config.myChasUrl}`;
  public MyChasPortalApi = () => `${this.config.baseApiUrl}`;
  public Alerts = () => `${this.config.baseApiUrl}/Notifications/documentVerificationsByViewType`;
  public AssignAlert = () => `${this.config.baseApiUrl}/Notifications/assigndocverification`;
  public LaunchCrm = () => `${this.config.crmBaseUrl}`;
  public assessorCosts = () => `${this.config.baseApiUrl}/financial/assessorCosts`;
  public featureFlags = () => `${this.config.baseApiUrl}/feature-flags/all`;
}
