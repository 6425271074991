import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../State';
import { FeatureFlagsLoad, FeatureFlagsLoadError, FeatureFlagsLoadSuccess } from '../actions/feature-flag.actions';
import { FeatureFlag } from '../../services/feature-flags/feature-flag';

export interface State extends AppState.State {
  featureFlags: FeatureFlagState[];
}

export interface FeatureFlagState {
  loaded: boolean;
  enabledFeatures: FeatureFlag[];
}

export const initialState: FeatureFlagState = {
  loaded: false,
  enabledFeatures: [],
};

const _featureFlagReducer = createReducer(
  initialState,
  on(FeatureFlagsLoad, (state: FeatureFlagState) => ({
    ...state,
  })),
  on(FeatureFlagsLoadSuccess, (state, action) => ({
    ...state,
    enabledFeatures: action.data,
    loaded: true,
  })),
  on(FeatureFlagsLoadError, (state, action) => ({
    ...state,
    error: action.error,
  }))
);

export function FeatureFlagReducer(state, action): FeatureFlagState {
  return _featureFlagReducer(state, action);
}
