<div class="panel">
    <div class="panel__content" >
    <div *ngIf="formGroup" [formGroup]="formGroup">
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Company Name</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="companyName" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Trading Name</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="tradingName" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Membership ID</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="membershipId" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>ABN Number</mat-label>
        </div>
                <div  class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="abn" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>ACN Number</mat-label>
        </div>
                <div  class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="acn" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label">
          <mat-label>Website</mat-label>
        </div>
        <div class="form-field__input">
          <div>
            <mat-form-field appearance="fill">
                            <input formControlName="website" matInput [readonly]="!editing"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel__footer" *ngIf="displayActionButtons">
    <div class="panel__footer" *ngIf="displayActionButtons">
      <app-panel-action-buttons
        (buttonStateEmitter)="editing = $event"
        [loading]="loading"
        (submitEmitter)="submit()[editing] = editing"
        [editState]="editing"
      ></app-panel-action-buttons>
    </div>
    <div *ngIf="loading" class="panel__loading">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div>
