import { createReducer, on } from "@ngrx/store";
import { Assessment } from "src/app/shared/models/assessment";
import * as AppState from "../../../State";
import {
  ProductAssessmentsTableLoad,
  ProductAssessmentsTableLoadError,
  ProductAssessmentsTableLoadSuccess,
} from "../actions/productAssessmentsTable.actions";

export interface State extends AppState.State {
  assessments: Assessment[];
}

export interface ProductAssessmentsTableState {
  assessments: Assessment[];
  loading: boolean;
  error: string;
}

const initialState: ProductAssessmentsTableState = {
  assessments: undefined,
  loading: true,
  error: "",
};

const _productAssessmentsTableReducer = createReducer(
  initialState,
  on(ProductAssessmentsTableLoad, (state, action) => ({
    ...state,
    loading: true,
    error: "",
  })),
  on(
    ProductAssessmentsTableLoadSuccess,
    (state, action): ProductAssessmentsTableState => ({
      ...state,
      loading: false,
      error: "",
      assessments: action.assessments,
    })
  ),
  on(
    ProductAssessmentsTableLoadError,
    (state, action): ProductAssessmentsTableState => ({
      ...state,
      error: action.error,
    })
  )
);

export function ProductAssessmentsTableReducer(state, action) {
  return _productAssessmentsTableReducer(state, action);
}
