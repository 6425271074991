<div class="progress-card">
  <div class="progress-card-header">
    <div class="progress-card-label">
      <i aria-hidden="true" class="fa {{ headerIcon }} mr-3"></i> {{ header }}
    </div>
  </div>
  <div class="progress-card-body">
    <ng-container *ngFor="let row of stats">
      <div
        (click)="onRowClicked(row)"
        [class.selected]="row.label == this.getSelected()"
        class="col {{ row.cssClass }} number-panel"
      >
        <div>
          <p class="row-value">{{ row.value }}</p>
          <br />
          <p class="row-label">{{ row.label }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
