import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { WorkCategory } from 'src/app/shared/models/Work-Category';
import { getWorkCategoriesLoadingSelector, getWorkCategoriesSelector } from 'src/app/core/ngrx/selectors/WorkCategories.selectors';
import { WorkCategoriesActions } from 'src/app/core/ngrx/actions/WorkCategory.actions';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-work-categories-table',
  templateUrl: './work-categories-table.component.html',
  styleUrls: ['./work-categories-table.component.scss'],
})
export class WorkCategoriesTableComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<WorkCategory>;
  displayedColumns: string[] = ['name', 'workCode', 'Actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchKey: string;

  ProductCurrentMembershipTableSub: Subscription;
  isLoading$ = this.store.select(getWorkCategoriesLoadingSelector);
  selectedRows = [];

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.ProductCurrentMembershipTableSub = this.store.select(getWorkCategoriesSelector).subscribe((t) => {
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(t);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
    this.store.dispatch({
      type: WorkCategoriesActions.Load,
      accountId: this.route.snapshot.params.id,
    });
  }

  ngOnDestroy(): void {
    if (this.ProductCurrentMembershipTableSub) {
      this.ProductCurrentMembershipTableSub.unsubscribe();
    }
  }

  get readOnly() {
    return true;
  }

  onSelect({ selected }): void {
    this.selectedRows = selected;
  }

  OnEdit(id: string): void {
    // this.assessmentService.selectAssessment(assessmentId);
    this.router.navigate([`/assessment/editWorkCategory/${id}`]);
  }

  onDelete(id: string): void {
    // this.assessmentService.selectAssessment(assessmentId);
    //this.router.navigate([`/assessment/editModule/${assessmentId}`]);
  }

  onSearchClear(): void {
    this.searchKey = '';
  }

  applyFilter(): void {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
