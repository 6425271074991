import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentUserPermissions } from '../../ngrx/selectors/currentUser.selectors';
import { Observable, firstValueFrom, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationPermission } from '../../../shared/models/application-permission';
import { PermissionsMap } from 'src/app/shared/maps/permissions-map';
import { PermissionsEnum } from 'src/app/shared/enums/permissions-enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private readonly store$: Store) {}

  /**
   * Maps through user permissions from the store
   * Returns true/false observable based on whether the permission has been found
   * @param applicationPermissionRequestId
   */
  public authenticateUser$(applicationPermissionRequestId): Observable<boolean> {
    return this.store$.select(selectCurrentUserPermissions).pipe(
      map((permissions: any) => {
        return !!permissions.find(
          (applicationPermission: ApplicationPermission) => applicationPermission.id === applicationPermissionRequestId
        );
      }),
      take(1)
    );
  }

  public async hasPermission(permission: PermissionsEnum): Promise<boolean> {
    const permissionGuid = PermissionsMap.get(permission);

    return await firstValueFrom(this.authenticateUser$(permissionGuid));
  }
}
