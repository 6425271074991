<div class="modal-header">
  <h4 class="modal-title pull-left">Further Information Request</h4>
  <button (click)="cancel()" aria-label="Close" class="close pull-right" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="furtherInfoResponses.length > 0">
    You are about to request the following further information...
  </p>

  <div *ngFor="let response of furtherInfoResponses" [class.has-error]="response.sectionCompliant"
    class="further-info-modal-section">
    <div class="info-heading">{{ response.section }}</div>
    <div class="info-response">{{ response.value }}</div>
    <div *ngIf="response.sectionCompliant" class="info-compliance">
      This section is marked as 'Compliant' and therefore will not be shown to
      the contractor.
    </div>
  </div>

  <div *ngIf="furtherInfoResponses.length === 0">
    <p class="text-danger">
      No new further information details have been provided.
    </p>
    <p>Please edit any Further Information boxes to request new information.</p>
  </div>

  <div *ngIf="furtherInfoResponses.length > 0 && (validatePermissions$() | async)">
    <label for="responseByField">Response required by: </label>
    <input [(ngModel)]="responseBy" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [minDate]="today" bsDatepicker
      class="form-control col-5" id="responseByField" placeholder="Datepicker" />
  </div>
  <div class="mt-3">
    <button [disabled]="furtherInfoResponses.length <= 0" (click)="modalConfirmFurtherInfo()" class="btn btn-primary mr-2" type="button">
      Continue
    </button>
    <button (click)="cancel()" class="btn btn-secondary" type="button">
      Cancel
    </button>
    <span *ngIf="furtherInfoHasError" class="text-danger ml-2">
      Please correct the above errors
    </span>
  </div>
</div>