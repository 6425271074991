import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ModuleTableState } from "../reducers/ModuleTable.reducer";

export const selectModuleTableFeatureState =
  createFeatureSelector<ModuleTableState>("moduleTable");

export const getModuleTableSelector = createSelector(
  selectModuleTableFeatureState,
  (state) => state.modules
);

export const getModuleTableLoadingSelector = createSelector(
  selectModuleTableFeatureState,
  (state) => state.loading
);
