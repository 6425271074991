import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectOptionModel } from "./model/select-option-model";

@Component({
  selector: "app-single-option-select-field",
  templateUrl: "./single-option-select-field.component.html",
  styleUrls: ["./single-option-select-field.component.scss"],
})
export class SingleOptionSelectFieldComponent implements OnInit {
  constructor() {}

  @Input() name: string;
  @Input() options: SelectOptionModel[];
  @Output() selectChange: EventEmitter<SelectOptionModel> = new EventEmitter();
  selectedOption: SelectOptionModel;
  default: SelectOptionModel = {
    name: "Select Assessor",
    value: "default",
  };

  ngOnInit(): void {
    this.options = [];
    this.selectedOption = null;
  }

  getSelectedOption(): SelectOptionModel {
    return this.selectedOption;
  }

  get value(): any {
    return this.selectedOption.value;
  }

  onChange(newValue: SelectOptionModel): void {
    this.selectChange.emit(newValue);
  }

  get assessorName(): string {
    return this.selectedOption.name;
  }

  setSelection(assessorId: string): void {
    if (assessorId !== null) {
      const assessors = this.options.filter((x) => x.value === assessorId);
      if (assessors.length < 1) {
        this.selectedOption = this.default;
      } else {
        this.selectedOption = assessors[0];
      }
    } else {
      this.selectedOption = this.default;
    }
  }
}
