import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectContractorAccountBusinessSummary,
  selectContractorAccountWorkCategories,
} from '../../../../core/ngrx/selectors/contractor-account.selectors';

@Component({
  selector: 'app-work-areas-tab',
  templateUrl: './work-areas-tab.component.html',
  styleUrls: ['./work-areas-tab.component.scss', '../../panels/panel-abstract/panel-abstract.component.scss'],
})
export class WorkAreasTabComponent {
  public businessDescription$ = this.store.select(selectContractorAccountBusinessSummary);
  public primaryWorkCategory$ = this.store.select(selectContractorAccountWorkCategories);
  constructor(private readonly store: Store) {}
}
