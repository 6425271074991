import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Observable } from 'rxjs';
import { CurrentUserState } from '../../ngrx/reducers/CurrentUser.reducer';
import { ContactRole, UserRolePrivilege } from 'src/app/shared';
import { selectCurrentUserFeatureState } from '../../ngrx/selectors/currentUser.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  private readonly data$: Observable<CurrentUserState> = this.store.select(selectCurrentUserFeatureState);
  private roles: ContactRole[];
  private rolesCache: Map<string, ContactRole>;

  constructor(private readonly store: Store) {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.roles = data.roles;
      }
    });
  }

  public getAll(): ContactRole[] {
    return this.roles;
  }

  public async privilegeType(name: string): Promise<string> {
    const role = await this.findRole(name);
    return role.privilege;
  }

  public async hasPrivilege(name: string): Promise<boolean> {
    const role = await this.findRole(name);
    return role.privilege === UserRolePrivilege.NO_ACCESS || role.privilege === UserRolePrivilege.VIEW_ONLY;
  }

  private async findRole(name: string): Promise<ContactRole> {
    const data: CurrentUserState = await lastValueFrom(this.data$);
    const roles: ContactRole[] = data.roles;
    const role = roles.find((f) => f.name === name);

    return role;
  }
}
