import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { Product } from "../../../shared/models/product";
import { HttpClient } from "@angular/common/http";
import { AccountSelector } from "../account-selection/account-selector";
import { Environment, IEnvironment } from "src/app/shared/classes/environment";

enum ProductOptions {
  HealthAndSafety = "Health & Safety",
  Environmental = "Environmental",
}

export const testSubject = new Subject();

// @ts-ignore
@Injectable({
  providedIn: "root",
})
export class NewSearchService implements OnDestroy {
  CurrentAccount: any;
  private config: IEnvironment;

  constructor(
    private http: HttpClient,
    private accountSelectorService: AccountSelector
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
    this.accountSelectorService.getSubject().subscribe((x) => {
      this.CurrentAccount = x.id;
    });
  }

  ngOnDestroy(): void {
    this.CurrentAccount.unsubscribe();
  }

  getProducts(): Observable<Product[]> {
    return of([
      {
        id: ProductOptions.HealthAndSafety,
        name: ProductOptions.HealthAndSafety,
      } as Product,
      {
        id: ProductOptions.Environmental,
        name: ProductOptions.Environmental,
      } as Product,
    ]);
  }
}
