import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ImsAndinsuranceLoad, ImsAndinsuranceLoadError, ImsAndinsuranceLoadSuccess } from '../actions/imsAndinsurance.actions';
import { AccountService } from '../../services/account/account.service';

@Injectable()
export class ImsAndinsuranceEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImsAndinsuranceLoad),
      mergeMap((action) =>
        this.accountService.getInsurance(action.accountId).pipe(
          map((b) => ImsAndinsuranceLoadSuccess({ ImsAndinsurance: b })),
          catchError((error) => of(ImsAndinsuranceLoadError({ error: error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private accountService: AccountService) {}
}
