import {Component, Input} from '@angular/core';
import {DataTableAbstract} from "../../../shared/abstract-classes/data-table-abstract/data-table-abstract";
import {ApiRequestTypes} from "../../../core/ngrx/actions/MultigridAssessmentViewType.actions";
import {MultigridDashboardButton} from "../../../core/ngrx/actions/MultigridDashboardButton.actions";
import {UIFilter} from "../../../shared/models/ui-filter";
import {DataRequestTypeEnum} from "../../../shared/enums/data-request-type-enum";
import {AlertsApiRequestTypes, AlertsService} from "../../../core/services/alerts/alerts.service";
import {MatDialog} from "@angular/material/dialog";
import {SearchService} from "../../../core/services/search/search.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AssessmentService} from "../../../core/services/assessment/assessment.service";
import {ActionsSubject, Store} from "@ngrx/store";
import {PermissionsService} from "../../../core/services/permissions/permissions.service";
import {AccountService} from "../../../core/services/account/account.service";
import {BundlesService} from "../../../core/services/bundles/bundles.service";
import {TrackingEventService} from "../../../core/services/tracking-event-service/tracking-event.service";

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent extends DataTableAbstract {
  @Input() tableName: string = '';
  @Input() apiRequestType: ApiRequestTypes;
  @Input() enabledButtons: MultigridDashboardButton[];
  @Input() enableRowClick: boolean;
  @Input() uiFilter: UIFilter[];
  @Input() dataRequestType: DataRequestTypeEnum | AlertsApiRequestTypes;
  @Input() displayedColumns;
  @Input() displayAssignButton: boolean;
  @Input() columnNames: Array<any>;

  constructor(
      protected dialog: MatDialog,
      protected searchService: SearchService,
      protected router: Router,
      protected assessmentService: AssessmentService,
      protected store: Store,
      protected permissionsService: PermissionsService,
      protected actions: ActionsSubject,
      protected accountService: AccountService,
      protected bundleService: BundlesService,
      protected alertsService: AlertsService,
      protected activatedRoute: ActivatedRoute,
      protected trackingService: TrackingEventService
  ) {
    super(
        dialog,
        searchService,
        router,
        assessmentService,
        store,
        permissionsService,
        actions,
        accountService,
        bundleService,
        alertsService,
        activatedRoute
    );
  }

  async ngOnInit(): Promise<void> {
    await this.retrieveDataRequest(this.uiFilter, this.apiRequestType);
    super.ngOnInit();
  }
}
