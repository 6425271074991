import { Component, OnInit } from '@angular/core';
import {AccountHttpService} from "../../../../core/services/account/account-http.service";
import {firstValueFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {CompanyInformation, Contact} from "../../../../core/open-api";

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss']
})
export class ClientContactsComponent implements OnInit {

  public isLoading = true;
  public clientId = this.route.snapshot.paramMap.get("clientId");
  public displayedColumns: string[];
  public dataSource: Contact[];
  public client: CompanyInformation;

  constructor(private readonly accountService: AccountHttpService,
              private readonly route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.dataSource = await firstValueFrom(this.accountService.getContacts(this.clientId));
    this.client = await firstValueFrom(this.accountService.getAccountInformation(this.clientId));
    this.displayedColumns = [ 'fullName', 'email', 'mobilePhone', 'position', 'actions' ];
    this.isLoading = false;
  }
}
