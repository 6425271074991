import { createAction, props } from '@ngrx/store';
import { AccountAddress } from '../../../shared/models/company-details';
import { WorkArea } from '../../../shared';
import { Contact } from '../../open-api/model/contact.model';

export interface CompanySummary1State {
  registerAddress: AccountAddress;
  correspondenceAddress: AccountAddress;
  organisationType: string;
  companyStatus: string;
  healthSafetyAssessmentType: string;
  healthSafetyApplicationType: string;
  immediateParentCompany: string;
  CustomerType: string;
  previousBusinessName: string;
  workAreas: WorkArea;
}

export interface CompanySummary2State {
  printPack: string;
  charityDonationAmount: number;
  numberofDirectEmployees: number;
  labourOnlySubcontractors: number;
  totalEffectiveNoofEmployees: number;
  bonafideSubContractors: number;
  turnover: number;
  website: string;
  businessSummary: string;
  howdidYouHearAboutCHAS: string;
}

export interface CompanySummary3State {
  removeFromFeefo: string;
  charityRegNumber: string;
  vatRegNumber: string;
  cASDataSharing: string;
  thirdPartyCASProvider: string;
  thirdPartyMembershipID: string;
  dateCASDataUpdated: string;
  termsAndConditions: string;
}

export interface CompanyInformationState {
  companyName: string;
  tradingAs: string;
  companyHouseNo: string;
  membershipId: string;
}

export interface CompanyTimelineState {
  subject: string;
  type: string;
  createdBy: string;
  description: string;
  createdOn: Date;
  apiType: string;
}

export type PurchaseHistory = {
  type: string; // Chas Australia Quote Request
  bundleName: string;
  membershipExpiry: Date;
  estimatedRevenue: number;
  paymentAmount: number;
  paymentReceiptNumber: string;
  paymentDate: Date;
  creationDate: Date;
};

export enum ContractorAccountActions {
  Load = '[ContractorAccount] Load',
  LoadSuccess = '[ContractorAccount] Load Successful',
  LoadError = '[ContractorAccount] Load Error',
  Save = '[ContractorAccount] Save',
  RefreshTimeline = '[Contractor Account] Refresh Timeline',
  RefreshContractorAccountTimelineSuccess = '[Contractor Account] Refresh Timeline Successful',
  SaveSuccess = '[Assessor] Save Success',
  SaveError = '[Assessor] Save Error',
  UpdateIndex = '[ContractorAccount] Update tab Index',
  RefreshContacts = '[Contact List] Refresh Contacts',
  RefreshContactsSuccess = '[Contact List] Refresh Contacts Success',
  RefreshContactsFailure = '[Contact List] Refresh Contacts Failure',
}

export const ContractorAccountLoad = createAction(ContractorAccountActions.Load, props<{ accountId: string }>());

export const ContractorAccountLoadSuccess = createAction(
  ContractorAccountActions.LoadSuccess,
  props<{
    summary;
    contact;
    address;
    membership;
    companyDetails;
    workRegions;
    employee;
    accountId;
    timeline;
    workCategories;
    company;
    insurance;
    distanceToOffice;
  }>()
);

export const RefreshContractorAccountTimeline = createAction(ContractorAccountActions.RefreshTimeline, props<{ accountId: string }>());
export const RefreshContractorAccountTimelineSuccess = createAction(
  ContractorAccountActions.RefreshContractorAccountTimelineSuccess,
  props<{ timeline: any }>()
);
export const UpdateTabIndex = createAction(ContractorAccountActions.UpdateIndex, props<{ tabIndex: number }>());
export const ContractorAccountLoadError = createAction(ContractorAccountActions.LoadError, props<{ error: Error }>());

export const RefreshContacts = createAction(ContractorAccountActions.RefreshContacts, props<{ accountId: string }>());
export const RefreshContactsSuccess = createAction(ContractorAccountActions.RefreshContactsSuccess, props<{ contacts: Contact[] }>());
export const RefreshContactsFailure = createAction(ContractorAccountActions.RefreshContactsFailure, props<{ error: Error }>());
