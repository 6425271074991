<div mat-dialog-content>
  <p>{{header}}</p>
  <mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select [(ngModel)]="selectedValue">
      <mat-option *ngFor="let item of items" [value]="item.id">
        {{ item.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancelClick()">Cancel</button>
  <button mat-button (click)="assignClick()" [mat-dialog-close]="data.selectedValue" cdkFocusInitial>
    Assign
  </button>
</div>