<div *ngIf="isLoading; else elseBlock" class="loading-spinner_container">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #elseBlock>
  <div class="assessment-container">
    <div class="row top-row mb-5">
      <div class="col-md-4">
        <h1 class="h2">Documents</h1>
      </div>
      <div class="col-md-4" *ngIf="areAnyDocumentsPresent">
        <button
          id="downloadAllDocuments"
          (click)="downloadAllDocuments()"
          class="btn btn-link"
        >
          Download All Documents
        </button>
      </div>
      <div class="col-md-4">
        <div class="input-group float-md-right">
          <input
            id="DocumentSearch"
            type="text"
            #documentSearch
            class="form-control"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder="search for document"
            (keyup.enter)="runDocumentSearch(documentSearch.value)"
          />
          <div class="input-group-append">
            <button
              id="searchExecute"
              type="button"
              class="btn btn-primary"
              (click)="runDocumentSearch(documentSearch.value)"
            >
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <tabset>
        <tab
          heading="Current Application(s) Documents"
          customClass="headingCss"
          (selectTab)="onTabSelected($event)"
        >
          <div class="pt-3">
            <ngx-datatable
              [rows]="filteredCurrentDocuments"
              [columns]="columns"
              columnMode="force"
              rowHeight="auto"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loading"
              [limit]="15"
              [scrollbarH]="true"
              class="material"
            >
              <ngx-datatable-column name="Product" prop="product">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.product }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Document Type" prop="type">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.type }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="File Name" prop="name">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Expiry Date" prop="expiryDate">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.expiryDate | date: "dd/MM/yyyy" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Date Uploaded" prop="uploadDate">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.uploadDate | date: "dd/MM/yyyy" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Download">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  <button class="btn btn-primary" (click)="downloadFile(row)">
                    Download
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </tab>
        <tab
          heading="Old Application(s) Documents"
          customClass="headingCss"
          (selectTab)="onTabSelected($event)"
        >
          <div class="pt-3">
            <ngx-datatable
              [rows]="filteredPreviousDocuments"
              [columns]="columns"
              columnMode="force"
              rowHeight="auto"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loading"
              [limit]="15"
              [scrollbarH]="true"
              class="material"
            >
              <ngx-datatable-column name="Product" prop="product">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.product }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Document Type" prop="type">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.type }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="File Name" prop="name">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Expiry Date" prop="expiryDate">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.expiryDate | date: "dd/MM/yyyy" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Date Uploaded" prop="uploadDate">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  {{ row.uploadDate | date: "dd/MM/yyyy" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Download">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  <button class="btn btn-primary" (click)="downloadFile(row)">
                    Download
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</ng-template>
