import { Component, Input } from '@angular/core';
import { Environment, IEnvironment } from '../../classes/environment';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent {
  @Input() public buildVersion: string;
  private readonly config: IEnvironment;
  public currentYear: string;
  public email: string;
  public telephone: string;
  public versionNumber: string;

  constructor() {
    const env = new Environment();
    this.config = env.getConfigSync();
    this.email = this.config.siteFooter.email;
    this.currentYear = new Date().getFullYear().toString();
    this.telephone = this.config.siteFooter?.telephone;
    this.versionNumber = this.config.buildNumber;
  }
}
